import React from 'react';
import Logo from '../../Assets/Images/cautiousLogo.svg'
import './plancardError.css';

const PlancardError = ({ quotesContent }) => {

    if (quotesContent.length > 0) {
        return (
            <div className='planError_container'>
                <div className='planErrorBox1'>
                    <div className='casutionbox'>
                        <img src={Logo} alt='logo' />
                        <span>Quotation Not Found!</span>
                    </div>
                    <div id='card_Content'>
                        We did not receive quotes from the following insurers due to the following reasons.
                    </div>
                    <div className='LogoofCom'>
                        {quotesContent?.map((e) => {
                            return (
                                <>
                                    <img src={e.companyImg} key={e.id} alt={e.companyName} />
                                </>
                            )
                        })}
                    </div>
                </div>
                <div className='planErrorBox2'>
                    <div>
                        Following are the possible reasons:
                    </div>
                    <div className='List_Data'>
                        <ul class="numbered-list">
                            <li>Selected Vehicle is not mapped with insurer.</li>
                            <li>Insurance Plans are not available for selected combination.</li>
                            <li>Insurer is not reachable to provide the line quotes currently.</li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    } else {
        return ""
    }
}

export default PlancardError;
