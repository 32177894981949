import { createSlice } from "@reduxjs/toolkit";

export const inspectionReducer = createSlice({
    name: "inspectDetails",
    initialState: {
        data: {
            "isInspectionReq": false
        }
    },
    reducers: {
        isInitialAction: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { isInitialAction } = inspectionReducer.actions;
