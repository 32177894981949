import React from 'react'
import tick from "../../../../Assets/Images/success.svg";
import { values } from 'pdf-lib';

const ActivePolicy = (props) => {

    const { policyDetails, handleDownloadPolicy, ClinetPolicyActiveData, car, FormValue, coverages } = props;
    let IDvvalues = JSON.parse(ClinetPolicyActiveData[0]['policy_breakup'])
    console.log(IDvvalues, "ClinetPolicyActiveDataClinetPolicyActiveData");

    const pdfUrl = 'https://drive.google.com/file/d/1RtSFbtIkwfS1uZnghhyMSjvvDs_1FRmD/view?usp=sharing'
    return (
        <div className="ProfileCard-container">
            {ClinetPolicyActiveData?.map((data, key) => {
                let IDvvaluesandCover = JSON.parse(data['policy_breakup'])

                IDvvaluesandCover?.addons.map((e) => {
                    console.log("sfjdkfjsakfj")
                })

                return (
                    <section className="NoPoliciesFound-container PoliciesFound-container ">
                        <div className="product-info-container">
                            <div className="product-info-left-container">
                                <div className="product-icon-container">
                                    <img src={car} alt="" srcSet="" />
                                </div>
                                <div className="product-insurer-container">
                                    <p>
                                        {data.policy_number} - {data.insured_product_type}
                                    </p>
                                    <p>{data.insurer_name}</p>
                                </div>
                            </div>

                            <div className="product-info-right-container">
                                <p>
                                    <span>Start:</span> {data.policy_start_date}
                                </p>
                                <p>
                                    <span>End:</span> {data.policy_end_date}
                                </p>
                            </div>
                        </div>

                        <div className="seprator-policyCard"></div>

                        <div className="vehicleInfo-policyCard ">
                            <p className="textWithKey">
                                <span>Name: </span>
                                {FormValue?.fullName}
                            </p>
                            <p className="textWithKey">
                                <span>Car Number: </span> {data?.vechileNumber ?? "NEW"}
                            </p>
                            <p className="textWithKey">
                                <span>IDV Value: </span> {IDvvalues?.values[0].idv_amount}
                            </p>
                        </div>

                        <div className="breakup-contianer-policyCard">

                            <div className="breakup-contianer-part-policyCard">

                                {
                                    IDvvaluesandCover?.addons?.map((e, key) => {
                                        if (key <= 2) {
                                            console.log(e.display_name, 'e.display_name')
                                            return (
                                                <div className="breakup-contianer-part-left-policyCard">
                                                    <div className="txt-with-tick-policycard">
                                                        <p className="textWithKey textWithKey-bold">
                                                            <span>{e.display_name}</span> {e?.price?.gross_premium}
                                                        </p>
                                                        <img src={tick} alt="" srcSet="" />
                                                    </div>
                                                </div>
                                            )
                                        }


                                    })

                                }

                            </div>
                            <div className="breakup-contianer-part-policyCard">
                                <div className="breakup-contianer-part-left-policyCard">
                                    {IDvvaluesandCover?.addons?.map((data, key) => key > 2 && key <= 5 ? (
                                        <div key={key} className="txt-with-tick-policycard">
                                            <p className="textWithKey textWithKey-bold">
                                                <span>{data.display_name}</span> {data?.price?.gross_premium}
                                            </p>
                                            <img src={tick} alt="" srcSet="" />
                                        </div>
                                    ) : (
                                        ""
                                    )
                                    )}
                                </div>
                            </div>

                            <div className="breakup-contianer-part-policyCard">
                                {IDvvaluesandCover?.addons?.map((data) => { })}
                                <div className="breakup-contianer-part-left-policyCard">
                                    {IDvvaluesandCover?.addons?.map((data, key) => {
                                        if (key > 5) {
                                            return (
                                                <div className="txt-with-tick-policycard" >
                                                    <p className="textWithKey textWithKey-bold">
                                                        <span>{data.display_name}</span> {data?.price?.gross_premium}
                                                    </p>
                                                    <img src={tick} alt="" srcSet="" />
                                                </div>
                                            )
                                        }


                                    })}

                                </div>
                            </div>
                        </div>
                        <div className="bottom-tabs-option-policyCard-container">
                            <div className="bottom-tabs-option-policyCard">
                                <a
                                    href={data?.policy_pdf_url}
                                    download="fileName555555555.pdf"
                                    target="_blank"
                                >
                                    <div
                                        className="tabs-option-policyCard"
                                        onClick={(e) => handleDownloadPolicy(data.policy_pdf_url)}
                                    >
                                        <p>Download Policy</p>
                                    </div>
                                </a>


                            </div>
                            <div className="bottom-tabs-option-policyCard">
                                <a
                                    href={data?.kyc_url}
                                    download
                                    target="_blank"

                                >
                                    <div
                                        className="tabs-option-policyCard"
                                        onClick={(e) => handleDownloadPolicy(data.kyc_url)}
                                    >

                                        <p>Kyc Link</p>
                                    </div>
                                </a>

                            </div>
                        </div>


                    </section>
                )


            })}
        </div>
    )
}

export default ActivePolicy
