import React from 'react'
import InputFields from '../../../InputFields/InputFields';
import Botton from '../../../Button/Button';
import RadioButton from '../../../RadioButton/RadioButton';
import SearchSelect from '../../../DropDown/Search Select/SearchSelect';

const UploadDocument = (props) => {

    const {
        ClaimFormValues,
        handleFormValue,
        toggleColapsible,
        handleInsurerSelect,
    } = props;

    return (
        <>

            {/* <div className="inputGropOne_form inputGrop inputGrop-profile ">
                <div className="inputGropOne_form inputGrop inputGropKyc-custom ">
                    <p>Upload Document</p>
                    <input
                        type="file"
                        className="hideRealUploadBtn"
                        id="uploadImg-Aadhar"
                        accept="image/*, application/pdf"
                        name="userUploadedDocument"
                        onChange={(e) => handleInputChange(e)}
                        hidden
                    />
                    <label className="customLabel-kyc" htmlFor="uploadImg-Aadhar">
                        <p className="btn-kyc-">upload</p>
                    </label>
                </div>
                <p>File cannot be more then 1MB. (JPG or PNG)</p>
            </div> */}

            <form className="formSection-pform  formSection-claimIntimation" action="" >

                <div className="input_group_pform">
                    <div className="inputGropOne_form inputGrop inputGrop-profile ">
                        <div className="inputGropOne_form inputGrop inputGropKyc-custom ">
                            <p>Upload Document</p>
                            <input
                                type="file"
                                className="hideRealUploadBtn"
                                id="uploadImg-Aadhar"
                                accept="image/*, application/pdf"
                                name="userUploadedDocument"
                                // onChange={(e) => handleInputChange(e)}
                                hidden
                            />
                            <label className="customLabel-kyc" htmlFor="uploadImg-Aadhar">
                                <p className="btn-kyc-">upload</p>
                            </label>
                        </div>
                        <p>File cannot be more then 1MB. (JPG or PNG)</p>
                    </div>
                    <div className="inputGropOne_form inputGrop inputGrop-profile ">
                        <div className="inputGropOne_form inputGrop inputGropKyc-custom ">
                            <p>Upload Document</p>
                            <input
                                type="file"
                                className="hideRealUploadBtn"
                                id="uploadImg-Aadhar"
                                accept="image/*, application/pdf"
                                name="userUploadedDocument"
                                // onChange={(e) => handleInputChange(e)}
                                hidden
                            />
                            <label className="customLabel-kyc" htmlFor="uploadImg-Aadhar">
                                <p className="btn-kyc-">upload</p>
                            </label>
                        </div>
                        <p>File cannot be more then 1MB. (JPG or PNG)</p>
                    </div>
                </div>

                <div className="input_group_pform">
                    <div className="inputGropOne_form inputGrop ">
                        <p className="customLabel_pform">
                            Do you have accident spot photos
                        </p>
                        <div className="radioContainer-pform">
                            <RadioButton
                                label="Yes"
                                className=""
                                id="carLoanYes"
                                groupName="carIsInLoan"
                                value="Yes"
                                changeEvent={(e) => handleFormValue(e)}
                            />
                            <RadioButton
                                label="No"
                                className=""
                                id="carLoanNo"
                                groupName="carIsInLoan"
                                value="No"
                                changeEvent={(e) => handleFormValue(e)}
                            />
                        </div>
                    </div>
                    <div className="inputGropOne_form inputGrop inputGrop-profile ">
                        <div className="inputGropOne_form inputGrop inputGropKyc-custom ">
                            <p>Upload Document</p>
                            <input
                                type="file"
                                className="hideRealUploadBtn"
                                id="uploadImg-Aadhar"
                                accept="image/*, application/pdf"
                                name="userUploadedDocument"
                                // onChange={(e) => handleInputChange(e)}
                                hidden
                            />
                            <label className="customLabel-kyc" htmlFor="uploadImg-Aadhar">
                                <p className="btn-kyc-">upload</p>
                            </label>
                        </div>
                        <p>File cannot be more then 1MB. (JPG or PNG)</p>
                    </div>
                </div>

                <section className="btnContainer_pform">
                    <Botton
                        text="Cancel"
                        className="button btn_pForm  secondry_btn"
                        event={(e) => console.log("documentDetails")}
                    />
                    <Botton
                        text="Save"
                        className="button btn_pForm "
                        event={(e) => toggleColapsible("documentDetails")}
                    />
                </section>

            </form>

        </>
    )
}

export default UploadDocument
