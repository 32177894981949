import { createAsyncThunk } from "@reduxjs/toolkit";

// Production URL get vehicle details
export const commonVehicleFetchApiAction = createAsyncThunk(
  "fetchVehicleDetails",
  async (regNo) => {
    const response = await fetch(
      `${process.env.REACT_APP_GET_VEHICLE_DETAILS_URL}?regNo=${regNo}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );

    return response.json();
  }

);


export const common2WVehicleFetchApiAction = createAsyncThunk(
  "fetchVehicleDetails",
  async (regNo) => {
    const response = await fetch(
      `${process.env.REACT_APP_GET_VEHICLE_DETAILS_URL}?regNo=${regNo}&vehicleType=2W`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);