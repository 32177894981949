import {
    hdfcQuickQuoteAction,
    hdfcProposalAction,
    hdfcKycAuto,
    hdfcChecksumAction,
    hdfcPdfDownloadAction,
    hdfcProposalDocument
} from "../Actions/hdfcApiAction";
import { createSlice } from "@reduxjs/toolkit";

export const hdfcQuickQuoteReducer = createSlice({
    name: "hdfcQuikQuotes",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(hdfcQuickQuoteAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(hdfcQuickQuoteAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(hdfcQuickQuoteAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resethdfcQuoteAction: (state, action) => {
            state.data = undefined
        }
    }
});

export const { resethdfcQuoteAction } = hdfcQuickQuoteReducer.actions;

export const hdfcKycAutoReducer = createSlice({
    name: "hdfcKycAutoReducer",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(hdfcKycAuto.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(hdfcKycAuto.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(hdfcKycAuto.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resethdfcKycAutoAction: (state, action) => {
            state.data = undefined
        },
        addhdfcAutoKycDataAction: (state, action) => {
            state.data = action.payload;
        },

    }
});

export const { resethdfcKycAutoAction, addhdfcAutoKycDataAction } = hdfcKycAutoReducer.actions;

export const hdfcProposalReducer = createSlice({
    name: "hdfcProposal",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    reducers: {
        resethdfcProposalAction: (state) => {
            state.isLoading = false;
            state.data = null;
            state.isError = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(hdfcProposalAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(hdfcProposalAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(hdfcProposalAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
});
export const { resethdfcProposalAction } = hdfcProposalReducer.actions;

export const hdfcChecksumReducer = createSlice({
    name: "hdfcChecksum",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    reducers: {
        resethdfcChecksum: (state) => {
            state.isLoading = false;
            state.data = null;
            state.isError = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(hdfcChecksumAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(hdfcChecksumAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(hdfcChecksumAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resethdfcChecksum: (state, action) => {
            state.data = undefined
        },
    }
});

export const { resethdfcChecksum } = hdfcChecksumReducer.actions;

export const hdfcpdfDownloadReducer = createSlice({
    name: "hdfcpdfDownload",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    reducers: {
        resethdfcpdfDownload: (state) => {
            state.isLoading = false;
            state.data = null;
            state.isError = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(hdfcPdfDownloadAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(hdfcPdfDownloadAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(hdfcPdfDownloadAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resetHdfcpdfDownload: (state, action) => {
            state.data = undefined
        },
    }
});

export const { resetHdfcpdfDownload } = hdfcpdfDownloadReducer.actions;

export const hdfcManualKycReducer = createSlice({
    name: "hdfcManualKycReducer",
    initialState: {},
    reducers: {
        addhdfcManualKycDataAction: (state, action) => {
            state.data = action.payload;
        },
        resethdfcManualKycAction: (state, action) => {
            state.data = undefined
        }
    },
});

export const { addhdfcManualKycDataAction, resethdfcManualKycAction } = hdfcManualKycReducer.actions;

export const hdfcProposalDocumentReducer = createSlice({
    name: "hdfProposalDownload",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    reducers: {
        resethdfcProposalDownload: (state) => {
            state.isLoading = false;
            state.data = null;
            state.isError = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(hdfcProposalDocument.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(hdfcProposalDocument.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(hdfcProposalDocument.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resetHdfcProposalDownload: (state, action) => {
            state.data = undefined
        },
    }
});

export const { resetHdfcProposalDownload } = hdfcProposalDocumentReducer.actions;

