import { sbiQuickQuoteAction, sbiKyc, sbiKycAuto, sbiFullQuoteAction, sbiPolicyGenerateAction, sbiPdfDownloadAction } from "../Actions/sbiApiAction";

import { createSlice } from "@reduxjs/toolkit";

export const sbiQuickQuoteReducer = createSlice({
  name: "sbiQuickQuoteReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(sbiQuickQuoteAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(sbiQuickQuoteAction.fulfilled, (state, action) => {
      state.isLoading = false;
      if (!action.payload?.body?.data?.quote?.code) {
        state.data = action.payload
      }
    });
    builder.addCase(sbiQuickQuoteAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetQuoteAction: (state, action) => {
      state.data = undefined
    }
  }
});
export const { resetQuoteAction } = sbiQuickQuoteReducer.actions;

export const sbiKycReducer = createSlice({
  name: "sbiKycReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(sbiKyc.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(sbiKyc.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(sbiKyc.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetKycAction: (state, action) => {
      state.data = undefined
    }
  }
});

export const { resetKycAction } = sbiKycReducer.actions;




export const sbiKycAutoReducer = createSlice({
  name: "sbiKycAutoReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(sbiKycAuto.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(sbiKycAuto.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(sbiKycAuto.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetKycAutoAction: (state, action) => {
      state.data = undefined
    }
  }
});

export const { resetKycAutoAction } = sbiKycAutoReducer.actions;



export const sbiFullQuoteReducer = createSlice({
  name: "sbiFullQuoteReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(sbiFullQuoteAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(sbiFullQuoteAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(sbiFullQuoteAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetSbiFullQuote: (state, action) => {
      state.data = undefined
    }
  }
});

export const { resetSbiFullQuote } = sbiFullQuoteReducer.actions;


export const sbiPolicyGenerateReducer = createSlice({
  name: "sbiPolicyGenerateReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(sbiPolicyGenerateAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(sbiPolicyGenerateAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(sbiPolicyGenerateAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetSbiPolicyGenerate: (state, action) => {
      state.data = undefined
    }
  }
});

export const { resetSbiPolicyGenerate } = sbiPolicyGenerateReducer.actions;


export const sbiPdfDownloadReducer = createSlice({
  name: "sbiPdfDownloadReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(sbiPdfDownloadAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(sbiPdfDownloadAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(sbiPdfDownloadAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetSbiPdfDownload: (state, action) => {
      state.data = undefined
    }
  }
});

export const { resetSbiPdfDownload } = sbiPdfDownloadReducer.actions;