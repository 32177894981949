const Container = (props) => {
    const { title, contentTitleMain, spanTitle, contentTitleChild, className, afterBreakTag } = props;
    return <>
        <div className="buble_container_landing">
            <div className="featureParts_container_child_parts">
                <div className="bubble_landing">
                    <p> {title}<span>{spanTitle} </span></p>
                </div>
                <div className={className}>
                    <p className='bubble_landing_content_title  bubble_landing_content_titleBold' >{contentTitleMain}</p>
                    <p className='bubble_landing_content_title' >{contentTitleChild} <br /> {afterBreakTag}</p>
                </div>
            </div>
        </div>
    </>
}

export default Container;