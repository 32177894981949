import React, { useEffect } from "react";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import "./PolicyCancellation.css";
import hero from "../../../Assets/Images/policyCancellationHero.svg";

const PolicyCancellation = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <>
        <Navbar />

        {/* hero */}

        <div className="page-container">
          <div className="page-container-parent page-container-hero page-container-hero-policyCancellation maxWidth">
            <div className="policy-cancellation-content">
              {/* Policy Cancellation & Refund */}
              <p className="black-blue-header">
                Policy <span>Cancellation & Refund</span>
              </p>
              <br />
              <p>
                At Book My Insurance We are committed to providing transparent
                and customer-centric services to ensure your peace of mind. Our
                refund policy is designed to offer flexibility and protection to
                our valued policyholders.
              </p>
            </div>

            <img src={hero} alt="" srcset="" />
          </div>

          <div className="policyCancellation-content maxWidth">
            <p>Below are the key components of our refund policy:</p>

            <div className="policyCancellation-block-content">
              <p> 1. Free Look Period (FLP):</p>
              <div className="policyCancellation-block-content-points">
                <span>•</span>
                <p>
                  The Free Look Period (FLP) for policies purchased through our
                  website,{" "}
                  <a
                    href="https://bookmyinsurance.com"
                    style={{ color: "#5086FF", textDecoration: "underline" }}
                    target="_blank"
                  >
                    https://bookmyinsurance.com
                  </a>{" "}
                  , is 15 days from the date of policy purchase or based on the
                  respective insurance company from which the policy is booked.
                </p>
              </div>
              <div className="policyCancellation-block-content-points">
                <span>•</span>
                <p>
                  During the FLP, policyholders have the right to review the
                  policy's terms and conditions. If you decide not to accept the
                  policy, you can return it within the FLP without any
                  penalties.
                </p>
              </div>
            </div>

            <div className="policyCancellation-block-content">
              <p> 2. Refund Eligibility:</p>
              <div className="policyCancellation-block-content-points">
                <p>
                  To be eligible for a refund, policyholders must not make any
                  claims during the FLP.
                </p>
              </div>
            </div>

            <div className="policyCancellation-block-content">
              <p> 3. Refund Process:</p>
              <div className="policyCancellation-block-content-points">
                <p>
                  Refunds will be processed by the Book My Insurance Team in
                  accordance with our terms and conditions. We will also inform
                  and coordinate with the respective insurance company for your
                  refund until they initiate the refund process promptly.
                </p>
              </div>
            </div>

            <div className="policyCancellation-block-content">
              <p> 4. Refund Amount:</p>
              <div className="policyCancellation-block-content-points">
                <span>•</span>
                <p>
                  If you cancel the policy within the FLP and have not made any
                  claims, you are entitled to a refund of any premiums paid.
                </p>
              </div>
              <div className="policyCancellation-block-content-points">
                <span>•</span>
                <p>
                  The refund amount will be determined based on the premiums
                  paid by you, excluding any applicable fees or charges.
                </p>
              </div>
            </div>

            <div className="policyCancellation-block-content">
              <p> 5. Pro-Rata Refund:</p>
              <div className="policyCancellation-block-content-points">
                <span>•</span>
                <p>
                  If you decide to surrender the policy after the FLP ends, you
                  may be eligible for a pro-rata refund of your premium,
                  depending on the type of policy and the terms and conditions
                  of the respective insurance company.
                </p>
              </div>
              <div className="policyCancellation-block-content-points">
                <span>•</span>
                <p>
                  The pro-rata refund will be calculated based on the remaining
                  duration of the policy period and any applicable surrender
                  charges as per our policy.
                </p>
              </div>
            </div>

            <div className="policyCancellation-block-content">
              <p> 6. Insurance Company Refund Policy:</p>
              <div className="policyCancellation-block-content-points">
                <span>•</span>
                <p>
                  For detailed information regarding the refund process and
                  eligibility criteria, please refer to the refund policy of the
                  respective Insurance Company from which the insurance is
                  booked.
                </p>
              </div>
              <div className="policyCancellation-block-content-points">
                <span>•</span>
                <p>
                  The refund policy may vary depending on the type of policy
                  purchased.
                </p>
              </div>
            </div>

            <div className="policyCancellation-block-content">
              <p> 7. Consumer Protection:</p>
              <div className="policyCancellation-block-content-points">
                <span>•</span>
                <p>
                  The Free Look Period is a consumer-friendly initiative
                  designed to empower policyholders and ensure transparency in
                  the insurance purchasing process.
                </p>
              </div>
              <div className="policyCancellation-block-content-points">
                <span>•</span>
                <p>
                  It allows policyholders the opportunity to make informed
                  decisions and provides a safeguard against purchasing policies
                  that do not meet their needs.
                </p>
              </div>
            </div>

            <span>
              We encourage all our policyholders to thoroughly review the policy
              documents during the Free Look Period and reach out to us for any
              inquiries regarding refunds or policy cancellations. Your
              satisfaction and trust are our top priorities. If you have any
              further questions or require assistance, please don't hesitate to
              contact our customer service team. We are here to support you
              every step of the way.
            </span>

            <div className="termsAndConditioncontainer">
              <p className="black-blue-header">
                Terms <span>& Conditions</span>
              </p>
              <br />
              <p>
                Viaansh Insurance Brokers Pvt. Ltd. (hereinafter
                "BookMyInsurance") operates the website {" "}
                <a
                  href="https://bookmyinsurance.com"
                  style={{ color: "#5086FF", textDecoration: "underline" }}
                  target="_blank"
                >
                  https://bookmyinsurance.com
                </a>{" "}
                to provide users with a platform to compare and purchase
                insurance products from various providers.
                <br /> <br />
                BookMyInsurance is not an insurance company but a licensed
                insurance broker under the Insurance Regulatory and Development
                Authority of India (IRDAI), with Direct Broker Code:{" "}
                <span style={{ color: "#5086FF", fontWeight: "500" }}>
                  IRDAI License no.762 Direct Broker (Life & General){" "}
                </span>
                valid from{" "}
                <span style={{ color: "#5086FF", fontWeight: "500" }}>
                  29-07-2024
                </span>{" "}
                to{" "}
                <span style={{ color: "#5086FF", fontWeight: "500" }}>
                  28-07-2027
                </span>
                .
                <br /> <br />
                Information on the website is partially supplied by
                participating insurance companies, and while BookMyInsurance
                strives for accuracy, it cannot guarantee the timeliness or
                completeness of the information. You are responsible for
                selecting your product and should review all relevant details
                before purchase. If you need assistance, you are advised to seek
                further information from either BookMyInsurance or the
                respective provider.
                <br /> <br />
                BookMyInsurance may pass your personal information to the
                relevant provider if you apply for a policy, but cannot
                guarantee when or if the policy will be issued. Premium
                collection on the website is done on behalf of the insurer, and
                issuance of the policy is at the sole discretion of the insurer.
                If the policy is not issued, BookMyInsurance will assist in
                securing a refund.
                <br /> <br />
                By using the website, you agree to the terms outlined in this
                User Agreement and Privacy Policy. BookMyInsurance reserves the
                right to modify these terms without notice. You are responsible
                for ensuring the confidentiality of your account and for all
                activities carried out under it. Unauthorized use of the website
                or its resources is prohibited, and BookMyInsurance holds no
                liability for losses resulting from such misuse.
                <br /> <br />
                BookMyInsurance is not liable for delays in policy issuance,
                inaccuracies in website content, or any damages resulting from
                the use of the website. All intellectual property on the
                website, including text, logos, and graphics, is owned by
                BookMyInsurance and protected under applicable laws. Termination
                of access to the website may occur without notice if these terms
                are breached. Legal disputes will be governed by the laws of
                Maharashtra, India, and under the jurisdiction of Mumbai courts.
                BookMyInsurance may provide third-party services or offers, but
                it is not liable for these services. The company is also not
                responsible for repairs or claims under its Express Claims
                Services or Nominee Assistance Program. For any assistance or
                claims processing, BookMyInsurance will liaise with the insurer,
                but the ultimate decision lies with the insurance provider. By
                using the website, you authorize BookMyInsurance to contact you
                for insurance needs, overriding your registration under the
                National Do Not Call (NDNC) registry.
              </p>
            </div>
          </div>
        </div>

        <Footer />
      </>
    </>
  );
};

export default PolicyCancellation;
