import { createAsyncThunk } from "@reduxjs/toolkit";

export const iciciQuickQuoteAction = createAsyncThunk(
  "iciciQuikQuotes",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ICICI_QUICK_QUOTE_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);


export const iciciProposalAction = createAsyncThunk(
  "iciciProposal",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ICICI_PROPOSAL_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const iciciKyc = createAsyncThunk(
  "iciciKyc",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ICICI_MANUAL_KYC_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const iciciKycAuto = createAsyncThunk(
  "iciciKycAuto",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ICICI_AUTO_KYC_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const iciciCreateTreansactionAction = createAsyncThunk(
  "iciciCreateTransaction",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ICICI_CREATE_TXN_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const icicipdfDownloadAction = createAsyncThunk(
  "icicipdfDownload",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ICICI_PDF_DOWNLOAD_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);
