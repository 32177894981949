import React from "react";
import "./RadioButton.css";

const RadioButton = (props) => {
  const {
    label,
    className,
    id,
    groupName,
    value,
    changeEvent,
    isChecked,
    errState,
    modalBtn,
    errClassName,
    isDisabled,
  } = props;

  return (
    <>
      <div>
        <div className="radio-group">
          <input
            className="radio-input"
            name={groupName}
            id={id}
            value={value}
            type="radio"
            onChange={(e) => changeEvent(e)}
            checked={isChecked}
            disabled={isDisabled | false}
          />
          <label
            className={errClassName ? "radio-label " + errClassName : "radio-label"}
            style={{ height: modalBtn ? "48px" : "" }}
            htmlFor={id}
          >
            <span className="radio-inner-circle">
              <div className="radio-inactive-circle"></div>
            </span>
            {label}
          </label>
        </div>
      </div>
    </>
  );
};

export default RadioButton;
