import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputFields from "../InputFields/InputFields";
import Botton from "../Button/Button";
import RadioButton from "../RadioButton/RadioButton";
import DropDown from "../DropDown/DropDown";
import grayArrow from "../../Assets/Images/grayArrow.svg";
import SearchSelect from "../DropDown/Search Select/SearchSelect";
import {
  getInsurerList,
  getloanProvider,
} from "../../redux/Actions/stateDetails";
import { useLocation, Link, useNavigate, useHistory } from "react-router-dom";
import backIcon from "../../Assets/Images/backIcon.svg";
import { SHREERAM } from "../../utils/commonUtils";
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader";
import { partnerPnaKycAction } from "../../redux/Actions/LoginAction";
import CustomDateField from "../InputFields/CustomDateField/CustomDateField";
import CustomCalendar from "../InputFields/Calendar/Calendar";
import { transactionURLAction } from "../../redux/Actions/TransactionAction";

const PropsalFour = (props) => {
  const {
    prevPolicyNumber,
    prevPolicyNameInsurer,
    chassisNumber,
    engineNumber,
    carIsInLoan,
    carHypothecationCompany,
    hideInsurerInCaseBundleShriRam,
    HandleInputChange,
    handleSubmitForm,
    handleBackBtn,
    handleOdInsurer,
    handleBundleInsurer,
    formData,
    bodyData,
    insurerList,
    CompanyName,
    handleFinancier,
    mappingAllData,
    Edit,
    errors,
    businessType,
    type,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [GetSearchedValue, setGetSearchedValue] = useState("")
  const [ShowInsDropdown, setShowInsDropdown] = useState(false);
  const [ShowOdInsDropdown, setShowOdInsDropdown] = useState(false);
  const [ShowLoanCompanies, setShowLoanCompanies] = useState(false);

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  const handlePopUps = (e) => {
    if (ShowInsDropdown) {
      setShowInsDropdown(false);
    }
    if (ShowLoanCompanies) {
      setShowLoanCompanies(false);
    }
    if (ShowOdInsDropdown) {
      setShowOdInsDropdown(false);
    }
  };

  const loanProviderResponse = useSelector((state) => {
    return state?.getFinancierNameDetails?.data?.data?.result;
  });

  const loanProviderList = loanProviderResponse?.map((data) => {
    return {
      label: data?.loan_provider_name,
      value: data?.loan_provider_name,
    };
  });

  useEffect(() => {
    dispatch(getloanProvider(GetSearchedValue));
  }, [GetSearchedValue]);

  let vehicleType = bodyData?.data?.vehicleType;

  let transactionStatus = useSelector((state) => {
    return state.getTransactionStatus;
  });
  

  return (
    <div onClick={handlePopUps}>

      <div className="titleContainer titleContainer_pform ">
        <p>Vehicle Details</p>
        <p>All fields are mandatory</p>
      </div>
      <form className="formSection-pform" action="" onClick={handlePopUps} onSubmit={(e) => { e.preventDefault(); }}>
          {
            !mappingAllData[0]?.prevPolicyExpLastMonth &&
            (
              businessType !== "New" && (
                <div className="input_group_pform">
                  <div className="inputGropOne_form inputGrop ">
                    <InputFields
                      value={prevPolicyNumber}
                      placeholder={mappingAllData[0]?.prevPolicyType == "Bundled (3Y TP + 1Y OD)" ||
                        mappingAllData[0]?.prevPolicyType == "Bundled" ||
                        mappingAllData[0]?.PolicyTypeValue == "Own Damage" ? "Previous Bundle Policy Number" : "Previous Policy Number"}
                      id="prevPolicyNumber"
                      type="text"
                      name="prevPolicyNumber"
                      onChangeEvent={(e) => HandleInputChange(e)}
                      errState={errors?.prevPolicyNumber ? true : false}
                      errMsg={errors?.prevPolicyNumber}
                      errClassName="input-err"
                      className="uppercaseText"
                    />
                  </div>
                  <div className="inputGropOne_form inputGrop ">
                    <SearchSelect
                      type="submit"
                      optionList={insurerList}
                      handleSelect={handleBundleInsurer}
                      value={prevPolicyNameInsurer}
                      placeholder={mappingAllData[0]?.prevPolicyType == "Bundled (3Y TP + 1Y OD)" ||
                        mappingAllData[0]?.prevPolicyType == "Bundled" ||
                        mappingAllData[0]?.PolicyTypeValue == "Own Damage" ? "Previous Bundle Policy Insurer" : "Previous Policy Insurer"}
                      // labelClass="modifiy-label-css"
                      label={mappingAllData[0]?.prevPolicyType == "Bundled (3Y TP + 1Y OD)" ||
                        mappingAllData[0]?.prevPolicyType == "Bundled" ||
                        mappingAllData[0]?.PolicyTypeValue == "Own Damage" ? "Previous Bundle Policy Insurer" : "Previous Policy Insurer"}
                      errState={errors?.prevPolicyNameInsurer ? true : false}
                      errMsg={errors?.prevPolicyNameInsurer}
                      errClassName="input-err"
                    />
                  </div>
                </div>
              )
            )

          }

          {((mappingAllData[0]?.prevPolicyType == "Bundled (3Y TP + 1Y OD)" ||
            mappingAllData[0]?.prevPolicyType == "Bundled") &&
            mappingAllData[0]?.PolicyTypeValue == "Own Damage") ? (
            <div className="input_group_pform">
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={formData?.prevOdPolicyNumber}
                  labelClass="modifiy-label-css"
                  placeholder="Previous Own Damage Policy Number"
                  id="prevOdPolicyNumber"
                  type="text"
                  name="prevOdPolicyNumber"
                  onChangeEvent={(e) => HandleInputChange(e)}
                  errState={errors?.prevOdPolicyNumber ? true : false}
                  errMsg={errors?.prevOdPolicyNumber}
                  errClassName="input-err"
                  className="uppercaseText"
                  isDisabled={
                    CompanyName === SHREERAM &&
                      hideInsurerInCaseBundleShriRam === true
                      ? true
                      : false
                  }
                />
              </div>
              <div className="inputGropOne_form inputGrop ">
                <SearchSelect
                  type="submit"
                  optionList={insurerList}
                  handleSelect={handleOdInsurer}
                  value={formData?.prevOdPolicyNameInsurer}
                  placeholder="Previous Own Damage Policy Insurer"
                  // label="Previous Own Damage Policy Insurer"
                  errState={errors?.prevOdPolicyNameInsurer ? true : false}
                  errMsg={errors?.prevOdPolicyNameInsurer}
                  errClassName="input-err"
                  labelClass="modifiy-label-css"
                  isDisabled={
                    CompanyName === SHREERAM &&
                      hideInsurerInCaseBundleShriRam === true
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          ) : null}

          <div className="input_group_pform">
            <div className="inputGropOne_form inputGrop ">
              <InputFields
                value={chassisNumber.trim("")}
                placeholder="Chassis Number"
                id="chassisNumber"
                type="text"
                name="chassisNumber"
                onChangeEvent={(e) => HandleInputChange(e)}
                errState={errors?.chassisNumber ? true : false}
                errMsg={errors?.chassisNumber}
                errClassName="input-err"
                className="uppercaseText"
              />
            </div>
            <div className="inputGropOne_form inputGrop ">
              <InputFields
                value={engineNumber.trim("")}
                placeholder="Engine Number"
                id="engineNumber"
                type="text"
                name="engineNumber"
                onChangeEvent={(e) => HandleInputChange(e)}
                errState={errors?.engineNumber ? true : false}
                errMsg={errors?.engineNumber}
                errClassName="input-err"
                className="uppercaseText"
              />
            </div>
          </div>

          <div className="input_group_pform input_group_pform-loan ">
            <div className="inputGropOne_form inputGrop inputGrop_caseTwo  ">
              <p className="customLabel_pform">{vehicleType == "2W" ? "Bike is on Loan?" : "Car is on Loan?"}</p>
              <div className="radioContainer-pform">
                <RadioButton
                  label="Yes"
                  className=""
                  id="carLoanisYes"
                  groupName="carIsInLoan"
                  value="Yes"
                  changeEvent={(e) => HandleInputChange(e)}
                  isChecked={formData?.carIsInLoan === "Yes" ? true : false}
                />
                <RadioButton
                  label="No"
                  className=""
                  id="carLoanisNot"
                  groupName="carIsInLoan"
                  value="No"
                  changeEvent={(e) => HandleInputChange(e)}
                  isChecked={formData?.carIsInLoan === "No" ? true : false}
                />
              </div>
              <p className="err-msg">
                {errors?.carIsInLoan ? errors.carIsInLoan : ""}
              </p>
            </div>

            {
              carIsInLoan === "Yes" && (
                // <div className="input_group_pform  ">
                <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
                  <div className="">
                    {carIsInLoan === "Yes" && (
                      <SearchSelect
                        type="submit"
                        optionList={loanProviderList}
                        handleSelect={handleFinancier}
                        value={carHypothecationCompany}
                        GetSearchedValue={setGetSearchedValue}
                        placeholder="Loan Provider Name"
                        label="Loan Provider Name"
                      // labelClass="modifiy-label-css"
                      />
                    )}
                  </div>
                  <p className="err-msg">
                    {errors?.carHypothecationCompany ? errors.carHypothecationCompany : ""}
                  </p>
                </div>
              )
            }

          </div>

          <section className="btnContainer_pform btnContainer_pform_collapsible">
            <Botton
              text="Back"
              className="button btn_pForm  secondry_btn"
              event={() => handleBackBtn("vehicleDetails")}
            />
            {transactionStatus?.isLoading ?
              <ButtonLoader className="btn_pForm" />
              : <Botton
                text="Save"
                type="submit"
                className="button btn_pForm"
                event={(e) =>
                  handleSubmitForm(Edit ? "kycDetails" : "vehicleDetails", e, 'editvehicleDetails')

                }
              />}
          </section>
        </form>
    </div>
  );
};

export default PropsalFour;
