import React, { useState, useEffect } from 'react'
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader";
import { partnerPnaKycAction } from "../../redux/Actions/LoginAction";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Link, useNavigate, useHistory } from "react-router-dom";
import { transactionURLAction } from "../../redux/Actions/TransactionAction";
import InputFields from "../InputFields/InputFields";
import Botton from "../Button/Button";

const DealerPanKyc = (props) => {
  const {
    emailValue,
    phoneValue,
    panValue,
    gstValue,
    HandleInputChange,
    handleSubmitForm,
    ownerValue,
    compNameValue,
    formData,
    Edit,
    setEdit,
    errors,
    errors2,
    companyName,
    sbiAutoKycData,
    dateValue,
    isPaCoverChecked,
    ShowNilDepState,
    isZeroDepChecked,
    handleTataNilDep,
    ZeroDepValue,
    bodyData,
    handleDobClone,
    saveCalendarValue,
    cancelCalendarValue,
    ShowDobCalendar,
    setShowDobCalendar,
    DobClone,
    businessType,
    mappingAllData,
    type,
    vehicleType,
    phonestatussloading,
    checkphone,
    setpanDobConataier,
    panDobConataier,
    kycPanNumber,
    setFormData,
    setShowForm,
    setErrors,
    StepperValue,
    setYear,
    Year,
    setMonth,
    Month,
    setDay,
    Day,
    validateInput
  } = props;
  const [ParamaterForRoute, setParamaterForRoute] = useState("dealerKyc");
  const navigate = useNavigate()
  let checkAutoKycFlagAcko = localStorage.getItem('icicAutoKycData')
  const [ShowKYClist, setShowKYClist] = useState(false);
  const [Loading, setLoading] = useState(false)
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const dispatch = useDispatch()

  useEffect(() => {
    setFormData((prevItems) => ({
      ...prevItems,
      dob: `${Year}-${Month}-${Day}`
    }))

  }, [Day, Month, Year]);

  const handlePopUp = () => {
    if (ShowKYClist) {
      setShowKYClist(false);
    }
  };
  const handleSubmitFormPartner = (() => {
    let ValidatePan = validateInput()
    if (ValidatePan) {
      (async function () {
        const dateString = formData?.dob;
        const dateObject = new Date(dateString);
        const formattedDate = dateObject.getFullYear() + '-' +
          String(dateObject.getMonth() + 1).padStart(2, '0') + '-' +
          String(dateObject.getDate()).padStart(2, '0');

        let bodyData = {
          pan: formData.kycPanNumber,
          dob: formData?.dob
        }
        setLoading(true)
        let StateData = null
        let partnerKycData = await dispatch(partnerPnaKycAction(bodyData))
        if (partnerKycData?.payload?.body?.data?.kyc_details) {
          // if(partnerKycData?.payload?.body?.data?.kyc_details?.permanent_address?.pin_code){
          // await  getStateCityData(partnerKycData?.payload?.body?.data?.kyc_details?.permanent_address?.pin_code)
          // }

          let obj = {
            email: partnerKycData?.payload?.body?.data?.kyc_details?.email || "",
            firstName: `${partnerKycData?.payload?.body?.data?.kyc_details?.first_name}` || "",
            lastName: partnerKycData?.payload?.body?.data?.kyc_details?.last_name || "",
            phoneNumber: partnerKycData?.payload?.body?.data?.kyc_details?.mobile_number || phoneValue || "",
            addressOne: partnerKycData?.payload?.body?.data?.kyc_details?.permanent_address?.address_line_1 || sbiAutoKycData?.addressOne || "",
            addressTwo: partnerKycData?.payload?.body?.data?.kyc_details?.permanent_address?.address_line_1 || sbiAutoKycData?.addressTwo || "",
            state: partnerKycData?.payload?.body?.data?.kyc_details?.permanent_address?.state || "",
            city: partnerKycData?.payload?.body?.data?.kyc_details?.permanent_address?.district || sbiAutoKycData?.closestMatchCity || "",
            pincode: partnerKycData?.payload?.body?.data?.kyc_details?.permanent_address?.pin_code || (bodyData?.data?.pincode ? Number(bodyData?.data?.pincode) : (sbiAutoKycData?.pincode ? sbiAutoKycData?.pincode : "")),
          }

          setFormData((prev) => ({
            ...prev,
            email: partnerKycData?.payload?.body?.data?.kyc_details?.email || "",
            firstName: `${partnerKycData?.payload?.body?.data?.kyc_details?.first_name} ` || "",
            lastName: partnerKycData?.payload?.body?.data?.kyc_details?.last_name || "",
            phoneNumber: partnerKycData?.payload?.body?.data?.kyc_details?.mobile_number || phoneValue || "",
            addressOne: partnerKycData?.payload?.body?.data?.kyc_details?.permanent_address?.address_line_1 || sbiAutoKycData?.addressOne || "",
            addressTwo: partnerKycData?.payload?.body?.data?.kyc_details?.permanent_address?.address_line_1 || sbiAutoKycData?.addressTwo || "",
            state: partnerKycData?.payload?.body?.data?.kyc_details?.permanent_address?.state || "",
            city: partnerKycData?.payload?.body?.data?.kyc_details?.permanent_address?.district || sbiAutoKycData?.closestMatchCity || "",
            pincode: partnerKycData?.payload?.body?.data?.kyc_details?.permanent_address?.pin_code || (bodyData?.data?.pincode ? Number(bodyData?.data?.pincode) : (sbiAutoKycData?.pincode ? sbiAutoKycData?.pincode : "")),
          }))


          setLoading(false)
          localStorage.setItem('icicAutoKycData', 'true')
          setpanDobConataier(false)
          setShowForm("ownerDetail")
          let dataval = {
            uuid: id,
            bodyData: { ...bodyData?.data, ...obj },
            companyName: formData?.companyName,
            formData: { ...formData, ...obj },
            formValue: "ownerDetail",
            stepperValue: StepperValue + 1,
            type: "update_proposal"

          };

          await dispatch(transactionURLAction(dataval));
        } else {
          setLoading(false)
          setpanDobConataier(false)
          setShowForm("ownerDetail")
          // setShowForm("FormDetails");
          // setStepperValue(6);
        }
        console.log(partnerKycData, "  ")
      }())
    }


  })
  return (
    <div>
      <>
        <div>
          <div className="titleContainer">
            <p></p>
            <p>All fields are mandatory</p>
          </div>
          <div
            id="PropsalFormContainer_child-formFields"
            className="formContainer_Acko formContainer_acko PropsalFormContainer_child formContainer-collapsible"
          >
            <div className="kyc_container_photo_conteent">
              <div className="kyc_container_content">
                <div className="kyc_info_container">
                  <p className="title_kyc_pf">Update your KYC</p>
                  <p className="small_text"> {kycContent} </p>
                  <p className="title_kyc_pf"> {kycContentTwo} </p>
                </div>
              </div>
            </div>

            {/* form container */}

            <div className="acko_kyc_container">
              <form
                className="formSection-pform"
                onClick={handlePopUp}
                action=""
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >

                <>
                  <div className="input_group_pform input_group_pform_kyc_acko">
                    <div className="inputGropOne_form inputGrop ">
                      <p className="kyc_label_acko">
                        Enter Your PAN Number
                      </p>
                      <input
                        type="text"
                        name="kycPanNumber"
                        id="kycPanNumber"
                        placeholder="xxxxx1234x"
                        className={`acko_kyc_input ${errors?.kycPanNumber ? "input-err" : ""}`}
                        value={kycPanNumber}
                        onChange={(e) => HandleInputChange(e)}
                        errState={errors?.kycPanNumber ? true : false}
                        errMsg={errors?.kycPanNumber}
                      />
                      {errors?.kycPanNumber && (
                        <p className="err-msg">{errors?.kycPanNumber}</p>
                      )}
                    </div>
                    <div className="inputGropOne_form inputGrop ">
                      <p className="kyc_label_acko">
                        Enter Your Date of birth
                        {/* {!proposalData?.user?.is_corporate ? "Enter Your Date of birth" : "Enter Date of Incorporation"} */}
                      </p>
                      <div className="custom_dob_container">
                        <InputFields
                          type="number"
                          value={Day}
                          containerID="custom_dob"
                          label="DD"
                          className={`custom_dob ${errors?.dob && !Day && 'input-err'} `}
                          onChangeEvent={(e) => {
                            delete errors.dob
                            setDay(
                              e.target.value.length <= 2 &&
                                e.target.value <= 31
                                ? e.target.value
                                : Day
                            )
                          }


                          }
                        />
                        <InputFields
                          type="number"
                          value={Month}
                          containerID="custom_dob"
                          label="MM"
                          className={` ${errors?.dob && !Month && 'input-err'} `}
                          onChangeEvent={(e) => {
                            delete errors.dob
                            setMonth(
                              e.target.value.length <= 2 &&
                                e.target.value <= 12
                                ? e.target.value
                                : Month
                            )
                          }

                          }
                        />
                        <InputFields
                          type="number"
                          value={Year}
                          containerID="custom_dob"
                          label="YYYY"
                          className={`custom_dob ${errors?.dob && !Year && 'input-err'} `}
                          onChangeEvent={(e) => {
                            delete errors.dob
                            setYear(
                              e.target.value.length <= 4
                                ? e.target.value
                                : Year
                            )
                          }

                          }
                        />
                      </div>
                      {errors?.dob && (
                        <p className="err-msg">{errors?.dob}</p>
                      )}
                    </div>
                  </div>
                </>
                <section className="btnContainer_pform btnContainer_pform_collapsible">
                  <Botton
                    text={"Cancel"}
                    className="button btn_pForm  secondry_btn"
                    event={() => {
                      navigate(vehicleType == "2W" ? "/View-Plans-Bike?id=" + id : "/View-Plans?id=" + id)

                    }}
                  />
                  {Loading ?
                    <ButtonLoader className="btn_pForm" />
                    : <Botton
                      text="Update"
                      type="submit"
                      className="button btn_pForm"
                      event={(e) =>
                        handleSubmitFormPartner()
                      }
                    />}
                </section>

                {/* <section className="btnContainer_pform btnContainer_pform_collapsible">
                  {
                    Loading ? (
                      <ButtonLoader className="btn_kycForm_acko_loading" />
                    ) : (
                      <Botton
                        text={

                          "Validate your PAN"

                        }
                        type="submit"
                        className="button btn_kycForm_acko"
                        event={() => handleSubmitFormPartner()}
                      />
                    )
                  }
                </section> */}



              </form>
            </div>
          </div>





        </div>
      </>

    </div>
  )
}
let kycContent = `From January 1, 2023, KYC authentication has been a mandatory process to purchase insurance policies from all insurance providers in India.`
let kycContentTwo = `Your details are safe with us. You can complete your KYC in 1 min.`

export default DealerPanKyc
