import React from "react";
import "./ButtonLoader.css";

const ButtonLoader = (props) => {
    const { className, PlancardLoder } = props;

    return (
        <>
            <div className="btn_container-loader" style={className === "btn_kycForm_acko_loading" ? { width: "100%" } : {}} >
                <button className={className ? className + " loader-btn" : "loader-btn"}>
                    <section className="dots-container">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </section>
                </button>
            </div>
        </>
    );
};

export default ButtonLoader;
