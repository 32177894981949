import React, { useState, useEffect } from "react";
import "./AboutUs.css";
import Navbar from "../../Navbar/Navbar";
import client1 from "../../../Assets/Images/testimonial.png";
import client2 from "../../../Assets/Images/testimonial2.png";
import linkedin from "../../../Assets/Images/linkedinColor.svg";
import twitter from "../../../Assets/Images/twitterColor.svg";
import fb from "../../../Assets/Images/fbColor.svg";
import office from "../../../Assets/Images/fakeOffice.png";
import buyIns from "../../../Assets/Images/buyIns.svg";
import manageIns from "../../../Assets/Images/manageIns'.svg";
import rocket from "../../../Assets/Images/launch.svg";
import claimIns from "../../../Assets/Images/claimIns.svg";
import cultureOne from "../../../Assets/Images/cultureOne.png";
import cultureTwo from "../../../Assets/Images/cultureTwo.png";
import cultureThree from "../../../Assets/Images/cultureThree.jpeg";
import cultureFour from "../../../Assets/Images/cultureFour.jpeg";
import male from "../../../Assets/Images/maleExpert.jpeg";
import female from "../../../Assets/Images/femaleExpert.jpeg";
import saleOne from "../../../Assets/Images/saleOne.png";
import saleTwo from "../../../Assets/Images/saleTwo.png";
import saleThree from "../../../Assets/Images/saleThree.png";
import saleFour from "../../../Assets/Images/saleFour.png";
import saleFive from "../../../Assets/Images/saleFive.png";
import saleSix from "../../../Assets/Images/saleSix.png";
import saleSeven from "../../../Assets/Images/saleSeven.png";
import saleEight from "../../../Assets/Images/saleEight.png";
import saleNine from "../../../Assets/Images/saleNine.png";
import saleTen from "../../../Assets/Images/saleTen.png";
import techOne from "../../../Assets/Images/techOne.png";
import techTwo from "../../../Assets/Images/Vaibhav_Dubey.png";
import techThree from "../../../Assets/Images/techThree.png";
import techFour from "../../../Assets/Images/techFour.png";
import techFive from "../../../Assets/Images/techFive.png";
import circle from "../../../Assets/Images/circle.svg";
import borderCircle from "../../../Assets/Images/bordercircle.svg";
import Card from "../../Card/Card";
import Footer from "../../Footer/Footer";
import leaderOne from "../../../Assets/Images/adarshTiwariJi.png";
import leaderTwo from "../../../Assets/Images/Rajesh-Kumar.png";
import leaderThree from "../../../Assets/Images/PawanPoojary.png";
import Botton from "../../Button/Button";

const AboutUs = () => {
  const [ShowAllSalesExpert, setShowAllSalesExpert] = useState(false);

  let profileDetails = [
    {
      image: leaderTwo,
      fullName: "Mr. Rajesh Kumar",
      designation: "Chief Executive Officer",
      linkedinLink: "https://www.bookmyinsurance.com/",
      twitterLink: "https://www.bookmyinsurance.com/",
      facebookLink: "https://www.bookmyinsurance.com/",
    },
    {
      image: client1,
      fullName: "Mr. Jayesh Dubey",
      designation: "Chief Executive Officer",
      linkedinLink: "https://www.bookmyinsurance.com/",
      twitterLink: "https://www.bookmyinsurance.com/",
      facebookLink: "https://www.bookmyinsurance.com/",
    },
    {
      image: leaderThree,
      fullName: "Mr. Pawan Poojary",
      designation: "Chief Executive Officer",
      linkedinLink: "https://www.bookmyinsurance.com/",
      twitterLink: "https://www.bookmyinsurance.com/",
      facebookLink: "https://www.bookmyinsurance.com/",
    },
    {
      image: leaderOne,
      fullName: "Mr. Adarsh Tiwari",
      designation: "Chief Executive Officer",
      linkedinLink: "https://www.bookmyinsurance.com/",
      twitterLink: "https://www.bookmyinsurance.com/",
      facebookLink: "https://www.bookmyinsurance.com/",
    },
  ];

  let salesExpertList = [
    {
      img: saleNine,
      className: "experts-circle-container experts-circle-container-one ",
      name: "abc",
    },
    {
      img: saleTwo,
      className:
        "experts-circle-container position-bottom-circle experts-circle-container-two",
      name: "abc",
    },
    {
      img: saleThree,
      className: "experts-circle-container experts-circle-container-three ",
      name: "abc",
    },
    {
      img: saleFour,
      className:
        "experts-circle-container position-bottom-circle experts-circle-container-four",
      name: "abc",
    },
    {
      img: saleFive,
      className: "experts-circle-container experts-circle-container-five",
      name: "abc",
    },
  ];

  let salesExpertListTwo = [
    {
      img: saleSix,
      className: "experts-circle-container experts-circle-container-one ",
      name: "abc",
    },
    {
      img: saleSeven,
      className:
        "experts-circle-container position-bottom-circle experts-circle-container-two",
      name: "abc",
    },
    {
      img: saleEight,
      className: "experts-circle-container experts-circle-container-three ",
      name: "abc",
    },
    {
      img: saleOne,
      className:
        "experts-circle-container position-bottom-circle experts-circle-container-four",
      name: "abc",
    },
    {
      img: saleTen,
      className: "experts-circle-container experts-circle-container-five",
      name: "abc",
    },
  ];

  let techExpertList = [
    {
      img: techOne,
      className: "experts-circle-container experts-circle-container-one ",
      name: "Akshay Yadav",
    },
    {
      img: techTwo,
      className:
        "experts-circle-container position-bottom-circle experts-circle-container-two",
      name: "Vaibhav Dubey",
    },
    {
      img: techThree,
      className: "experts-circle-container experts-circle-container-three ",
      name: "Aman Verma",
    },
    {
      img: techFour,
      className:
        "experts-circle-container position-bottom-circle experts-circle-container-four",
      name: "Ansh Khurana",
    },
    {
      img: techFive,
      className: "experts-circle-container experts-circle-container-five",
      name: "Shraddha Kedar",
    },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Navbar />
      <div className="aboutus-container">
        <div className="aboutus-container-parent ">
          {/* hero content */}

          <div className="hero-container-aboutus maxWidth ">
            <div className="hero-parts-aboutus hero-parts-aboutus-content ">
              <p className="hero-parts-aboutus-head">
                About <span>BookMyInsurance</span>
              </p>
              <p className="hero-parts-aboutus-description">
                We are an IRDAI licenced insurance broking company. We started in
                Gurgaon as most dynamic insurance brokers and extended our
                business to Mumbai to now becoming the fastest growing in the
                country.
              </p>
              <p className="hero-parts-aboutus-summary">
                In a short time, we have managed to rev up Book my insurance.com
                with technology that is robust not only to the company but also
                our customers. Our team with most creative minds, aims at
                customer centricity by making insurance buying effortless and
                safe.
              </p>
              <p className="hero-parts-aboutus-summary">
                We have managed to secure over 1+ lac customers. Right from
                offering third party motor to comprehensive motor insurance with
                a plethora of top insurance companies to choose from.
              </p>
              {/* <p className="hero-parts-aboutus-summary">
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s. Lorem Ipsum is simply dummy text of the
                printing and typesetting industry.
              </p> */}
              <div className="clients-container-aboutus">
                <div className="clients-photos-aboutus">
                  <div className="img-circle-aboutus img-1-abtus">
                    <img src={client1} alt="" srcSet="" />
                  </div>
                  <div className="img-circle-aboutus img-2-abtus">
                    <img src={client2} alt="" srcSet="" />
                  </div>
                  <div className="img-circle-aboutus img-3-abtus">
                    <img src={client1} alt="" srcSet="" />
                  </div>
                  <div className="img-circle-aboutus img-4-abtus">
                    <img src={client2} alt="" srcSet="" />
                  </div>
                </div>
                <div className="clients-numbers-aboutus">
                  <p>2 L + Happy</p>
                  <p>Customer</p>
                </div>
              </div>
            </div>
            <div className="hero-parts-aboutus">
              <img src={office} alt="" srcSet="" />
            </div>
          </div>

          {/* leadership section */}

          {/* <div className="leadership-container-aboutus">
            <div className="leadership-parent-container-aboutus maxWidth">
              <p className="hero-parts-aboutus-head">
                Our <span>Leadership</span>
              </p>
              <div className="leadership-profile-container">
                {profileDetails.map((data, key) => (
                  <div key={key} className="leader-profile-square">
                    <div className="square-ui">
                      <img src={data.image} alt="" srcSet="" />
                      <div className="social-icons-abtus">
                        <div className="social-icon-square">
                          <a href={data.linkedinLink} target="_blank">
                            <img src={linkedin} alt="" srcSet="" />
                          </a>
                        </div>
                        <div className="social-icon-square">
                          <a href={data.twitterLink} target="_blank">
                            <img src={twitter} alt="" srcSet="" />
                          </a>
                        </div>
                        <div className="social-icon-square  ">
                          <a href={data.facebookLink} target="_blank">
                            <img src={fb} alt="" srcSet="" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="name-designation-abtus">
                      <p className="abtus-name">{data.fullName}</p>
                      <i className="abtus-desg">{data.designation}</i>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div> */}

          {/* how we help */}

          <div className="cards_container_parent cards_container_parent-abtus maxWidth">
            <p className="hero-parts-aboutus-head">
              How we <span>Help You?</span>
            </p>
            <div className="card_div_container card_div_container-abtus ">
              <div className="card_container_child card_container_child-abtus ">
                <Card
                  icons={buyIns}
                  className="card_landing card_landing-aboutus "
                  classNameTwo="cardLanding_content"
                  classNameThree="icon_container_landing"
                  title="Buy Insurance"
                  subtitle="We offer financial protection against various risks through the payment of premiums, providing individuals and businesses with peace of mind and security."
                />
                <Card
                  icons={manageIns}
                  className="card_landing card_landing-aboutus "
                  classNameTwo="cardLanding_content"
                  classNameThree="icon_container_landing"
                  title="Manage Insurance"
                  subtitle="We offer comprehensive insurance management solutions to efficiently handle policies, claims, and risk assessments, ensuring clients' coverage needs are met seamlessly."
                />
                <Card
                  icons={claimIns}
                  className="card_landing card_landing-aboutus "
                  classNameTwo="cardLanding_content"
                  classNameThree="icon_container_landing"
                  title="Claim Insurance"
                  subtitle="We specialize in streamlined claim insurance management, facilitating smooth processing and resolution of claims to provide our clients with swift and efficient reimbursement and support."
                />
              </div>
            </div>
          </div>

          {/* MISSON SECTION */}

          <div className="leadership-container-aboutus misson-container-aboutus ">
            <img src={rocket} className="rocket-mission" alt="" srcSet="" />
            <div className="leadership-parent-container-aboutus misson-parent-container-aboutus maxWidth">
              <p className="hero-parts-aboutus-head">
                Our <span>Mission</span>
              </p>
              <div className="misson-left-content">
                <p className="hero-parts-aboutus-description">
                  We aim to be a leading General Insurance Broking company known
                  for professionalism and excellent customer service. Our
                  commitment is to deliver high-quality, customized insurance
                  products for both businesses and individuals.
                </p>
                <p className="hero-parts-aboutus-summary">
                  By understanding our client's needs, we strive to be their
                  preferred choice, providing expertise and top-notch
                  personalized service. We're also dedicated to closing the tech
                  gap in insurance, ensuring a better user experience for our
                  customers.
                </p>
              </div>
            </div>
          </div>

          {/* OUR SALES EXPERT */}

          <div className="experts-container-aboutus maxWidth ">
            <p className="hero-parts-aboutus-head">
              Our <span>Sales Experts</span>
            </p>
            <div className="photos-section-expert">
              <div className="photos-container-experts">
                {salesExpertList.map((data, key) => (
                  <div key={key} className={data.className}>
                    <div className="photos-circle-experts">
                      <img
                        src={data.img}
                        className="expert-img"
                        alt=""
                        srcSet=""
                      />
                      <img
                        src={circle}
                        className="expert-circle-img"
                        alt=""
                        srcSet=""
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="photos-container-experts">
                {salesExpertListTwo.map((data, key) => (
                  <div key={key} className={data.className}>
                    <div className="photos-circle-experts">
                      <img
                        src={data.img}
                        className="expert-img"
                        alt=""
                        srcSet=""
                      />
                      <img
                        src={circle}
                        className="expert-circle-img"
                        alt=""
                        srcSet=""
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* our culture */}

          {/* <div className="leadership-container-aboutus culture-container-aboutus ">
            <div className="leadership-parent-container-aboutus maxWidth">
              <p className="hero-parts-aboutus-head">
                Our <span>Culture</span>
              </p>
              <div className="culture-img-container culture-img-container-margin ">
                <div className="block-culture-img block-one-culture">
                  <img
                    src={cultureOne}
                    className="block-one-culture-img"
                    alt=""
                    srcSet=""
                  />
                </div>

                <div className="block-culture-img block-two-culture">
                  <div className="block-two-culture-block">
                    <div className="picOne-container-culture"></div>
                    <div className="picOne-container-culture"></div>
                  </div>

                  <div className="block-two-culture-block">
                    <img
                      src={cultureTwo}
                      className="block-two-culture-block-img"
                      alt=""
                      srcSet=""
                    />
                  </div>
                </div>

                <div className="block-culture-img block-three-culture">
                  <img
                    src={cultureThree}
                    className="block-three-culture-img"
                    alt=""
                    srcSet=""
                  />
                </div>
              </div>

              <div className="culture-img-container">
                <div className="block-culture-img block-culture-img-two  block-threee-culture ">
                  <div className="block-two-culture-block">
                    <div className="picOne-container-culture"></div>
                    <div className="picOne-container-culture"></div>
                  </div>
                </div>

                <div className="block-culture-img block-threee-culture  block-culture-img-two">
                  <img
                    src={cultureFour}
                    className="block-three-culture-img"
                    alt=""
                    srcSet=""
                  />
                </div>

                <div className="block-culture-img block-culture-img-two  block-three-culture ">
                  <div className="block-two-culture-block block-two-culture-block-2">
                    <div className="picOne-container-culture"></div>
                    <div className="picOne-container-culture"></div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* OUR TECH EXPERT */}

          <div className="tech-experts-container">
            {/* <img src={borderCircle} className="borderCircle" alt="" srcSet="" /> */}
            <div className="experts-container-aboutus experts-tech-container-aboutus  maxWidth ">
              <p className="hero-parts-aboutus-head">
                Our <span>Tech Experts</span>
              </p>
              <div className="photos-section-expert">
                <div className="photos-container-experts">
                  {techExpertList.map((data, key) => (
                    <div key={key} className={data.className}>
                      <div className="photos-circle-experts">
                        <img
                          src={data.img}
                          className="expert-img"
                          alt=""
                          srcSet=""
                        />
                        <img
                          src={circle}
                          className="expert-circle-img"
                          alt=""
                          srcSet=""
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
