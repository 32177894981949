import React, { Component, useEffect } from 'react';
import "./App.css";
import '../src/components/style/Global.css'
import Root from './Root';

function App() {

  // const setZoomLevel = () => {
  //   let zoomLevel;
  //   if (window.innerWidth >= 1000 && window.innerWidth <= 1300) {
  //     zoomLevel = 0.9;
  //   } else if (window.innerWidth <= 400) {
  //     zoomLevel = 0.9;
  //   } else {
  //     zoomLevel = 1;
  //   }
  //   document.body.style.zoom = zoomLevel.toString();
  // };

  // useEffect(() => {
  //   setZoomLevel();
  //   window.addEventListener('resize', setZoomLevel);
  //   return () => window.removeEventListener('resize', setZoomLevel);
  // }, []);

  return (
    <>
      <Root />
    </>
  );
}

export default App;