import React, { useState, useEffect } from "react";
import "./ClientAccount.css";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import userIcon from "../../../Assets/Images/profile.svg";
import myPolicy from "../../../Assets/Images/myPolicy.svg";
import myRequest from "../../../Assets/Images/myRequest.svg";
import settings from "../../../Assets/Images/settings.svg";
import logout from "../../../Assets/Images/logout.svg";
import InputFields from "../../InputFields/InputFields";
import ProfileCard from "./Profile Section/ProfileCard";
import PolicyCard from "./Policy Section/PolicyCard";
import Botton from "../../Button/Button";
import RequestCard from "./Request Section/RequestCard";
import SettingsCard from "./Settings Section/SettingsCard";
import { useSelector, useDispatch } from "react-redux";
import {
  Routes,
  Route,
  useNavigate,
  Link,
  useLocation,
} from "react-router-dom";
import { isLoggedInAction } from "../../../redux/Reducers/isLoginAuthentication";
import { getpolicyDataAction } from "../../../redux/Actions/GetpolicyDataAction";

const ClientAccount = () => {
  // policySection profileSection requestSection settingSection
  const [ContentType, setContentType] = useState("profileSection");
  const [IsUploadPolicyAttempted, setIsUploadPolicyAttempted] = useState(false); // true false
  const [NoPolicyFound, setNoPolicyFound] = useState(false); // true false
  const [ShowPolicyTypeList, setShowPolicyTypeList] = useState(false); // true false
  // navigation
  const navigate = useNavigate();
  //  dispatch
  const dispatch = useDispatch();
  const ClientData = useSelector((state) => {
    return state.getLoggedInStatus
  })
  console.log(ClientData,"ClientData");
  

  let formValues = {
    fullName: `${ClientData?.data?.loginData?.name}`  || "",
    phoneNumber: ClientData?.data?.loginData?.mobile|| "",
    email:ClientData?.data?.loginData?.email||"",
    userUploadedDocument: "",
    uploadedPolicyType: "",
    uploadedPolicyNumber: "",
    uploadedPolicyInsurer: "",
    uploadedPolicyDocument: "",
    uploadedPolicyStartDate: "",
    uploadedPolicyEndDate: "",
    isKycUpdated: true,
    sendAllNotification: false,
    sendPolicyUpdates: false,
    sendPolicyRenewalReminder: false,
    sendOffers: false,
    sendReminderCalls: false,
  };

  const [FormValue, setFormValue] = useState(formValues);
let token=ClientData?.data?.loginData?.token
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [ContentType])
  useEffect(()=>{
   dispatch(getpolicyDataAction(token))
  },[token])

  console.log(ClientData, "ClientDataClientData")

  const handleInputChange = (e) => {
    const { id, value, name, checked, type } = e?.target || {};
    if (type == "checkbox") {
      if (name == "sendAllNotification" && checked == true) {
        setFormValue((prevItems) => ({
          ...prevItems,
          sendAllNotification: true,
          sendPolicyUpdates: true,
          sendPolicyRenewalReminder: true,
          sendOffers: true,
          sendReminderCalls: true,
        }));
      } else if (name == "sendAllNotification" && checked == false) {
        setFormValue((prevItems) => ({
          ...prevItems,
          sendAllNotification: false,
          sendPolicyUpdates: false,
          sendPolicyRenewalReminder: false,
          sendOffers: false,
          sendReminderCalls: false,
        }));
      } else {
        setFormValue((prevItems) => ({
          ...prevItems,
          [name]: checked,
        }));
      }
    } else {
      setFormValue((prevItems) => ({
        ...prevItems,
        [name]: value,
      }));
    }
  };

  const handleUploadedPolicyInsurer = (e) => {
    setFormValue((prevItems) => ({
      ...prevItems,
      uploadedPolicyInsurer: e.value,
    }));
  };

  let breadcrumb = [
    {
      icon: userIcon,
      title: "My Profile",
      name: "profileSection",
    },
    {
      icon: myPolicy,
      title: "My Policies",
      name: "policySection",
    },
    // {
    //   icon: myRequest,
    //   title: "My Request",
    //   name: "requestSection",
    // },
    // {
    //   icon: settings,
    //   title: "Settings",
    //   name: "settingSection",
    // },
    {
      icon: logout,
      title: "Logout",
      name: "logoutSection",
    },
  ];

  const handleContentReveal = (name) => {
    // alert(name);
    if (name === "logoutSection") {
      dispatch(isLoggedInAction({ isLoggedIn: false }));
      navigate("/");
    }
    setContentType(name);
  };

  var today = new Date();
  var curHr = today.getHours();
  // let greetingTitle = "Hello, Good Evening!";

  // if (curHr < 12) {
  //   greetingTitle = "Hello, Good Morning!";
  // } else if (curHr < 18) {
  //   greetingTitle = "Hello, Good Afternoon!";
  // }

  const handleUploadPolicyScreen = (product) => {
    // alert(product);
    setIsUploadPolicyAttempted(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCancelPolicyUpload = () => {
    setIsUploadPolicyAttempted(false);
  };

  const handlePopUp = () => {
    if (ShowPolicyTypeList) {
      setShowPolicyTypeList(!ShowPolicyTypeList);
    }
  };

  const addNewPolicy = () => {
    alert("Add new Policy Btn Clicked!!");
  };

  return (
    <>
      <Navbar />
      <section className="clientAccount-container" onClick={handlePopUp}>
        <div className="clientAccount-parent maxWidth">
          <div className="content-container-profile">
            <div className="left-content-container-profile">
              <div className="breadCrumb-container-profile-section">
                {breadcrumb.map((data, key) => (
                  <div
                    className={
                      ContentType === data.name
                        ? "breadCrumb-profilesection breadCrumb-profilesection-active "
                        : "breadCrumb-profilesection"
                    }
                    key={key}
                    onClick={(e) => handleContentReveal(data.name)}
                  >
                    <div className="icon-profile-breadcrumb">
                      <img src={data.icon} alt="" srcSet="" />
                    </div>
                    <p>{data.title}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="right-content-container-profile">
              {
                ContentType !== "settingSection" && (
                  <div className="heading_rightSide_findMycar heading_rightSide_findMycar-policyCard">
                    <div>
                      {/* <p>{greetingTitle}</p> */}
                      <p
                        className={
                          NoPolicyFound &&
                            ContentType === "policySection" &&
                            !IsUploadPolicyAttempted
                            ? "info_block_left_desc info_block_left_desc-red"
                            : "info_block_left_desc"
                        }
                      >
                        {NoPolicyFound &&
                          ContentType === "policySection" &&
                          !IsUploadPolicyAttempted
                          ? "We don't find any policies in your in your account yet. Get your policy in 2min."
                          : ContentType === "policySection" && !NoPolicyFound
                            ? "You can manage all your policies."
                            : "Welcome to your profile."}
                      </p>
                    </div>
                    {/* {ContentType === "policySection" && !NoPolicyFound && (
                      <div>
                        <Botton
                          text="Add New Policy"
                          className="button btn-polsection"
                          event={(e) => addNewPolicy()}
                        />
                      </div>
                    )} */}
                  </div>
                )
              }

              {ContentType === "profileSection" && (
                <ProfileCard
                  FormValue={FormValue}
                  handleInputChange={handleInputChange}
                  ClientData={ClientData}
                />
              )}

              {ContentType === "policySection" && (
                <PolicyCard
                  FormValue={FormValue}
                  handleInputChange={handleInputChange}
                  NoPolicyFound={NoPolicyFound}
                  IsUploadPolicyAttempted={IsUploadPolicyAttempted}
                  handleUploadPolicy={handleUploadPolicyScreen}
                  ShowPolicyTypeList={ShowPolicyTypeList}
                  setShowPolicyTypeList={setShowPolicyTypeList}
                  handleUploadedPolicyInsurer={handleUploadedPolicyInsurer}
                  handleCancelPolicyUpload={handleCancelPolicyUpload}
                />
              )}

              {
                ContentType === "requestSection" && (
                  <RequestCard />
                )
              }

              {
                ContentType === "settingSection" && (
                  <SettingsCard
                    handleInputChange={handleInputChange}
                    FormValue={FormValue}
                  />
                )
              }

            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ClientAccount;
