import React, { useEffect } from "react";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import "./PrivacyPolicy.css";
import hero from "../../../Assets/Images/privacyPolicyHero.svg";

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <>
            <Navbar />

            {/* hero */}

            <div className="page-container">
                <div className="page-container-parent page-container-hero maxWidth">
                    <div className="privacy-policy-content">
                        <p className="black-blue-header">
                            Privacy <span>Policy</span>
                        </p>

                        <p>
                            This Privacy Policy outlines how BookMyInsurance Private Limited,
                            along with  the Insurance Providers (specified in the Terms
                            of Use), gather, retain, utilize,  transfer, and manage your
                            data (excluding non-personal data/information)  for the
                            purpose of delivering the Platform and/or the Services
                            (defined in the Terms of Use, accessible on the Platform) through
                            the Platform.
                        </p>

                        <p>
                            In order to facilitate and provide you (and any co-applicants,
                            members,  nominees, appointees, if applicable) with the
                            Services through the Platform,  BookMyInsurance needs to
                            collect various types of data and information  from you,
                            encompassing both personal and sensitive data, with your prior{" "}
                            explicit consent.
                        </p>
                    </div>

                    <img src={hero} alt="" srcset="" />
                </div>
            </div>

            {/* Acknowledgment */}

            <div className="claim-container-hero claim-container-hero-intiate">
                <div className="block-container-claims acknowledgment-block-container-claims maxWidth">
                    <p>Acknowledgment</p>

                    <span>
                        You confirm that you've read and understood this Policy. You also
                        give clear consent for us and our insurance providers to collect,
                        keep, share, process, and store your personal and sensitive
                        information according to this Policy. If you don't agree with this
                        Policy, please refrain from using/accessing this Platform or
                        obtaining any services/products from the insurance provider.
                    </span>

                    <span>
                        You recognize that you have the legal rights and authority to share
                        information with us. Furthermore, you acknowledge that by
                        collecting, sharing, processing, and transferring the information
                        you provide, it will not cause any loss or wrongful gain to you or
                        any other person.
                    </span>

                    <span>
                        If you disagree with the terms of this Privacy Policy, please
                        refrain from accessing or using our platform or availing any of our
                        services on our platform.
                    </span>
                </div>
            </div>

            {/* What We Collect */}

            <div className="page-container">
                <div className="page-container-parent  maxWidth">
                    <div className="privacy-policy-content">
                        <p className="black-blue-header">
                            What <span> We Collect</span>
                        </p>
                        <p className="head-bold-privacy">Personal Information:</p>
                        <p>
                            At BookMyInsurance, we prioritize respecting the privacy of our
                            users by upholding the highest standards for secure transactions
                            and safeguarding their personal information.
                        </p>
                        <p>
                            BookMyInsurance may gather personal identification information
                            from users through various means, including but not limited to,
                            when users visit the website, register on the site, place an
                            order, download details of any product/service, subscribe to the
                            newsletter, respond to a survey, fill out a form, and engage with
                            other activities, services, features, or resources provided by
                            BookMyInsurance.
                        </p>
                        <p>
                            Users may be requested to provide personally identifiable details,
                            financial and health-related information, such as name, age,
                            gender, email address, mailing address, phone number, PAN/Aadhar
                            number, nominee details, photograph, payment information,
                            payment/purchase history, credit score, monthly/annual income,
                            loan/financial history, health/medical/physical fitness
                            information, bank account details, credit card information, user’s
                            mobile device location, and any other related information required
                            for offering products and services by BookMyInsurance.
                        </p>
                        <p>
                            Users have the option to visit the website anonymously.
                            BookMyInsurance will only collect personal identification
                            information from users if they voluntarily submit such
                            information. Users can always choose not to provide personal
                            identification information, although it may limit their
                            participation in certain website-related activities.
                        </p>

                        <p className="head-bold-privacy">Non Personal Information:</p>
                        <p>
                            BookMyInsurance may gather non-personal identification information
                            about users whenever they engage with the website. This
                            information could encompass details like the browser used, the
                            type of device, and technical information regarding the user's
                            connection to the site, including the operating system and
                            internet service provider used, among other similar details.
                        </p>

                        <p className="black-blue-header">
                            How BookMyInsurance <span> Uses Collected Information</span>
                        </p>
                        <p className="head-bold-privacy">
                            BookMyInsurance may collect and use Users personal information for
                            the following purposes:
                        </p>
                        <p>
                            • To enhance customer service and efficiently address user
                            inquiries/requests. <br />
                            • To tailor the user experience, we may analyze aggregate
                            information to understand how users collectively utilize the
                            services and resources available on the website. <br />
                            • To enhance the user experience, BookMyInsurance may share
                            voluntarily provided information from users with third-party
                            service providers as necessary. <br />
                            • To optimize and enhance website usage/experience, we may utilize
                            user feedback to improve the products and services offered. <br />
                            • For payment processing, BookMyInsurance will only use user
                            information provided during order placement to fulfill that
                            specific order. We do not disclose this information to external
                            parties except as required to provide the service. <br />
                            • To share certain information (excluding personal details like
                            name, address, email address, or phone number) with third-party
                            advertising firms and/or ad agencies for targeted advertising when
                            users visit the website. <br />• We also share relevant user
                            information with selected insurance companies to fulfill user
                            requests for products or services. <br />
                            • To address user inquiries and resolve concerns related to any
                            product or service offered on the website. <br />
                            • BookMyInsurance may share user information in other instances
                            where we have user consent. <br />
                        </p>

                        <p className="black-blue-header">
                            Sharing User's <span> Personal Information</span>
                        </p>
                        <p className="head-bold-privacy">Third Party Websites</p>
                        <p>
                            Users may encounter content on the BookMyInsurance website that
                            directs to the websites and services of insurance companies, which
                            may be featured for online insurance sales. BookMyInsurance does
                            not govern the content or links on these external sites and is not
                            accountable for the practices they employ. Moreover, these
                            external sites or services, along with their content and links,
                            may undergo continuous changes. They may also have their own
                            privacy policies and customer service guidelines. Browsing and
                            engaging with any other website, including those linking to the
                            BookMyInsurance site, is subject to the terms and policies of that
                            specific website.
                        </p>

                        {/* How BookMyInsurance Protects User’s Information */}

                        <p className="black-blue-header">
                            How BookMyInsurance <span> Protects User's Information</span>
                        </p>
                        <p>
                            At BookMyInsurance, we prioritize the security of Your
                            Information, ensuring it is protected from unauthorized access,
                            usage, disclosure, or alteration. We adhere to legal mandates and
                            industry standards, implementing reasonable security practices and
                            procedures encompassing technical, operational, managerial, and
                            physical controls to safeguard your personal information while it
                            remains under our control.
                        </p>
                        <p>
                            While we strive to uphold industry-standard security measures to
                            protect Your Personal Information, we acknowledge that the
                            internet and computer networks are not entirely secure. Therefore,
                            we cannot guarantee absolute assurance regarding the security of
                            Your Personal Information. Consequently, You understand and agree
                            that BookMyInsurance shall not be held responsible in any manner
                            for any loss of Your Information or other data shared with us or
                            held by us, in the event of loss or damage caused to You due to
                            Force Majeure events. You further acknowledge that any
                            information, including Your Personal Information, transmitted to
                            us or arising from Your use of the Platform, is done so at Your
                            own risk.
                        </p>

                        {/* Changes To This Policy */}

                        <p className="black-blue-header">
                            Changes <span> To This Policy</span>
                        </p>
                        <p>
                            BookMyInsurance reserves the right to update this Privacy Policy
                            as deemed necessary. We encourage users to regularly review this
                            page for any changes to stay informed about how BookMyInsurance is
                            committed to safeguarding the personal information we collect.
                            Users acknowledge and agree that it is their responsibility to
                            periodically review this Policy and stay informed of any
                            modifications.
                        </p>

                        {/* Contact Us */}

                        <p className="black-blue-header">
                            Contact <span> Us</span>
                        </p>
                        <p>
                            If you have any questions, comments, or grievances in relation to our privacy policy as outlined above, you may write to our Grievance Office at below:
                        </p>

                        <div className="contact-privPolicy">
                            <p>Name:</p>
                            <p>Mr. Pawan Poojary</p>
                        </div>
                        <div className="contact-privPolicy">
                            <p>Address:</p>
                            <p>UNIT NO. 212 & 212 A, Suncity Success Tower, Golf Course Ext Rd, Sector 65, Gurugram, Haryana 122005.</p>
                        </div>
                        <div className="contact-privPolicy contact-privPolicy-hyperLink">
                            <p>Email ID:</p>
                            <p> <a href="mailto:grievance@bookmyinsurance.com">grievance@bookmyinsurance.com</a> </p>
                        </div>
                    </div>
                </div>
            </div>



            <Footer />
        </>
    );
};

export default PrivacyPolicy;
