import React, { useEffect, useState } from "react";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import close from "../../Assets/Images/Close.svg";
import Calender from "../InputFields/Calender";
import DropDown from "../DropDown/DropDown";
import grayArrow from "../../Assets/Images/grayArrow.svg";
import ncbToolTipIcon from "../../Assets/Images/ncbToolTipIcon.svg";
import Botton from "../Button/Button";
import CheckBox from "../Checkbox/CheckBox";
import RadioButton from "../RadioButton/RadioButton";
import { NEW } from "../../utils/commonUtils";
import { Routes, Route, useNavigate, Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import loader2 from "../../Assets/Loader/Loader.gif";
import "../RegNumberForm/loaderMain.css";
import InputFields from "../InputFields/InputFields";
import { alladdOnDataDataAction } from "../../redux/Reducers/addOnsDataReducer";
import { useDispatch } from "react-redux";
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader";
import CustomDateField from "../InputFields/CustomDateField/CustomDateField";
import { isInitialAction } from "../../redux/Reducers/inspectionReducer";
import { transactionURLAction } from "../../redux/Actions/TransactionAction";
import SearchSelect from "../DropDown/Search Select/SearchSelect";
const ConfirmDetails = (props) => {
  const dispatch = useDispatch();
  const {
    closeModal,
    carDetails,
    allCarDetails,
    CompanyImage,
    CompanyName,
    businessType,
    handleInputCarDetails,
    vehicleData,
    isFindMyCar,
    passVehicleData,
    policyTypeList,
    setCarDetails,
    ShowExpDateCalendar,
    setShowExpDateCalendar,
    ShowRegDateCalendar,
    setShowRegDateCalendar,
    ShowOdExpDateCalendar,
    setShowOdExpDateCalendar,
    CompanyWiseLoader,
    CalendarDeactiveFlag,
    type,
    setIsRegDateActive,
    handleRoutePform,
    setcarDetailsCopyState,
    carDetailsCopyState,
    handleCopiedDetails,
    setLoading1,
    setCheckboxList,
    CheckboxList,
    loading1,
    errors

  } = props;

  const month = new Date(carDetails.policyRegDate).toLocaleString("default", {
    month: "long",
  });

  const year = new Date(carDetails.policyRegDate).toLocaleString("default", {
    year: "numeric",
  });

  const navigate = useNavigate();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  const sbiQuickQuote = useSelector((state) => {
    return state?.sbiQuickQuote;
  });

  const sbiQuickQuoteLoading = sbiQuickQuote?.isLoading;

  const [ShowMonthList, setShowMonthList] = useState(false);
  const [ShowYearList, setShowYearList] = useState(false);
  const [MonthValue, setMonthValue] = useState(month);
  const [YearValue, setYearValue] = useState(year);
  const [isApiLoaded, setIsApiLoaded] = useState(false);
  const [showRegNoErr, setshowRegNoErr] = useState(false);
  const [showDeclaration, setshowDeclaration] = useState(true);
  const [ShowPolicyTypeList, setShowPolicyTypeList] = useState(false);


  const [StoreVehicleNumber, setStoreVehicleNumber] = useState(carDetails?.vehicleNumber);


  useEffect(() => {
    let carNo = (carDetails?.vehicleNumber).toUpperCase() // add for vehicle  into the upperCase
    setStoreVehicleNumber(carNo)
    setcarDetailsCopyState((prev) => ({
      ...prev,
      vehicleNumberCopy: carNo
    }))
  }, [carDetails?.vehicleNumber])

  const handleClick = (checkboxName) => {
    setCheckboxList({
      ...CheckboxList,
      [checkboxName]: !CheckboxList[checkboxName]
    })
    setshowDeclaration(!showDeclaration);

  }

  useEffect(() => {
    setIsApiLoaded(CompanyWiseLoader);
  }, [CompanyWiseLoader]);


  const handlePopUps = () => {
    if (ShowMonthList === true) {
      setShowMonthList(false);
    } else if (ShowYearList === true) {
      setShowYearList(false);
    } else if (ShowPolicyTypeList === true) {
      setShowPolicyTypeList(false);
    }
  };

  let policyListForNew = [
    {
      value: "Bundled (3Y TP + 1Y OD)",
      label: "Bundled",
      desc: "1 Year OD + 3 year Third Party",
    },
  ]

  const handleBlurVehicleNumber = (e) => {
    if (carDetails?.vehicleNumber.length <= 11) {
      setCarDetails((prevItems) => ({
        ...prevItems,
        vehicleNumber: e.target.value,
      }))
    }
  }

  useEffect(() => {
    if (carDetailsCopyState.vehicleNumberCopy?.length < 5) {
      setshowRegNoErr(true)
    }
    else {
      setshowRegNoErr(false)
    }
  }, [carDetailsCopyState?.vehicleNumberCopy

  ])

  const HandleCarNo = ((e) => {
    let x = e.target.value;
    let carNo = x.toUpperCase()
    if (carNo?.length <= 11) {
      setStoreVehicleNumber(carNo)
    }

  })

  const style = {
    borderColor: {
      border: showRegNoErr && '1px solid red'
    }
  }
  const HanleCloseModal = (() => {
    closeModal()
    setcarDetailsCopyState((...prev) => ({
      ...prev,
      vehicleNumberCopy: carDetails?.vehicleNumber?.toUpperCase() || "",
      makeCopy: carDetails?.make || "",
      modelCopy: carDetails?.model || "",
      varientCopy: carDetails?.varient || "",
      fuelTypeCopy: carDetails?.fuelType || "",
      ccCopy: carDetails?.cc || "",
      regDateCopy: carDetails?.policyRegDate || "",
      prevPolicyTypeCopy: carDetails?.prevPolicyType || "",
      policyExpCopy: carDetails?.policyExpDate || "",
      odPolicyExpCopy: carDetails?.prevPolicyODexpDate || "",
      tpPolicyExpCopy: carDetails?.prevPolicyTPexpDate || "",
      isClaimPrevPolicyCopy: carDetails?.isClaimPrevPolicy,
      prevNcbCopy: carDetails?.prevNcb,
      newNcbCopy: carDetails?.newNcb,
      electricalAccesoriesCopy: carDetails?.electricalAccesories || "",
      policyTypeValueCopy: carDetails?.PolicyTypeValue || "",
      idvValueCopy: carDetails?.idvValue || "",

    }))
  })

  let yearListRange = range(1990, getYear(new Date()) + 0, 1);
  if (carDetails?.typeOfBusiness?.toUpperCase() == "NEW") {
    yearListRange = range(getYear(new Date()) - 1, getYear(new Date()) + 1);
  }


  // const yearListRange = range(1990, getYear(new Date()) + 0, 1);

  const yearList = yearListRange.map((data, key) => ({
    label: data,
    value: data,
  }));

  yearList.reverse();

  return (
    <>
      <div className="confirm-details-parent" onClick={handlePopUps} style={{ position: "relative" }} >
        <div id="cover-spin" style={{ display: loading1 ? "" : "none" }}>
          <img src={loader2} className="gifLoader"></img>
        </div>
        <form className="confirmDetailContainer-popUp">
          <div className="head-confirmDetailContainer-popUp">
            <p>Verify details for accurate premium.</p>
            <img src={close} onClick={HanleCloseModal} alt="" srcSet="" />
          </div>
          <div className="form-cnfrmDetails-popup" id="confirmContainer">
            {
              businessType !== NEW && (
                <div className="form-cnfrmDetails-inputGrp">
                  <div className="inputGropOne_form inputGrop inputGrop_caseTwo" style={{ width: "100%" }}>
                    <p className="label-popUp label-popUp-two">
                      Vehicle No.
                    </p>
                    <div className="">
                      <InputFields
                        type="text"
                        className="inputs-form uppercaseText"
                        onChangeEvent={handleCopiedDetails}
                        showRegNoErr={showRegNoErr}
                        value={(carDetails?.vehicleNumber)?.trim("")}
                        name="vehicleNumber"
                        id="vehicleNumber"
                      />
                      {showRegNoErr && (
                        <p className="err-msg">
                          Please enter a valid Registration Number.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
                  </div>
                </div>
              )
            }

            <div className="form-cnfrmDetails-inputGrp">
              {businessType == NEW && CompanyName == "shreeram" ? ("") : (
                <div className="cnfrmDetails-inputGrp-container">
                  <label htmlFor="" className="label-popUp">
                    Registration Date
                  </label>
                  <div className="calender-cnfrm-popup">
                    <CustomDateField
                      className="pForm_datePicker pForm_datePicker-tab "
                      btnId="policyRegDateBtn"
                      textId="policyRegDateTxt"
                      imgId="policyRegDateImg"
                      dateValue={carDetailsCopyState?.regDateCopy}
                      calendarReveal={ShowRegDateCalendar}
                      CalendarDeactiveFlag={CalendarDeactiveFlag}
                      setCalendarReveal={setShowRegDateCalendar}
                      setIsRegDateActive={setIsRegDateActive}
                    />
                  </div>
                </div>)}

              <div className="cnfrmDetails-inputGrp-container">
                <label htmlFor="" className="label-popUp">
                  Manufacturing Date
                </label>
                <div className={(businessType == NEW && CompanyName == "shreeram") ? "dpGrp-popUp dpGrp-popUp-gap" : "dpGrp-popUp"}>
                  <SearchSelect
                    type="submit"
                    optionList={monthList}
                    value={MonthValue}
                    handleSelect={(e) => setMonthValue(e.value)}
                    placeholder="Select Month"
                    errState={errors?.MonthValue ? true : false}
                    errMsg={errors?.MonthValue ? errors?.MonthValue : ""}
                  />

                  {/* <SearchSelect
                    type="submit"
                    optionList={yearList}
                    handleSelect={(e) => setYearValue(e.value)}
                    value={Number(YearValue)}
                    placeholder="Select Year"
                    errState={errors?.YearValue ? true : false}
                    errMsg={errors?.YearValue ? errors?.YearValue : ""}
                  /> */}
                  <DropDown
                    type="submit"
                    dropDownMenu={yearList}
                    Head="Select Year"
                    clickEvent={(e) => console.log(e)}
                    revealOptions={ShowYearList}
                    value={YearValue}
                    arrowInGray={grayArrow}
                    afterTrueClass="dropDown_container NoBorderRadius"
                    className="dropDown_container"
                    valueSelectionEvent={(e) => setYearValue(e.target.value)}
                    headClick={(e) => setShowYearList(!ShowYearList)}
                    revealOptionsClasses=" dropDownOptions showdropDownOptions"
                    classNameTwo="dropDownOptions"
                    dropDownHeadClass="dropDownHead"
                  />
                </div>
              </div>
            </div>

            <div className="form-cnfrmDetails-inputGrp">
              <div className="cnfrmDetails-inputGrp-container">
                <label htmlFor="" className="label-popUp">
                  {
                    businessType !== NEW ? "Previous Policy Type" : "New Policy Type"
                  }
                </label>
                <DropDown
                  dropDownMenu={businessType !== NEW ? policyTypeList : []}  // policyListForNew i removed this value for dropdown in new and put emoty array 
                  type="submit"
                  Head="Select Month"
                  name={businessType !== NEW ? "prevPolicyType" : "PolicyTypeValue"}
                  clickEvent={(e) => console.log(e)}
                  revealOptions={ShowPolicyTypeList}
                  value={businessType !== NEW ? carDetailsCopyState?.prevPolicyTypeCopy : carDetailsCopyState?.policyTypeValueCopy}
                  arrowInGray={businessType !== NEW ? "" : carDetailsCopyState?.policyTypeValueCopy}

                  afterTrueClass="dropDown_container NoBorderRadius"
                  className="dropDown_container"
                  valueSelectionEvent={handleCopiedDetails}
                  headClick={(e) => setShowPolicyTypeList(!ShowPolicyTypeList)}
                  revealOptionsClasses=" dropDownOptions   showdropDownOptions "
                  classNameTwo="dropDownOptions"
                  dropDownHeadClass="dropDownHead"
                />
                {/* {carDetailsCopyState?.prevPolicyTypeCopy === "Third Party" && carDetailsCopyState?.policyTypeValueCopy === "Comprehensive" && (
                  <p className="msgIn-red">
                    Inspection required for Comprehensive Policy.
                  </p>
                )} */}
              </div>
              <div className="exp-calendar-container">
                {
                  businessType !== NEW ? (
                    <>
                      {(carDetailsCopyState?.prevPolicyTypeCopy === "Bundled (3Y TP + 1Y OD)" ||
                        carDetailsCopyState?.prevPolicyTypeCopy === "Bundled") && carDetailsCopyState?.policyTypeValueCopy === "Own Damage" ? (
                        <>
                          <div className="cnfrmDetails-inputGrp-container">
                            <label htmlFor="" className="label-popUp">
                              Previous Policy TP Expiry Date
                            </label>
                            <div className="calender-cnfrm-popup">
                              <CustomDateField
                                className="pForm_datePicker pForm_datePicker-tab "
                                btnId="policyExpDateBtn"
                                textId="policyExpDateTxt"
                                imgId="policyExpDateImg"
                                dateValue={carDetailsCopyState?.policyExpCopy}
                                calendarReveal={ShowExpDateCalendar}
                                CalendarDeactiveFlag={CalendarDeactiveFlag}
                                setCalendarReveal={setShowExpDateCalendar}

                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="cnfrmDetails-inputGrp-container">
                          <label htmlFor="" className="label-popUp">
                            Previous Policy Expiry Date
                          </label>
                          <div className="calender-cnfrm-popup">
                            <CustomDateField
                              className="pForm_datePicker pForm_datePicker-tab "
                              btnId="policyExpDateBtn"
                              textId="policyExpDateTxt"
                              imgId="policyExpDateImg"
                              dateValue={carDetailsCopyState?.policyExpCopy}
                              calendarReveal={ShowExpDateCalendar}
                              CalendarDeactiveFlag={CalendarDeactiveFlag}
                              setCalendarReveal={setShowExpDateCalendar}

                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="cnfrmDetails-inputGrp-container">
                      <label htmlFor="policyStartDate" className="label-popUp">
                        New Policy Start Date
                      </label>
                      <div className="calender-cnfrm-popup">
                        <CustomDateField
                          className="pForm_datePicker pForm_datePicker-tab "
                          btnId="policyRegDateBtn"
                          textId="policyRegDateTxt"
                          imgId="policyRegDateImg"
                          dateValue={carDetailsCopyState?.regDateCopy}
                          calendarReveal={ShowRegDateCalendar}
                          CalendarDeactiveFlag={CalendarDeactiveFlag}
                          setCalendarReveal={setShowRegDateCalendar}
                          setIsRegDateActive={setIsRegDateActive}
                          activeVal="NewDate"
                          isDisabled={(CompanyName === "shreeram") ? true : false}
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>

            {
              businessType !== NEW && (
                <div className=" form-cnfrmDetails-inputGrp" >
                  {(carDetailsCopyState?.policyTypeValueCopy === "Third Party" ||
                    carDetailsCopyState?.prevPolicyTypeCopy === "Third Party" ||
                    passVehicleData.prevPolicyExpLastMonth === true) ? (
                    ""
                  ) :
                    (
                      <>
                        {businessType !== NEW && (
                          <div className="input_group_editForm input_group_editForm-popUp">
                            <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
                              <p className="label-popUp label-popUp-two">
                                Have you taken any Claim in your existing policy?
                              </p>
                              <div className="radioContainer-pform">
                                <RadioButton
                                  label="Yes"
                                  className=""
                                  id="claimYesConfirm"
                                  groupName="isClaimPrevPolicyCopy"
                                  value="Yes"
                                  changeEvent={handleCopiedDetails}
                                  isChecked={
                                    carDetailsCopyState?.isClaimPrevPolicyCopy === true
                                      ? true
                                      : false
                                  }
                                />
                                <RadioButton
                                  label="No"
                                  className=""
                                  id="claimNoConfirm"
                                  groupName="isClaimPrevPolicyCopy"
                                  value="No"
                                  changeEvent={handleCopiedDetails}
                                  isChecked={
                                    carDetailsCopyState?.isClaimPrevPolicyCopy === false
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )
                  }
                  <div className="cnfrmDetails-inputGrp-container">
                    {
                      (carDetailsCopyState?.prevPolicyTypeCopy === "Bundled (3Y TP + 1Y OD)" ||
                        carDetailsCopyState?.prevPolicyTypeCopy === "Bundled") && carDetailsCopyState?.policyTypeValueCopy === "Own Damage" && (
                        <>
                          <label htmlFor="" className="label-popUp">
                            Previous Policy OD Expiry Date
                          </label>
                          <div className="calender-cnfrm-popup">
                            <CustomDateField
                              className="pForm_datePicker pForm_datePicker-tab "
                              btnId="policyOdExpDateBtn"
                              textId="policyOdExpDateTxt"
                              imgId="policyOdExpDateImg"
                              dateValue={carDetailsCopyState?.odPolicyExpCopy}
                              calendarReveal={ShowOdExpDateCalendar}
                              CalendarDeactiveFlag={CalendarDeactiveFlag}
                              setCalendarReveal={setShowOdExpDateCalendar}
                            />
                          </div>
                          <p className="od-msg" >(For bundled policies (1-yr OD + 5-yr TP), check the OD expiry date)</p>
                        </>
                      )
                    }
                  </div>
                </div>
              )
            }

            {businessType !== NEW && <div className="hr-popUp"></div>}

            {(carDetailsCopyState?.policyTypeValueCopy === "Third Party" ||
              carDetailsCopyState?.prevPolicyTypeCopy === "Third Party" ||
              passVehicleData.prevPolicyExpLastMonth === true) ? (
              ""
            ) :
              (
                <>
                  {businessType !== NEW && (
                    <>
                      {!carDetailsCopyState?.isClaimPrevPolicyCopy ? (
                        <div className="form-cnfrmDetails-inputGrp">
                          <div className="cnfrmDetails-inputGrp-container">
                            <div className="Ncb_alert_tooltip">
                              <label htmlFor="" className="label-popUp">
                                Previous NCB
                              </label>
                              <div class="tooltip-container">
                                <img src={ncbToolTipIcon} alt="" />
                                <span class="tooltip-text">Wrong NCB declaration may reject your claim</span>
                              </div>
                            </div>

                            <div className="ncb-btn-editform">
                              <Botton
                                text="0%"
                                className={
                                  carDetailsCopyState.prevNcbCopy == "0"
                                    ? "activeNcbBtn ncbBtn-editForm"
                                    : "ncbBtn-editForm"
                                }
                                event={(e) => {
                                  // setIsApiLoaded(true);
                                  handleCopiedDetails(0, "prevNcbCopy", "20");
                                }}
                              />
                              <Botton
                                text="20%"
                                className={
                                  carDetailsCopyState.prevNcbCopy == "20"
                                    ? "activeNcbBtn ncbBtn-editForm"
                                    : "ncbBtn-editForm"
                                }
                                event={(e) => {
                                  // setIsApiLoaded(true);
                                  handleCopiedDetails("20", "prevNcbCopy", "25");
                                }}
                              />
                              <Botton
                                text="25%"
                                className={
                                  carDetailsCopyState.prevNcbCopy == "25"
                                    ? "activeNcbBtn ncbBtn-editForm"
                                    : "ncbBtn-editForm"
                                }
                                event={(e) => {
                                  // setIsApiLoaded(true);
                                  handleCopiedDetails("25", "prevNcbCopy", "35");
                                }}
                              />
                              <Botton
                                text="35%"
                                className={
                                  carDetailsCopyState.prevNcbCopy == 35
                                    ? "activeNcbBtn ncbBtn-editForm"
                                    : "ncbBtn-editForm"
                                }
                                event={(e) => {
                                  // setIsApiLoaded(true);
                                  handleCopiedDetails("35", "prevNcbCopy", "45");
                                }}
                              />
                              <Botton
                                text="45%"
                                className={
                                  carDetailsCopyState.prevNcbCopy == 45
                                    ? "activeNcbBtn ncbBtn-editForm"
                                    : "ncbBtn-editForm"
                                }
                                event={(e) => {
                                  // setIsApiLoaded(true);
                                  handleCopiedDetails("45", "prevNcbCopy", "50");
                                }}
                              />
                              <Botton
                                text="50%"
                                className={
                                  carDetailsCopyState.prevNcbCopy == 50
                                    ? "activeNcbBtn ncbBtn-editForm"
                                    : "ncbBtn-editForm"
                                }
                                event={(e) => {
                                  // setIsApiLoaded(true);
                                  handleCopiedDetails("50", "prevNcbCopy", "50");
                                }}
                                name="prevNcbCopy"
                                value="50"
                              />
                            </div>
                          </div>

                          <div className="cnfrmDetails-inputGrp-container">
                            <label htmlFor="" className="label-popUp">
                              New NCB
                            </label>
                            <div className="ncb-btn-editform">
                              <Botton
                                text={!carDetailsCopyState?.newNcbCopy || carDetailsCopyState?.newNcbCopy !== "0" ? carDetailsCopyState?.newNcbCopy + "%" : carDetailsCopyState?.ncbCopy + "%"}
                                className="activeNcbBtn ncbBtn-editForm"
                                name="newNcb"
                                value={carDetailsCopyState?.newNcbCopy || carDetailsCopyState?.newNcbCopy !== "0" ? carDetailsCopyState?.newNcbCopy : carDetailsCopyState?.ncbCopy}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}
                </>
              )
            }

            <div className="checkbox_container checkbox_container-popUp "  >
              <p className="small_text small_textModal checkBox-label"> By clicking on "Buy Now",  I agree to T&C and I declare the information provided above are right as per my knowledge. And there won’t be any objection during the claim process.
              </p>
            </div>

            {!showDeclaration && (
              <p className="err-msg modified-err-msg">
                *Please accept the declaration before proceeding.
              </p>
            )}
          </div>

          {/* <div className="btnContainer_pform btnContainer_pform-popUp btnContainer_pform-cnfrmDetails btnContainer_pform-cnfrmDetails-Mobile ">
            <Botton
              text="Bacccck"
              className="button btn_pForm  secondry_btn"
              event={closeModal}
            />
            {
              !loading1 ? (
                <Botton
                  type="subddmit"
                  text="Proceed"
                  className="button btn_pForm"
                  isDisabledButton={isApiLoaded}
                  event={(e) => handleRoutePform(e)}
                />
              ) : (
                <ButtonLoader className="btn_pForm" />
              )
            }
          </div> */}
        </form >

        <div className="btnContainer_pform btnContainer_pform-popUp btnContainer_pform-cnfrmDetails  ">
          <Botton
            text="Back"
            className="button btn_pForm  secondry_btn"
            event={closeModal}
          />
          {
            !loading1 ? (
              <Botton
                // type="submit"
                text="Buy Now"
                className="button btn_pForm"
                // isDisabledButton={isApiLoaded}
                event={(e) => handleRoutePform(e)}
              />
            ) : (
              <ButtonLoader className="btn_pForm" />
            )
          }
        </div>
      </div >
    </>
  );
};


let monthList = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

export default ConfirmDetails;