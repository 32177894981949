import {
    transactionURLAction
} from "../Actions/TransactionAction";

import { createSlice } from "@reduxjs/toolkit";

export const transactionURLReducer = createSlice({
    name: "getTransactionStatus",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(transactionURLAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(transactionURLAction.fulfilled, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(transactionURLAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        restTransactionReducer: (state, action) => {
            state.data = undefined
        }
    }
});
export const { restTransactionReducer } = transactionURLReducer.actions;
