import React, { useEffect } from "react";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import "./Grievance.css";
import hero from "../../../Assets/Images/grievanceHero.svg";

const Grievance = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <>
            <Navbar />

            {/* hero */}

            <div className="page-container">
                <div className="page-container-parent page-container-hero maxWidth">
                    <div className="privacy-policy-content">
                        {/* Grievance Redressal Policy */}
                        <p className="black-blue-header">
                            Grievance <span>Redressal Policy</span>
                        </p>
                        <p>
                            BookMyInsurance believes that delivering excellent customer
                            service is crucial for continuous business growth. Therefore, the
                            company is committed to resolving customer complaints and
                            grievances in a manner that effectively addresses their concerns
                            and ensures customer satisfaction.
                        </p>
                    </div>

                    <img src={hero} alt="" srcset="" />
                </div>
            </div>

            {/* Acknowledgment */}

            <div className="claim-container-hero claim-container-hero-intiate">
                <div className="block-container-claims block-container-grievance  maxWidth">
                    <p>OBJECTIVE</p>

                    <p>
                        The purpose of this policy is to establish a grievance redressal
                        mechanism that is efficient and effective for policyholders,
                        nominees, and other individuals claiming under policies. This policy
                        has been developed with the following considerations in mind:
                    </p>

                    <span>
                        • Customer complaints are addressed promptly and courteously. <br /><br />
                        • Fair treatment of customers is ensured at all times. <br /><br />•
                        Transparency with customers is maintained throughout the process.{" "}
                        <br /><br />• Efficient and fair handling of all complaints is guaranteed.{" "}
                        <br /><br />
                        • Customers are informed about internal escalation procedures for
                        their complaints/grievances. <br /><br />• Customers are made aware of
                        their options for alternative remedies if they are not completely
                        satisfied with the Company's response. <br /><br />• We acknowledge that
                        our quality and business objectives are interconnected, and we
                        continuously enhance our customer complaint handling process using
                        various tools and available information technology for business
                        process improvement.
                    </span>
                </div>
            </div>

            {/* Scope Of Policy */}

            <div className="page-container">
                <div className="page-container-parent  maxWidth">
                    <div className="grievance-content">
                        <p className="black-blue-header">
                            Scope <span> Of Policy</span>
                        </p>
                        <br />
                        <p>
                            The policy applies to all complaints or grievances submitted by
                            the policyholder, nominee, beneficiary, or authorized person (with
                            written consent from the policy owner). Complaints from third
                            parties or agencies on behalf of the customer will only be
                            accepted if we have written consent from the policyholder.
                        </p>
                        <br />
                        <p>
                            Grievances received from consumer forums, ombudsman offices, or
                            courts will be handled separately by the legal team.
                        </p>
                        <br />
                        <p>Inquiries or requests are not included in this policy.</p>
                        <br />
                        <br />

                        {/* Some Important Definations */}

                        <p className="black-blue-header">
                            Some <span> Important Definations</span>
                        </p>
                        <br />
                        <p>
                            The term <span>"Complainant"</span> refers to a policyholder,
                            prospective customer, or any beneficiary of an insurance policy
                            who has lodged a complaint or grievance against the insurer or the
                            company.
                        </p>
                        <br />
                        <p>
                            A <span>"Complaint"</span> or <span>"Grievance"</span> refers to a
                            written expression, which can also include electronic
                            communication, where a person expresses dissatisfaction with an
                            insurer, company, or other regulated entities regarding their
                            actions or lack of actions, or about the quality of service
                            provided.
                        </p>
                        <br />
                        <p className="head-bold-privacy">Important Note:</p>
                        <p>
                            An inquiry or request would not fall within the definition of the
                            complaint or grievance.
                        </p>
                        <br />
                        <p style={{ color: "#1A1B1D" }}>
                            An Inquiry and Request would mean the following:
                        </p>
                        <br />
                        <p>
                            An <span> "Inquiry" </span> is any message from a customer asking
                            for information about a company and/or its services.
                        </p>
                        <br />
                        <p>
                            A <span> "Request" </span> is any message from a customer asking
                            for a service, like a change or modification in the policy.
                        </p>
                        <br />

                        {/* Complaint Redressal Process */}

                        <p className="black-blue-header">
                            Complaint <span> Redressal Process</span>
                        </p>
                        <br />
                        <p style={{ color: "#1A1B1D" }}>
                            If you have a complaint you'd like to address, feel free to reach
                            out to us using any of the following methods, providing the
                            details of your concern.
                        </p>
                        <br />

                        {/* Means Of Communication */}

                        <div className="grievance-block">
                            <div className="grievance-block-header">
                                <p>Means Of Communication</p>
                            </div>

                            <div className="grievance-block-elements">
                                <p>
                                    Email:{" "}
                                    <a href="mailto:support@bookmyinsurance.com" style={{ color: "#5086FF" }} >
                                        support@bookmyinsurance.com
                                    </a>
                                </p>
                                <p>
                                    Letter:{" "}
                                    <span>
                                        Grievance Officer, UNIT NO. 212 & 212 A, Suncity Success
                                        Tower, Golf Course Ext Rd, Sector 65, Gurugram, Haryana
                                        122005.
                                    </span>
                                </p>
                                <p>
                                    Contact Us:{" "}
                                    <span>
                                        Customers can reach us at 9811223458 any time, seven days a
                                        week, including holidays, 24 hours a day.
                                    </span>
                                </p>
                                <p>
                                    Branch:{" "}
                                    <span>
                                        Customers have the option to speak with the branch head to
                                        address their concerns, or they can be directed on how to
                                        register a complaint. Branch location has been provided on our
                                        website at <a href="https://www.bookmyinsurance.com/#/Contact-us" style={{ color: "#5086FF" }} >https://www.bookmyinsurance.com/#/Contact-us</a>
                                    </span>
                                </p>
                                <p>
                                    Grievance Officer:{" "}
                                    <span>
                                        If the customer is dissatisfied with the decision or has not received any response within 14 working days, they can escalate the matter to Grievance Redressal Officer, <span style={{ color: "#000" }} >Mr. Pawan Poojary,</span> at <a href="mailto:grievance@bookmyinsurance.com" style={{ color: "#5086FF" }} >grievance@bookmyinsurance.com</a> .
                                    </span>
                                </p>
                            </div>

                        </div>

                        {/* Procedure for resolving inquiries */}

                        <br />

                        <div className="grievance-block">
                            <div className="grievance-block-header">
                                <p>Procedure for resolving inquiries</p>
                            </div>
                            <div className="grievance-block-elements-gray">
                                <p>All complaints will receive an acknowledgment receipt within 24 business hours of being received.</p>
                                <p>All courier inquiries will receive a response within 14 days of receipt.</p>
                                <p>Immediate acknowledgment will be provided for all grievances from walk-in customers, and a record will be maintained accordingly.</p>
                                <p>Depending on the nature of the grievance, the company will endeavor to resolve it within 14 business days from the date the complaint is received.</p>
                                <p>Upon resolution of the complaint, a closure email will be sent to the customer, inviting them to rate their experience.</p>
                            </div>
                        </div>

                        <br />

                        {/* Grievance Resolution */}

                        <div className="grievance-block">
                            <div className="grievance-block-header">
                                <p>Procedure for resolving inquiries</p>
                            </div>
                            <div className="grievance-block-elements-">
                                <p>Bookmyinsurance strives to address all customer grievances to their satisfaction. To ensure equitable resolution, regulatory conditions stipulate criteria for closing grievances. According to IRDAI regulations, a grievance is considered resolved when:</p>
                                <span>• When Bookmyinsurance has fully complied with the complainant's request.</span>
                                <span>• When the complainant has provided written confirmation of accepting the company's response.</span>
                                <span>• If the complainant has not replied to the Company within 8 weeks of receiving the Company's written response.</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Grievance;
