import { createAsyncThunk } from "@reduxjs/toolkit";

// Production Url rto.js
//get Rto List
export const getRtoAction = createAsyncThunk("getRtoList", async (type) => {
  const response = await fetch(
    `${process.env.REACT_APP_GET_RTO_URL}?type=${type}`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );
  return response.json();
});

// get State List
export const getStateAction = createAsyncThunk("getStateList", async (type) => {
  const response = await fetch(
    `${process.env.REACT_APP_GET_RTO_URL}?type=${type}`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );
  return response.json();
});

// get City List
export const getCityAction = createAsyncThunk(
  "getCityList",
  async (stateName) => {
    const response = await fetch(
      `${process.env.REACT_APP_GET_RTO_URL}?type=city&statename=${stateName}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const getCityAction1 = createAsyncThunk(
  "getCityList",
  async (stateName) => {
    const response = await fetch(
      `${process.env.REACT_APP_GET_RTO_URL}?type=city&isAllCityRequired=${true}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);
// Production Url loanprovider.js
// LOAN PROVIDER
export const getloanProvider = createAsyncThunk(
  "getLoanProvider",
  async (financierName) => {
    const response = await fetch(
      `${process.env.REACT_APP_GET_LOAN_PROVIDER}?type=loanprovider&word=${financierName}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

// INSURER LIST -->
export const getInsurerList = createAsyncThunk(
  "getInsurerList",
  async (typeOfResponse) => {
    const response = await fetch(
      `${process.env.REACT_APP_GET_LOAN_PROVIDER}?type=${typeOfResponse}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);
