import React from "react";
import carr from "../../../Assets/Images/car.svg";
import { stringCompare } from "igniteui-react-core";

const ComparePolicy = (props) => {
  const {
    title,
    titleInBold,
    policyTypeFirst,
    policyTypeFirstDesc,
    policyTypeSecond,
    policyTypeSecondDesc,
    tableHeading,
    tableObject,
  } = props;

  return (
    <>
      <div className="content-container-prod-insurance comparison-container-prod-insurance">
        <div className="content-container-prod-insurance-parent maxWidth">
          <h1 className="title-product-ins">
            {title} <span>{titleInBold}</span>
          </h1>
          <div className="policy-container-prod-pg">
            <div className="policy-container-block">
              <div className="icon-container-policy-container-block">
                <img src={carr} alt="" srcSet="" />
              </div>
              <div className="policy-container-block-content">
                <p className="policy-container-block-head">{policyTypeFirst}</p>
                <p className="policy-container-block-desc">
                  {policyTypeFirstDesc}
                </p>
              </div>
            </div>
            <div className="policy-container-block">
              <div className="icon-container-policy-container-block">
                <img src={carr} alt="" srcSet="" />
              </div>
              <div className="policy-container-block-content">
                <p className="policy-container-block-head">
                  {policyTypeSecond}
                </p>
                <p className="policy-container-block-desc">
                  {policyTypeSecondDesc}
                </p>
              </div>
            </div>
          </div>

          <div className="table-container-prod-pg">
            <div className="custom-table">
              <div className="custom-table-head">
                {tableHeading.map((item, key) => (
                  <p key={key}>{item}</p>
                ))}
              </div>
              <div className="custom-table-body">
                <div className="custom-table-body-parts">
                  {tableObject.map((item, key) => (
                    <div key={key} className="row-container">
                      <p className="p-tag-custom-table">{item.addonName}</p>
                      {item.coveredInTp == "Through Ad-On" ? (
                        <p className="p-tag-custom-table">
                          {item.coveredInTp}
                        </p>
                      ) : (
                        <p className="p-tag-custom-table">
                          <img src={item.coveredInTp} alt="" srcSet="" />
                        </p>
                      )}

                      {item.coveredInComprehensive == "Through Ad-On" ? (
                        <p className="p-tag-custom-table">
                          {item.coveredInComprehensive}
                        </p>
                      ) : (
                        <p className="p-tag-custom-table">
                          <img
                            src={item.coveredInComprehensive}
                            alt=""
                            srcSet=""
                          />
                        </p>
                      )}
                      {
                        item?.coveredInOd ? (
                          item?.coveredInOd == "Through Ad-On" ? (
                            <p className="p-tag-custom-table">
                              {item?.coveredInOd}
                            </p>
                          ) : (
                            <p className="p-tag-custom-table">
                              <img
                                src={item?.coveredInOd}
                                alt=""
                                srcSet=""
                              />
                            </p>
                          )
                        ) : ""
                      }
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="table-container-prod-pg-mobile">
            <div className="table-container-prod-pg-mobile-parent">

              {
                tableObject.map((data, key) => (
                  <div key={key} className="head-title-tab-mob">
                    <div className="heading-tab-mobile">
                      <p>{data.addonName}</p>
                    </div>
                    <div className="value-container-tab-mobile">
                      <div className="value-container-tab-mobile-parts value-container-tab-mobile-parts-one">
                        <p>Third Party</p>
                        <img src={data.coveredInTp} alt="" srcSet="" />
                      </div>
                      <div className="value-container-tab-mobile-parts">
                        <p>Comprehensive</p>
                        {
                          data.coveredInComprehensive === "Through Ad-On" ? (
                            <>
                              <p> {data.coveredInComprehensive} </p>
                            </>
                          ) : (
                            <img src={data.coveredInComprehensive} alt="" srcSet="" />
                          )
                        }
                      </div>
                    </div>
                  </div>
                ))
              }



              {/* <div className="head-title-tab-mob">
                <div className="heading-tab-mobile">
                  <p>Third-Party Vehicle Damage</p>
                </div>
                <div className="value-container-tab-mobile">
                  <div className="value-container-tab-mobile-parts value-container-tab-mobile-parts-one">
                    <p>Third Party</p>
                    <p>tick</p>
                  </div>
                  <div className="value-container-tab-mobile-parts">
                    <p>Third Party</p>
                    <p>tick</p>
                  </div>
                </div>
              </div> */}

            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default ComparePolicy;
