import { createAsyncThunk } from "@reduxjs/toolkit";

export const unitedQuickQuoteAction = createAsyncThunk(
  "unitedQuickQuote",
  async (bodyData) => {
    const response = await fetch(
        `${process.env.REACT_APP_UNITED_QUICK_QUOTE_URL}`,
        {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const unitedProposalAction = createAsyncThunk(
  "unitedProposal",
  async (bodyData) => {
    const response = await fetch(
        `${process.env.REACT_APP_UNITED_PROPOSAL_URL}`,
        {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const unitedAutoKycAction = createAsyncThunk(
  "unitedAutoKyc",
  async (bodyData) => {
    const response = await fetch(
        `${process.env.REACT_APP_UNITED_KYC_AUTO_URL}`,
        {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);
