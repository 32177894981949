import { createAsyncThunk } from "@reduxjs/toolkit";

export const rahejaGetIdvAction = createAsyncThunk(
  "rahejaGetIdv",
  async (bodyData) => {
    const response = await fetch(
      `https://5tm23avge1.execute-api.us-east-1.amazonaws.com/raheja-get-vehicle-idv`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const rahejaPremiumCalculationAction = createAsyncThunk(
  "rahejaPremiumCalculation",
  async (bodyData) => {
    const response = await fetch(
      `https://5tm23avge1.execute-api.us-east-1.amazonaws.com/raheja-premium-calculation`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const rahejaSaveQuoationAction = createAsyncThunk(
  "rahejaSaveQuote",
  async (bodyData) => {
    const response = await fetch(
      `https://5tm23avge1.execute-api.us-east-1.amazonaws.com/raheja-save-motor-quotation`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const rahejaProposalCreationAction = createAsyncThunk(
  "rahejaProposalCreation",
  async (bodyData) => {
    const response = await fetch(
      `https://5tm23avge1.execute-api.us-east-1.amazonaws.com/raheja-motor-proposal-creation`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const rahejaGenerateTransactionNumberAction = createAsyncThunk(
  "rahejaGenerateTransationNumber",
  async (bodyData) => {
    const response = await fetch(
      `https://5tm23avge1.execute-api.us-east-1.amazonaws.com/raheja-generate-transation-number`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const rahejaGeneratePolicyNumberAction = createAsyncThunk(
  "rahejaGeneratePolicyNumber",
  async (bodyData) => {
    const response = await fetch(
      `https://5tm23avge1.execute-api.us-east-1.amazonaws.com/raheja-generate-policy-number`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const rahejaGetTraceIdAction = createAsyncThunk(
  "rahejaTraceId",
  async (userName) => {
    const response = await fetch(
      `https://5tm23avge1.execute-api.us-east-1.amazonaws.com/raheja-get-trace-id?UserName=${userName}`,
      {
        method: "GET",
        // body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);
