import React, { useEffect } from "react";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import "./Claim.css";
import hero from "../../../Assets/Images/ClaimsHero.svg";
import circle from "../../../Assets/Images/ellipseClaimHero.svg";
import circle2 from "../../../Assets/Images/ellipseClaim2.svg";
import circle3 from "../../../Assets/Images/ellipseClaim3.svg";
import bgSquare from "../../../Assets/Images/serviceBg.svg";
import car from "../../../Assets/Images/animatedCar.svg";
import bike from "../../../Assets/Images/animatedBike.svg";
import health from "../../../Assets/Images/animatedHealth.svg";
import taxi from "../../../Assets/Images/animatedTaxi.svg";
import cashless1 from "../../../Assets/Images/cashlessClaim.svg";
import cashless2 from "../../../Assets/Images/trackClaim.svg";
import cashless3 from "../../../Assets/Images/claimAssistance.svg";
import cashless4 from "../../../Assets/Images/qualityEnsure.svg";
import stepperHero from "../../../Assets/Images/ClaimsStepper.svg";
import tick from "../../../Assets/Images/claimTick.svg";
import uploadPolicy from "../../../Assets/Images/uploadPolicy.svg";
import incidentDetail from "../../../Assets/Images/incidentDetails.svg";
import uploadDoc from "../../../Assets/Images/uploadDoc.svg";
import verifyDetails from "../../../Assets/Images/verifyDetails.svg";
import Partners from "../../Partners/Partners";
import Accordian from "../../Accordian/Accordian";
import faq from "../../../Assets/Images/claimFaq.svg"

const Claim = (props) => {
    const { firstLot, secondLot } = props;

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    const claimContent = [
        {
            question: "Could you share some prevalent cases of insurance misselling?",
            answer:
                "Insurance misselling encompasses unethical practices like misrepresenting policy terms, pressuring for unnecessary coverage, hidden fees, and selling to ineligible individuals, eroding trust in the industry.",
        },
        {
            question: "Why do insurance compaines reject claims?",
            answer:
                "Insurance companies reject claims due to reasons such as lack of coverage, policy exclusions, insufficient documentation, errors, or suspicion of fraud, ensuring claims adhere to policy terms and validity.",
        },
        {
            question: "What documents do I need to file a claim?",
            answer:
                "To file a claim, you'll generally require a filled claim form, your policy document, proof of loss (like a police report for theft), medical records (for health claims), original bills, receipts, and any other documents specified by your insurance provider.",
        },
        {
            question: "How long does it take to settle a claim?",
            answer:
                "Claim settlement time varies based on factors like claim complexity and insurer processes. Simple claims may be settled quickly, while complex ones could take weeks or months.",
        },
        {
            question: "Is there a deadline for filing a claim?",
            answer:
                `Yes, there's a deadline, known as the "claim filing period," varying by insurance type and policy terms. It's crucial to file within this timeframe for a valid claim.`,
        },
        {
            question: "What happens if my claim is denied?",
            answer:
                "If your claim is denied, you'll get a written explanation from the insurer. You can appeal or seek clarification, but review your policy for details first.",
        },
    ];

    return (
        <>
            <Navbar />

            {/* hero */}

            <div className="claim-container-hero claim-container-hero-noPadding">
                <img src={circle} className="circle-1-claim" alt="" srcset="" />
                <img src={circle2} className="circle-2-claim" alt="" srcset="" />
                <img src={circle3} className="circle-3-claim" alt="" srcset="" />
                <div className="claim-content-hero-container maxWidth">
                    <div className="claim-content-hero">
                        <p>
                            Do you have any concerns regarding <br /> the
                            <span className="span-blue"> Cashless Claim Facility?</span>
                        </p>
                        <p>Don't worry, we are here for you.</p>
                        <p>
                            <span className="span-black">"BookMyInsurance"</span> simplifies
                            the process of initiating a cashless claim with an insurance
                            provider for a covered loss, damage, or specific event.
                        </p>
                    </div>
                    <img src={hero} alt="" />
                </div>
            </div>

            {/* our services */}

            <div className="claim-container-hero claim-container-hero-services">
                <div className="service-container-claim maxWidth">
                    <p className="head-service-container-claim">
                        Our <span>Services</span>
                    </p>
                    <p>
                        We're here to assist individuals in raising their concerns and
                        finding solutions when they feel they've been treated unfairly or
                        unjustly. <br /> Discover the range of services we offer:
                    </p>
                    <div className="services-square-container-claim">
                        <div className="service-square">
                            <img src={bgSquare} className="bgSquare" alt="" srcset="" />
                            <img src={car} alt="" srcset="" />
                            <p> Car Claims</p>
                        </div>

                        <div className="service-square">
                            <img src={bgSquare} className="bgSquare" alt="" srcset="" />
                            <img src={bike} alt="" srcset="" />
                            <p>Bike Claims</p>
                        </div>

                        <div className="service-square service-square-health">
                            {/* <img src={bgSquare} className="bgSquare" alt="" srcset="" /> */}
                            <img src={health} alt="" srcset="" />
                            <p>Health Claims</p>
                        </div>

                        <div className="service-square">
                            <img src={bgSquare} className="bgSquare" alt="" srcset="" />
                            <img src={taxi} alt="" srcset="" />
                            <p> Taxi Claims</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* initiate cashless claims */}

            <div className="claim-container-hero claim-container-hero-intiate">
                <div className="block-container-claims maxWidth">
                    <p className="head-service-container-claim">
                        What makes BookMyInsurance a preferred choice for{" "}
                        <span>Initiating Cashless Claims?</span>
                    </p>
                    <div className="flex-block-container">
                        <div className="block-cashless-claim">
                            <img src={cashless1} alt="" srcset="" />
                            <p className="block-header-cashless-claim">
                                Assured cashless claims <br /> nationwide in India.
                            </p>
                            <p className="block-summary-cashless-claim">
                                100% cashless" means that all financial transactions occur
                                electronically or digitally, eliminating the need for physical
                                cash.
                            </p>
                        </div>

                        <div className="block-cashless-claim">
                            <img src={cashless2} alt="" srcset="" />
                            <p className="block-header-cashless-claim">
                                Tracking of Claims <br /> in Real-Time
                            </p>
                            <p className="block-summary-cashless-claim">
                                Live tracking of claims enables policyholders to keep a
                                real-time watch on their insurance claims' progress and status
                                without any extra charges.
                            </p>
                        </div>

                        <div className="block-cashless-claim">
                            <img src={cashless3} alt="" srcset="" />
                            <p className="block-header-cashless-claim">
                                Assistance with <br /> Claims
                            </p>
                            <p className="block-summary-cashless-claim">
                                Policyholders can access support and guidance for filing
                                insurance claims without any extra service charges or fees.
                            </p>
                        </div>

                        <div className="block-cashless-claim">
                            <img src={cashless4} alt="" srcset="" />
                            <p className="block-header-cashless-claim">Ensuring Quality</p>
                            <p className="block-summary-cashless-claim">
                                100% cashless" means all transactions are digital, no physical
                                cash involved
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* CLAIM STEPPER */}

            <div className="claim-container-hero claim-stepper-container-hero">
                <div className="claim-stepper-container maxWidth">
                    <p className="head-service-container-claim">
                        Get Cashless<span> Claims in few simple steps</span>
                    </p>

                    <div className="claim-stepper-img-container">
                        <img src={stepperHero} alt="" srcset="" />
                        <div className="claim-stepper-div">
                            <div className="stepper-line">
                                <div className="stepper-content-claim-container">
                                    <div className="stepper-content-circle stepper-content-circle-two">
                                        1
                                    </div>
                                    <div className="stepper-content-claim">
                                        <img src={uploadPolicy} alt="" srcset="" />
                                        <div className="stepper-content-claim-text stepper-content-claim-text-two">
                                            <p>Upload Policy</p>
                                            <p className="summ-claim-stepper summ-claim-stepper-two">
                                                <>Upload </> your car, bike and health Insurance Policy.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="stepper-content-claim-container stepper-content-claim-container-two">
                                    <div className="stepper-content-circle stepper-content-circle-two">
                                        2
                                    </div>
                                    <div className="stepper-content-claim">
                                        <img src={incidentDetail} alt="" srcset="" />
                                        <div className="stepper-content-claim-text stepper-content-claim-text-two">
                                            <p>Enter Incident Details</p>
                                            <p className="summ-claim-stepper summ-claim-stepper-two">
                                                <>Enter </> all valid Incident Details to get cashless
                                                Claims.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="stepper-content-claim-container stepper-content-claim-container-three">
                                    <div className="stepper-content-circle stepper-content-circle-two">
                                        3
                                    </div>
                                    <div className="stepper-content-claim">
                                        <img src={uploadDoc} alt="" srcset="" />
                                        <div className="stepper-content-claim-text stepper-content-claim-text-two">
                                            <p>Upload Documents</p>
                                            <p className="summ-claim-stepper summ-claim-stepper-two">
                                                <>Verify </> all the details and Initiate your Cashless
                                                Claims.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="stepper-content-claim-container stepper-content-claim-container-four">
                                    <div className="stepper-content-circle stepper-content-circle-two">
                                        4
                                    </div>
                                    <div className="stepper-content-claim">
                                        <img src={verifyDetails} alt="" srcset="" />
                                        <div className="stepper-content-claim-text stepper-content-claim-text-two">
                                            <p>Verify Details</p>
                                            <p className="summ-claim-stepper summ-claim-stepper-two">
                                                <>Upload </> all the Documents to speedup Cashless
                                                Claims.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Partners */}

            <div className="claim-container-hero claim-container-hero-intiate">
                <div className="block-container-claims maxWidth">
                    <Partners
                        firstLot={firstLot}
                        secondLot={secondLot}
                        noPadding={true}
                        component="viewPlans"
                    />
                </div>
            </div>

            {/* FAQ's */}

            <div className="claim-container-hero">
                <div className="claim-stepper-container maxWidth">
                    <p className="head-service-container-claim">
                        Frequently<span> Asked Questions</span>
                    </p>

                    <div className="claim-stepper-img-container claim-accordian-img-container">
                        <img src={faq} alt="" srcset="" />
                        <div className="claim-stepper-div-accordian">
                            <Accordian
                                content={claimContent}
                                screen="claim"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Claim;
