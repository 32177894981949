import React, { useState, useEffect } from "react";
import Select, { createFilter } from "react-select";
import { FixedSizeList as List } from "react-window"; // Import FixedSizeList for virtualization
import "./SeacrhSelect.css";
import { error } from "pdf-lib";

//here change for dropdown list 

const SearchSelect = (props) => {
  const {
    name,
    label,
    value,
    errMsg,
    errState,
    optionList,
    labelClass,
    isDisabled,
    placeholder,
    handleSelect,
    errClassName,
    GetSearchedValue,
    id,
    businessType
  } = props;

  const [inputValue, setInputValue] = useState("");
  const [ValueFromProps, setValueFromProps] = useState(value);
  const [menuHeight, setMenuHeight] = useState(null);
  const [menuHeight2, setMenuHeight2] = useState(false);
    let countchilder = 5
    let countchilders = 2
    let countchid = optionList?.length || 0; 
    
    useEffect(() => {

      if (id) {  
        if (optionList && countchid > 0) {
          setMenuHeight2(true);
          const height = Math.min(countchid * 42, 200);
          setMenuHeight(height);
      } else {
          setMenuHeight2(false);
          setMenuHeight(0);
      }
      
      } else if (businessType !== 'New') {
        if (optionList && optionList.length >= 0) {
          setMenuHeight2(true);
          const height = Math.min(countchilder * 42, 200);
          setMenuHeight(height);
        }
      } else if (businessType === 'New') {
        if (optionList && optionList.length >= 0) {
          setMenuHeight2(true);
          const height = Math.min(countchilders * 42, 100);
          setMenuHeight(height);
        }
      }
    }, [optionList, inputValue, id]);
    
  const style1 = {
    control: (base, state) => ({
      ...base,
      height: 52,
      minHeight: 52,
      border: errState
        ? "1px solid #FF464D !important"
        : "1px solid #B1C9FF !important",
      boxShadow: "0 !important",
      "&:hover": {
        border: errState
          ? "1px solid #FF464D !important"
          : "1px solid #B1C9FF !important",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isHovered ? "#F3F7FF" : "transparent",
      color: state.isHovered ? "#000" : "#000",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#F3F7FF",
        color: "#000",
      },
    }),
  };

  const handleInputChange = (inputValue) => {
    const upperCaseValue = inputValue?.toUpperCase();
    if (GetSearchedValue) {
      GetSearchedValue(upperCaseValue);
    }
    setInputValue(upperCaseValue);
  };

  const VirtualizedMenuList = ({ children, maxHeight, getValue }) => {
    countchilder = children.length
    countchid = children.length
    return (
      <List
        height={countchid || countchilder !== undefined ? menuHeight || 200 : 0} //here change for dropdown list 
        itemCount={optionList?.length}
        itemSize={42}
        width="100%"
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  };


  return (
    <>
      <div className="search-select-container">
        <Select
          filterOption={createFilter({ ignoreAccents: false })}
          menuPlacement="auto"
          options={optionList}
          placeholder={placeholder}
          value={ id!=="stopdrop"? optionList?.filter(function (option) {
            return option.value === value;
          }):optionList.find(option => option.value === value) || { value: value, label: value }}
          name={name}
          onChange={handleSelect}
          isSearchable={true}
          styles={style1}
          isDisabled={isDisabled ? isDisabled : false}
          onInputChange={handleInputChange}
          inputValue={inputValue}
          components={{ MenuList: VirtualizedMenuList }}
        // defaultMenuIsOpen={true} // to set options open byDefault
        />
        {errMsg ? <p className="err-msg"> {errMsg} </p> : ""}
        {value && label ? (
          <p
            className={
              labelClass
                ? labelClass + "label-dropdown label-dropdown-search"
                : "label-dropdown label-dropdown-search"
            }
          >
            {label}
          </p>
        ) : null}
      </div>
    </>
  );
};

export default SearchSelect;

