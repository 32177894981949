import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import "./PageNotFound.css";
import pic4040 from "../../Assets/Images/404.svg";
import Button from "../Button/Button";

const PageNotFound = () => {
  const navigate = useNavigate();

  const HandleHomePage = () => {
    navigate("/");
  };
  return (
    <>
      <Navbar componentPath="/" />
      <div className="container404page">
        <div className="boxcontaier404">
          <h1 className="opps">Oops!</h1>
          <img src={pic4040} alt="" />
          <div className="content404">
            <h3>PAGE NOT FOUND</h3>
            <div>
              <p>
                The requested page or resource you are trying to reach could not
                be found.
              </p>
            </div>
          </div>
          <Button
            text="Back to Home"
            className="button btn-404"
            event={HandleHomePage}
          />
        </div>
      </div>
      <Footer />;
    </>
  );
};

export default PageNotFound;
