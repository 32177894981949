import { createSlice } from "@reduxjs/toolkit";

export const addOnDataDataReducer = createSlice({
  name: "addOnData",
  initialState: {},
  reducers: {
    alladdOnDataDataAction: (state, action) => {
      state.data = action.payload;
    },
    resetaddOnDataDataAction: (state, action) => {
      state.data = undefined
    }
  },
});

export const { alladdOnDataDataAction, resetaddOnDataDataAction } = addOnDataDataReducer.actions;
