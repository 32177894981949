import React from "react";
import loader from "../../../Assets/Loader/Loader.mp4";
import loader2 from "../../../Assets/Loader/Loader.gif";
import "./Loader.css";

const Loader = () => {
  return (
    <>
      <section className="loader-container">
        <img src={loader2} className="loader-bmi" alt="" srcSet="" />
      </section>
    </>
  );
};

export default Loader;
