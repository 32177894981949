import React from 'react'
import './SettingsCard.css'
import Botton from '../../../Button/Button';
import ToggleSwitch from '../../../InputFields/ToggleSwitch/ToggleSwitch';

const SettingsCard = (props) => {

    const { handleInputChange, FormValue } = props;

    var today = new Date();
    var curHr = today.getHours();

    // let greetingTitle = "Hello, Good Evening!";

    // if (curHr < 12) {
    //     greetingTitle = "Hello, Good Morning!";
    // } else if (curHr < 18) {
    //     greetingTitle = "Hello, Good Afternoon!";
    // }

    let settingsList = [
        {
            title: "Policy Updates",
            titleDescription: "Important updates for your purchased policies including payment, issuance, claims and renewal reminders",
            switchName: "sendPolicyUpdates",
            changeEvent: handleInputChange,
            status: FormValue.sendPolicyUpdates,
            switchValue: FormValue.sendPolicyUpdates,
        },
        {
            title: "Policy Renewal Reminder",
            titleDescription: "Get reminder on your policy renewals.",
            switchName: "sendPolicyRenewalReminder",
            changeEvent: handleInputChange,
            status: FormValue.sendPolicyRenewalReminder,
            switchValue: FormValue.sendPolicyRenewalReminder,
        },
        {
            title: "Offers",
            titleDescription: "Get updated on latest offers for all the products.",
            switchName: "sendOffers",
            changeEvent: handleInputChange,
            status: FormValue.sendOffers,
            switchValue: FormValue.sendOffers,
        },
        {
            title: "Reminder Calls",
            titleDescription: "Get Call reminder on your phones for renewal and offers.",
            switchName: "sendReminderCalls",
            changeEvent: handleInputChange,
            status: FormValue.sendReminderCalls,
            switchValue: FormValue.sendReminderCalls,
        },
    ]

    return (
        <>
            <div className="heading_rightSide_findMycar heading_rightSide_findMycar-policyCard">
                {/* <div> */}
                    {/* <p>{greetingTitle}</p> */}
                    {/* <p className="info_block_left_desc" >Welcome to your profile.</p> */}
                {/* </div> */}
                <div className='toggle-container-settingsCard' >
                    <p className='toggleLabel' >All Notifications</p>
                    <ToggleSwitch
                        name="sendAllNotification"
                        event={handleInputChange}
                        status={FormValue.sendAllNotification}
                        value={FormValue.sendAllNotification}
                    />
                </div>
            </div>

            <div className="settings-container">
                {settingsList.map((data, key) => (
                    <div key={key} className="settings-container-tab">
                        <div className="settings-name-content">
                            <div className="settings-name">
                                <p className='toggleLabel-head'>{data.title}</p>
                            </div>
                            <div className="settings-content">
                                <p className='toggleLabel' >
                                    {data.titleDescription}
                                </p>
                            </div>
                        </div>
                        <div className="toggle-container-tab">
                            <ToggleSwitch
                                name={data.switchName}
                                event={data.changeEvent}
                                status={data.status}
                                value={data.switchValue}
                            />
                        </div>
                    </div>))}
            </div>
        </>
    )
}

export default SettingsCard
