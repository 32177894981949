import { createAsyncThunk } from "@reduxjs/toolkit";

export const nationalQuickQuoteAction = createAsyncThunk(
  "nationalQuikQuotes",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_NATIONAL_QUICK_QUOTE_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);
export const nationalKyc = createAsyncThunk(
  "nationalKyc",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_NATIONAL_MANUAL_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const  nationalKycAuto = createAsyncThunk(
  "nationalKycAuto",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_NATIONAL_KYC_AUTO_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    
    
    return response.json();
  }
  
);

export const nationalFullQuoteAction = createAsyncThunk(
  "nationalFullQuotes",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_NATIONAL_PROPOSAL_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const nationalPdfDownloadAction = createAsyncThunk(
  "nationalPdfDownload",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_NATIONAL_PDF_DOWNLOAD_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);