import React, { useState } from 'react'
import OtpInput from "react-otp-input";
import Botton from '../Button/Button';
import close from "../../Assets/Images/Close.svg"
import './Modal.css'

const OtpModal = (props) => {

    const { closeModal, openModal, getOtp } = props;

    const [otp, setOtp] = useState("");

    return (
        <div className="login-child login-child-form login-child login-child-form-kyc">
            <div className="form-login-head-kyc">
                <p className="login-child-head">OTP Verification</p>
                <img src={close} alt="" srcSet="" onClick={(e) => closeModal(otp)} />
                {/* <p className="login-child-text login-child-text-two ">
                    Please enter OTP sent on &nbsp;
                    <span style={{ color: "var(--primary)" }}>9876543210</span>
                </p> */}
            </div>

            <div className="otpInput-container">
                <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderInput={(props) => <input {...props} />}
                />
            </div>

            <Botton
                text="Verify OTP"
                className="button login-btn "
                event={(e) => getOtp(otp)}
            />

            {/* <div className="footer-login-text">
                <p className="login-child-text login-child-text-two login-child-text-three ">
                    Don’t get OTP?
                    <span className="footer_hyper_link footer_hyper_link-login underline_text">
                        Resend now.
                    </span>
                </p>

                <span style={{ color: "var(--primary)", fontSize: ".75rem" }}>
                    Entered wrong number?
                </span>
            </div> */}
        </div>
    )
}

export default OtpModal
