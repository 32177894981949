import { createAsyncThunk } from "@reduxjs/toolkit";

export const tataQuickQuoteAction = createAsyncThunk(
  "tataQuickQuote",
  async (bodyData) => {
    const response = await fetch(
        `${process.env.REACT_APP_TATA_QUICK_QUOTE_URL}`,
        {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const tataQuickQuoteActionWithAddOn = createAsyncThunk(
  "tataQuikQuotesAddon",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_TATA_QUICK_QUOTE_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const tataProposalAction = createAsyncThunk(
  "tataProposal",
  async (bodyData) => {
    const response = await fetch(
        `${process.env.REACT_APP_TATA_PROPOSAL_URL}`,
        {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const tataAutoKycAction = createAsyncThunk(
  "tataAutoKyc",
  async (bodyData) => {
    const response = await fetch(
        `${process.env.REACT_APP_TATA_KYC_AUTO_URL}`,
        {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const tataManualKycAction = createAsyncThunk(
  "tataManualKyc",
  async (bodyData) => {
    const response = await fetch(
        `${process.env.REACT_APP_TATA_KYC_MANUAL_URL}`,
        {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const tataOnlinePaymentAction = createAsyncThunk(
  "tataOnlinePayment",
  async (bodyData) => {
    const response = await fetch(
        `${process.env.REACT_APP_TATA_ONLINE_PAYMENT_URL}`,
        {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const tataVerifyPaymentAction = createAsyncThunk(
  "tataVerifyPayment",
  async (bodyData) => {
    const response = await fetch(
        `${process.env.REACT_APP_TATA_VERIFY_PAYMENT_URL}`,
        {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const tataPolicyDocumentAction = createAsyncThunk(
  "tataPolicyDocumentAction",
  async (bodyData) => {
    const response = await fetch(
        `${process.env.REACT_APP_TATA_GET_PDF_URL}`,
        {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const tataOtpVerifyAction = createAsyncThunk(
  "tataOtpVerify",
  async (bodyData) => {
    const response = await fetch(
        `${process.env.REACT_APP_TATA_OTP_VERIFY_URL}`,
        {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);
