import {
  getModelAction,
  getFuelTypeAction,
  getVariantAction,
  getBikeBrandAction
} from "../Actions/mmvAction";
import { createSlice } from "@reduxjs/toolkit";

export const getModelReducer = createSlice({
  name: "getModelReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getModelAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getModelAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getModelAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetModelAction: (state, action) => {
      state.data = undefined
    }
  }
});

export const { resetModelAction } = getModelReducer.actions;

export const getBrandReducer = createSlice({
  name: "getBrandReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getBikeBrandAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getBikeBrandAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getBikeBrandAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
});

export const getFuelTypeReducer = createSlice({
  name: "getFuelTypeReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getFuelTypeAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getFuelTypeAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getFuelTypeAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetFuelAction: (state, action) => {
      state.data = undefined
    }
  }
});
export const { resetFuelAction } = getFuelTypeReducer.actions;

export const getVariantReducer = createSlice({
  name: "getVariantReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getVariantAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getVariantAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getVariantAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetVariantAction: (state, action) => {
      state.data = undefined
    }
  }
});

export const { resetVariantAction } = getVariantReducer.actions;