import { createSlice } from "@reduxjs/toolkit";
import { commonVehicleFetchApiAction } from "../Actions/commonVehicleFetchApiAction";

export const commonVehicleFetchApiReducer = createSlice({
  name: "fetchVehicleDetails",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },

  reducers: {
    updateVehicleInfoAction: (state, action) => {
      state.data = action?.payload || {};
    },
    resetVehicleData: (state, action) => {
      state.data = undefined
    }
  },
  extraReducers: (builder) => {
    builder.addCase(commonVehicleFetchApiAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(commonVehicleFetchApiAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(commonVehicleFetchApiAction.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });
  },
});

export const { updateVehicleInfoAction, resetVehicleData } = commonVehicleFetchApiReducer.actions;
