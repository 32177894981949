import { createAsyncThunk } from "@reduxjs/toolkit";

export const digitQuickQuoteAction = createAsyncThunk(
  "digitQuikQuotes",
  async (bodyData) => {
    console.log(bodyData,'bodyDatabodyDatahhj');
    const response = await fetch(
      `${process.env.REACT_APP_DIGIT_QUICK_QUOTE_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const digitQuickQuoteAction12 = createAsyncThunk(
  "digitQuikQuotes12",

  async (bodyData) => {
    let data = bodyData?.data?.data;
    let rto = bodyData?.data?.data?.rto?.split("-");
   
    const response = await fetch(
      `${process.env.REACT_APP_DIGIT_QUICK_QUOTE_URL2}?regNo=${rto[0]}&make=${data?.make
      }&model=${data?.model}&varient=${data?.varient}&fuelType=${data?.fuelType
      }&cc=${data?.cc}&businessType=${data?.businessType
      }&isFindMyCar=${true}&vehicleType=4W`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const digitCreateQuoteAction = createAsyncThunk(
  "digitCreateQuotes",
  async (bodyData) => {
    const response = await fetch(
      //"https://t61qx5z813.execute-api.us-east-1.amazonaws.com/dev/createProposal", API GAETWAY
      `${process.env.REACT_APP_DIGIT_CREATE_PROPOSAL_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const digitKycStatusAction = createAsyncThunk(
  "digitKycStatus",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_DIGIT_KYC_STATUS_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const digitPdfDownloadAction = createAsyncThunk(
  "digitDownloadPdf",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_DIGIT_PDF_DOWNLOAD_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);
