import { createSlice } from "@reduxjs/toolkit";
import { razorCreateOrderAction } from "../Actions/razorCreateOrderApiAction"

export const razorCreateOrderReducer = createSlice({
    name: "razorCreateOrderReducer",
    initialState: {
      isLoading: false,
      data: null,
      isError: false,
    },
    extraReducers: (builder) => {
      builder.addCase(razorCreateOrderAction.pending, (state, action) => {
        state.isLoading = true;
      });
      builder.addCase(razorCreateOrderAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
      builder.addCase(razorCreateOrderAction.rejected, (state, action) => {
        console.error("Error", action.payload);
        state.isError = true;
        state.isLoading = false;
      });
    },
  });