import { createAsyncThunk } from "@reduxjs/toolkit";

export const shreeRamQuickQuoteAction = createAsyncThunk(
  "shreeRamQuikQuotes",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_SHREERAM_QUICK_QUOTE_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);
export const shreeRamProposalAction = createAsyncThunk(
  "shreeRamProposal",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_SHREERAM_PROPOSAL_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);
