import React from "react";

const AddRemoveBtn = ({ isChecked, onChange, id, icon }) => {
    const handleClick = () => {
        let event = {
            target: {
                id: id,
                checked: !isChecked,
            },
        };
        onChange(event);
    };

    return (
        <button id={id} className="btn-checkbox" onClick={handleClick}>
            {isChecked ? <img src={icon} /> : "Add"}
        </button>
    );
};

export default AddRemoveBtn;
