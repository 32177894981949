import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import digit from "../../Assets/Partners_logo/digit.svg";
import fail from "../../Assets/Images/failure.svg";
import success from "../../Assets/Images/success.svg";
import Botton from "../Button/Button";
import close from "../../Assets/Images/Close.svg";
import backIcon from "../../Assets/Images/backIcon.svg";
import { SBI, SHREERAM, ICICI, ZUNO, ACKO, HDFC, TATA, UNITED, NATIONAL } from "../../utils/commonUtils";
import {  DIGIT } from "../../utils/commonUtilsFinal";
import { NEW, carDetailsforProposal } from "../../utils/commonUtils";
import greenTick from "../../Assets/Images/greenTick.svg"
import redCross from "../../Assets/Images/redCross.svg"
import arrowRight from "../../Assets/Images/Vector_arrow_right.svg"
import compCover1 from "../../Assets/Images/accidentCoverage.svg"
import compCover2 from "../../Assets/Images/fireCoverage.svg"
import compCover3 from "../../Assets/Images/theftCoverage.svg"
import compCover4 from "../../Assets/Images/calamitiesCoverage.svg"
import compCover5 from "../../Assets/Images/tpLosses.svg"
import compNotCover1 from "../../Assets/Images/nonAccident.svg"
import compNotCover2 from "../../Assets/Images/commercialUsage.svg"
import compNotCover3 from "../../Assets/Images/preExisting.svg"
import compNotCover4 from "../../Assets/Images/illegalDriving.svg"
import tick from "../../Assets/Images/success.svg"
import disable from "../../Assets/Images/disable.svg"
import SkeletonLoader from "../Pages/Loader/Skeleton loader/SkeletonLoader";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const PlanDetails = (props) => {
  const {
    test,
    bodyData,
    carDetails,
    companyImg,
    triggerTab,
    QuickQuote,
    DigitAddon,
    companyName,
    handleBuyNow,
    allIDVValues,
    setDigitAddon,
    setShowBottomTab,
    OnClickAddonsplan,
    vehicleBreakupPrice,
    vehicleStructureDetails,
  } = props;

  let vehiclebreakup = ''
  let deviceWidth = window.innerWidth;

  if (bodyData.data !== undefined) {
    if (companyName == "digit" && carDetails?.quotePackageNameDigit) {
      vehiclebreakup = carDetailsforProposal(companyName, QuickQuote, bodyData, test, "Key", OnClickAddonsplan);

    } else if (companyName == "icici" && carDetails?.quotePackageNameICICI) {
      vehiclebreakup = carDetailsforProposal(companyName, QuickQuote, bodyData, test, carDetails?.quotePackageNameICICI);

    } else {
      vehiclebreakup = carDetailsforProposal(companyName, QuickQuote, bodyData, test);
    }


  }

  const [modalIsOpen, setIsOpen] = useState(false);
  const [ContentType, setContentType] = useState("premiumBreakup");

  function formatDate(date) {
    date = new Date(date)
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    return `${day} ${months[month]} ${year}`;
  }

  let newPolicyStartDate = formatDate(carDetails?.policyRegDate);
  let formattedExpDate = formatDate(carDetails?.policyExpDate);
  let odformateExpDate = formatDate(carDetails.prevPolicyODexpDate);

  function generatePolicyDate(previousDate, typeOfBusiness = null) {
    function calculateOneYearLater(date) {
      const newDate = new Date(date);
      if (typeOfBusiness == "new") {
        newDate.setFullYear(newDate.getFullYear() + 3);
      } else {
        newDate.setFullYear(newDate.getFullYear() + 1);
      }
      newDate.setDate(newDate.getDate() - 1);
      return newDate;
    }
    let previousDateFormatted = previousDate.split("/").reverse().join("-")
    const today = new Date(String(previousDateFormatted));
    const tomorrow = new Date(today);
    if (typeOfBusiness != "new") {
      tomorrow.setDate(tomorrow.getDate() + 1);
    }
    const formattedToday = formatDate(tomorrow);
    const oneYearLater = calculateOneYearLater(tomorrow);
    const formattedOneYearLater = formatDate(oneYearLater);
    return { formattedToday, formattedOneYearLater };
  }

  let policyDateObj;

  if (carDetails.typeOfBusiness !== NEW) {
    if (carDetails.PolicyTypeValue !== "Own Damage") {
      if (carDetails.policyExpDate < new Date()) {
        policyDateObj = generatePolicyDate(new Date().toISOString(), carDetails.typeOfBusiness);
      } else {
        policyDateObj = generatePolicyDate(String(carDetails.policyExpDate), carDetails.typeOfBusiness);
      }
    } else {
      if (carDetails?.prevPolicyODexpDate < new Date()) {
        policyDateObj = generatePolicyDate(new Date().toISOString(), carDetails.typeOfBusiness);
      } else {
        policyDateObj = generatePolicyDate(String(carDetails?.prevPolicyODexpDate), carDetails.typeOfBusiness);
      }
    }
  } else {
    policyDateObj = generatePolicyDate(String(carDetails?.policyRegDate), carDetails.typeOfBusiness);
  }

  let cover;
  let notCover;

  if (companyName == SBI) {
    notCover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] === false
    );
    cover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] !== false
    );
  }

  if (companyName == SHREERAM) {
    notCover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] === false
    );
    cover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] !== false
    );
  }

  if (companyName == DIGIT) {
    notCover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] === false
    );
    cover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] !== false
    );
  }

  if (companyName == ICICI) {
    notCover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] === false
    );
    cover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] !== false
    );
  }

  if (companyName == HDFC) {
    notCover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] === false
    );
    cover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] !== false
    );
  }

  if (companyName == ACKO) {
    notCover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] === false
    );
    cover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] !== false
    );
  }

  // Zuno
  if (companyName == ZUNO) {
    notCover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] === false
    );
    cover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] !== false
    );
  }

  // tata
  if (companyName == TATA) {
    notCover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] === false
    );
    cover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] !== false
    );
  }

  // united
  if (companyName == UNITED) {
    notCover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] === false
    );
    cover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] !== false
    );
  }

  // national
  if (companyName == NATIONAL) {
    notCover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] === false
    );
    cover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] !== false
    );
  }

  function openModal() {
    setIsOpen(true);
    setShowBottomTab(false);
    let zoomLevel;
    if (window.innerWidth <= 400) {
      zoomLevel = 1;
      document.body.style.zoom = zoomLevel?.toString();
    }
  }

  function closeModal() {
    setIsOpen(false);
    setShowBottomTab(true);
    if (window.innerWidth <= 400) {
      let zoomLevel = 0.9;
      document.body.style.zoom = zoomLevel?.toString();
    }
  }

  const menuList = [
    {
      id: 1,
      label: "Premium Breakup",
      params: "premiumBreakup"
    },
    {
      id: 3,
      label: "What's Covered",
      params: "coveredDetails"
    },
    {
      id: 4,
      label: "What's Not Covered",
      params: "notCoveredDetails"
    },
    {
      id: 2,
      label: "Selected Addon's",
      params: "addonsSelected"
    },
  ]

  // COVERED LIST OBJECT ----- >
  let coveredListComprehensive = [
    {
      icon: greenTick,
      scene: "Accidents",
      sceneDescription: "Covers repair/replacement costs if your car is damaged in an accident.",
      image: compCover1,
      title: "Accident Coverage"
    },
    {
      icon: greenTick,
      scene: "Fire",
      sceneDescription: "Covers the damages caused due to self-ignition, explosion or lightning.",
      image: compCover2,
      title: "Fire Coverage"
    },
    {
      icon: greenTick,
      scene: "Theft",
      sceneDescription: "If your car is stolen, we will pay you the value of your car as per your policy.",
      image: compCover3,
      title: "Theft Coverage"
    },
    {
      icon: greenTick,
      scene: "Calamities",
      sceneDescription: "We will cover damage caused by natural or man-made calamities such as earthquakes, riots, etc.",
      image: compCover4,
      title: "Calamities Coverages"
    },
    {
      icon: greenTick,
      scene: "Calamities",
      sceneDescription: "We will cover damage caused by natural or man-made calamities such as earthquakes, riots, etc.",
      image: compCover5,
      title: "Third Party Losses"
    },
  ]

  let coveredListOwnDamage = [
    {
      icon: greenTick,
      scene: "Accidents",
      sceneDescription: "Covers repair/replacement costs if your car is damaged in an accident.",
    },
    {
      icon: greenTick,
      scene: "Fire",
      sceneDescription: "Covers the damages caused due to self-ignition, explosion or lightning.",
    },
    {
      icon: greenTick,
      scene: "Rat bite damages",
      sceneDescription: "Cover the damages caused by rat bites.",
    },
    {
      icon: greenTick,
      scene: "Theft",
      sceneDescription: "If your car is stolen, we will pay you the value of your car as per your policy.",
    },
    {
      icon: greenTick,
      scene: "Calamities",
      sceneDescription: "We will cover damage caused by natural or man-made calamities such as earthquakes, riots, etc.",
    },
  ]

  let coveredListThirdParty = [
    {
      icon: greenTick,
      scene: "Third-party injury/death",
      sceneDescription: "We provide coverage for death/ injuries caused to individuals outside of your vehicle in accidents involving your car.",
    },
    {
      icon: greenTick,
      scene: "Third-party property",
      sceneDescription: "We also cover any damage/loss to third-party property.",
    },
  ]

  let coveredListBundled = [
    {
      icon: greenTick,
      scene: "Own Car Damage",
      sceneDescription: "Covers your car in case of road accidents.",
    },
    {
      icon: greenTick,
      scene: "Personal Accident",
      sceneDescription: "Covers you in case of an unfortunate accident that may be lethal or cause mutilation/disability.",
    },
    {
      icon: greenTick,
      scene: "Theft",
      sceneDescription: "Covers your car against theft/burgalary/robbery.",
    },
    {
      icon: greenTick,
      scene: "Natural Calamities",
      sceneDescription: "Covers damages done to your car due to natural clamaties like earthquake, floods, thunderstorms, etc.",
    },
    {
      icon: greenTick,
      scene: "Man made hazards",
      sceneDescription: "Covers damages done to your car due to terrorism, riots, strike or malicious act.",
    },
  ]

  // NOT COVERED LIST OBJECT --->
  let notCoveredComprehensive = [
    {
      icon: redCross,
      scene: "Non-accident damages",
      sceneDescription: "Excludes coverage for natural wear and tear of parts like tyres, tubes, and engine",
      image: compNotCover1,
      title: "Non-accident damages",
    },
    {
      icon: redCross,
      scene: "Commercial usage of the car",
      sceneDescription: "Excludes coverage for damages incurred while using car for commercial purposes (e.g. deliveries, transportation for a fee).",
      image: compNotCover2,
      title: "Commercial Usage",
    },
    {
      icon: redCross,
      scene: "Pre-existing damages",
      sceneDescription: "Excludes coverage for any damage that was already present before the purchase of the policy.",
      image: compNotCover3,
      title: "Pre-existing Damages",
    },
    {
      icon: redCross,
      scene: "Illegal driving",
      sceneDescription: "This refers to any violation of traffic laws, such as driving without a valid license or driving under the influence of drugs, alcohol, etc.",
      image: compNotCover4,
      title: "Illegal Driving",
    },
  ]

  let notCoveredThirdParty = [
    {
      icon: redCross,
      scene: "Own car damages",
      sceneDescription: "Damages to your own car are not covered",
    },
    {
      icon: redCross,
      scene: "Illegal driving",
      sceneDescription: "This refers to any violation of traffic laws, such as driving without a valid license or driving under the influence of drugs, alcohol, etc",
    },
  ]

  let notCoveredOwnDamage = [
    {
      icon: redCross,
      scene: "Third-party injury/death",
      sceneDescription: "Excludes coverage for death/ injuries caused to individuals outside of your vehicle in accidents involving your car.",
    },
    {
      icon: redCross,
      scene: "Third-party property",
      sceneDescription: "Excludes coverage for any damage/loss to third-party property",
    },
    {
      icon: redCross,
      scene: "Non-accident damages",
      sceneDescription: "Excludes coverage for natural wear and tear of parts like tyres, tubes, and engine",
    },
    {
      icon: redCross,
      scene: "Commercial usage of the car",
      sceneDescription: "Excludes coverage for damages incurred while using car for commercial purposes (e.g. deliveries, transportation for a fee).",
    },
    {
      icon: redCross,
      scene: "Pre-existing damages",
      sceneDescription: "Excludes coverage for any damage that was already present before the purchase of the policy.",
    },
    {
      icon: redCross,
      scene: "Illegal driving",
      sceneDescription: "This refers to any violation of traffic laws, such as driving without a valid license or driving under the influence of drugs, alcohol, etc.",
    },
  ]

  let notCoveredBundled = [
    {
      icon: redCross,
      scene: "General aging, wear and tear",
      sceneDescription: "Insuring Against the Effects of Time: Aging and Wear.",
    },
    {
      icon: redCross,
      scene: "Damage by a person driving",
      sceneDescription: "Damage resulting from the actions of a driver without a valid license.",
    },
    {
      icon: redCross,
      scene: "Mechanical or electrical breakdown, failure",
      sceneDescription: "Breakdown or malfunction due to mechanical or electrical issues.",
    },
    {
      icon: redCross,
      scene: "Impaired Driving Damage",
      sceneDescription: "Damage while driving under the influence of liquor or drugs.",
    },
    {
      icon: redCross,
      scene: "Accident Excluded Tyre Damage",
      sceneDescription: "Damage to tyres and tubes, unless damaged during an accident.",
    },
    {
      icon: redCross,
      scene: "Consequential Damage",
      sceneDescription: "Indirect losses covered under insurance policies.",
    },
  ]

  // addons list obj 
  let checkboxTrueFalseObject = {
    paCover: false,
    zeroDep: false,
    consumables: false,
    roadAssistance: false,
    engineProtection: false,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    accessories: false,
    tyreProtection: false,
    emiProtection: false,
    lossofPersonalBelongings: false,
    dailyAllowance: false,
    shreeramProtect: false,
  };

  for (let addon of test) {
    if (addon.name in checkboxTrueFalseObject) {
      checkboxTrueFalseObject[addon.name] = addon.checked ? true : false;
    }
  }

  let testFlag = test.filter((e) => {
    if (e.checked == false) {
      return test
    }
  })

  let count = test.filter((e) => {
    // if (companyName === TATA && carDetails?.quotePackageName !== "") { }
    return e.checked == true && companyName !== TATA && companyName !== UNITED && companyName !== NATIONAL && vehiclebreakup[`${e.name == 'paCover' ? 'paOwnerDriver' : e.name}`] > 0;
  })

  if ((carDetails?.quotePackageName && companyName == TATA) || (carDetails?.quotePackageNameDigit && companyName == DIGIT) || (carDetails?.quotePackageNameICICI && companyName == ICICI)) {
    console.log("BookmyInsurace")
  } else {
    if (count.length == 0) {
      menuList.pop()
    }

  }

  if (deviceWidth <= 610) {
    menuList.shift();
  }

  useEffect(() => {
    if (deviceWidth <= 610) {
      setContentType("coveredDetails");
    }
  }, [deviceWidth]);

  //  Select Plans for Digit
  let RsaplanDig = () => {
    if (carDetails.quotePackageNameDigit) {
      return true
    } else {
      return false
    }
  }

  let KeyplanForDig = () => {
    if (carDetails.quotePackageNameDigit && carDetails?.quotePackageNameDigit !== "RSA") {
      return true
    } else {
      return false
    }
  }

  let LOPlanForDig = () => {
    if (carDetails.quotePackageNameDigit && carDetails?.quotePackageNameDigit !== "RSA") {
      return true
    } else {
      return false
    }
  }

  let ZeroDepDig = () => {
    if (carDetails?.quotePackageNameDigit !== "RSA" && carDetails?.quotePackageNameDigit !== "Pro") {
      return true
    } else {
      return false
    }

  }

  let consumablesforDig = () => {
    if (carDetails?.quotePackageNameDigit == "DC Pro" || carDetails?.quotePackageNameDigit == "DCE Pro" || carDetails?.quotePackageNameDigit == "DCT Pro" || carDetails?.quotePackageNameDigit == "DCET Pro" || carDetails?.quotePackageNameDigit == "DCRTI Pro" || carDetails?.quotePackageNameDigit == "DCTRTI Pro" || carDetails?.quotePackageNameDigit == "DCETRTI Pro" || carDetails?.quotePackageNameDigit == "DCERTI Pro") {
      return true
    } else {
      return false
    }
  }

  let EngineplanForDig = () => {
    if (carDetails?.quotePackageNameDigit == "DCE Pro" || carDetails?.quotePackageNameDigit == "DCET Pro" || carDetails?.quotePackageNameDigit == "DCETRTI Pro" || carDetails?.quotePackageNameDigit == "DCERTI Pro") {
      return true
    } else {
      return false
    }
  }

  let TyerPlanForDig = () => {
    if (carDetails?.quotePackageNameDigit == "DCT Pro" || carDetails?.quotePackageNameDigit == "DCET Pro" || carDetails?.quotePackageNameDigit == "DCRTI Pro" || carDetails?.quotePackageNameDigit == "DCTRTI Pro" || carDetails?.quotePackageNameDigit == "DCETRTI Pro" || carDetails?.quotePackageNameDigit == "DCERTI Pro") {
      return true
    } else {
      return false
    }
  }

  let RTIPlanForDig = () => {
    if (carDetails?.quotePackageNameDigit == "DCERTI Pro" || carDetails?.quotePackageNameDigit == "DCETRTI Pro" || carDetails?.quotePackageNameDigit == "DCTRTI Pro" || carDetails?.quotePackageNameDigit == "DCRTI Pro") {
      return true
    } else {
      return false
    }
  }

  // select plans for ICICI

  let zerodepplanicici = () => {
    if (carDetails.quotePackageNameICICI) {
      return true
    } else {
      return false
    }
  }

  let LOBforICICI = () => {
    if (carDetails?.quotePackageNameICICI == "RSA Plus 1" || carDetails?.quotePackageNameICICI == "RSA Plus 2" || carDetails?.quotePackageNameICICI == "RSA Plus 3" || carDetails?.quotePackageNameICICI == "RSA Plus 4" || carDetails?.quotePackageNameICICI == "RSA Plus 5") {
      return true
    } else {
      return false
    }
  }

  let Engineplanicici = () => {
    if (carDetails?.quotePackageNameICICI == "RSA Plus 2" || carDetails?.quotePackageNameICICI == "RSA Plus 4" || carDetails?.quotePackageNameICICI == "RSA Plus 5" || carDetails?.quotePackageNameICICI == "RSA With Key 2" || carDetails?.quotePackageNameICICI == "RSA With Key 4" || carDetails?.quotePackageNameICICI == "RSA With Key 5") {
      return true
    } else {
      return false
    }
  }

  let TyerPlanicici = () => {
    if (carDetails?.quotePackageNameICICI == "RSA Plus 3" || carDetails?.quotePackageNameICICI == "RSA Plus 4" || carDetails?.quotePackageNameICICI == "RSA Plus 5" || carDetails?.quotePackageNameICICI == "RSA With Key 3" || carDetails?.quotePackageNameICICI == "RSA With Key 4" || carDetails?.quotePackageNameICICI == "RSA With Key 5") {
      return true
    } else {
      return false
    }
  }

  let RTIPlanicici = () => {
    if (carDetails?.quotePackageNameICICI == "RSA Plus 5" || carDetails?.quotePackageNameICICI == "RSA With Key 5") {
      return true
    } else {
      return false
    }
  }

  //Select plans for TATA
  let RsaTata = () => {
    if (carDetails.quotePackageName && carDetails.quotePackageName !== "SILVER") {
      return true
    } else {
      return false
    }
  }

  let ZeroDepTata = () => {
    if (carDetails.quotePackageName && carDetails.quotePackageName !== "SILVER" && carDetails.quotePackageName !== "GOLD") {
      return true
    } else {
      return false
    }
  }

  let consumablesTata = () => {
    if (carDetails.quotePackageName == "PEARL+" || carDetails.quotePackageName == "SAPPHIRE" || carDetails.quotePackageName == "SAPPHIRE+" || carDetails.quotePackageName == "SAPPHIRE++" || carDetails.quotePackageName == "CORAL" || carDetails.quotePackageName == "PEARL++") {
      return true
    } else {
      return false
    }
  }

  let EnginTata = () => {
    if (carDetails.quotePackageName == "SAPPHIRE+" || carDetails.quotePackageName == "SAPPHIRE++" || carDetails.quotePackageName == "PLATINUM" || carDetails.quotePackageName == "PEARL++" || carDetails.quotePackageName == "PEARL+") {
      return true
    } else {
      return false
    }
  }

  let TyperTata = () => {
    if (carDetails.quotePackageName == "SAPPHIRE" || carDetails.quotePackageName == "SAPPHIRE+" || carDetails.quotePackageName == "SAPPHIRE++") {
      return true
    } else {
      return false
    }
  }

  let RTITata = () => {
    if (carDetails.quotePackageName == "PEARL++" || carDetails.quotePackageName == "PLATINUM" || carDetails.quotePackageName == "SAPPHIRE++") {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <p onClick={openModal}>{triggerTab} <img src={arrowRight} className="arrowRight-icon-mob" /> </p>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        id=""
      >
        <div className="modal_container modal-plandetails full-height">
          <div className="plan-detail-parent">

            <div className="leftSide_modal part_modal_container leftSide_modal-plandetails ">
              <div className="pol-details-premiumContainer hideInMobile" >
                <div className="car-details-premContainer">
                  <p>{carDetails.PolicyTypeValue}</p>
                  <p>{(carDetails?.vehicleNumber)}</p>
                </div>
                <div className="mmv_premiumContainer">
                  <p>{carDetails?.make || ""}</p>
                  <p>{`${carDetails?.model || ""} ${carDetails?.varient || ""
                    } (${carDetails?.cc || ""} cc)`}</p>
                </div>
                <div className="policy-det-premContent">
                  <div className="policy-det-premContent-block">
                    <p>Policy Start Date</p>
                    {
                      (carDetails.typeOfBusiness === "New") ?
                        (
                          <p>
                            {newPolicyStartDate}
                          </p>
                        ) :
                        (
                          <p>
                            {policyDateObj?.formattedToday}
                          </p>
                        )
                    }
                  </div>
                  <div className="policy-det-premContent-block">
                    <p>New NCB</p>
                    <p>
                      {
                        (carDetails.newNcb != 0 && carDetails.typeOfBusiness !== "New" && carDetails?.isClaimPrevPolicy !== true) ? (
                          <>
                            {carDetails.newNcb} %
                          </>
                        ) : (
                          <>
                            N/A
                          </>
                        )
                      }
                    </p>
                  </div>
                </div>
              </div>
              <br />
              <div className="ins-card-planDetails hideInMobile">
                <img src={companyImg} alt="" srcSet="" />
                <p>₹ {Math.round(vehiclebreakup?.DuePremium)?.toLocaleString('en-IN')}
                  <span>Price inclusive of GST</span>
                </p>
              </div>

              <div className="leftSide_modal-mobile mobileTab-planDetails">
                <div className="leftSide_modal-mobile-mmv-container">
                  <div className="head-mmv-modal head-mmv-modal-planDetails">
                    <p className="leftSide_modal-mm">
                      {`${carDetails?.make || ""} ${carDetails?.model || ""}`}
                    </p>
                    <img src={companyImg} />
                  </div>
                  <p className="leftSide_modal-CarNum"><span>IND</span>{carDetails?.vehicleNumber}</p>
                  <div className="leftSide_modal-vfr">
                    <p>
                      {`${carDetails?.varient || ""}`} <span>,</span> {`${carDetails?.fuelType || ""}`} {" "} ({`${carDetails?.cc || ""}`}cc)
                    </p>
                  </div>
                </div>
                <div className="selected-policy-tab">
                  {carDetails.PolicyTypeValue}
                </div>
              </div>

              <div className="footer-plandetails-tab" >
                <div className="plandetails-tab-value">
                  <p>IDV</p>
                  <p>
                    {carDetails?.idvValue
                      ? Math.round(carDetails?.idvValue)?.toLocaleString("en-IN")
                      : allIDVValues.min == Infinity
                        ? <SkeletonLoader width={100} />
                        : Math.round(allIDVValues.min)?.toLocaleString("en-IN")}
                  </p>
                </div>
                <div className="plandetails-tab-value">
                  <p>NCB</p>
                  <p> {carDetails?.isClaimPrevPolicy === true ? "Not Applicable" : `${carDetails?.newNcb}%`} </p>
                </div>
                {
                  carDetails?.typeOfBusiness !== "New" ? (
                    <div className="plandetails-tab-value">
                      <p>Policy End Date</p>
                      <p>{carDetails?.PolicyTypeValue == "Own Damage" ? odformateExpDate
                        : formattedExpDate}</p>
                    </div>
                  ) : null
                }
              </div>

              <div className="breadcrumb-container-plandetails hideInMobile" style={{ marginTop: "0px" }}>
                {
                  menuList?.map((data, key) => (
                    <div
                      className={
                        ContentType === data.params
                          ? "breadCrumb-planDetails breadCrumb-planDetails-active"
                          : "breadCrumb-planDetails "
                      }
                      key={key}
                      onClick={(e) => setContentType(data.params)}
                    >
                      <p>{data.label}</p>
                    </div>
                  ))
                }
              </div>
            </div>

            <div className="rightSide_modal rightSide_modal-plandetails part_modal_container ">
              <img
                src={close}
                className="closeImg_modal closeImg_modal-plandetails"
                onClick={closeModal}
              />

              <div className="breadcrumb-container-plandetails hideInPC" style={{ marginTop: "0px" }}>
                {
                  menuList?.map((data, key) => (
                    <div
                      className={
                        ContentType === data.params
                          ? "breadCrumb-planDetails breadCrumb-planDetails-active"
                          : "breadCrumb-planDetails "
                      }
                      key={key}
                      onClick={(e) => setContentType(data.params)}
                    >
                      <p>{data.label}</p>
                    </div>
                  ))
                }
              </div>

              {ContentType === "premiumBreakup" && (
                <section className="section-planDetails">
                  <p className="title-rightside-plandetails">
                    Premium Breakup of this policy.
                  </p>

                  <div className="coveredDetails-plandetails coveredDetails-plandetails-breakup">

                    {/* OWN DAMAGE COVER'S DISPLAYED HERE! */}

                    {
                      bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Third Party".toUpperCase() && (
                        <div className="details-covered breakup-head-planDetails ">
                          <p>Own Damage Cover (A)</p>
                          <p className="">
                            Cover Value(IDV): <span>{Math.round(vehiclebreakup?.idv).toLocaleString('en-IN')}</span>
                          </p>
                        </div>
                      )
                    }

                    {companyName == SHREERAM ? (
                      bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                        "Third Party".toUpperCase() ? (
                        <div className="details-covered details-covered-breakup-section">
                          <p>Own Damage:</p>
                          <p className="amt-breakup-plandetails">
                            ₹{" "} {Math.round(vehiclebreakup?.basicOd).toLocaleString('en-IN') || ""}
                          </p>
                        </div>
                      ) : (
                        ""
                      )
                    ) : bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Third Party".toUpperCase() ? (
                      <div className="details-covered details-covered-breakup-section">
                        <p>Own Damage:</p>
                        <p className="amt-breakup-plandetails">
                          ₹{" "}
                          {companyName == SBI
                            ? (
                              Math.round(vehiclebreakup?.OD_After_Disc +
                                vehiclebreakup?.OD_NCBAmount).toLocaleString('en-IN')
                            ) || ""
                            : ((companyName == DIGIT) ? Math.round(vehiclebreakup?.OD_After_Disc).toLocaleString('en-IN') :

                              Math.round(vehiclebreakup?.OD_After_Disc + vehiclebreakup?.OD_NCBAmount).toLocaleString('en-IN')
                            )}
                        </p>
                      </div>
                    ) : null}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Third Party".toUpperCase() ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>NCB Discount</p>
                        <p className="amt-breakup-plandetails">
                          -₹ {Math.round(vehiclebreakup?.OD_NCBAmount).toLocaleString('en-IN')}
                        </p>
                      </div>
                    ) : null}

                    {/* this should not present when typeOfBusiness === "New" */}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Third Party".toUpperCase() ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>Basic Own Damage (After NCB Discount):</p>
                        <p className="amt-breakup-plandetails">
                          ₹ {(companyName == DIGIT) ? Math.round(vehiclebreakup?.OD_After_Disc - vehiclebreakup?.OD_NCBAmount).toLocaleString('en-IN') : (Math.round(vehiclebreakup?.OD_After_Disc).toLocaleString('en-IN') || "")}
                        </p>
                      </div>
                    ) : null}

                    {companyName == SHREERAM ? (
                      bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                        "Third Party".toUpperCase() ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>De Tarrif Dicount:</p>
                          <p className="amt-breakup-plandetails">
                            ₹ {Math.round(vehiclebreakup?.deTarrifDiscount).toLocaleString('en-IN') || ""}
                          </p>
                        </div>
                      ) : (
                        ""
                      )
                    ) : null}

                    {companyName == SHREERAM ? (
                      bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                        "Third Party".toUpperCase() ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>Motor Protection:</p>
                          <p className="amt-breakup-plandetails">
                            ₹ {Math.round(vehiclebreakup?.shreeRamProtect).toLocaleString('en-IN') || ""}
                          </p>
                        </div>
                      ) : null
                    ) : null}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Third Party".toUpperCase() ? (
                      vehiclebreakup?.zeroDep ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>Zero Dep:</p>
                          <p className="amt-breakup-plandetails">
                            ₹ {Math.round(vehiclebreakup?.zeroDep).toLocaleString('en-IN') || "0"}
                          </p>
                        </div>
                      ) : null
                    ) : null}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Third Party".toUpperCase() ? (
                      vehiclebreakup?.roadAssistance ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>Road Side Assistance: </p>
                          <p className="amt-breakup-plandetails">
                            ₹ {Math.round(vehiclebreakup?.roadAssistance).toLocaleString('en-IN') || "0"}
                          </p>
                        </div>
                      ) : null
                    ) : null}

                    {(bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Third Party".toUpperCase() && companyName == ACKO) ? (
                      vehiclebreakup?.rsaKeyProtectCombo ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>RSA & Key Protect Combo: </p>
                          <p className="amt-breakup-plandetails">
                            ₹ {Math.round(vehiclebreakup?.rsaKeyProtectCombo).toLocaleString('en-IN') || "0"}
                          </p>
                        </div>
                      ) : null
                    ) : null}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Third Party".toUpperCase() ? (
                      vehiclebreakup?.consumables ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>Consumables: </p>
                          <p className="amt-breakup-plandetails">
                            ₹ {Math.round(vehiclebreakup?.consumables).toLocaleString('en-IN') || "0"}
                          </p>
                        </div>
                      ) : null
                    ) : null}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Third Party".toUpperCase() ? (
                      vehiclebreakup?.engineProtection ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>Engine Protection: </p>
                          <p className="amt-breakup-plandetails">
                            ₹ {Math.round(vehiclebreakup?.engineProtection).toLocaleString('en-IN') || "0"}
                          </p>
                        </div>
                      ) : null
                    ) : null}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Third Party".toUpperCase() ? (
                      vehiclebreakup?.tyreProtection ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>Tyre Protection: </p>
                          <p className="amt-breakup-plandetails">
                            ₹ {Math.round(vehiclebreakup?.tyreProtection).toLocaleString('en-IN') || "0"}
                          </p>
                        </div>
                      ) : null
                    ) : null}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Third Party".toUpperCase() ? (
                      vehiclebreakup?.invoiceCover ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>Return to Invoice: </p>
                          <p className="amt-breakup-plandetails">
                            ₹ {Math.round(vehiclebreakup?.invoiceCover).toLocaleString('en-IN') || "0"}
                          </p>
                        </div>
                      ) : null
                    ) : null}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Third Party".toUpperCase() ? (
                      vehiclebreakup?.keyLockReplacement ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>Key & Lock Replacement: </p>
                          <p className="amt-breakup-plandetails">
                            ₹ {Math.round(vehiclebreakup?.keyLockReplacement).toLocaleString('en-IN') || "0"}
                          </p>
                        </div>
                      ) : null
                    ) : null}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Third Party".toUpperCase() ? (
                      vehiclebreakup?.ncbProtection ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>NCB Protection: </p>
                          <p className="amt-breakup-plandetails">
                            ₹ {Math.round(vehiclebreakup?.ncbProtection).toLocaleString('en-IN') || "0"}
                          </p>
                        </div>
                      ) : null
                    ) : null}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Third Party".toUpperCase() ? (
                      vehiclebreakup?.dailyAllowance ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>Daily Allowance: </p>
                          <p className="amt-breakup-plandetails">
                            ₹ {Math.round(vehiclebreakup?.dailyAllowance).toLocaleString('en-IN') || "0"}
                          </p>
                        </div>
                      ) : null
                    ) : null}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Third Party".toUpperCase() ? (
                      vehiclebreakup?.personalBelogging ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>Loss of Belongings: </p>
                          <p className="amt-breakup-plandetails">
                            ₹ {Math.round(vehiclebreakup?.personalBelogging).toLocaleString('en-IN') || "0"}
                          </p>
                        </div>
                      ) : null
                    ) : null}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Third Party".toUpperCase() ? (
                      vehiclebreakup?.accessories ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>Accessories: </p>
                          <p className="amt-breakup-plandetails">(Included)</p>
                        </div>
                      ) : null
                    ) : null}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Third Party".toUpperCase() ? (
                      vehiclebreakup?.odExternalCngLpg || vehiclebreakup?.odInternalCngLpg ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>CNG Fitted: </p>
                          <p className="amt-breakup-plandetails">(Included)</p>
                        </div>
                      ) : null
                    ) : null}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Third Party".toUpperCase() ? (
                      <div className="details-covered details-covered-breakup-section details-covered-breakup-section-highlighted ">
                        <p>Total Own Damage: </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {(companyName == DIGIT) ? (Math.round(vehiclebreakup?.OD_TotalPremium - vehiclebreakup?.OD_NCBAmount).toLocaleString('en-IN')) : (Math.round(vehiclebreakup?.OD_TotalPremium).toLocaleString('en-IN') || "0")}
                        </p>
                      </div>
                    ) : null}

                    {/* THIRD PARTY ADDONS LIST STARTS FROM HERE! */}

                    {
                      bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Own Damage".toUpperCase() && (
                        <div className="details-covered breakup-head-planDetails ">
                          <p style={{ color: "#1A1B1D" }} >Third Party Cover (B)</p>
                        </div>
                      )
                    }

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Own Damage".toUpperCase() ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>Basic TP:  </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.basicTP).toLocaleString('en-IN') || "0"}
                        </p>
                      </div>
                    ) : null}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Own Damage".toUpperCase() ? (
                      vehiclebreakup?.passengerCover ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>Passenger Cover: </p>
                          <p className="amt-breakup-plandetails">
                            ₹ {Math.round(vehiclebreakup?.passengerCover).toLocaleString('en-IN') || "0"}
                          </p>
                        </div>
                      ) : null
                    ) : null}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Own Damage".toUpperCase() ? (
                      vehiclebreakup?.paOwnerDriver ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>PA Cover: </p>
                          <p className="amt-breakup-plandetails">
                            ₹ {Math.round(vehiclebreakup?.paOwnerDriver).toLocaleString('en-IN') || "0"}
                          </p>
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Own Damage".toUpperCase() ? (
                      vehiclebreakup?.driverCover ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>Driver Cover: </p>
                          <p className="amt-breakup-plandetails">
                            ₹ {Math.round(vehiclebreakup?.driverCover).toLocaleString('en-IN') || "0"}
                          </p>
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() ==
                      "Third Party".toUpperCase() || bodyData?.data?.PolicyTypeValue?.toUpperCase() ==
                      "Comprehensive".toUpperCase() ? (
                      vehiclebreakup?.tpCngLpg ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>CNG Fitted: </p>
                          <p className="amt-breakup-plandetails">(Included)</p>
                        </div>
                      ) : null
                    ) : null}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Own Damage".toUpperCase() ? (
                      vehiclebreakup?.paidDriver ? (
                        <div className="details-covered details-covered-breakup-section ">
                          <p>Paid Driver: </p>
                          <p className="amt-breakup-plandetails">
                            ₹ {Math.round(vehiclebreakup?.paidDriver).toLocaleString('en-IN') || "0"}
                          </p>
                        </div>
                      ) : null
                    ) : null}

                    {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Own Damage".toUpperCase() ? (
                      <div className="details-covered details-covered-breakup-section details-covered-breakup-section-highlighted ">
                        <p>Total Third Party: </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.TP_TotalPremium).toLocaleString('en-IN')}
                        </p>
                      </div>
                    ) : null}

                    {/* TOTAL PREMIUM SECTION - (A + B) */}

                    <div className="details-covered breakup-head-planDetails ">
                      <p style={{ color: "#1A1B1D" }} >Total Premium</p>
                    </div>

                    <div className="details-covered details-covered-breakup-section ">
                      <p>Net Payable (A+B):  </p>
                      <p className="amt-breakup-plandetails">
                        ₹ {Math.round(vehiclebreakup?.netPayble).toLocaleString('en-IN')}
                      </p>
                    </div>

                    <div className="details-covered details-covered-breakup-section ">
                      <p>GST:</p>
                      <p className="amt-breakup-plandetails">
                        ₹ {Math.round(vehiclebreakup?.TGST).toLocaleString('en-IN')}
                      </p>
                    </div>

                    <div className="details-covered details-covered-breakup-section details-covered-breakup-section-highlighted ">
                      <p>Gross Premium: </p>
                      <p className="amt-breakup-plandetails">
                        ₹ {Math.round(vehiclebreakup?.DuePremium).toLocaleString('en-IN')}
                      </p>
                    </div>
                  </div>
                </section>
              )}

              {ContentType === "addonsSelected" && (
                <>
                  <section className="section-planDetails">
                    <div className="covered-details-container">
                      <p className="covered-details-container-title">Selected Addon's</p>
                      {cover?.map((coveredKeys) => {
                        return (
                          <>
                            {
                              (checkboxTrueFalseObject?.zeroDep || (ZeroDepDig() && companyName == DIGIT) || (zerodepplanicici() && companyName == ICICI) || (ZeroDepTata() && companyName == TATA)) && (
                                <>
                                  {coveredKeys == "zeroDep" &&
                                    bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                                    "Third Party".toUpperCase() ? (
                                    <div className="list-coverages-">
                                      <div className="title-list-coverages">
                                        <img src={greenTick} alt="" />
                                        <p>Zero Depreciation</p>
                                      </div>
                                      <p>A zero dep cover is an add-on in car insurance under which we won't charge you for depreciation during the claim settlement.</p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )
                            }
                            {
                              checkboxTrueFalseObject?.shreeramProtect && (
                                <>
                                  {(coveredKeys == "shreeramProtect" && companyName == SHREERAM) &&
                                    bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                                    "Third Party".toUpperCase() ? (
                                    <div className="list-coverages-">
                                      <div className="title-list-coverages">
                                        <img src={greenTick} alt="" />
                                        <p>Motor Protection</p>
                                      </div>
                                      <p>In case of any motor accident arising out of the use of the Insured Vehicle, We will provide you an advocate to guide and/or defend you from police and proceedings in the court of judicial magistrate only.</p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )
                            }
                            {
                              checkboxTrueFalseObject?.paCover && (
                                <>
                                  {coveredKeys == "paCover" &&
                                    bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                                    "Own Damage".toUpperCase() ? (
                                    <div className="list-coverages-">
                                      <div className="title-list-coverages">
                                        <img src={greenTick} alt="" />
                                        <p>PA Cover</p>
                                      </div>
                                      <p>A PA cover under the motor insurance policy will pay for the compensation in case of bodily injuries, death or any permanent disability resulting due to an accident.</p>
                                    </div>
                                  ) : null}
                                </>
                              )
                            }
                            {
                              (checkboxTrueFalseObject?.consumables || (consumablesforDig() && companyName == DIGIT) || (zerodepplanicici() && companyName == ICICI) || (consumablesTata() && companyName == TATA)) && (
                                <>
                                  {coveredKeys == "consumables" &&
                                    bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                                    "Third Party".toUpperCase() ? (
                                    <div className="list-coverages-">
                                      <div className="title-list-coverages">
                                        <img src={greenTick} alt="" />
                                        <p>Consumables</p>
                                      </div>
                                      <p>Covers expenses incurred towards "Consumables" (nut and bolt, screw, washers, grease, lubricants clip, ac gas, bearings, distilled water, engine oil, oil filter, fuel filter, break oil and the like) arising out of damage to the vehicle.</p>
                                    </div>
                                  ) : null}
                                </>
                              )
                            }
                            {
                              (checkboxTrueFalseObject?.roadAssistance || (RsaplanDig() && companyName == DIGIT) || (zerodepplanicici() && companyName == ICICI)) || (RsaTata() && companyName == TATA) && (
                                <>
                                  {coveredKeys == "roadAssistance" &&
                                    bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                                    "Third Party".toUpperCase() ? (
                                    <div className="list-coverages-">
                                      <div className="title-list-coverages">
                                        <img src={greenTick} alt="" />
                                        <p>24/7 Road Assistance</p>
                                      </div>
                                      <p>Road side assistance provides support for basic on-road breakdown situations like tyre change, battery jump start, emergency fuel, medical assistance etc which are not covered under Insurance. As the price is very nominal, it is a good to have add-on.</p>
                                    </div>
                                  ) : null}
                                </>
                              )
                            }
                            {
                              (checkboxTrueFalseObject?.engineProtection || (EngineplanForDig() && companyName == DIGIT) || (Engineplanicici() && companyName == ICICI) || (EnginTata() && companyName == TATA)) && (
                                <>
                                  {coveredKeys == "engineProtection" &&
                                    bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                                    "Third Party".toUpperCase() ? (
                                    <div className="list-coverages-">
                                      <div className="title-list-coverages">
                                        <img src={greenTick} alt="" />
                                        <p>Engine Protector</p>
                                      </div>
                                      <p>When the Engine of the car is submerged in a water logged area, using or cranking the engine can result in engine ceasing. This is not covered under regular Insurance. Engine protector covers such non-accidental exclusions related to your engine. It is a must buy for luxury cars where engine is very costly & is placed at low ground clearance.</p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )
                            }
                            {
                              (checkboxTrueFalseObject?.invoiceCover || (RTIPlanForDig() && companyName == DIGIT) || (RTIPlanicici() && companyName == ICICI) || (RTITata() && companyName == TATA)) && (
                                <>
                                  {coveredKeys == "invoiceCover" &&
                                    bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                                    "Third Party".toUpperCase() ? (
                                    <div className="list-coverages-">
                                      <div className="title-list-coverages">
                                        <img src={greenTick} alt="" />
                                        <p>Invoice Cover</p>
                                      </div>
                                      <p>In case of theft or total damage to your car, you are only eligible for reimbursement up to the Insured declared value of your car, which will be very less than the Invoice value. In case of such an event, selecting Invoice cover makes you eligible for full Invoice amount reimbursement.</p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )
                            }
                            {
                              (checkboxTrueFalseObject?.keyLockReplacement || (KeyplanForDig() && companyName == DIGIT) || (zerodepplanicici() && companyName == ICICI) || (RsaTata() && companyName == TATA)) && (
                                <>
                                  {coveredKeys == "keyLockReplacement" &&
                                    bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                                    "Third Party".toUpperCase() ? (
                                    <div className="list-coverages-">
                                      <div className="title-list-coverages">
                                        <img src={greenTick} alt="" />
                                        <p>Key and Lock Replacement</p>
                                      </div>
                                      <p>In case your keys are lost or stolen, you have to request a new one from the manufacturer. In most cases, you may even need to replace the locks, which is another added expenditure. Key and Lock Replacement Cover covers the expenses incurred for procuring a new key. It is a must buy for high end and luxury cars as the new set of keys can be very expensive.</p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )
                            }
                            {
                              checkboxTrueFalseObject?.ncbProtection && (
                                <>
                                  {coveredKeys == "ncbProtection" &&
                                    bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                                    "Third Party".toUpperCase() ? (
                                    <div className="list-coverages-">
                                      <div className="title-list-coverages">
                                        <img src={greenTick} alt="" />
                                        <p>NCB Protection</p>
                                      </div>
                                      <p>An NCB Protect cover gives you extra cushioning. It keeps the bonus component intact even if some claims (depending upon the terms and conditions) are raised during the policy period. You can opt for NCB protection cover to benefit from the renewal discount irrespective of a claim (or claims) during the policy period</p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )
                            }
                            {
                              checkboxTrueFalseObject?.driverCover && (
                                <>
                                  {coveredKeys == "driverCover" &&
                                    bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                                    "Own Damage".toUpperCase() ? (
                                    <div className="list-coverages-">
                                      <div className="title-list-coverages">
                                        <img src={greenTick} alt="" />
                                        <p>Driver Cover</p>
                                      </div>
                                      <p>It covers medical costs incurred in the treatment of the injured driver and passengers. It gives financial aid in case of the death of owner-driver/passengers. It provides disability liability cover to the owner-driver and passengers of an insured car.</p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )
                            }
                            {
                              checkboxTrueFalseObject?.passengerCover && (
                                <>
                                  {coveredKeys == "passengerCover" &&
                                    bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                                    "Own Damage".toUpperCase() ? (
                                    <div className="list-coverages-">
                                      <div className="title-list-coverages">
                                        <img src={greenTick} alt="" />
                                        <p>Passenger Cover</p>
                                      </div>
                                      <p>Passenger cover in car insurance is financial coverage for passengers who are travelling in the insured's car. While it is necessary to have the personal accident cover for the owner-driver of the car, covering passengers is not mandatory.</p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )
                            }
                            {
                              checkboxTrueFalseObject?.accessories && (
                                <>
                                  {coveredKeys == "accessories" &&
                                    bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                                    "Third Party".toUpperCase() ? (
                                    <div className="list-coverages-">
                                      <div className="title-list-coverages">
                                        <img src={greenTick} alt="" />
                                        <p>Accessories</p>
                                      </div>
                                      <p>Cover your car's extra fitted electrical and non-electrical accessories.</p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )
                            }
                            {
                              (checkboxTrueFalseObject?.lossofPersonalBelongings || (LOPlanForDig() && companyName == DIGIT) || (LOBforICICI() && companyName == ICICI) || (RsaTata() && companyName == TATA)) && (
                                <>
                                  {coveredKeys == "personalBelonging" || coveredKeys == "lossofPersonalBelongings" &&
                                    bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                                    "Third Party".toUpperCase() ? (
                                    <div className="list-coverages-">
                                      <div className="title-list-coverages">
                                        <img src={greenTick} alt="" />
                                        <p>Loss of Belongings</p>
                                      </div>
                                      <p>Personal belongings add on provides you coverage against such belongings kept in your car. While theft of a car is covered, theft of the items kept in the car is not. Personal belongings might include items such as laptop, smartphone, camera, musical instruments, etc.</p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )
                            }
                            {
                              (checkboxTrueFalseObject?.tyreProtection || (TyerPlanForDig() && companyName == DIGIT) || (TyerPlanicici() && companyName == ICICI) || (TyperTata() && companyName == TATA)) && (
                                <>
                                  {
                                    coveredKeys == "tyreProtection" &&
                                      bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                                      "Third Party".toUpperCase() ? (
                                      <div className="list-coverages-">
                                        <div className="title-list-coverages">
                                          <img src={greenTick} alt="" />
                                          <p>Tyre Protection</p>
                                        </div>
                                        <p>A tyre protection cover is a car insurance add-on cover that gives coverage for the repair and replacement cost of tyres. This add-on cover provides insurance cover for accidental loss and damage or cuts to the tyres in addition to the labour charges incurred during the repair or replacement of tyres.</p>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                </>
                              )
                            }
                            {
                              checkboxTrueFalseObject?.dailyAllowance && (
                                <>
                                  {coveredKeys == "dailyAllowance" &&
                                    bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                                    "Third Party".toUpperCase() ? (
                                    <div className="list-coverages-">
                                      <div className="title-list-coverages">
                                        <img src={greenTick} alt="" />
                                        <p>Daily Allowance</p>
                                      </div>
                                      <p>The daily allowance add-on cover for car insurance can be purchased with a maximum coverage duration. It basically means that the insurance company will pay you the compensation for a fixed number of days that is mentioned in the policy document or till the time your car gets repaired, whichever happens earlier.</p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )
                            }
                          </>
                        );
                      })}
                    </div>
                  </section>
                </>
              )}

              {ContentType === "coveredDetails" && (
                <>
                  <div className="covered-details-container hideInMobile">

                    {/* Comprehensive cover */}

                    {
                      carDetails.PolicyTypeValue === "Comprehensive" && (
                        <div className="plan-coverages-container">
                          <p className="covered-details-container-title">Comprehensive</p>
                          <p className="covered-details-container-desc">Covers damage to your car and others.</p>
                          {
                            coveredListComprehensive?.map((data, key) => (
                              <div key={key} className="list-coverages-">
                                <div className="title-list-coverages">
                                  <img src={greenTick} alt="" />
                                  <p>{data.scene}</p>
                                </div>
                                <p>{data.sceneDescription}</p>
                              </div>
                            ))
                          }
                        </div>
                      )
                    }

                    {/* Own Damage cover list */}

                    {
                      carDetails.PolicyTypeValue === "Own Damage" && (
                        <div className="plan-coverages-container">
                          <p className="covered-details-container-title">Own Damage</p>
                          <p className="covered-details-container-desc">Covers damages to your own vehicle.</p>
                          {
                            coveredListOwnDamage?.map((data, key) => (
                              <div key={key} className="list-coverages-">
                                <div className="title-list-coverages">
                                  <img src={greenTick} alt="" />
                                  <p>{data.scene}</p>
                                </div>
                                <p>{data.sceneDescription}</p>
                              </div>
                            ))
                          }
                        </div>
                      )
                    }

                    {/* Third Party Only */}

                    {
                      carDetails.PolicyTypeValue === "Third Party" && (
                        <div className="plan-coverages-container">
                          <p className="covered-details-container-title">Third Party Only</p>
                          <p className="covered-details-container-desc">Covers damages to others property.</p>
                          {
                            coveredListThirdParty?.map((data, key) => (
                              <div key={key} className="list-coverages-">
                                <div className="title-list-coverages">
                                  <img src={greenTick} alt="" />
                                  <p>{data.scene}</p>
                                </div>
                                <p>{data.sceneDescription}</p>
                              </div>
                            ))
                          }
                        </div>
                      )
                    }

                    {/* Bundled  Only */}

                    {
                      carDetails.PolicyTypeValue === "Bundled" && (
                        <div className="plan-coverages-container">
                          <p className="covered-details-container-title">Bundled (1 yr OD + 3yr TP)</p>
                          <p className="covered-details-container-desc">Covers damages to multiple assets or risks under a single policy.</p>
                          {
                            coveredListBundled?.map((data, key) => (
                              <div key={key} className="list-coverages-">
                                <div className="title-list-coverages">
                                  <img src={greenTick} alt="" />
                                  <p>{data.scene}</p>
                                </div>
                                <p>{data.sceneDescription}</p>
                              </div>
                            ))
                          }
                        </div>
                      )
                    }

                  </div>

                  <div className="covered-details-mobile hideInDeskTop">
                    {
                      coveredListComprehensive?.map((data, key) => (
                        <div key={key} className="tab-img-title" >
                          <img src={data.image} />
                          <p> {data.title} </p>
                        </div>
                      ))
                    }
                  </div>

                  <div className="coverage-content hideInDeskTop" >

                    <p>Additional Coverages</p>

                    <div className="list-coverages- ">
                      <div className="title-list-coverages list-coverages-contnet">
                        <img src={tick} alt="" />
                        <p>Consumables</p>
                      </div>
                      <p>Consumable cover adds an extra layer of protection to your car by covering the cost of engine oils, screws, nuts, bolts, grease, and other such consumables during repairs. </p>
                    </div>

                    <div className="list-coverages- ">
                      <div className="title-list-coverages list-coverages-contnet">
                        <img src={tick} alt="" />
                        <p>Compulsory Personal Accident Cover</p>
                      </div>
                      <p>This cover provides coverage ( Max Rs. 15 lakh) for bodily injury or death of the owner-driver in case of an unfortunate accident.</p>
                    </div>

                  </div>
                </>
              )}

              {ContentType === "notCoveredDetails" && (
                <>
                  <div className="covered-details-container hideInMobile">
                    {/* Comprehensive cover */}
                    {
                      carDetails.PolicyTypeValue === "Comprehensive" && (
                        <div className="plan-coverages-container">
                          <p className="covered-details-container-title">Comprehensive</p>
                          {
                            notCoveredComprehensive?.map((data, key) => (
                              <div key={key} className="list-coverages-">
                                <div className="title-list-coverages title-list-notCovered">
                                  <img src={data.icon} alt="" />
                                  <p>{data.scene}</p>
                                </div>
                                <p>{data.sceneDescription}</p>
                              </div>
                            ))
                          }
                        </div>
                      )
                    }

                    {/* Third Party Only */}

                    {
                      carDetails.PolicyTypeValue === "Third Party" && (
                        <div className="plan-coverages-container">
                          <p className="covered-details-container-title">Third Party Only</p>
                          {
                            notCoveredThirdParty?.map((data, key) => (
                              <div key={key} className="list-coverages-">
                                <div className="title-list-coverages title-list-notCovered">
                                  <img src={data.icon} alt="" />
                                  <p>{data.scene}</p>
                                </div>
                                <p>{data.sceneDescription}</p>
                              </div>
                            ))
                          }
                        </div>
                      )
                    }

                    {/* Own Damage Only */}

                    {
                      carDetails.PolicyTypeValue === "Own Damage" && (
                        <div className="plan-coverages-container">
                          <p className="covered-details-container-title">Own Damage</p>
                          {
                            notCoveredOwnDamage?.map((data, key) => (
                              <div key={key} className="list-coverages-">
                                <div className="title-list-coverages title-list-notCovered">
                                  <img src={data.icon} alt="" />
                                  <p>{data.scene}</p>
                                </div>
                                <p>{data.sceneDescription}</p>
                              </div>
                            ))
                          }
                        </div>
                      )
                    }

                    {/* Bundled Only */}

                    {
                      carDetails.PolicyTypeValue === "Bundled" && (
                        <div className="plan-coverages-container">
                          <p className="covered-details-container-title">Bundled (1 yr OD + 3yr TP)</p>
                          {
                            notCoveredBundled?.map((data, key) => (
                              <div key={key} className="list-coverages-">
                                <div className="title-list-coverages title-list-notCovered">
                                  <img src={data.icon} alt="" />
                                  <p>{data.scene}</p>
                                </div>
                                <p>{data.sceneDescription}</p>
                              </div>
                            ))
                          }
                        </div>
                      )
                    }
                  </div>

                  <div className="covered-details-mobile hideInDeskTop">
                    {
                      notCoveredComprehensive?.map((data, key) => (
                        <div key={key} className="tab-img-title" >
                          <img src={data.image} />
                          <p> {data.title} </p>
                        </div>
                      ))
                    }
                  </div>

                  <div className="coverage-content coverage-not-content hideInDeskTop" >

                    <p style={{ color: "#F00" }} >Not Included As Per Standard Exclusions</p>

                    <div className="list-coverages- ">
                      <div className="title-list-coverages list-coverages-contnet">
                        <img src={disable} alt="" />
                        <p>Non-Accidental Damages</p>
                      </div>
                      <p>Damages resulting from wear and tear, breakdowns, and mechanical failures.</p>
                    </div>

                    <div className="list-coverages- ">
                      <div className="title-list-coverages list-coverages-contnet">
                        <img src={disable} alt="" />
                        <p>Compulsory Personal Accident Cover</p>
                      </div>
                      <p>Regular wear and tear of these items is not covered by the policy. However, if the items are damaged in an accident along with the vehicle damage, they will be covered with a 50% depreciation cut.</p>
                    </div>

                    <div className="list-coverages- ">
                      <div className="title-list-coverages list-coverages-contnet">
                        <img src={disable} alt="" />
                        <p>Undeclared Non-OEM parts</p>
                      </div>
                      <p>If you are installing non-OEM parts such as halogen bulbs, stereos, or bifuel kits, please inform us in advance so that we can add them to your coverage</p>
                    </div>

                  </div>
                </>
              )}

              {/* mobile view premium breakup */}

              <div className="prem-breakup-container-mob">
                <p>Premium Breakup</p>
                <section className="section-planDetails  hideInPC">
                  <div className="coveredDetails-plandetails coveredDetails-plandetails-breakup coveredDetails-plandetails-mob">

                    {/* OWN DAMAGE COVER'S DISPLAYED HERE! */}

                    {
                      bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Third Party".toUpperCase() && (
                        <div className="od-container-mob">

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Third Party".toUpperCase() ? (
                            <div className="details-covered details-covered-breakup-section details-covered-breakup-section-highlighted ">
                              <p>Total Own Damage (A) </p>
                              <p className="amt-breakup-plandetails">
                                ₹ {(companyName == DIGIT) ? (Math.round(vehiclebreakup?.OD_TotalPremium - vehiclebreakup?.OD_NCBAmount).toLocaleString('en-IN')) : (Math.round(vehiclebreakup?.OD_TotalPremium).toLocaleString('en-IN') || "0")}
                              </p>
                            </div>
                          ) : null}

                          {companyName == SHREERAM ? (
                            bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                              "Third Party".toUpperCase() ? (
                              <div className="details-covered details-covered-breakup-section">
                                <p>Own Damage:</p>
                                <p className="amt-breakup-plandetails">
                                  ₹{" "} {Math.round(vehiclebreakup?.basicOd).toLocaleString('en-IN') || ""}
                                </p>
                              </div>
                            ) : (
                              ""
                            )
                          ) : bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Third Party".toUpperCase() ? (
                            <div className="details-covered details-covered-breakup-section">
                              <p>Own Damage:</p>
                              <p className="amt-breakup-plandetails">
                                ₹{" "}
                                {companyName == SBI
                                  ? (
                                    Math.round(vehiclebreakup?.OD_After_Disc +
                                      vehiclebreakup?.OD_NCBAmount).toLocaleString('en-IN')
                                  ) || ""
                                  : ((companyName == DIGIT) ? Math.round(vehiclebreakup?.OD_After_Disc).toLocaleString('en-IN') :

                                    Math.round(vehiclebreakup?.OD_After_Disc + vehiclebreakup?.OD_NCBAmount).toLocaleString('en-IN')
                                  )}
                              </p>
                            </div>
                          ) : null}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Third Party".toUpperCase() ? (
                            <div className="details-covered details-covered-breakup-section ">
                              <p>NCB Discount</p>
                              <p className="amt-breakup-plandetails">
                                -₹ {Math.round(vehiclebreakup?.OD_NCBAmount).toLocaleString('en-IN')}
                              </p>
                            </div>
                          ) : null}

                          {/* this should not present when typeOfBusiness === "New" */}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Third Party".toUpperCase() ? (
                            <div className="details-covered details-covered-breakup-section details-covered-bold">
                              <p>OD After NCB Discount:</p>
                              <p className="amt-breakup-plandetails">
                                ₹ {(companyName == DIGIT) ? Math.round(vehiclebreakup?.OD_After_Disc - vehiclebreakup?.OD_NCBAmount).toLocaleString('en-IN') : (Math.round(vehiclebreakup?.OD_After_Disc).toLocaleString('en-IN') || "")}
                              </p>
                            </div>
                          ) : null}

                          {companyName == SHREERAM ? (
                            bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                              "Third Party".toUpperCase() ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>De Tarrif Dicount:</p>
                                <p className="amt-breakup-plandetails">
                                  ₹ {Math.round(vehiclebreakup?.deTarrifDiscount).toLocaleString('en-IN') || ""}
                                </p>
                              </div>
                            ) : (
                              ""
                            )
                          ) : null}

                          {companyName == SHREERAM ? (
                            bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                              "Third Party".toUpperCase() ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>Motor Protection:</p>
                                <p className="amt-breakup-plandetails">
                                  ₹ {Math.round(vehiclebreakup?.shreeRamProtect).toLocaleString('en-IN') || ""}
                                </p>
                              </div>
                            ) : null
                          ) : null}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Third Party".toUpperCase() ? (
                            vehiclebreakup?.zeroDep ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>Zero Dep:</p>
                                <p className="amt-breakup-plandetails">
                                  ₹ {Math.round(vehiclebreakup?.zeroDep).toLocaleString('en-IN') || "0"}
                                </p>
                              </div>
                            ) : null
                          ) : null}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Third Party".toUpperCase() ? (
                            vehiclebreakup?.roadAssistance ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>Road Side Assistance: </p>
                                <p className="amt-breakup-plandetails">
                                  ₹ {Math.round(vehiclebreakup?.roadAssistance).toLocaleString('en-IN') || "0"}
                                </p>
                              </div>
                            ) : null
                          ) : null}

                          {(bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Third Party".toUpperCase() && companyName == ACKO) ? (
                            vehiclebreakup?.rsaKeyProtectCombo ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>RSA & Key Protect Combo: </p>
                                <p className="amt-breakup-plandetails">
                                  ₹ {Math.round(vehiclebreakup?.rsaKeyProtectCombo).toLocaleString('en-IN') || "0"}
                                </p>
                              </div>
                            ) : null
                          ) : null}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Third Party".toUpperCase() ? (
                            vehiclebreakup?.consumables ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>Consumables: </p>
                                <p className="amt-breakup-plandetails">
                                  ₹ {Math.round(vehiclebreakup?.consumables).toLocaleString('en-IN') || "0"}
                                </p>
                              </div>
                            ) : null
                          ) : null}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Third Party".toUpperCase() ? (
                            vehiclebreakup?.engineProtection ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>Engine Protection: </p>
                                <p className="amt-breakup-plandetails">
                                  ₹ {Math.round(vehiclebreakup?.engineProtection).toLocaleString('en-IN') || "0"}
                                </p>
                              </div>
                            ) : null
                          ) : null}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Third Party".toUpperCase() ? (
                            vehiclebreakup?.tyreProtection ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>Tyre Protection: </p>
                                <p className="amt-breakup-plandetails">
                                  ₹ {Math.round(vehiclebreakup?.tyreProtection).toLocaleString('en-IN') || "0"}
                                </p>
                              </div>
                            ) : null
                          ) : null}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Third Party".toUpperCase() ? (
                            vehiclebreakup?.invoiceCover ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>Return to Invoice: </p>
                                <p className="amt-breakup-plandetails">
                                  ₹ {Math.round(vehiclebreakup?.invoiceCover).toLocaleString('en-IN') || "0"}
                                </p>
                              </div>
                            ) : null
                          ) : null}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Third Party".toUpperCase() ? (
                            vehiclebreakup?.keyLockReplacement ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>Key & Lock Replacement: </p>
                                <p className="amt-breakup-plandetails">
                                  ₹ {Math.round(vehiclebreakup?.keyLockReplacement).toLocaleString('en-IN') || "0"}
                                </p>
                              </div>
                            ) : null
                          ) : null}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Third Party".toUpperCase() ? (
                            vehiclebreakup?.ncbProtection ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>NCB Protection: </p>
                                <p className="amt-breakup-plandetails">
                                  ₹ {Math.round(vehiclebreakup?.ncbProtection).toLocaleString('en-IN') || "0"}
                                </p>
                              </div>
                            ) : null
                          ) : null}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Third Party".toUpperCase() ? (
                            vehiclebreakup?.dailyAllowance ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>Daily Allowance: </p>
                                <p className="amt-breakup-plandetails">
                                  ₹ {Math.round(vehiclebreakup?.dailyAllowance).toLocaleString('en-IN') || "0"}
                                </p>
                              </div>
                            ) : null
                          ) : null}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Third Party".toUpperCase() ? (
                            vehiclebreakup?.personalBelogging ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>Loss of Belongings: </p>
                                <p className="amt-breakup-plandetails">
                                  ₹ {Math.round(vehiclebreakup?.personalBelogging).toLocaleString('en-IN') || "0"}
                                </p>
                              </div>
                            ) : null
                          ) : null}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Third Party".toUpperCase() ? (
                            vehiclebreakup?.accessories ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>Accessories: </p>
                                <p className="amt-breakup-plandetails">(Included)</p>
                              </div>
                            ) : null
                          ) : null}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Third Party".toUpperCase() ? (
                            vehiclebreakup?.odExternalCngLpg || vehiclebreakup?.odInternalCngLpg ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>CNG Fitted: </p>
                                <p className="amt-breakup-plandetails">(Included)</p>
                              </div>
                            ) : null
                          ) : null}

                        </div>
                      )
                    }

                    {/* THIRD PARTY ADDONS LIST STARTS FROM HERE! */}

                    {
                      bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                      "Own Damage".toUpperCase() && (
                        <div className="tp-container-mob">

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Own Damage".toUpperCase() ? (
                            <div className="details-covered details-covered-breakup-section details-covered-breakup-section-highlighted ">
                              <p>Total Third Party (B) </p>
                              <p className="amt-breakup-plandetails">
                                ₹ {Math.round(vehiclebreakup?.TP_TotalPremium).toLocaleString('en-IN')}
                              </p>
                            </div>
                          ) : null}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Own Damage".toUpperCase() ? (
                            <div className="details-covered details-covered-breakup-section ">
                              <p>Basic TP:  </p>
                              <p className="amt-breakup-plandetails">
                                ₹ {Math.round(vehiclebreakup?.basicTP).toLocaleString('en-IN') || "0"}
                              </p>
                            </div>
                          ) : null}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Own Damage".toUpperCase() ? (
                            vehiclebreakup?.passengerCover ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>Passenger Cover: </p>
                                <p className="amt-breakup-plandetails">
                                  ₹ {Math.round(vehiclebreakup?.passengerCover).toLocaleString('en-IN') || "0"}
                                </p>
                              </div>
                            ) : null
                          ) : null}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Own Damage".toUpperCase() ? (
                            vehiclebreakup?.paOwnerDriver ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>PA Cover: </p>
                                <p className="amt-breakup-plandetails">
                                  ₹ {Math.round(vehiclebreakup?.paOwnerDriver).toLocaleString('en-IN') || "0"}
                                </p>
                              </div>
                            ) : (
                              ""
                            )
                          ) : null}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Own Damage".toUpperCase() ? (
                            vehiclebreakup?.driverCover ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>Driver Cover: </p>
                                <p className="amt-breakup-plandetails">
                                  ₹ {Math.round(vehiclebreakup?.driverCover).toLocaleString('en-IN') || "0"}
                                </p>
                              </div>
                            ) : (
                              ""
                            )
                          ) : null}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() ==
                            "Third Party".toUpperCase() || bodyData?.data?.PolicyTypeValue?.toUpperCase() ==
                            "Comprehensive".toUpperCase() ? (
                            vehiclebreakup?.tpCngLpg ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>CNG Fitted: </p>
                                <p className="amt-breakup-plandetails">(Included)</p>
                              </div>
                            ) : null
                          ) : null}

                          {bodyData?.data?.PolicyTypeValue?.toUpperCase() !==
                            "Own Damage".toUpperCase() ? (
                            vehiclebreakup?.paidDriver ? (
                              <div className="details-covered details-covered-breakup-section ">
                                <p>Paid Driver: </p>
                                <p className="amt-breakup-plandetails">
                                  ₹ {Math.round(vehiclebreakup?.paidDriver).toLocaleString('en-IN') || "0"}
                                </p>
                              </div>
                            ) : null
                          ) : null}

                        </div>
                      )
                    }

                    {/* TOTAL PREMIUM SECTION - (A + B) */}

                    <div className="totalPrice-container-mob">
                      <div className="details-covered breakup-head-planDetails ">
                        <p style={{ color: "#1A1B1D" }} >Total Premium</p>
                      </div>

                      <div className="details-covered details-covered-breakup-section ">
                        <p>Net Payable (A+B):  </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.netPayble).toLocaleString('en-IN')}
                        </p>
                      </div>

                      <div className="details-covered details-covered-breakup-section ">
                        <p>GST:</p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.TGST).toLocaleString('en-IN')}
                        </p>
                      </div>

                      <div className="details-covered details-covered-breakup-section details-covered-breakup-section-highlighted ">
                        <p style={{ color: "#1A1B1D" }} >Gross Premium: </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.DuePremium).toLocaleString('en-IN')}
                        </p>
                      </div>
                    </div>

                  </div>
                </section>
              </div>

              <section className="btnContainer_pform btnContainer-planDetails">
                <Botton
                  text="Back"
                  className="button btn_pForm  secondry_btn"
                  event={closeModal}
                />
                <Botton
                  text="Buy Now"
                  className="button btn_pForm  "
                  event={() => {
                    handleBuyNow();
                    closeModal();
                  }}
                />
              </section>
            </div>

          </div>
        </div>
      </Modal>
    </>
  );
};

export default PlanDetails;