import { createAsyncThunk } from "@reduxjs/toolkit";

// Production URL make model by id
export const getModelAction = createAsyncThunk("getModel", async (id) => {
  const response = await fetch(
    `${process.env.REACT_APP_GET_MAKE_MODEL_URL}?Id=${id}&type=model`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );
  return response.json();
});

export const getBikeModelAction = createAsyncThunk("getModel", async (id) => {
  const response = await fetch(
    `${process.env.REACT_APP_GET_MAKE_MODEL_URL}?Id=${id}&type=model&vehicleType=bike`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );
  return response.json();
});

export const getFuelTypeAction = createAsyncThunk(
  "getFuelType",
  async (reqObj) => {
    const response = await fetch(
      `${process.env.REACT_APP_GET_MAKE_MODEL_URL}?Id=${reqObj?.id}&Model=${reqObj?.modelName}&type=fueltype&vehicleType=${reqObj?.vehicleType ? reqObj?.vehicleType : "car"}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const getVariantAction = createAsyncThunk(
  "getVariant",
  async (reqObj) => {
    const response = await fetch(
      `${process.env.REACT_APP_GET_MAKE_MODEL_URL}?Id=${reqObj?.id}&Model=${reqObj?.modelName}&fuelType=${reqObj?.selectedFuel}&type=variant&vehicleType=${reqObj?.vehicleType ? reqObj?.vehicleType : "car"}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const getBikeBrandAction = createAsyncThunk("getBrand", async () => {
  const response = await fetch(
    `${process.env.REACT_APP_GET_MAKE_MODEL_URL}?type=brand&vehicleType=bike`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );
  return response.json();
});

export const getCarBrandAction = createAsyncThunk("getBrand", async () => {
  const response = await fetch(
    `${process.env.REACT_APP_GET_MAKE_MODEL_URL}?type=brand&vehicleType=car`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );
  return response.json();
});