import { createAsyncThunk } from "@reduxjs/toolkit";
import { Buffer } from 'buffer';

// Production Url razorpay create order
export const razorCreateOrderAction = createAsyncThunk(
  "razorCreateOrder",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_RAZORPAY_CREATE_ORDER_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          'Content-Type': 'application/json'
        },
      }
    );
    return response.json();
  }
);

export const razorAckoCreateOrderAction = createAsyncThunk(
  "razorCreateOrder",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_RAZORPAY_CREATE_ORDER_URL}`,
      // "https://ltdbb4yanl.execute-api.us-east-1.amazonaws.com/dev/razorPayCreateOrder",
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          'Content-Type': 'application/json'
        },
      }
    );
    return response.json();
  }
);

// Basic cnpwX3Rlc3RfZmtWd2F3VjZoT1V6OU86OERYcm1rZjlqNUNUcmRkUUV3djVKUHVQ
// Basic cnpwX3Rlc3RfZmtWd2F3VjZoT1V6OU86OERYcm1rZjlqNUNUcmRkUUV3djVKUHVQ