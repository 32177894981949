import React, { useState, useEffect } from "react";
import "./ViewPlans.css";
import AOS from "aos";
import "aos/dist/aos.css";

const ToolTip = ({ content, children, condition, conditionTwo }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 200,
    });
    AOS.refresh();
  }, []);

  const handleMouseEnter = () => {
    if (condition || conditionTwo) {
      if (condition === true || conditionTwo === true) {
        setShowTooltip(false);
      } else {
        setShowTooltip(true);
      }
    } else {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <>
      <div className="tooltip-container">
        <div
          className="tooltip-trigger"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {children}
        </div>
        {showTooltip && (
          <div className="tooltip" data-aos="fade-left">
            <div className="tooltip-arrow"></div>
            {content}
          </div>
        )}
      </div>
    </>
  );
};

export default ToolTip;
