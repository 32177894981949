import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CheckBox from "../Checkbox/CheckBox";
import RadioButton from "../RadioButton/RadioButton";
import InputFields from "../InputFields/InputFields";
import Botton from "../Button/Button";
import { NEW, ROLLOVER } from "../../utils/commonUtils";
import moment from "moment";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import { isInitialAction } from "../../redux/Reducers/inspectionReducer";
import loader2 from "../../Assets/Loader/Loader.gif";
import "./loaderMain.css";
import close from "../../Assets/Images/Close.svg"
import AOS from "aos";
import "aos/dist/aos.css";
import SearchSelect from "../DropDown/Search Select/SearchSelect";
import { getFuelTypeAction, getModelAction, getBikeModelAction, getVariantAction } from "../../redux/Actions/mmvAction";

const ModalInp = (props) => {

  const {
    clickEvent,
    closeModal,
    ManualInpValue,
    validateModalInp,
    businessType,
    errors,
    uniqueClass,
    vehicleType,
    edit,
    make,
    model,
    vehicleNumber,
    varient,
    fuelType,
    fuelTypeCopy,
    regDate,
    handleRouteToFMC,
    vehicleDetails,
    pincode,
    type,
    SwitchFunction,
    handleProceed,
    ShowUserDataModal,
    setShowUserDataModal,
    setManualInpValue,
    makeList,
    ModelList,
    fuelList,
    variantList,
    yearOfRegistration,
    currentYear,
    rtoArray,
    handleRtoValue,
  } = props;

  console.log(props, "propsInModal");
  const dispatch = useDispatch();
  const getModel = useSelector((state) => {
    return state?.getModel
  })

  console.log(vehicleType, "vehicleTypeeee");


  useEffect(() => {
    AOS.init({
      duration: 200,
    });
    AOS.refresh();
  }, []);
  console.log(getModel, "currentYear")

  const getVehicleData = useSelector((state) => {
    return state?.getVehicleData;
  });

  let prevPolicyData = {
    isPrevPolicyExp: null || getVehicleData?.data?.data?.data?.isPrevPolicyExp,
    prevPolicyExpLastMonth: null || getVehicleData?.data?.data?.data?.prevPolicyExpLastMonth,
    isClaimPrevPolicy: null || getVehicleData?.data?.data?.data?.isClaimPrevPolicy,
  };

  const navigate = useNavigate();

  const [prevPolicyInfo, setPrevPolicyInfo] = useState(prevPolicyData);
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [ShowEditModal, setShowEditModal] = useState(false);

  const [checkboxes, setCheckboxes] = useState({
    isCngLpgVehicleType: false || getVehicleData?.data?.data?.data?.isCngLpgVehicleType,
    isPospSale: false || getVehicleData?.data?.data?.data?.isPospSale,
  });

  const [userData, setUserData] = useState({
    cngKitValue: "" || getVehicleData?.data?.data?.data?.cngKitValue,
    mobileNo: '' || getVehicleData?.data?.data?.data?.mobileNo,
    pincode: pincode || getVehicleData?.data?.data?.data?.pincode,
  })

  localStorage.setItem("businessType", businessType);

  const handleCheckboxChange = (checkboxName) => {
    setCheckboxes({
      ...checkboxes,
      [checkboxName]: !checkboxes[checkboxName],
    });
  };

  const handleRadioButtonChange = (event, type) => {
    const { name, value } = event.target;
    if (name && errors) {
      delete errors[name]
    }

    if (name == "isPrevPolicyExp") {
      delete errors.isPrevPolicyExp;
      dispatch(
        isInitialAction({
          isInspectionReq: value == "Yes" ? true : false,
        })
      );
      setPrevPolicyInfo((prevItems) => ({
        ...prevItems,
        prevPolicyExpLastMonth: null,
      }));
    }


    let getDate90Days = moment().subtract(91, "days").format("DD MMM YYYY");
    setPrevPolicyInfo((prevItems) => ({
      ...prevItems,
      [name]: value == "Yes" ? true : false,
      dateAgo: getDate90Days,
    }));
  };


  console.log(prevPolicyData, "sbbhds");

  const handlePhoneNumber = (e) => {
    const { id, value, name } = e?.target || {};
    if (name == 'mobileNo') {
      delete errors.phoneNumber
      if (value.length <= 10) {
        setUserData((prevItems) => ({
          ...prevItems,
          mobileNo: value,
        }));
      }
    }
    if (name == 'cngKitValue') {
      delete errors.cngKitValue
      if (value.length <= 6) {
        setUserData((prevItems) => ({
          ...prevItems,
          cngKitValue: value,
        }));
      }
    }
  };

  const handleEditModal = () => {
    setShowEditModal(!ShowEditModal)
  };

  if (businessType !== "New") {
    if (yearOfRegistration?.includes(currentYear)) {
      yearOfRegistration?.splice(yearOfRegistration.indexOf(currentYear), 1);
    }
  }

  const sortedRegList = yearOfRegistration?.map(data => {
    return {
      label: data,
      value: data,
    }
  })

  console.log(vehicleType, "sortedRegList");

  const sortedMakeList = Array.isArray(makeList) && makeList?.map((data, key) => {
    return {
      label: data.make,
      value: data.make,
    }
  })

  // let vehicleTypeName = vehicleType === "4W" ? "car" : "bike";

  const sortedModelList = ModelList?.map((data, key) => {
    if (vehicleType === "2W") {
      return {
        label: data.model,
        value: data.model,
      }
    }
    return {
      label: data.Model,
      value: data.Model,
    }
  })

  const sortedfuelList = fuelList?.map((data, key) => {
    if (vehicleType === "2W") {
      return {
        label: data.fuel_type,
        value: data.fuel_type,
      }
    }
    return {
      label: data.Fuel_Type === "INTERNAL_LPG_CNG" ? "Petrol + CNG" : data.Fuel_Type,
      value: data.Fuel_Type,
    }
  })

  const sortedVariantListt = variantList?.map((data, key) => {
    return {
      label: data.Variant,
      value: data.Variant,
    }
  })

  const sortedCcList = variantList?.map((data, key) => {
    return {
      label: data.Cubic_Capacity + "cc",
      value: data.Cubic_Capacity,
    }
  })

  const handleBrand = (data) => {
    setManualInpValue((prevItems) => ({
      ...prevItems,
      make: data?.value,
      model: "",
      fuelType: "",
      varient: "",
    }));
    // console.log(data?.value,"data?.value")
    if (vehicleType === "4W") {
      dispatch(getModelAction(data?.value));
    } else {
      dispatch(getBikeModelAction(data?.value));
    }
  }

  const handleModel = (data) => {
    setManualInpValue((prevItems) => ({
      ...prevItems,
      model: data?.value,
      fuelType: "",
      varient: "",
    }));

    let fuelReqObj = {}

    if (vehicleType === "4W") {
      fuelReqObj = {
        id: make,
        modelName: data?.value,
      };
    } else {
      fuelReqObj = {
        id: make,
        modelName: data?.value,
        vehicleType: "bike",
      };
    }
    dispatch(getFuelTypeAction(fuelReqObj));
  }

  const handleFuel = (data) => {
    console.log(data, "datadd");
    setManualInpValue((prevItems) => ({
      ...prevItems,
      fuelType: data?.value,
      varient: "",
    }));

    console.log(fuelType, "Fuel")

    let variantReqObj = {}

    if (vehicleType === "4W") {
      variantReqObj = {
        id: make,
        modelName: model,
        selectedFuel: data?.value,
      };
    } else {
      variantReqObj = {
        id: make,
        modelName: model,
        selectedFuel: data?.value,
        vehicleType: "bike",
      };
    }

    dispatch(getVariantAction(variantReqObj));
  }

  const handleVariant = (data) => {
    setManualInpValue((prevItems) => ({
      ...prevItems,
      varient: data?.value,
      cc: "",
    }));
  }

  const handleCC = (data) => {
    setManualInpValue((prevItems) => ({
      ...prevItems,
      cc: data?.value,
    }));
  }

  const handleRegYear = (data) => {
    console.log(data?.value, "handleRegYear");
    setManualInpValue((prevItems) => ({
      ...prevItems,
      regn_dt: data?.value,
    }));
  }

  let vehicleTypeName = vehicleType === "4W" ? "car" : "bike";

  return (
    <>
      <form action="" onSubmit={(e) => { e.preventDefault(); }} className="modal-inputs-parent" >
        <div className="modal-inputs-parent ">
          <div className="leftSide_modal-mobile">
            <div className="leftSide_modal-mobile-mmv-container">
              <div className="head-mmv-modal">
                <p className="leftSide_modal-mm">
                  {make} {model}
                </p>
                <Botton
                  type="button"
                  icons={edit}
                  text="Edit"
                  event={handleEditModal}
                  className="edit_btn"
                />
              </div>
              <p className="leftSide_modal-CarNum"><span>IND</span>{vehicleNumber}</p>
              <div className="leftSide_modal-vfr">
                <p>
                  {varient} <span>•</span> {fuelType} <span>•</span> {regDate}{" "}
                </p>
              </div>
            </div>
          </div>

          <div className={`modal_selection_container ${prevPolicyInfo?.prevPolicyExpLastMonth ? 'modal_selection_container_caseTwo' : ''} `}  >
            <p className="modal_selection_question">
              Has your previous policy expired?
            </p>
            <div className="modal_options_container">
              <RadioButton
                label="Yes"
                value="Yes"
                id="polExpiry"
                groupName="isPrevPolicyExp"
                changeEvent={handleRadioButtonChange}
                errClassName={errors?.isPrevPolicyExp ? "input-err" : ""}
                isChecked={prevPolicyInfo?.isPrevPolicyExp === true ? true : false}
              />
              <RadioButton
                label="No"
                value="No"
                id="polExpiryNo"
                groupName="isPrevPolicyExp"
                changeEvent={handleRadioButtonChange}
                errClassName={errors?.isPrevPolicyExp ? "input-err" : ""}
                isChecked={prevPolicyInfo?.isPrevPolicyExp === false ? true : false}
              />
            </div>
            {prevPolicyInfo.isPrevPolicyExp && (
              <>
                <p className=" small_textModal">
                  Did your previous policy expired before {" "}
                  {prevPolicyInfo.dateAgo ? prevPolicyInfo.dateAgo : ""}?
                </p>
                <div className="modal_options_container">
                  <RadioButton
                    label="Yes"
                    id="polExp"
                    value="Yes"
                    groupName="prevPolicyExpLastMonth"
                    changeEvent={handleRadioButtonChange}
                    errClassName={errors?.prevPolicyExpLastMonth ? "input-err" : ""}
                    isChecked={prevPolicyInfo?.prevPolicyExpLastMonth === true ? true : false}
                  />
                  <RadioButton
                    label="No"
                    id="polExpNo"
                    value="No"
                    groupName="prevPolicyExpLastMonth"
                    changeEvent={handleRadioButtonChange}
                    errClassName={errors?.prevPolicyExpLastMonth ? "input-err" : ""}
                    isChecked={prevPolicyInfo?.prevPolicyExpLastMonth === false ? true : false}
                  />
                </div>
              </>
            )}
          </div>

          {
            !prevPolicyInfo?.prevPolicyExpLastMonth && (
              <div className="modal_selection_container">
                <p className="modal_selection_question">
                  Made a claim in previous policy?
                </p>
                <div className="modal_options_container"  >
                  <RadioButton
                    label="Yes"
                    value="Yes"
                    id="claimYes"
                    groupName="isClaimPrevPolicy"
                    changeEvent={handleRadioButtonChange}
                    errClassName={errors?.isClaimPrevPolicy ? "input-err" : ""}
                    isChecked={prevPolicyInfo?.isClaimPrevPolicy === true ? true : false}
                  />
                  <RadioButton
                    label="No"
                    value="No"
                    id="claimNo"
                    groupName="isClaimPrevPolicy"
                    changeEvent={handleRadioButtonChange}
                    errClassName={errors?.isClaimPrevPolicy ? "input-err" : ""}
                    isChecked={prevPolicyInfo?.isClaimPrevPolicy === false ? true : false}
                  />
                </div>
              </div>
            )
          }

          {
            fuelType?.toLowerCase() == "petrol" && (
              <div className="checkbox_container checkbox_container-modalinp" >
                {vehicleType === "4W" && (
                  <CheckBox
                    label="I have CNG/LPG fitted after the purchase"
                    className="small_text small_textModal checkBox-label"
                    type="checkbox"
                    checked={checkboxes.isCngLpgVehicleType}
                    changeEvent={() => handleCheckboxChange("isCngLpgVehicleType")}
                  />
                )}
              </div>
            )
          }

          {
            !prevPolicyInfo?.prevPolicyExpLastMonth && (
              <div className="border-modalInp"></div>
            )
          }

          {checkboxes.isCngLpgVehicleType === true && (
            <div className="input_group_pform input_group_pform-modalInp">
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={userData.cngKitValue}
                  placeholder="CNG/LPG Kit Value"
                  id="cngKitValue"
                  type="number"
                  name="cngKitValue"
                  errState={errors?.cngKitValue ? true : false}
                  errMsg={errors?.cngKitValue}
                  errClassName="input-err"
                  onChangeEvent={(e) => handlePhoneNumber(e)}
                />
              </div>
            </div>
          )}

          {
            !SwitchFunction ? (
              <div className="input_group_pform input_group_pform-modalInp">
                <div className="inputGropOne_form inputGrop inputGrop-modalInp ">
                  <InputFields
                    value={userData.mobileNo && Math.max(0, userData.mobileNo)}
                    placeholder="Phone Number"
                    id="mobileNo"
                    type="number"
                    name="mobileNo"
                    errState={errors?.phoneNumber ? true : false}
                    errMsg={errors?.phoneNumber}
                    errClassName="input-err"
                    onChangeEvent={(e) => handlePhoneNumber(e)}
                  />
                  <p className="msg-phoneNo">
                    Strictly no spam. Get quote and policy updates
                  </p>
                </div>
                <div className="inputGropOne_form inputGrop inputGrop-modalInp">
                  <InputFields
                    value={userData.pincode && Math.max(0, userData.pincode)}
                    placeholder="Pin code"
                    id="pincode"
                    type="number"
                    name="pincode"
                    errState={errors?.pincode ? true : false}
                    errMsg={errors?.pincode}
                    errClassName="input-err"
                    onChangeEvent={(e) => {
                      delete errors.pincode
                      if (e.target.value <= 1000000) {
                        setUserData({
                          ...userData,
                          pincode: e.target.value,
                        })
                      }

                    }
                    }
                  />
                  <p className="msg-phoneNo">
                    Enter your current address Pincode for a special discount.
                  </p>
                </div>
              </div>
            ) : null
          }
        </div>

        {
          SwitchFunction ? (
            <div className="button_container">
              <Botton
                text="Back"
                className="button modalBtn secondry_btn"
                event={closeModal}
                type="button"
              />
              <Botton
                text="Proceed"
                className="button modalBtn"
                type="submit"
                event={() =>
                  handleProceed({
                    ...ManualInpValue,
                    ...prevPolicyInfo,
                    ...checkboxes,
                    ...userData,
                  })
                }
              />
            </div>
          ) : (
            <div className="button_container">
              <Botton
                text="Back To Search"
                className="button modalBtn secondry_btn"
                event={closeModal}
                type="button"
              />
              <Botton
                text="Check Premium"
                className="button modalBtn"
                type="submit"
                event={(e) =>
                  clickEvent(
                    businessType !== ROLLOVER
                      ? {
                        ...ManualInpValue,
                        ...prevPolicyInfo,
                        ...checkboxes,
                        ...userData,
                        e,
                      }
                      : {
                        ...prevPolicyInfo,
                        ...getVehicleData?.data?.data,
                        ...checkboxes,
                        ...ManualInpValue,
                        ...userData,
                        e,
                      }
                  )
                }
              />
            </div>
          )
        }
      </form>

      {ShowUserDataModal && (
        <div
          className="edit-tabs-container-mob-height"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          <div className="active-bottom-edit-policy-tabs edit-modalInp-container">
            <div className="inputs-parent-mob-modal">
              <p className="head-p-img" > <img src={close} onClick={(e) => setShowUserDataModal(false)} />  </p>
              <p className="head-p" >Enter Mobile Number </p>
              <InputFields
                value={userData?.mobileNo}
                placeholder="Mobile Number"
                id="mobileNo"
                type="number"
                name="mobileNo"
                errState={errors?.phoneNumber ? true : false}
                errMsg={errors?.phoneNumber}
                errClassName="input-err"
                onChangeEvent={(e) => handlePhoneNumber(e)}
                hideLabel={true}
              />
              <p></p>
              <span>Get quote and policy updates</span>
            </div>

            <div className="inputs-parent-mob-modal">
              <p>Enter Pincode </p>
              <InputFields
                value={userData?.pincode}
                placeholder="Pin code"
                id="pincode"
                type="number"
                name="pincode"
                errState={errors?.pincode ? true : false}
                errMsg={errors?.pincode}
                errClassName="input-err"
                hideLabel={true}
                onChangeEvent={(e) => {
                  delete errors?.pincode
                  setUserData((prevItems) => ({
                    ...prevItems,
                    pincode: e.target.value?.length <= 6 ? e.target.value : userData?.pincode,
                  }))
                }}
              />
              <p></p>
              <span>Enter your current address Pincode for a special discount.</span>
            </div>

            <div className="policy-type-list-container-mob" style={{ padding: "0rem" }}>
              <section className="btnContainer_pform btnContainer_pform-edit">
                <Botton
                  text="Check Premium"
                  className="button btn_pForm"
                  event={(e) =>
                    clickEvent(
                      businessType !== ROLLOVER
                        ? {
                          ...ManualInpValue,
                          ...prevPolicyInfo,
                          ...checkboxes,
                          ...userData,
                          e,
                        }
                        : {
                          ...prevPolicyInfo,
                          ...getVehicleData?.data?.data,
                          ...checkboxes,
                          ...ManualInpValue,
                          e,
                        }
                    )
                  }
                />
              </section>
            </div>

          </div>
        </div>
      )}

      {ShowEditModal && (
        <div
          className="edit-tabs-container-mob-height"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          <div className="active-bottom-edit-policy-tabs edit-modalInp-container" style={{ paddingBottom: "6rem" }} >
            <p className="heading-edit-modal" > Edit my car {`${vehicleTypeName}`} details</p>

            <SearchSelect
              optionList={rtoArray}
              handleSelect={handleRtoValue}
              value={ManualInpValue?.rto}
              ManualInpValue={ManualInpValue}
              placeholder="Select RTO"
              label="Select RTO"
            // errState={errors?.rto ? true : false}
            // errMsg={errors?.rto ? errors?.rto : ""}
            />

            <SearchSelect
              optionList={sortedMakeList}
              handleSelect={handleBrand}
              value={make}
              placeholder="Select Brand"
              label="Brand"
              name="make"
            // errState={errors?.city ? true : false}
            // errMsg={errors?.city}
            // errClassName="input-err"
            />

            <SearchSelect
              optionList={sortedModelList}
              handleSelect={handleModel}
              value={model}
              placeholder="Select Model"
              label="Model"
              name="model"
            // errState={errors?.city ? true : false}
            // errMsg={errors?.city}
            // errClassName="input-err"
            />

            <SearchSelect
              optionList={sortedfuelList}
              handleSelect={handleFuel}
              value={fuelType}
              placeholder="Select Fuel"
              label="Fuel"
              name="fuelType"
            // errState={errors?.city ? true : false}
            // errMsg={errors?.city}
            // errClassName="input-err"
            />

            <SearchSelect
              optionList={sortedVariantListt}
              handleSelect={handleVariant}
              value={varient}
              placeholder="Select Variant"
              label="Variant"
              name="varient"
            // errState={errors?.city ? true : false}
            // errMsg={errors?.city}
            // errClassName="input-err"
            />

            <SearchSelect
              optionList={sortedCcList}
              handleSelect={handleCC}
              value={ManualInpValue?.cc}
              placeholder="Select CC"
              label="cc"
              name="cc"
            // errState={errors?.city ? true : false}
            // errMsg={errors?.city}
            // errClassName="input-err"
            />

            <SearchSelect
              optionList={sortedRegList}
              handleSelect={handleRegYear}
              value={ManualInpValue?.regn_dt}
              placeholder="Select Reg Year"
              label="Reg Year"
              name="regn_dt"
            // errState={errors?.city ? true : false}
            // errMsg={errors?.city}
            // errClassName="input-err"
            />
          </div>

          <div className="button_container button_container_editModal">
            <Botton
              text="Back"
              className="button modalBtn secondry_btn"
              event={() => setShowEditModal(!ShowEditModal)}
              type="button"
            />
            <Botton
              text="Update"
              className="button modalBtn"
              type="submit"
              event={() => setShowEditModal(!ShowEditModal)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ModalInp;
