import React, { useState, useEffect } from "react";
import arrowRight from "../../Assets/Images/arrRight.svg";
import Modal from "react-modal";
import close from "../../Assets/Images/Close.svg";
import Botton from "../Button/Button";
import RangeSlider from "../InputFields/RangeSlider";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

Modal.setAppElement("#root");

const IdvTab = (props) => {

    const {
        idvValue,
        minIdvValue,
        maxIdvValue,
        handleInputCarDetails,
        setcarDetailsCopyState,
        setCarDetails,
        idv,
        isDisabled,
        showModal,
        setShowIdvTab,
    } = props;

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [SelectedIdvTerm, setSelectedIdvTerm] = useState("minimum");
    const [PrevSelectedIdvTerm, setPrevSelectedIdvTerm] = useState("minimum");

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleSelectedValue = (selectedTerm) => {
        setSelectedIdvTerm(selectedTerm);
        switch (selectedTerm) {

            case "minimum":
                setcarDetailsCopyState((prevItems) => ({
                    ...prevItems,
                    idvValueCopy: Math.round(minIdvValue),
                }));
                break;

            case "customIdv":
                setcarDetailsCopyState((prevItems) => ({
                    ...prevItems,
                    idvValueCopy: idvValue,
                }));
                break;

            case "maximum":
                setcarDetailsCopyState((prevItems) => ({
                    ...prevItems,
                    idvValueCopy: Math.round(maxIdvValue),
                }));
                break;

            default:
                break;
        }
    };

    const cancelIdvSelection = () => {
        setSelectedIdvTerm("");
        setCarDetails((prevItems) => ({
            ...prevItems,
            idvValue: idv,
        }));
        setcarDetailsCopyState((prevItems) => ({
            ...prevItems,
            idvValueCopy: idv,
        }));

        if (showModal === "true") {
            setIsOpen(false);
        } else {
            setShowIdvTab(false);
        }

    };

    const saveIdvSelection = () => {

        setPrevSelectedIdvTerm(SelectedIdvTerm);
        setSelectedIdvTerm("");

        switch (SelectedIdvTerm) {
            case "minimum":
                setCarDetails((prevItems) => ({
                    ...prevItems,
                    idvValue: minIdvValue,
                }));
                break;

            case "customIdv":
                setCarDetails((prevItems) => ({
                    ...prevItems,
                    idvValue: idvValue,
                }));
                break;

            case "maximum":
                setCarDetails((prevItems) => ({
                    ...prevItems,
                    idvValue: maxIdvValue,
                }));
                break;

            default:
                break;
        }

        if (showModal === "true") {
            setIsOpen(false);
        } else {
            setShowIdvTab(false);
        }

    };

    useEffect(() => {
        setSelectedIdvTerm(PrevSelectedIdvTerm);
    }, [modalIsOpen === true])

    const modalContent = (
        <form action="" onSubmit={(e) => { e.preventDefault(); }} className="idvModal-container">
            <div className="head-container-idvTab editDetail_header">
                <p>Car insured value (IDV)</p>
                <img src={close} alt="" srcSet="" onClick={closeModal} />
            </div>
            <div className="policy-type-list-container-mob">
                {/* MINIMUM IDV */}

                <div
                    className={
                        SelectedIdvTerm === "minimum"
                            ? "pol-tab-rect pol-tab-rect-active"
                            : "pol-tab-rect"
                    }
                    onClick={(e) => handleSelectedValue("minimum")}
                >
                    <div className="fake-radio-tab-mobile">
                        <div className="circle-radio-mob circle-radio-mob-idv">
                            <div
                                className={
                                    SelectedIdvTerm === "minimum"
                                        ? "circle-radio-mob-child circle-radio-mob-child-active"
                                        : "circle-radio-mob-child"
                                }
                            ></div>
                        </div>
                        <div className="label-value-idv">
                            <p className="label-idvTab">Minimum IDV</p>
                            <p className="amt-idvTab">
                                ₹ {Math.round(minIdvValue).toLocaleString('en-IN')}
                            </p>
                        </div>
                    </div>
                </div>

                {/* CUSTOM IDV */}

                <div
                    className={
                        SelectedIdvTerm === "customIdv"
                            ? "pol-tab-rect pol-tab-rect-active"
                            : "pol-tab-rect"
                    }
                    onClick={(e) => handleSelectedValue("customIdv")}
                >
                    <div className="fake-radio-tab-mobile">
                        <div className="circle-radio-mob circle-radio-mob-idv">
                            <div
                                className={
                                    SelectedIdvTerm === "customIdv"
                                        ? "circle-radio-mob-child circle-radio-mob-child-active"
                                        : "circle-radio-mob-child"
                                }
                            ></div>
                        </div>
                        <div className="label-value-idv">
                            <p className="label-idvTab">Choose your own IDV</p>
                        </div>
                    </div>
                    {(SelectedIdvTerm === "customIdv") && (
                        <>
                            <RangeSlider
                                type="submit"
                                className="range-container-modal"
                                minValue={minIdvValue}
                                maxValue={maxIdvValue}
                                name="idvValueCopy"
                                value={idvValue}
                                changeEvent={handleInputCarDetails}
                            />
                            <input
                                type="number"
                                pattern="^[\d,]+$"
                                className="idv-tab-input"
                                value={idvValue}
                                name="idvValueCopy"
                                onChange={handleInputCarDetails}
                                id=""
                            />
                        </>
                    )}
                </div>

                {/* MAXIMUM IDV */}

                <div
                    className={
                        SelectedIdvTerm === "maximum"
                            ? "pol-tab-rect pol-tab-rect-active"
                            : "pol-tab-rect"
                    }
                    onClick={(e) => handleSelectedValue("maximum")}
                >
                    <div className="fake-radio-tab-mobile" >
                        <div className="circle-radio-mob circle-radio-mob-idv">
                            <div
                                className={
                                    SelectedIdvTerm === "maximum"
                                        ? "circle-radio-mob-child circle-radio-mob-child-active"
                                        : "circle-radio-mob-child"
                                }
                            ></div>
                        </div>
                        <div className="label-value-idv" >
                            <p className="label-idvTab">maximum IDV</p>
                            <p className="amt-idvTab">
                                ₹ {Math.round(maxIdvValue).toLocaleString('en-IN')}
                            </p>
                        </div>
                    </div>
                </div>

                {/* SAVE BUTTONS */}

                <section className="btnContainer_pform btnContainer_pform-edit-idv btnContainer_pform-edit">
                    <Botton
                        text="Cancel"
                        className="button btn_pForm  secondry_btn"
                        event={cancelIdvSelection}
                    />
                    <Botton
                        text="Save"
                        type="submit"
                        className="button btn_pForm"
                        event={saveIdvSelection}
                    />
                </section>
            </div>
        </form>
    )

    return (
        <>
            {
                showModal === "true" && (
                    <div className={isDisabled ? "content_container_vp-disabled idv-topcontent-container" : "idv-topcontent-container"} onClick={openModal}>
                        <p>
                            Cover Value IDV:
                            {
                                isDisabled ? (
                                    <span>0</span>
                                ) : (
                                    <span>
                                        {idv
                                            ? Math.round(idv).toLocaleString('en-IN')
                                            : Math.round(minIdvValue).toLocaleString('en-IN')}
                                    </span>
                                )
                            }
                        </p>
                        <img src={arrowRight} />
                    </div>
                )
            }

            {
                showModal === "true" ? (
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        {modalContent}
                    </Modal>
                ) : (
                    <> {modalContent}</>
                )
            }


        </>
    );
};

export default IdvTab;
