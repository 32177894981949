import { createSlice } from "@reduxjs/toolkit";
export const companyNameReducer = createSlice({
    name: "companyName",
    initialState: {
        data: {
            "companyName": ""
        }
    },
    reducers: {
        getCompanyName: (state, action) => {
            state.data = action.payload;
        },
        


    },

});
export const companyNameReducerForProposalForm = createSlice({
    name: "companyNameForForm",
    initialState: {
        data: {
            "companyNameForForm": ""
        }
    },
    reducers: {
        getCompanyNameForForm: (state, action) => {
            state.data = action.payload;
        },

    },

});
export const { getCompanyNameForForm } = companyNameReducerForProposalForm.actions;
export const { getCompanyName } = companyNameReducer.actions;