import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import "./ContactUs.css";
import InputFields from "../../InputFields/InputFields";
import Textarea from "../../InputFields/Textarea";
import Botton from "../../Button/Button";
import location from "../../../Assets/Images/locationContact.svg"
import call from "../../../Assets/Images/phoneContact.svg"
import mail from "../../../Assets/Images/mailContact.svg"
import fb from "../../../Assets/Images/fbContact.svg"
import twitter from "../../../Assets/Images/TwitterContact.svg"
import linkedin from "../../../Assets/Images/LinkedInContact.svg"
import plane from "../../../Assets/Images/paperPlane.svg"
import heroMobile from "../../../Assets/Images/ContactusMob.svg"
import img1 from "../../../Assets/Images/buyNewPolicy.svg"
import img2 from "../../../Assets/Images/existPolicy.svg"
import img3 from "../../../Assets/Images/otherComplaints.svg"
import contactIllu from "../../../Assets/Images/contactIllu.svg"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <>
            <Navbar />
            <ToastContainer />
            <div className="page-container page-container-contactUs">
                <div className="page-container-parent page-container-parent-contactUs maxWidth">
                    <div className="map-container">
                        <p>Contact <span>Us</span></p>
                        <img src={contactIllu} alt="" srcSet="" />
                    </div>

                    <div className="mobile-contact-us-content mobile-contact-us-content-pc">

                        <p>Feel Free To Call Us If You Need <span>Any Assistance.</span></p>

                        <div className="mobile-contact-block">
                            <div className="icon-cont-mob icon-cont-pc ">
                                <img src={img1} alt="" srcset="" />
                            </div>
                            <div className="content-cont-mob content-cont-pc ">
                                <p>Need Help buying A New Policy?</p>
                                <p> Call at <a href="tel:9811223458" style={{ color: "#5086FF" }} >9811223458</a></p>
                                <p> Email at  <a href="mailto:support@bookmyinsurance.com" style={{ color: "#5086FF" }} >support@bookmyinsurance.com</a></p>
                            </div>
                        </div>

                        <div className="mobile-contact-block">
                            <div className="icon-cont-mob icon-cont-pc ">
                                <img src={img2} alt="" srcset="" />
                            </div>
                            <div className="content-cont-mob content-cont-pc ">
                                <p>Need Help With Existing Policy?</p>
                                <p> Call at <a href="tel:9811223458" style={{ color: "#5086FF" }} >9811223458</a></p>
                                <p> Email at  <a href="mailto:support@bookmyinsurance.com" style={{ color: "#5086FF" }} >support@bookmyinsurance.com</a></p>
                            </div>
                        </div>

                        <div className="mobile-contact-block">
                            <div className="icon-cont-mob icon-cont-pc ">
                                <img src={img3} alt="" srcset="" />
                            </div>
                            <div className="content-cont-mob content-cont-pc ">
                                <p>For Any Other Complaints</p>
                                <p> Call at <a href="tel:9811223458" style={{ color: "#5086FF" }} >9811223458</a></p>
                                <p> Email at  <a href="mailto:support@bookmyinsurance.com" style={{ color: "#5086FF" }} >support@bookmyinsurance.com</a></p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="mobile-contact-us-screen">

                    <img src={heroMobile} alt="" srcset="" />
                    <div className="mobile-contact-us-content">

                        <p>Feel Free To Call Us If You Need <span>Any Assistance.</span></p>

                        <div className="mobile-contact-block">
                            <div className="icon-cont-mob">
                                <img src={img1} alt="" srcset="" />
                            </div>
                            <div className="content-cont-mob">
                                <p>Need Help buying A New Policy?</p>
                                <p> Call at <a href="tel:9811223458" style={{ color: "#5086FF" }} >9811223458</a></p>
                                <p> Email at  <a href="mailto:support@bookmyinsurance.com" style={{ color: "#5086FF" }} >support@bookmyinsurance.com</a></p>
                            </div>
                        </div>

                        <div className="mobile-contact-block">
                            <div className="icon-cont-mob">
                                <img src={img2} alt="" srcset="" />
                            </div>
                            <div className="content-cont-mob">
                                <p>Need Help With Existing Policy?</p>
                                <p> Call at <a href="tel:9811223458" style={{ color: "#5086FF" }} >9811223458</a></p>
                                <p> Email at  <a href="mailto:support@bookmyinsurance.com" style={{ color: "#5086FF" }} >support@bookmyinsurance.com</a></p>
                            </div>
                        </div>

                        <div className="mobile-contact-block">
                            <div className="icon-cont-mob">
                                <img src={img3} alt="" srcset="" />
                            </div>
                            <div className="content-cont-mob">
                                <p>For Any Other Complaints</p>
                                <p> Call at <a href="tel:9811223458" style={{ color: "#5086FF" }} >9811223458</a></p>
                                <p> Email at  <a href="mailto:support@bookmyinsurance.com" style={{ color: "#5086FF" }} >support@bookmyinsurance.com</a></p>
                            </div>
                        </div>

                        <p>Our <span>Location</span></p>

                        <div className="block-contact-us">
                            <img src={location} alt="" srcset="" />
                            <div className="communication-block-contact-us">
                                <p>Regd & Corporate Office:</p>
                                <p>Unit No. 212 & 212 A, Suncity Success <br /> Tower, Sec-65 Golf Course Ext. <br /> Road,Gurgaon (hr) :122005</p>
                            </div>
                        </div>

                        <div className="block-contact-us block-contact-us-center ">
                            <img src={call} alt="" srcset="" />
                            <div className="communication-block-contact-us-two">
                                <p> <a href="tel:9811223458" style={{ color: "#000" }} >+91 9811223458</a></p>
                            </div>
                        </div>

                        <div className="block-contact-us  block-contact-us-center">
                            <img src={mail} alt="" srcset="" />
                            <div className="communication-block-contact-us-two">
                                <p><a href="mailto:support@bookmyinsurance.com" style={{ color: "#000" }} >support@bookmyinsurance.com</a></p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <Footer />
        </>
    );
};

export default ContactUs;
