import React from 'react'
import './CallWidget.css'
import icon from "../../Assets/Images/callWidget.svg"

const CallWidget = (props) => {

    const { revealState, updateRevealState } = props;
    let mobileNumber = "770 099 1251"

    return (
        <>
            <div className="widget-container">
                <div className="widget-parent">
                    {
                        revealState && (
                            <div className={revealState ? "widget-num-container slide-left " : "widget-num-container"}  >
                                <a href="tel:+919811223458">
                                    <p>Call us on {mobileNumber}</p>
                                </a>
                            </div>
                        )
                    }
                    <div className={revealState ? "widget-circle rotate-90-cw" : "widget-circle"} onClick={(e) => updateRevealState(!revealState)} >
                        <img src={icon} alt="" srcset="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CallWidget
