import React, { useState, useEffect } from "react";
import car from "../../../../Assets/Images/car.svg";
import bike from "../../../../Assets/Images/bike.svg";
import health from "../../../../Assets/Images/health.svg";
import taxi from "../../../../Assets/Images/taxi.svg";
import grayArrow from "../../../../Assets/Images/grayArrow.svg";
import tick from "../../../../Assets/Images/success.svg";
import './RequestCard.css'
import Botton from "../../../Button/Button";

const RequestCard = (props) => {

    const { handleDownloadPolicy } = props

    let requestedList = [
        {
            productIcon: car,
            policyNumber: "DUMMYPOLICY0000001",
            insuredProductInfo: "Private Car Policy Insurance",
            insurerName: "HDFC Ergo",
            policyStartDate: "24 Jan 2023",
            policyEndDate: "24 Jan 2023",
            policyHolderName: "Vaibhav Dubey",
            vehicleNumber: "MH 02 CR 3380",
            currentStatusMsg: "Your Claim has been Initiated and its under process. We would request you to kindly have patience so that our team can connect with you to further update",
            currentStatus: "Initiated Done",
        },
        {
            productIcon: car,
            policyNumber: "DUMMYPOLICY0000002",
            insuredProductInfo: "Private Car Policy Insurance",
            insurerName: "Tata AIG",
            policyStartDate: "24 Jan 2023",
            policyEndDate: "24 Jan 2023",
            policyHolderName: "Shubh Trivedi",
            vehicleNumber: "MH 02 CR 7897",
            currentStatusMsg: "Your Claim has been Initiated and its under process. We would request you to kindly have patience so that our team can connect with you to further update",
            currentStatus: "Re-Inspection Process",
        },
        {
            productIcon: car,
            policyNumber: "DUMMYPOLICY0000003",
            insuredProductInfo: "Private Car Policy Insurance",
            insurerName: "HDFC Ergo",
            policyStartDate: "24 Jan 2023",
            policyEndDate: "24 Jan 2023",
            policyHolderName: "Vaibhav Dubey",
            vehicleNumber: "MH 02 CR 3380",
            currentStatusMsg: "Your Claim has been Initiated and its under process. We would request you to kindly have patience so that our team can connect with you to further update",
            currentStatus: "Edit Request Under Process",
        },
        {
            productIcon: car,
            policyNumber: "DUMMYPOLICY0000004",
            insuredProductInfo: "Private Car Policy Insurance",
            insurerName: "HDFC Ergo",
            policyStartDate: "24 Jan 2023",
            policyEndDate: "24 Jan 2023",
            policyHolderName: "Vaibhav Dubey",
            vehicleNumber: "MH 02 CR 3380",
            currentStatusMsg: "Your Claim has been Initiated and its under process. We would request you to kindly have patience so that our team can connect with you to further update",
            currentStatus: "Inspection Pending",
        },
        {
            productIcon: car,
            policyNumber: "DUMMYPOLICY0000005",
            insuredProductInfo: "Private Car Policy Insurance",
            insurerName: "HDFC Ergo",
            policyStartDate: "24 Jan 2023",
            policyEndDate: "24 Jan 2023",
            policyHolderName: "Vaibhav Dubey",
            vehicleNumber: "MH 02 CR 3380",
            currentStatusMsg: "Your Claim has been Initiated and its under process. We would request you to kindly have patience so that our team can connect with you to further update",
            currentStatus: "Initiated Not Generated",
        },
        {
            productIcon: car,
            policyNumber: "DUMMYPOLICY0000006",
            insuredProductInfo: "Private Car Policy Insurance",
            insurerName: "HDFC Ergo",
            policyStartDate: "24 Jan 2023",
            policyEndDate: "24 Jan 2023",
            policyHolderName: "Vaibhav Dubey",
            vehicleNumber: "MH 02 CR 3380",
            currentStatusMsg: "Your Claim has been Initiated and its under process. We would request you to kindly have patience so that our team can connect with you to further update",
            currentStatus: "Surveyor Appointed",
        },
        {
            productIcon: car,
            policyNumber: "DUMMYPOLICY0000007",
            insuredProductInfo: "Private Car Policy Insurance",
            insurerName: "HDFC Ergo",
            policyStartDate: "24 Jan 2023",
            policyEndDate: "24 Jan 2023",
            policyHolderName: "Vaibhav Dubey",
            vehicleNumber: "MH 02 CR 3380",
            currentStatusMsg: "Your Claim has been Initiated and its under process. We would request you to kindly have patience so that our team can connect with you to further update",
            currentStatus: "Inspection Process",
        },
        {
            productIcon: car,
            policyNumber: "DUMMYPOLICY0000008",
            insuredProductInfo: "Private Car Policy Insurance",
            insurerName: "HDFC Ergo",
            policyStartDate: "24 Jan 2023",
            policyEndDate: "24 Jan 2023",
            policyHolderName: "Vaibhav Dubey",
            vehicleNumber: "MH 02 CR 3380",
            currentStatusMsg: "Your Claim has been Initiated and its under process. We would request you to kindly have patience so that our team can connect with you to further update",
            currentStatus: "Approved",
        },
        {
            productIcon: car,
            policyNumber: "DUMMYPOLICY0000009",
            insuredProductInfo: "Private Car Policy Insurance",
            insurerName: "HDFC Ergo",
            policyStartDate: "24 Jan 2023",
            policyEndDate: "24 Jan 2023",
            policyHolderName: "Vaibhav Dubey",
            vehicleNumber: "MH 02 CR 3380",
            currentStatusMsg: "Your Claim has been Initiated and its under process. We would request you to kindly have patience so that our team can connect with you to further update",
            currentStatus: "Rejected",
        },
    ]

    return (
        <div className="ProfileCard-container">
            {/* <section className="NoPoliciesFound-container PoliciesFound-container ">
                <div className="product-info-container">
                    <div className="product-info-left-container">
                        <div className="product-icon-container">
                            <img src={car} alt="" srcSet="" />
                        </div>
                        <div className="product-insurer-container">
                            <p>987654321 - Private Car Policy Insurance</p>
                            <p>HDFC Ergo</p>
                        </div>
                    </div>
                    <div className="product-info-right-container">
                        <p>
                            <span>Start:</span> 24 Jan 2023
                        </p>
                        <p>
                            <span>End:</span> 24 Jan 2023
                        </p>
                    </div>
                </div>

                <div className="seprator-policyCard"></div>

                <div className="vehicleInfo-policyCard ">
                    <p className="textWithKey">
                        <span>Name: </span>Vaibhav Dubey
                    </p>
                    <p className="textWithKey">
                        <span>Car Number: </span> MH 01 CB 5421
                    </p>
                </div>

                <div className="request-msg-container">
                    <p>
                        Your Claim has been Initiated and its under process. We would request you to kindly have patience so that our team can
                        connect with you to further update
                    </p>
                    <button className="req-status-btn" >
                        Initiated Done
                    </button>
                </div>
            </section> */}

            {
                requestedList.map((data, key) => (
                    <section key={key} className="NoPoliciesFound-container PoliciesFound-container ">
                        <div className="product-info-container">
                            <div className="product-info-left-container">
                                <div className="product-icon-container">
                                    <img src={data.productIcon} alt="" srcSet="" />
                                </div>
                                <div className="product-insurer-container">
                                    <p>{data.policyNumber} - {data.insuredProductInfo}</p>
                                    <p>{data.insurerName}</p>
                                </div>
                            </div>
                            <div className="product-info-right-container">
                                <p>
                                    <span>Start:</span> {data.policyStartDate}
                                </p>
                                <p>
                                    <span>End:</span> {data.policyEndDate}
                                </p>
                            </div>
                        </div>

                        <div className="seprator-policyCard"></div>

                        <div className="vehicleInfo-policyCard ">
                            <p className="textWithKey">
                                <span>Name: </span>{data.policyHolderName}
                            </p>
                            <p className="textWithKey">
                                <span>Car Number: </span> {data.vehicleNumber}
                            </p>
                        </div>

                        <div className="request-msg-container">
                            <p>
                                {data.currentStatusMsg}
                            </p>
                            <button className={data.currentStatus === "Initiated Done" ? "req-status-btn light-blue-background"
                                : data.currentStatus === "Re-Inspection Process" ? "req-status-btn green-background"
                                    : data.currentStatus === "Edit Request Under Process" ? "req-status-btn green-background"
                                        : data.currentStatus === "Inspection Pending" ? "req-status-btn green-background"
                                            : data.currentStatus === "Initiated Not Generated" ? "req-status-btn peach-background"
                                                : data.currentStatus === "Surveyor Appointed" ? "req-status-btn orange-background"
                                                    : data.currentStatus === "Inspection Process" ? "req-status-btn yellow-background"
                                                        : data.currentStatus === "Approved" ? "req-status-btn dark-green-background"
                                                            : data.currentStatus === "Rejected" ? "req-status-btn red-background"
                                                                : ""}>
                                {data.currentStatus}
                            </button>
                        </div>
                    </section>
                ))
            }
        </div>
    )
}

export default RequestCard
