import {
    iciciQuickQuoteAction,
    iciciProposalAction,
    iciciKyc,
    iciciKycAuto,
    iciciCreateTreansactionAction,
    icicipdfDownloadAction,
} from "../Actions/iciciApiAction";
import { createSlice } from "@reduxjs/toolkit";

export const iciciQuickQuoteReducer = createSlice({
    name: "iciciQuikQuotes",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(iciciQuickQuoteAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(iciciQuickQuoteAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(iciciQuickQuoteAction.rejected, (state, action) => {
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        reseticiciQuoteAction: (state, action) => {
            state.data = undefined
        }
    }
});

export const { reseticiciQuoteAction } = iciciQuickQuoteReducer.actions;
export const iciciKycReducer = createSlice({
    name: "iciciKycReducer",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(iciciKyc.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(iciciKyc.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(iciciKyc.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        reseticiciKycAction: (state, action) => {
            state.data = undefined
        },
        addManualKycDataAction: (state, action) => {
            state.data = action.payload;
        },
    }
});

export const { reseticiciKycAction, addManualKycDataAction } = iciciKycReducer.actions;

export const iciciKycAutoReducer = createSlice({
    name: "iciciKycAutoReducer",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(iciciKycAuto.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(iciciKycAuto.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(iciciKycAuto.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        reseticiciKycAutoAction: (state, action) => {
            state.data = undefined
        },
        addAutoKycDataAction: (state, action) => {
            state.data = action.payload;
        },
    }
});

export const { reseticiciKycAutoAction, addAutoKycDataAction } = iciciKycAutoReducer.actions;

export const iciciProposalReducer = createSlice({
    name: "iciciProposal",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    reducers: {
        reseticiciProposal: (state) => {
            state.isLoading = false;
            state.data = null;
            state.isError = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(iciciProposalAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(iciciProposalAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(iciciProposalAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const iciciCreateTxnReducer = createSlice({
    name: "iciciCreateTransaction",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    reducers: {
        reseticiciCreateTransaction: (state) => {
            state.isLoading = false;
            state.data = null;
            state.isError = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(iciciCreateTreansactionAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(iciciCreateTreansactionAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(iciciCreateTreansactionAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resetIciciCreateTransaction: (state, action) => {
            state.data = undefined
        },
    }
});

export const { resetIciciCreateTransaction } = iciciCreateTxnReducer.actions;

export const icicipdfDownloadReducer = createSlice({
    name: "icicipdfDownload",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    reducers: {
        reseticicipdfDownload: (state) => {
            state.isLoading = false;
            state.data = null;
            state.isError = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(icicipdfDownloadAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(icicipdfDownloadAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(icicipdfDownloadAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resetIcicipdfDownload: (state, action) => {
            state.data = undefined
        },
    }
});

export const { resetIcicipdfDownload } = icicipdfDownloadReducer.actions;
