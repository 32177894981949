import { paymentWebhookAction, paymentSendURLAction } from "../Actions/paymentWebhookAction";

import { createSlice } from "@reduxjs/toolkit";

export const paymentWebhookReducer = createSlice({
  name: "paymentWebhookReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(paymentWebhookAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(paymentWebhookAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(paymentWebhookAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetPaymentWebhook: (state, action) => {
      state.data = undefined
    }
  }
});

export const { resetPaymentWebhook } = paymentWebhookReducer.actions;

export const paymentSendURLReducer = createSlice({
  name: "paymentSendURLReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(paymentSendURLAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(paymentSendURLAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(paymentSendURLAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetPaymentSendURL: (state, action) => {
      state.data = undefined
    }
  }
});

export const { resetPaymentSendURL } = paymentSendURLReducer.actions;