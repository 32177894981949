import {
    unitedQuickQuoteAction,
    unitedProposalAction,
    unitedAutoKycAction
} from "../Actions/unitedApiAction";

import { createSlice } from "@reduxjs/toolkit";

export const unitedQuickQuoteReducer = createSlice({
    name: "unitedQuickQuote",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(unitedQuickQuoteAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(unitedQuickQuoteAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(unitedQuickQuoteAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resetunitedQuickQuoteAction: (state, action) => {
            state.data = undefined
        }
    }
});

export const unitedProposalReducer = createSlice({
    name: "unitedProposal",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(unitedProposalAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(unitedProposalAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(unitedProposalAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resetunitedProposalAction: (state, action) => {
            state.data = undefined
        }
    }
});

export const { resetunitedProposalAction } = unitedProposalReducer.actions;

export const unitedAutoKycReducer = createSlice({
    name: "unitedAutoKyc",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(unitedAutoKycAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(unitedAutoKycAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(unitedAutoKycAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resetunitedAutoKycAction: (state, action) => {
            state.data = undefined
        }
    }
});

export const { resetunitedAutoKycAction } = unitedAutoKycReducer.actions;
