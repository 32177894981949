import React from "react";
import "./Footer.css";
import cross from "../../Assets/Images/cross.svg";
import logo from "../../Assets/Images/BMI_Logo.png";
import fb from "../../Assets/Images/facebook.png";
import linkedin from "../../Assets/Images/linkedin.png";
import instagram from "../../Assets/Images/instagram.png";
import twitter from "../../Assets/Images/twitter.png";
import call from "../../Assets/Images/call.svg";
import mail from "../../Assets/Images/mail.svg";
import {
  Routes,
  Route,
  useNavigate,
  Link,
  useLocation,
} from "react-router-dom";
import { currentYear } from "../../utils/commonUtils";

const Footer = (props) => {
  const { bottomContent } = props;

  const navigate = useNavigate();

  const handleQuickLinks = (menu) => {
    switch (menu) {
      case "aboutUs":
        navigate("/About-us");
        break;

      case "contactUs":
        navigate("/Contact-us");
        break;

      case "claim":
        navigate("/Claim");
        break;

      case "privacyPolicy":
        navigate("/Privacy-policy");
        break;

      case "grievanceRedressal":
        navigate("/Grievance-redressal");
        break;

      case "policyCancellation":
        navigate("/Policy-cancellation");
        break;

      default:
        break;
    }
  };

  return (
    <>
      <footer className=" footer_container">
        <div className="footer_parent maxWidth ">
          {/* <div className="card_footer">
            <img src={cross} className="cross_footer" alt="" srcSet="" />
            <img src={logo} className="logo_footer" alt="" srcSet="" />

            <div className="card_child_footer">
              <div className="head_card_child_footer">
                <p className="footer_title">Car</p>
              </div>
              <div className="menu_card_child_footer">
                <Link to="/Car-Insurance">
                  <p className="footer_card_content">Car Insurance</p>
                </Link>
                <Link to="/Third-party-policy">
                  <p className="footer_card_content">Third Party Car Insurance</p>
                </Link>
                <Link to="/Comprehensive-policy">
                  <p className="footer_card_content">
                    Comprehensive Car Insurance
                  </p>
                </Link>
                <Link to="/Standalone-policy">
                  <p className="footer_card_content">
                    Standalone Own Damage Car Insurance
                  </p>
                </Link>
                <Link to="/Zero-depreciation">
                  <p className="footer_card_content">
                    Zero Depreciation Car Insurance
                  </p>
                </Link>
                <p className="footer_card_content">Taxi Car Insurance</p>
                <p className="footer_card_content">Used Car Insurance</p>
              </div>
            </div>

            <div className="card_child_footer">
              <div className="head_card_child_footer">
                <p className="footer_title">Bike</p>
              </div>
              <div className="menu_card_child_footer">
                <p className="footer_card_content">
                  Third Party Bike Insurance
                </p>
                <p className="footer_card_content">
                  Comprehensive Bike Insurance
                </p>
                <p className="footer_card_content">
                  Zero Depreciation Bike Insurance
                </p>
                <p className="footer_card_content">Add On Cover</p>
                <p className="footer_card_content">Stand Alone Own Damage</p>
              </div>
            </div>

            <div className="card_child_footer">
              <div className="head_card_child_footer">
                <p className="footer_title">Health</p>
              </div>
              <div className="menu_card_child_footer">
                <p className="footer_card_content">Health Insurance Plans</p>
                <p className="footer_card_content">
                  Family Health Insurance Plans
                </p>
                <p className="footer_card_content">
                  Senior Citizen Health Insurance
                </p>
                <p className="footer_card_content">
                  Critical Illness Insurance
                </p>
                <p className="footer_card_content">Mediclaim Policy</p>
                <p className="footer_card_content">
                  Coronavirus Health Insurance
                </p>
                <p className="footer_card_content">
                  Health Insurance Tax Benefit
                </p>
              </div>
            </div>

            <div className="card_child_footer">
              <div className="head_card_child_footer">
                <p className="footer_title">Taxi</p>
              </div>
              <div className="menu_card_child_footer">
                <p className="footer_card_content">Car Insurance</p>
                <p className="footer_card_content">Third Party Car Insurance</p>
                <p className="footer_card_content">
                  Comprehensive Car Insurance
                </p>
                <p className="footer_card_content">
                  Standalone Own Damage Car Insurance
                </p>
                <p className="footer_card_content">
                  Zero Depreciation Car Insurance
                </p>
                <p className="footer_card_content">Taxi Car Insurance</p>
                <p className="footer_card_content">Used Car Insurance</p>
              </div>
            </div>
          </div> */}

          <div className="footer_content">
            <div className="footer_content_child">
              <div className="footer_content_child_title">
                <p className="footer_title">Quick Links</p>
              </div>
              <div className="footer_content_child_menu">
                <div className="footer_content_child_part">
                  <p
                    className="footer_card_content"
                    onClick={(e) => handleQuickLinks("aboutUs")}
                  >
                    About Us
                  </p>
                  <p
                    className="footer_card_content"
                    onClick={(e) => handleQuickLinks("contactUs")}
                  >
                    Contact Us
                  </p>
                  <p
                    className="footer_card_content"
                    onClick={(e) => handleQuickLinks("claim")}
                  >
                    Claim
                  </p>
                </div>
                <div className="footer_content_child_part">
                  <p
                    className="footer_card_content"
                    onClick={(e) => handleQuickLinks("privacyPolicy")}
                  >
                    Privacy Policy
                  </p>
                  <p
                    className="footer_card_content"
                    onClick={(e) => handleQuickLinks("grievanceRedressal")}
                  >
                    Grievance Redressal
                  </p>
                  <p
                    className="footer_card_content"
                    onClick={(e) => handleQuickLinks("policyCancellation")}
                  >
                    Policy Cancellation & Refund
                  </p>
                </div>
              </div>
            </div>
            <div className="footer_content_child">
              <div className="footer_content_child_title">
                <p className="footer_title">Contact</p>
              </div>
              <div className="footer_content_child_menu footer_content_child_menu_two ">
                <div className="footer_content_child_part_two">
                  <div className="parts_footer_content_child parts_footer_content_child-width">
                    <p className="footer_title_two">Book My Insurance</p>
                    <p className="footer_card_content">
                      Viaansh Insurance Brokers Pvt. Ltd.
                    </p>
                  </div>
                  <div className="parts_footer_content_child parts_footer_content_child-width ">
                    <p className="footer_card_content">
                      UNIT NO. 212 & 212 A, Suncity Success Tower, Golf Course
                      Ext Rd, Sector 65, Gurugram, Haryana 122005.
                    </p>
                  </div>
                </div>
                <div className="footer_content_child_part_two footer_content_child_part_four ">
                  {/* <div className="parts_footer_content_child  parts_footer_content_child_two">
                    <a
                      href="https://www.facebook.com/viaanshinsurance"
                      target="_blank"
                    >
                      <img src={fb} alt="" srcSet="" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/viaansh-insurance-brokers-private-limited/mycompany/"
                      target="_blank"
                    >
                      <img src={linkedin} alt="" srcSet="" />
                    </a>
                    <a
                      href="https://www.instagram.com/bookmyinsurance/"
                      target="_blank"
                    >
                      <img src={instagram} alt="" srcSet="" />
                    </a>
                    <a href="/">
                      <img src={twitter} alt="" srcSet="" />
                    </a>
                  </div> */}
                  <div className="parts_footer_content_child  parts_footer_content_child_three">
                    <a
                      href="mailto:support@bookmyinsurance.com"
                      className="footer_hyper_link"
                    >
                      <img src={mail} alt="" srcSet="" />
                      support@bookmyinsurance.com
                    </a>
                    <a href="tel:9811223458" className="footer_hyper_link">
                      <img src={call} alt="" srcSet="" />
                      9811223458
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer_bottom_container">
            <p>
              Website Approved by IRDAI License no.762 Direct Broker (Life &
              General) valid from 29-07-2024 to 28-07-2027 is the subject matter
              of solicitation. Visitors are hereby informed that their
              information submitted on the website may be shared with insurers.
              The product information for comparison displayed on this website
              is of the insurers with whom our company has an agreement.Product
              information is authentic and solely based on the information
              received from the Insurer. *The information provided on this
              website/page is for information purpose only. BookMyInsurance does
              not in any form or manner endorse the information so provided on
              the website and strives to provide factual and unbiased
              information to customers to assist in making informed insurance
              choices.
            </p>
          </div>

          <div className="footer_bottom_containerTwo">
            <div className="footer_bottom_containerTwo_part">
              <p>© {currentYear} Viaansh Insurance Brokers Pvt. Ltd.</p>
              <p>All rights reserved.</p>
            </div>
            <div className="footer_bottom_containerTwo_part">
              <p>
                BookMyInsurance and BookMyInsurance Logo are registered
                trademarks
              </p>
              <p>of Viaansh Insurance Brokers Pvt. Ltd.</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
