import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import search from "../../Assets/Images/search.svg";
import Footer from "../Footer/Footer";
import InputFields from "../InputFields/InputFields";
import Botton from "../Button/Button";
import DropDown from "../DropDown/DropDown";
import grayArrow from "../../Assets/Images/grayArrow.svg";
import { updateVehicleInfoAction } from "../../redux/Reducers/commonVehicleFetchApiReducer";
import { isFmcContentAction } from "../../redux/Reducers/FindMyCarContentType";
import {
  getBikeModelAction,
  getFuelTypeAction,
  getVariantAction,
  getBikeBrandAction
} from "../../redux/Actions/mmvAction";
import { getRtoAction } from "../../redux/Actions/stateDetails";
import {
  useNavigate,
  useLocation,
} from "react-router-dom";
import ModalInp from "./modalInp";
import Calender from "../InputFields/Calender";
import CustomDateField from "../InputFields/CustomDateField/CustomDateField";
import CustomCalendar from "../InputFields/Calendar/Calendar";

import SearchSelect from "../DropDown/Search Select/SearchSelect";
import { NEW } from "../../utils/commonUtils";
import Navbar from "../Navbar/Navbar";
import backIcon from '../../Assets/Images/backIcon.svg'
import SkeletonLoader from "../Pages/Loader/Skeleton loader/SkeletonLoader";

import FindMyVehicleMob from "./FindMyVehicleMob";
import close from "../../Assets/Images/Close.svg"


const FindMyBikeDetails = () => {
  const [sectionOne, setSectionOne] = useState(false)
  const dispatch = useDispatch();

  const handlePhoneNumber = (e) => {
    const { value } = e?.target || {}
    if (value.length <= 10) {
      setManualInpValue((prevItems) => ({
        ...prevItems,
        mobileNo: value,
      }))
    }
  }
  // getting details vehicle

  const getVehicleData = useSelector((state) => {
    return state?.getVehicleData?.data?.data;
  });

  const bodyData = useSelector((state) => {
    return state?.bodyData?.data;
  })

  let dealerDetails = useSelector((state) => {
    return state?.dealerData?.data
  });

  const modelDetails = getVehicleData?.model_details?.[0];

  // storing response object
  const getModelValue = useSelector((state) => {
    return state?.getModel;
  });

  const ModelList = getModelValue?.data?.data?.vehicelinfo;
  const uuid = getModelValue?.data?.data?.uuid;

  const getFuelTypeValue = useSelector((state) => {
    return state?.getFuel;
  });
  const fuelList = getFuelTypeValue?.data?.data?.vehicelinfo;

  const getVariantValue = useSelector((state) => {
    return state?.getVariant;
  });

  const variantList = getVariantValue?.data?.data?.vehicelinfo;

  console.log(variantList, "kjdkjds");


  const getRtoData = useSelector((state) => {
    return state?.getRtoDetails;
  });

  const rtoArray = getRtoData?.data?.data?.rtocity?.map((element) => {
    return {
      label: element?.rto + "-" + element?.city_name,
      value: element?.rto + "-" + element?.city_name,
    };
  });

  let getContentType = useSelector((state) => {
    return state?.getFmcContentType?.data;
  })

  const getInitialShowUserDataModal = () => {
    const storedValue = localStorage.getItem('ShowUserDataModal');
    return storedValue !== null ? JSON.parse(storedValue) : false;
  };

  const getInitialSwitchFunction = () => {
    const storedValue = localStorage.getItem('SwitchFunction');
    return storedValue !== null ? JSON.parse(storedValue) : false;
  };

  // navigation
  const navigate = useNavigate();
  const location = useLocation();
  let vehicleNumber = location?.state?.vehicleNumber;
  let businessType = location?.state?.businessType;

  // useState
  const [MakeId, setMakeId] = useState(bodyData?.make ? bodyData?.make : "");
  const [SearchedTerm, setSearchedTerm] = useState("");
  const [SearchedTermModel, setSearchedTermModel] = useState("");
  const [SearchedTermVarient, setSearchedTermVarient] = useState("");
  const [SearchedTermRto, setSearchedTermRto] = useState("");
  const [ShowPolicyStartDate, setShowPolicyStartDate] = useState(false);
  const [PolStartDateClone, setPolStartDateClone] = useState(null);
  const [isEnalbeBtn, setIsEnableBtn] = useState(true)

  // state to show the bottoms
  const [ShowMakeBottom, setShowMakeBottom] = useState(false);
  const [ShowModelBottom, setShowModelBottom] = useState(false);
  const [ShowRtoBottom, setShowRtoBottom] = useState(false)

  // state for the dropdown
  const [ShowRtoList, setShowRtoList] = useState(false);
  const [RegYearList, setRegYearList] = useState(false);
  const [ContentType, setContentType] = useState(getContentType);
  const [SwitchFunction, setSwitchFunction] = useState(getInitialSwitchFunction);
  const [ShowUserDataModal, setShowUserDataModal] = useState(getInitialShowUserDataModal);

  const currentYear = new Date().getFullYear();
  const yearOfRegistration = [];

  useEffect(() => {
    localStorage.setItem('ShowUserDataModal', JSON.stringify(ShowUserDataModal));
    localStorage.setItem('SwitchFunction', JSON.stringify(SwitchFunction));
  }, [ShowUserDataModal, SwitchFunction]);

  useEffect(() => {
    setContentType(getContentType);
  }, [])

  for (let year = 1990; year <= currentYear; year++) {
    if (businessType === "New") {
      if (year == currentYear || year == currentYear - 1) {
        yearOfRegistration.push(year);
      }
    } else {
      yearOfRegistration.push(year);
    }
  }

  const yearOfReg = yearOfRegistration.map((data, key) => ({
    label: data,
    value: data,
  }));


  // Reverse the array
  yearOfReg.reverse();

  const manualInputs = {
    make: null || bodyData?.make,
    model: null || bodyData?.model,
    varient: null || bodyData?.varient,
    fuelType: null || bodyData?.fuelType,
    rto: null || getVehicleData?.data?.rto,
    cc: null || bodyData?.cc,
    regn_dt: null || getVehicleData?.data?.regn_dt,
    policyStartDate: null || getVehicleData?.data?.policyStartDate,
    businessType: businessType ? businessType : null,
    mobileNo: null || bodyData?.mobileNo,
    pincode: null || bodyData?.pincode,
    isPospSale: false || bodyData?.isPospSale,
  };

  const [ManualInpValue, setManualInpValue] = useState(manualInputs);
  const [errors, setErrors] = useState({});

  const [FmcError, setFmcError] = useState({
    makeErr: null,
    modelErr: null,
    varientErr: null,
    fuelTypeErr: null,
    rtoErr: null,
    policyDetailsErr: null,
  });

  useEffect(() => {
    if (businessType === "rollover") {
      setManualInpValue((prevItems) => ({
        ...prevItems,
        make: modelDetails?.Brand,
        model: modelDetails?.Model,
        varient: modelDetails?.Variant_Name,
        fuelType: modelDetails?.fuelType,
        cc: modelDetails?.displacement,
      }));
    }
  }, [getVehicleData]);

  useEffect(() => {
    (async function () {
      let bikeBrand = await dispatch(getBikeBrandAction());
      setSectionOne(bikeBrand?.payload?.data?.vehicelinfo)
    })();
  }, [])

  const handleSearchQuery = (searchValue, type) => {
    switch (type) {
      case "make":
        setSearchedTerm(searchValue);
        break;

      case "model":
        setSearchedTermModel(searchValue);
        break;

      case "variant":
        setSearchedTermVarient(searchValue);
        break;

      case "rto":
        setSearchedTermRto(searchValue);
        break;

      default:
        break;
    }
  };

  let reorderedBikeBrands = []

  if (SearchedTerm !== "") {
    reorderedBikeBrands = SearchedTerm
      ? sectionOne?.filter((item) =>
        item?.make?.toLowerCase()?.includes(SearchedTerm?.toLowerCase())
      )
      : [];
  } else {
    reorderedBikeBrands = sectionOne
  }

  let reorderedBikeModels = []

  if (SearchedTermModel !== "") {
    reorderedBikeModels = SearchedTermModel
      ? ModelList?.filter((item) =>
        item?.model?.toLowerCase()?.includes(SearchedTermModel?.toLowerCase())
      )
      : [];
  } else {
    reorderedBikeModels = ModelList
  }

  let reorderedBikeVarients = [];

  if (SearchedTermVarient !== "") {
    reorderedBikeVarients = variantList?.filter((item) =>
      //   item?.Variant?.toLowerCase()?.includes(SearchedTermVarient?.toLowerCase()) ||
      // item.Cubic_Capacity.toLowerCase()?.includes(SearchedTermVarient?.toLowerCase())
      (item?.Variant?.toLowerCase() + (item.Cubic_Capacity)?.toString())?.includes(SearchedTermVarient?.toLowerCase())
    );
    if (reorderedBikeVarients.length === 0) {
      console.log("No results found");
    }
  } else {
    reorderedBikeVarients = variantList;
  }

  let reorderedRtoList = [];

  if (SearchedTermRto !== "") {
    reorderedRtoList = SearchedTermRto
      ? rtoArray?.filter((item) =>
        item?.value?.toLowerCase()?.includes(SearchedTermRto?.toLowerCase())
      )
      : [];
  } else {
    reorderedRtoList = rtoArray;
  }

  const handlePolicyStartDate = (e) => {
    const { name, value } = e?.target;
    setManualInpValue((prevItems) => ({
      ...prevItems,
      [name]: value,
    }));
  };

  const handleMakeData = (id, name) => {
    setMakeId(id);
    dispatch(getBikeModelAction(id));
    setManualInpValue((prevItems) => {
      const isDifferentMake = prevItems.make !== name;
      const isDifferentModel = prevItems.model !== name;
      const isDifferentfuelType = prevItems.fuelType !== name;

      return {
        ...prevItems,
        make: name,
        model: isDifferentMake ? null : prevItems.model,
        fuelType: (isDifferentMake && isDifferentModel) ? null : prevItems.fuelType,
        varient: (isDifferentMake && isDifferentModel && isDifferentfuelType) ? null : prevItems.varient,
      }
    });
    setFmcError((prevItems) => ({
      ...prevItems,
      makeErr: false,
      modelErr: false,
      fuelTypeErr: false,
    }));
    setContentType("Model");
    dispatch(isFmcContentAction("Model"));
  };

  const handleModelData = (name, key) => {
    let fuelReqObj = {
      id: MakeId,
      modelName: name,
      vehicleType: "bike"
    };
    setManualInpValue((prevItems) => {
      const isDifferentModel = prevItems.model !== name;
      const isDifferentfuelType = prevItems.fuelType !== name;

      return {
        ...prevItems,
        model: name,
        fuelType: isDifferentModel ? null : prevItems.fuelType,
        varient: (isDifferentModel && isDifferentfuelType) ? null : prevItems.varient,
      }
    });
    setFmcError((prevItems) => ({
      ...prevItems,
      makeErr: false,
      modelErr: false,
      fuelTypeErr: false,
      varientErr: false,
    }));

    dispatch(getFuelTypeAction(fuelReqObj));
    setContentType("FuelType");
    dispatch(isFmcContentAction("FuelType"));
  };

  const handleFuelTypeValue = (value) => {
    let variantReqObj = {
      id: MakeId,
      modelName: ManualInpValue?.model,
      selectedFuel: value,
      vehicleType: "bike"
    };
    setContentType("Varient");
    dispatch(isFmcContentAction("Varient"));
    dispatch(getVariantAction(variantReqObj));
    dispatch(getRtoAction("rto"));

    setManualInpValue((prevItems) => {
      const isDifferentfuelType = prevItems.fuelType !== value;

      return {
        ...prevItems,
        fuelType: value,
        varient: isDifferentfuelType ? null : prevItems.varient,
      }
    });
    setFmcError((prevItems) => ({
      ...prevItems,
      makeErr: false,
      varientErr: false,
      rtoErr: false,
    }));
  };

  const handleVarientData = (variant, cc) => {
    setManualInpValue((prevItems) => ({
      ...prevItems,
      varient: variant,
      cc: cc,
    }));
    setFmcError((prevItems) => ({
      ...prevItems,
      makeErr: false,
      varientErr: false,
      fuelTypeErr: false,
    }));
    setContentType("RTO");
    dispatch(isFmcContentAction("RTO"));

  };

  const showBottomContent = (contentType) => {
    switch (contentType) {
      case "Make":
        setShowMakeBottom(!ShowMakeBottom);
    }
  };

  const handleRtoValue = (value, resolution = false) => {
    delete errors?.rto
    setManualInpValue((prevItems) => ({
      ...prevItems,
      rto: resolution === "mobileView" ? value : value?.value,
    }));
    if (resolution === "mobileView") {
      setContentType("regYear");
      dispatch(isFmcContentAction("regYear"));
    }
  };

  const handleRegYearValue = (e, device) => {
    delete errors.regn_dt
    if (device === "mobile") {
      setManualInpValue((prevItems) => ({
        ...prevItems,
        regn_dt: e === "Buying a Brand New Bike" ? currentYear : e,
        businessType: e === currentYear ? "New" : businessType,
      }));
      if (e === currentYear) {
        businessType = "New"
      }
      setContentType("PolicyDetails");
      dispatch(isFmcContentAction("PolicyDetails"));
      setSwitchFunction(true);
    } else {
      setManualInpValue((prevItems) => ({
        ...prevItems,
        regn_dt: e?.value,
      }));
    }
  };

  const handlePopUps = () => {
    if (ShowRtoList) {
      setShowRtoList(false);
    }

    if (RegYearList) {
      setRegYearList(false);
    }
  };

  let breadCrumb = [
    {
      name: "Make",
      head: "Company Brand:",
      value:
        ManualInpValue?.make === "Maruti"
          ? "Maruti Suzuki"
          : ManualInpValue?.make || "Select Make",
    },
    {
      name: "Model",
      head: "Brand Model:",
      value: ManualInpValue?.model ? ManualInpValue?.model : "Select Model",
    },
    {
      name: "FuelType",
      head: "Fuel Type:",
      value:
        ManualInpValue?.fuelType === "INTERNAL_LPG_CNG"
          ? "Internal Fitted CNG"
          : ManualInpValue?.fuelType || "Select Fuel Type",
    },
    {
      name: "Varient",
      head: "Variant:",
      value: ManualInpValue?.varient
        ? ManualInpValue?.varient + " - " + ManualInpValue?.cc + "cc"
        : "Select Variant",
    },
    {
      name: "RTO",
      secondName: "PolicyDetails",
      head: "RTO and year of Registration: ",
      value: (ManualInpValue?.rto && ManualInpValue?.regn_dt)
        ? ManualInpValue?.rto + " and " + ManualInpValue?.regn_dt
        : "Select RTO",
    },
  ];

  const handleRoute = (vehicleInfo) => {
    if (vehicleInfo.e) {
      vehicleInfo?.e?.preventDefault();
    }
    const storeVehicleInfo = vehicleInfo;
    const isValid = validateModalInp(storeVehicleInfo);
    if (isValid) {
      const data = {
        data: {
          data: vehicleInfo,
        },
      };
      delete vehicleInfo.e
      dispatch(updateVehicleInfoAction(data));

      localStorage.setItem("isFindMyCar", true);
      localStorage.setItem("mobileNo", vehicleInfo.mobileNo);
      localStorage.setItem("pincode", vehicleInfo.pincode);
      localStorage.setItem("vehicleType", "2W");

      navigate(dealerDetails?.dealer_code ?
        `/View-Plans-Bike?id=${uuid}&Franchise_ID=${dealerDetails?.dealer_code}`
        : "/View-Plans-Bike?id=" + uuid, {
        state: {
          vehicleNumber: vehicleNumber,
          businessType: ManualInpValue?.businessType,
          isFindMyCar: true,
        },
      });
    }
  };

  const validateModalInp = (obj) => {
    const newErrors = {};

    if (ManualInpValue?.businessType != "New") {

      if (obj.isPrevPolicyExp == null) {
        newErrors.isPrevPolicyExp = "First Name is required";
      }
      if (obj.isPrevPolicyExp === true) {
        if (obj.prevPolicyExpLastMonth === null) {
          newErrors.prevPolicyExpLastMonth = "First Name is required";
        }
      }

      if (obj.prevPolicyExpLastMonth !== true) {
        if (obj.isClaimPrevPolicy == null) {
          newErrors.isClaimPrevPolicy = "First Name is required";
        }
      }
    }

    let regexMobile = /^[2-9]{1}[0-9]{9}$/;

    if (!obj.mobileNo) {
      newErrors.phoneNumber = "Phone Number is required";
    }

    if (!obj.rto) {
      newErrors.rto = "RTO is required";
    }

    if (!obj.regn_dt) {
      newErrors.regn_dt = "Registration year is required";
    }

    if (businessType === "New") {
      if (!obj.policyStartDate) {
        newErrors.policyStartDate = "Policy Start Date is required";
      }
    }

    if (obj.mobileNo && !regexMobile.test(obj.mobileNo)) {
      newErrors.phoneNumber = "Mobile number is Invalid";
    }

    if (!obj.pincode) {
      newErrors.pincode = "Pin code is required";
    }

    let pinregEx = /^[1-9]{1}[0-9]{2}[0-9]{3}$/;

    if (obj.pincode && !pinregEx.test(obj.pincode)) {
      newErrors.pincode = "Invalid pin code";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateRto = (obj) => {
    const newErrors = {};
    if (!obj.rto) {
      newErrors.rto = "RTO is required";
    }

    if (!obj.regn_dt) {
      newErrors.regn_dt = "Registration year is required";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setContentType("PolicyDetails");
      dispatch(isFmcContentAction("PolicyDetails"));
    }
  };

  const handleRouteNew = (manualValues) => {
    const isValid = validateModalInp(manualValues);
    if (isValid) {
      const data = {
        data: {
          data: manualValues,
        },
      };
      delete manualValues.e
      dispatch(updateVehicleInfoAction(data));
      localStorage.setItem("mobileNo", manualValues.mobileNo);
      localStorage.setItem("pincode", manualValues.pincode);
      localStorage.setItem("isFindMyCar", true);
      localStorage.setItem("vehicleType", "2W");
      navigate(dealerDetails?.dealer_code ?
        `/View-Plans-Bike?id=${uuid}&Franchise_ID=${dealerDetails?.dealer_code}`
        : "/View-Plans-Bike?id=" + uuid, {
        state: {
          vehicleNumber: vehicleNumber,
          businessType: ManualInpValue?.businessType,
          isFindMyCar: true,
        },
      });
    }
  };

  const handleContentReveal = (content) => {
    switch (content) {
      case "Make":
        setContentType("Make");
        dispatch(isFmcContentAction("Make"));
        break;

      case "Model":
        if (ManualInpValue?.make != null) {
          if (ContentType === "Make") {
            if (ManualInpValue?.make != null) {
              setContentType("Model");
              dispatch(isFmcContentAction("Model"));
              setFmcError((prevItems) => ({
                ...prevItems,
                makeErr: false,
              }));
            } else {
              setContentType("Make");
              dispatch(isFmcContentAction("Make"))
            }
          } else {
            setContentType("Model");
            dispatch(isFmcContentAction("Model"))
            setFmcError((prevItems) => ({
              ...prevItems,
              makeErr: false,
            }));
          }
        } else {
          setFmcError((prevItems) => ({
            ...prevItems,
            makeErr: true,
          }));
        }
        break;

      case "FuelType":
        if (ManualInpValue?.make != null && ManualInpValue?.model != null) {
          setContentType("FuelType");
          dispatch(isFmcContentAction("FuelType"))
          setFmcError((prevItems) => ({
            ...prevItems,
            fuelTypeErr: false,
            makeErr: false,
          }));
        } else {
          setFmcError((prevItems) => ({
            ...prevItems,
            fuelTypeErr: true,
            makeErr: true,
          }));
        }
        break;

      case "Varient":
        if (
          ManualInpValue?.make != null &&
          ManualInpValue?.model != null &&
          ManualInpValue?.fuelType != null
        ) {
          setContentType("Varient");
          dispatch(isFmcContentAction("Varient"));
          setFmcError((prevItems) => ({
            ...prevItems,
            varientErr: false,
            fuelTypeErr: false,
            makeErr: false,
          }));
        } else {
          setFmcError((prevItems) => ({
            ...prevItems,
            varientErr: true,
            fuelTypeErr: true,
            makeErr: true,
          }));
        }
        break;

      case "RTO":
        if (
          ManualInpValue?.make != null &&
          ManualInpValue?.model != null &&
          ManualInpValue?.fuelType != null &&
          ManualInpValue?.varient != null
        ) {
          setContentType("RTO");
          dispatch(isFmcContentAction("RTO"));
          setFmcError((prevItems) => ({
            ...prevItems,
            varientErr: false,
            fuelTypeErr: false,
            makeErr: false,
            rtoErr: false,
          }));
        } else {
          setFmcError((prevItems) => ({
            ...prevItems,
            varientErr: true,
            fuelTypeErr: true,
            makeErr: true,
            rtoErr: true,
          }));
        }
        break;

      case "PolicyDetails":
        if (
          ManualInpValue?.make != null &&
          ManualInpValue?.model != null &&
          ManualInpValue?.fuelType != null &&
          ManualInpValue?.varient != null &&
          ManualInpValue?.rto != null &&
          ManualInpValue?.regn_dt != null
        ) {
          setContentType("PolicyDetails");
          dispatch(isFmcContentAction("PolicyDetails"));
          setFmcError((prevItems) => ({
            ...prevItems,
            varientErr: false,
            fuelTypeErr: false,
            makeErr: false,
            rtoErr: false,
            policyDetailsErr: false,
          }));
        } else {
          setFmcError((prevItems) => ({
            ...prevItems,
            varientErr: true,
            fuelTypeErr: true,
            makeErr: true,
            rtoErr: true,
            policyDetailsErr: true,
          }));
        }
        break;

      default:
        break;
    }
  };

  const backToHome = () => {
    navigate("/")
  }

  const handleRouteToFMC = () => {
    setContentType("Make");
  }

  const closeModal = () => {
    if (window.innerWidth <= 610) {
      setContentType("regYear");

    } else {
      navigate("/");
    }
  }

  const validationMobDevice = (form) => {
    const newErrors = {};

    if (form.isPrevPolicyExp == null) {
      newErrors.isPrevPolicyExp = "First Name is required";
    }

    if (form.isPrevPolicyExp === true) {
      if (form.prevPolicyExpLastMonth === null) {
        newErrors.prevPolicyExpLastMonth = "First Name is required";
      }
    }

    if (form.prevPolicyExpLastMonth !== true) {
      if (form.isClaimPrevPolicy == null) {
        newErrors.isClaimPrevPolicy = "First Name is required";
      }
    }

    if (form.isCngLpgVehicleType === true) {
      if (!form.cngKitValue) {
        newErrors.cngKitValue = "CNG/LPG Kit Value is required";
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const handleProceed = (obj) => {
    const isValid = validationMobDevice(obj);
    if (isValid) {
      setShowUserDataModal(true);
    }
  }

  const handleManualInpValue = (e) => {
    const { name, value, label, checked } = e?.target || {};
    setManualInpValue({
      ...ManualInpValue,
      [name]: value,
    });
  }

  const cancelCalendarValue = () => {
    setPolStartDateClone(ManualInpValue?.policyStartDate)
    setShowPolicyStartDate(false);
  };

  const saveCalendarValue = () => {
    if (PolStartDateClone) {
      setManualInpValue((previtems) => ({
        ...previtems,
        policyStartDate: PolStartDateClone,
      }))
      setShowPolicyStartDate(false);
    }
  }

  const handlePolStartDateClone = (value) => {
    setPolStartDateClone(value);
    setIsEnableBtn(false);
  }

  return (
    <>
      <Navbar />
      <div className={`FindMyCar_container ${ContentType !== "PolicyDetails" && "Hide_FindMyCar_container"} `} onClick={handlePopUps}>
        <div className="findMyCar_parent maxWidth">
          <div className="content_block-findMyCar">
            <div className="leftContent_findMyCar contentDivide_findMyCar ">
              <p className="leftContent_findMyCar_head">Your Bike Details</p>
              {breadCrumb.map((item, key) => (
                <div
                  key={key}
                  className={
                    ContentType === item.name || ContentType === item.secondName
                      ? "info_block_left-active info_block_left_findMyCar "
                      : "info_block_left_findMyCar"
                  }
                  onClick={(e) => handleContentReveal(item.name)}
                >
                  <p className="info_block_left_head">{item?.head} </p>
                  <p className="info_block_left_desc">{item?.value}</p>
                </div>
              ))}
            </div>
            <div
              className={
                ContentType === "PolicyDetails"
                  ? "rightContent_findMyCar height-brandContainer-findMyCar contentDivide_findMyCar"
                  : "rightContent_findMyCar  contentDivide_findMyCar"
              }
            >
              {ContentType !== "PolicyDetails" ? (
                <p className="mobile-back-fmc" onClick={(e) => backToHome()} >
                  <img src={backIcon} alt="" srcSet="" /> Back to home  </p>
              ) : null
              }
              <div className="brandContainer-findMyCar">
                {ContentType === "Make" ? (
                  <>
                    <p className="title_brandCar">Select my Bike Brand </p>
                    {FmcError?.makeErr === true &&
                      (
                        <p className="imp-msg ">
                          Please select your bike Brand to proceed.
                        </p>
                      )}
                  </>
                ) : ContentType === "Model" ? (
                  <>
                    <p className="title_brandCar">Select my Bike Model</p>
                    {FmcError?.fuelTypeErr === true &&
                      (
                        <p className="imp-msg ">
                          Please select your bike Model to proceed.
                        </p>
                      )}
                  </>
                ) : ContentType === "FuelType" ? (
                  <>
                    <p className="title_brandCar">Select my Bike Fuel Type</p>
                    {FmcError?.varientErr === true &&
                      (
                        <p className="imp-msg ">
                          Please select your bike Fuel Type to proceed.
                        </p>
                      )}
                  </>
                ) : ContentType === "RTO" ? (
                  <>
                    <p className="title_brandCar">
                      Select RTO and year of bike registration
                    </p>
                    {FmcError?.policyDetailsErr === true &&
                      // ManualInpValue.rto === null && 
                      (
                        <>
                          {
                            (ManualInpValue?.rto !== undefined && ManualInpValue?.regn_dt === undefined) && (
                              <p className="imp-msg ">
                                Please select your Registration Year to proceed.
                              </p>
                            )
                          }

                          {
                            (ManualInpValue?.rto === undefined && ManualInpValue?.regn_dt !== undefined) && (
                              <p className="imp-msg ">
                                Please select your RTO to proceed.
                              </p>
                            )
                          }

                          {
                            (ManualInpValue?.rto === undefined && ManualInpValue?.regn_dt === undefined) && (
                              <p className="imp-msg ">
                                Please select your RTO and Registration Year to proceed.
                              </p>
                            )
                          }
                        </>

                      )}
                  </>
                ) : ContentType === "Varient" ? (
                  <>
                    <p className="title_brandCar">Select Variant</p>
                    {FmcError?.rtoErr === true &&
                      (
                        <p className="imp-msg ">
                          Please select your bike Varient to proceed.
                        </p>
                      )}
                  </>
                ) : (ContentType === "PolicyDetails" && ManualInpValue?.businessType === NEW) ? (
                  <>
                    <p className="title_brandCar">Policy Details </p>
                  </>
                ) : null}

                {ContentType === "Make" && (
                  <>
                    <div className="brandLogoContainer-input">
                      <img
                        src={search}
                        className="search-fmc"
                        alt=""
                        srcSet=""
                      />
                      <input
                        type="text"
                        className="fmcInput"
                        id="fmcInput"
                        name=""
                        value={SearchedTerm}
                        placeholder="Search your bike brand"
                        onChange={(e) =>
                          handleSearchQuery(e.target.value, "make")
                        }
                      />
                    </div>

                    <div className="brandLogoContainer-findMyCar  logo-findmycar model-findmycar">

                      {
                        !sectionOne ? (
                          <>
                            <div className="skeleton-container-fmc">
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="logosWrapper-fmc logosWrapper-fmc-makeList">
                              {!ShowMakeBottom ? (
                                <>
                                  {reorderedBikeBrands?.map(
                                    (item, key) =>
                                      key <= 8 && (
                                        <div
                                          className={
                                            ManualInpValue.make === item.make
                                              ? "model_tab model_tab-active"
                                              : "model_tab"
                                          }
                                          key={key}
                                          onClick={(e) =>
                                            handleMakeData(item.make, item.make)
                                          }
                                        >
                                          <p>{item.make}</p>
                                        </div>
                                      )
                                  )}
                                </>
                              ) : (
                                <>
                                  {reorderedBikeBrands?.map((item, key) => (
                                    <div
                                      className={
                                        ManualInpValue.make === item.make
                                          ? "model_tab model_tab-active"
                                          : "model_tab"
                                      }
                                      key={key}
                                      onClick={(e) =>
                                        handleMakeData(item.make, item.make)
                                      }
                                    >
                                      {/* <img src={item.img} alt="" srcSet="" /> */}
                                      <p>{item.make}</p>
                                    </div>
                                  ))}
                                </>
                              )}
                            </div>
                            <div className="btnContainer-fmc">
                              <Botton
                                className="secondry_btn secondry_btn-fmc"
                                text={ShowMakeBottom ? "Hide" : "View all 150 brands"}
                                event={(e) => showBottomContent("Make")}
                              />
                            </div>
                          </>
                        )
                      }
                    </div>
                    <div className="btnContainer-fmc btnContainer-fmc-noPadding btnContainer-fmc-mobile">
                      <Botton
                        className="secondry_btn secondry_btn-fmc"
                        text="Back"
                        event={(e) => backToHome()}
                      />
                      <Botton
                        className="button secondry_btn-fmc"
                        text="Next"
                        event={(e) => handleContentReveal("Model")}
                      />
                    </div>
                  </>
                )}

                {ContentType === "Model" && (
                  <>
                    <div className="brandLogoContainer-input">
                      <img
                        src={search}
                        className="search-fmc"
                        alt=""
                        srcSet=""
                      />
                      {/* <Botton className=" button fmc-btn fmc-btn-search" text="Search" /> */}
                      <input
                        type="text"
                        className="fmcInput"
                        id="fmcInput"
                        name=""
                        value={SearchedTermModel}
                        placeholder="Search your bike model"
                        onChange={(e) =>
                          handleSearchQuery(e.target.value, "model")
                        }
                      />
                    </div>

                    {
                      getModelValue?.isLoading ? (
                        <>
                          <div className="skeleton-container-fmc skeleton-container-fmc-border">
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="brandLogoContainer-findMyCar logo-findmycar model-findmycar ">
                            <div
                              className={
                                getModelValue?.isLoading === true
                                  ? " fmc-btn-container fmc-btn-container-varient blurEffect"
                                  : "fmc-btn-container fmc-btn-container-varient"
                              }
                            >
                              {reorderedBikeModels?.map((item, key) => (
                                <Botton
                                  key={key}
                                  className={
                                    ManualInpValue?.model === item.model
                                      ? "model_tab model_tab-active"
                                      : "model_tab"
                                  }
                                  text={item.model}
                                  event={(e) => handleModelData(item.model, key)}
                                />

                              ))}
                            </div>
                          </div>
                          <div className="btnContainer-fmc btnContainer-fmc-noPadding btnContainer-fmc-mobile ">
                            <Botton
                              className="secondry_btn secondry_btn-fmc"
                              text="Back"
                              event={(e) => setContentType("Make")}
                            />
                            <Botton
                              className="button secondry_btn-fmc"
                              text="Next"
                              event={(e) => handleContentReveal("FuelType")}
                            />
                          </div>
                        </>
                      )
                    }
                  </>
                )}

                {ContentType === "FuelType" && (
                  <>
                    {
                      getFuelTypeValue?.isLoading ? (
                        <>
                          <div className="skeleton-container-fmc skeleton-container-fmc-border">
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className={
                              getFuelTypeValue?.isLoading === true
                                ? " brandLogoContainer-findMyCar fuelTypeContainer-findMyCar blurEffect"
                                : "brandLogoContainer-findMyCar fuelTypeContainer-findMyCar"
                            }
                          >
                            {fuelList?.map((fuel, key) => (
                              <Botton
                                key={key}
                                text={
                                  fuel.fuel_type === "INTERNAL_LPG_CNG"
                                    ? "Internal Fitted CNG"
                                    : fuel.fuel_type
                                }
                                className={
                                  ManualInpValue?.fuelType === fuel.fuel_type
                                    ? "fuelType-btn fuelType-btn-active "
                                    : "fuelType-btn"
                                }
                                event={(e) => handleFuelTypeValue(fuel.fuel_type)}
                              />
                            ))}
                          </div>
                          <div className="btnContainer-fmc btnContainer-fmc-noPadding btnContainer-fmc-mobile">
                            <Botton
                              className="secondry_btn secondry_btn-fmc"
                              text="Back"
                              event={(e) => setContentType("Model")}
                            />
                            <Botton
                              className="button secondry_btn-fmc"
                              text="Next"
                              event={(e) => handleContentReveal("Varient")}
                            />
                          </div>
                        </>
                      )
                    }
                  </>
                )}

                {ContentType === "Varient" && (
                  <>
                    <div className="brandLogoContainer-input">
                      <img
                        src={search}
                        className="search-fmc"
                        alt=""
                        srcSet=""
                      />
                      {/* <Botton className=" button fmc-btn fmc-btn-search" text="Search" /> */}
                      <input
                        type="text"
                        className="fmcInput"
                        id="fmcInput"
                        name=""
                        value={SearchedTermVarient}
                        placeholder="Search your bike Variant"
                        onChange={(e) =>
                          handleSearchQuery(e?.target?.value, "variant")
                        }
                      />
                    </div>

                    {
                      getVariantValue?.isLoading ? (
                        <>
                          <div className="skeleton-container-fmc skeleton-container-fmc-border">
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="brandLogoContainer-findMyCar logo-findmycar model-findmycar  ">
                            <div
                              className={
                                getVariantValue?.isLoading === true
                                  ? " fmc-btn-container fmc-btn-container-varient blurEffect"
                                  : "fmc-btn-container fmc-btn-container-varient"
                              }
                            >
                              {reorderedBikeVarients?.map((item, key) => (
                                <button
                                  key={key}
                                  className={
                                    ManualInpValue?.varient === item.Variant
                                      ? "model_tab variant_tab_fmc model_tab-active "
                                      : "model_tab variant_tab_fmc "
                                  }
                                  onClick={(e) => handleVarientData(item.Variant, item.Cubic_Capacity)}
                                >
                                  <p> {item.Variant} <span>{item.Cubic_Capacity} {" "} CC</span> </p>
                                </button>
                              ))}
                            </div>
                          </div>
                          <div className="btnContainer-fmc btnContainer-fmc-noPadding btnContainer-fmc-mobile">
                            <Botton
                              className="secondry_btn secondry_btn-fmc"
                              text="Back"
                              event={(e) => setContentType("FuelType")}
                            />
                            <Botton
                              className="button secondry_btn-fmc"
                              text="Next"
                              event={(e) => handleContentReveal("RTO")}
                            />
                          </div>
                        </>
                      )
                    }
                  </>
                )}

                {ContentType === "RTO" && (
                  <form action="" onSubmit={(e) => { e.preventDefault(); }}
                    className={
                      businessType === "New"
                        ? "brandLogoContainer-findMyCar fuelTypeContainer-findMyCar  flexDiv-findMyCar fuelTypeContainer-findMyCar-  "
                        : "brandLogoContainer-findMyCar fuelTypeContainer-findMyCar  flexDiv-findMyCar"
                    }
                  >
                    <div className="input_group_pform input_group-FMC ">
                      <div className="inputGropOne_form inputGrop ">
                        <SearchSelect
                          type="submit"
                          optionList={rtoArray}
                          handleSelect={handleRtoValue}
                          value={ManualInpValue?.rto}
                          ManualInpValue={ManualInpValue}
                          placeholder="Select RTO"
                          label="Select RTO"
                          errState={errors?.rto ? true : false}
                          errMsg={errors?.rto ? errors?.rto : ""}
                        />
                      </div>
                      <div className="inputGropOne_form inputGrop ">
                        <SearchSelect
                          type="submit"
                          optionList={yearOfReg}
                          businessType={businessType}
                          id="stopdrop"
                          handleSelect={handleRegYearValue}
                          value={ManualInpValue?.regn_dt}
                          ManualInpValue={ManualInpValue}
                          placeholder="Year of Registration"
                          label="Registration Year"
                          errState={errors?.regn_dt ? true : false}
                          errMsg={errors?.regn_dt ? errors?.regn_dt : ""}
                        />

                      </div>
                    </div>
                    <div className="btnContainer-fmc btnContainer-fmc-rto btnContainer-fmc-noPadding ">
                      <Botton
                        className="secondry_btn secondry_btn-fmc secondry_btn-fmc-rto"
                        text="Back"
                        event={(e) => setContentType("Varient")}
                      />
                      {businessType === NEW ? (
                        <Botton
                          className="button secondry_btn-fmc secondry_btn-fmc-rto"
                          text="Next"
                          type="submit"
                          event={(e) => validateRto({ ...ManualInpValue })}
                        />
                      ) : (
                        <Botton
                          type="submit"
                          className="button secondry_btn-fmc secondry_btn-fmc-rto"
                          text="Next"
                          event={(e) => handleContentReveal("PolicyDetails")}
                        />
                      )}
                    </div>
                  </form>
                )}

                {ContentType === "PolicyDetails" && (
                  <>
                    {
                      ManualInpValue?.businessType === "New" ? (
                        <>
                          {
                            window.innerWidth <= 610 ? (
                              <div
                                className="edit-tabs-container-mob-height"
                                data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom"
                              >
                                <div className="active-bottom-edit-policy-tabs edit-modalInp-container">
                                  <div className="inputGropOne_form inputGrop inputs-parent-mob-modal">
                                    <p className="head-p" >Policy Start Date <span> <img src={close} onClick={(e) => setContentType("RTO")} /> </span> </p>
                                    {/* <label htmlFor="policyStartDate">
                                      <Calender
                                        className="pForm_datePicker"
                                        placeholder="Policy Start Date"
                                        name="policyStartDate"
                                        id="policyStartDate"
                                        value={ManualInpValue?.policyStartDate}
                                        changeEvent={(e) => { handlePolicyStartDate(e); delete errors?.policyStartDate }}
                                        errClassName={errors?.policyStartDate ? "input-err" : ""}
                                        errMsg={errors?.policyStartDate}
                                      />
                                    </label> */}
                                    <div className=" input_group_editForm ">
                                      <div className="inputGropOne_form inputGrop ">
                                        <CustomDateField
                                          className="pForm_datePicker pForm_datePicker-tab "
                                          btnId="policyStartDateBtn"
                                          textId="policyStartDateTxt"
                                          imgId="policyStartDateImg"
                                          dateValue={ManualInpValue?.policyStartDate}
                                          placeholder="Select Policy Start Date"
                                          placeholderId="regPlaceholderId"
                                          // CalendarDeactiveFlag={CalendarDeactiveFlag}
                                          // isDisabled={true}
                                          calendarReveal={ShowPolicyStartDate}
                                          setCalendarReveal={setShowPolicyStartDate}
                                          changeEvent={(e) => { handlePolicyStartDate(e); delete errors?.policyStartDate }}
                                          errClassName={errors?.policyStartDate ? "input-err" : ""}
                                          errMsg={errors?.policyStartDate}
                                        />
                                      </div>
                                      <div className="inputGropOne_form inputGrop inputGrop-hide-mobile "></div>
                                    </div>
                                  </div>

                                  <div className="inputs-parent-mob-modal">
                                    <p className="head-p" >Enter Phone Number</p>
                                    <InputFields
                                      value={ManualInpValue?.mobileNo}
                                      placeholder="Phone Number"
                                      id="mobileNo"
                                      type="number"
                                      name="mobileNo"
                                      errState={errors?.phoneNumber ? true : false}
                                      errMsg={errors?.phoneNumber}
                                      errClassName="input-err"
                                      onChangeEvent={(e) => {
                                        if (e.target.value.length <= 10) {
                                          handleManualInpValue(e)
                                        }
                                      }}

                                      hideLabel={true}
                                    />
                                  </div>

                                  <div className="inputs-parent-mob-modal">
                                    <p>Enter Pincode </p>
                                    <InputFields
                                      value={ManualInpValue?.pincode}
                                      placeholder="Pin code"
                                      id="pincode"
                                      type="number"
                                      name="pincode"
                                      errState={errors?.pincode ? true : false}
                                      errMsg={errors?.pincode}
                                      errClassName="input-err"
                                      hideLabel={true}
                                      onChangeEvent={(e) => {
                                        delete errors?.pincode
                                        setManualInpValue((prevItems) => ({
                                          ...prevItems,
                                          pincode: e.target.value?.length <= 6 ? e.target.value : ManualInpValue?.pincode,
                                        }))
                                      }}
                                    />
                                  </div>

                                  <div className="policy-type-list-container-mob" style={{ padding: "0rem" }}>
                                    <section className="btnContainer_pform btnContainer_pform-edit">
                                      <Botton
                                        text="Check Premium"
                                        className="button btn_pForm"
                                        event={() => handleRouteNew({ ...ManualInpValue })}
                                      />
                                    </section>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <form action="" onSubmit={(e) => { e.preventDefault(); }}
                                className={
                                  ManualInpValue?.businessType === "New"
                                    ? "brandLogoContainer-findMyCar fuelTypeContainer-findMyCar  flexDiv-findMyCar fuelTypeContainer-findMyCar-"
                                    : "brandLogoContainer-findMyCar fuelTypeContainer-findMyCar  flexDiv-findMyCar"
                                }
                              >
                                <div className="input_group_pform input_group-FMC input_group_new ">
                                  <div className="inputGropOne_form inputGrop ">
                                    {/* <div className=" input_group_editForm ">
                                      <div className="inputGropOne_form inputGrop "> */}
                                    <CustomDateField
                                      className="pForm_datePicker pForm_datePicker-tab "
                                      btnId="policyStartDateBtn"
                                      textId="policyStartDateTxt"
                                      imgId="policyStartDateImg"
                                      dateValue={ManualInpValue?.policyStartDate}
                                      placeholder="Select Policy Start Date"
                                      placeholderId="regPlaceholderId"
                                      // CalendarDeactiveFlag={CalendarDeactiveFlag}
                                      // isDisabled={true}
                                      calendarReveal={ShowPolicyStartDate}
                                      setCalendarReveal={setShowPolicyStartDate}
                                      changeEvent={(e) => { handlePolicyStartDate(e); delete errors?.policyStartDate }}
                                      errClassName={errors?.policyStartDate ? "input-err" : ""}
                                      errMsg={errors?.policyStartDate}
                                    />
                                    {/* </div> */}
                                    <div className="inputGropOne_form inputGrop inputGrop-hide-mobile "></div>
                                    {/* </div> */}
                                  </div>
                                  <div className="inputGropOne_form inputGrop ">
                                    <InputFields
                                      value={ManualInpValue?.mobileNo}
                                      placeholder="Phone Number"
                                      id="mobileNo"
                                      type="number"
                                      name="mobileNo"
                                      errState={errors?.phoneNumber ? true : false}
                                      errMsg={errors?.phoneNumber}
                                      errClassName="input-err"
                                      onChangeEvent={(e) => { handlePhoneNumber(e); delete errors?.phoneNumber }}
                                    />
                                  </div>
                                </div>
                                <div className="input_group_pform input_group-FMC">
                                  <div className="inputGropOne_form inputGrop ">
                                    <InputFields
                                      value={ManualInpValue?.pincode}
                                      placeholder="Pin code"
                                      id="pincode"
                                      type="number"
                                      name="pincode"
                                      errState={errors?.pincode ? true : false}
                                      errMsg={errors?.pincode}
                                      errClassName="input-err"
                                      // onChangeEvent={(e) => {
                                      //   delete errors.pincode
                                      //   if (e.target.value <= 1000000) {
                                      //     setUserData({
                                      //       ...userData,
                                      //       pincode: e.target.value,
                                      //     })
                                      //   }}
                                      // }
                                      onChangeEvent={(e) => {
                                        delete errors?.pincode
                                        setManualInpValue((prevItems) => ({
                                          ...prevItems,
                                          pincode: e.target.value?.length <= 6 ? e.target.value : ManualInpValue?.pincode,
                                        }))
                                      }}
                                    />
                                  </div>
                                  <div className="inputGropOne_form inputGrop inputGrop-fmc-new ">
                                  </div>
                                </div>
                                <div className="btnContainer-fmc btnContainer-fmc-rto btnContainer-fmc-noPadding ">
                                  <Botton
                                    className="secondry_btn secondry_btn-fmc secondry_btn-fmc-rto"
                                    text="Back"
                                    event={(e) => setContentType("RTO")}
                                  />
                                  <Botton
                                    className="button secondry_btn-fmc secondry_btn-fmc-rto"
                                    text="Check Premium"
                                    type="submit"
                                    event={(e) => handleRouteNew({ ...ManualInpValue })}
                                  />
                                </div>
                              </form>
                            )
                          }
                        </>
                      ) : (
                        <ModalInp
                          type="submit"
                          ManualInpValue={ManualInpValue}
                          businessType={ManualInpValue?.businessType}
                          clickEvent={handleRoute}
                          validateModalInp={validateModalInp}
                          errors={errors}
                          uniqueClass="positionUnique"
                          vehicleType="2W"
                          make={ManualInpValue?.make}
                          model={ManualInpValue?.model}
                          varient={ManualInpValue?.varient}
                          fuelType={ManualInpValue?.fuelType}
                          regDate={ManualInpValue?.regn_dt}
                          vehicleNumber={ManualInpValue?.rto}
                          handleRouteToFMC={handleRouteToFMC}
                          closeModal={closeModal}
                          SwitchFunction={SwitchFunction}
                          handleProceed={handleProceed}
                          setShowUserDataModal={setShowUserDataModal}
                          ShowUserDataModal={ShowUserDataModal}
                          setManualInpValue={setManualInpValue}
                          makeList={sectionOne}
                          ModelList={ModelList}
                          fuelList={fuelList}
                          variantList={variantList}
                          yearOfRegistration={yearOfRegistration}
                          currentYear={currentYear}
                          rtoArray={rtoArray}
                          handleRtoValue={handleRtoValue}
                        />
                      )
                    }
                  </>

                )}

              </div>
            </div>
          </div>
        </div>
      </div>



      {/* FMC CONTAINER - MOBILE */}

      <FindMyVehicleMob
        reorderedCarBrands={reorderedBikeBrands}
        ShowMakeBottom={ShowMakeBottom}
        showBottomContent={showBottomContent}
        handleSearchQuery={handleSearchQuery}
        SearchedTerm={SearchedTerm}
        backToHome={backToHome}
        ContentType={ContentType}
        handleMakeData={handleMakeData}
        SearchedTermModel={SearchedTermModel}
        ManualInpValue={ManualInpValue}
        setContentType={setContentType}
        reorderedCarModels={reorderedBikeModels}
        handleModelData={handleModelData}
        setShowModelBottom={setShowModelBottom}
        ShowModelBottom={ShowModelBottom}
        fuelList={fuelList}
        handleFuelTypeValue={handleFuelTypeValue}
        SearchedTermVarient={SearchedTermVarient}
        reorderedCarVarients={reorderedBikeVarients}
        handleVarientData={handleVarientData}
        SearchedTermRto={SearchedTermRto}
        rtoArray={reorderedRtoList}
        handleRtoValue={handleRtoValue}
        setShowRtoBottom={setShowRtoBottom}
        ShowRtoBottom={ShowRtoBottom}
        yearOfReg={yearOfReg}
        handleRegYearValue={handleRegYearValue}
        currentYear={currentYear}
        handleRoute={handleRoute}
        validateModalInp={validateModalInp}
        errors={errors}
        uniqueClass="positionUnique"
        vehicleType="2W"
        businessType={ManualInpValue?.businessType}
        handleRouteToFMC={handleRouteToFMC}
        SwitchFunction={SwitchFunction}
      />


      {/* policy Start Date */}

      {
        ShowPolicyStartDate && (
          <CustomCalendar
            value={PolStartDateClone}
            headText="Select your Policy Start Date"
            name="policyStartDate"
            btnId="policyStartDateBtn"
            textId="policyStartDateTxt"
            imgId="policyStartDateImg"
            placeholderId="polStartDatePlaceholderId"
            revealState={ShowPolicyStartDate}
            setRevealState={setShowPolicyStartDate}
            handleCopiedDetails={handlePolStartDateClone}
            cancelCalendarValue={cancelCalendarValue}
            saveCalendarValue={saveCalendarValue}
            onClose={(e) => setShowPolicyStartDate(false)}
            isEnalbeBtn={isEnalbeBtn}
          // maxDate={maxDobDate}
          />
        )
      }

      <Footer />
    </>
  );
};

export default FindMyBikeDetails;
