import React, { useState, useEffect } from "react";
import "./navbar.css";
import logo from "../../Assets/Images/BMI_Logo.png";
import arrow from "../../Assets/Images/arrow.svg";
import phone from "../../Assets/Images/call.svg"
import menu from "../../Assets/Images/Mobile_menu.svg";
import cross from "../../Assets/Images/closeInBlack.svg";
import Botton from "../Button/Button";
import user from "../../Assets/Images/userIconTwo.svg";
import rightArrow from "../../Assets/Images/arrRight.svg";
import claim from "../../Assets/Images/claimNav.svg";
import support from "../../Assets/Images/supportNav.svg";
import Newsupport from "../../Assets/Images/Newsupport.svg";
import aboutUs from "../../Assets/Images/abtUsNav.svg";
import { Routes, Route, useNavigate, Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";

const Navbar = (props) => {
  const { text, className, style, name, event, componentPath } = props;
  const search = useLocation().search;
  const dealer_code = new URLSearchParams(search).get("Franchise_ID");
  useEffect(() => {
    AOS.init({
      duration: 200,
    });
    AOS.refresh();
  }, []);

  const [showMobNav, setshowMobNav] = useState(false);

  const IsLoggedIn = useSelector((state) => {
    return state.getLoggedInStatus?.data?.isLoggedIn;
  });

  let dealerDetails = useSelector((state) => {
    return state?.dealerData?.data
  });

  // navigation
  const navigate = useNavigate();

  const handleHome = () => {
    if (dealer_code) {
      navigate(`/partners?Franchise_ID=${dealer_code}`);
    } else {
      navigate("/");
    }
  };

  const showMobileMenu = () => {
    setshowMobNav(true);
  };

  const handleLoginButton = () => {
    navigate("/Login", {
      state: {
        componentPath: componentPath,
      },
    });
  };

  const navbarItemm = [

    "Claims",
    "Support",
    "About Us",
    // "Buy Insurance",
    // "Renew Policy",
    // "Become Agent",
  ];

  const navbarItem = [
    {
      id: 1,
      icon: claim,
      title: "Claims",
    },
    {
      id: 2,
      icon: Newsupport, //support
      title: "Support",
    },
    {
      id: 3,
      icon: aboutUs,
      title: "About Us",
    },
  ]

  const routeMyAccount = () => {
    navigate("/My-Account");
  };

  const handleNavRoute = (menu) => {
    if (menu === "About Us") {
      navigate("/About-us");
    } else if (menu === "Claims") {
      navigate("/Claim");
    } else if (menu === "Support") {
      navigate("/Contact-us");
    }
  };

  return (
    <>
      <nav className="nav_container">
        <div className="nav_parent maxWidth ">
          <div className="nav_child">
            <img
              src={logo}
              className="navLogo"
              onClick={handleHome}
            />
          </div>
          <div className="nav_mob_menu_toggle" onClick={() => showMobileMenu()}>
            <img src={menu} alt="" srcSet="" />
          </div>
          <div className="nav_child nav_menu nav_menuPc">
            {dealerDetails?.dealer_name &&
              <>
                <div className="navItem_text">
                  <div
                    key={1}
                    className="navItem_agent"
                    id={"dealer"}
                  >
                    {/* <div className="navItem_arrow">
                      <img src={dealerDetails?.dealer_logo} alt="" srcSet="" height={78} width={120} />
                    </div> */}
                  </div>
                </div>
                <div className="navItem_text">
                  <div
                    key={3}
                    className="navItem_agent"
                    id={"dealer"}
                  >
                    Need Further assitance ?
                    <div className="navItem_arrow">
                      <a href={`tel:${dealerDetails?.mobile_no}`}>
                        <img src={phone} />
                        <b>{"  " + dealerDetails?.mobile_no}</b></a>
                    </div>
                  </div>
                </div>
              </>
            }
            <div className="navItem_text">
              {navbarItem.map((item, index) => (
                <div
                  key={index}
                  className="navItem"
                  id={index}
                  onClick={(e) => handleNavRoute(item.title)}
                >
                  {item.title}
                  {/* {index !== 3 && index !== 5 && index !== 6 && (
                    <div className="navItem_arrow">
                      <img src={arrow} alt="" srcSet="" />
                    </div>
                  )} */}
                </div>
              ))}
            </div>
            {IsLoggedIn ? (
              <button
                className="my-account-btn"
                onClick={(e) => routeMyAccount()}
              >
                <div className="icon-circle-navbar">
                  <img src={user} alt="" srcSet="" />
                </div>
                <p>My Account</p>
              </button>
            ) : (
              <Botton
                text="Login"
                className="button nav_btn"
                event={(e) => handleLoginButton(e)}
              />
            )}
          </div>
          {showMobNav && (
            <div className="nav_menuMob" data-aos="fade-left">
              <div className="nav_menuMob_head">
                <img
                  src={logo}
                  className="navLogo"
                />
                <img
                  src={cross}
                  onClick={() => setshowMobNav(false)}
                />
              </div>
              {dealerDetails?.dealer_name &&
                <>
                  <div className="navItem_text">
                    <div
                      key={1}
                      className="navItem_agent"
                      id={"dealer"}
                    >
                      {/* <div className="navItem_arrow">
                        <img src={dealerDetails?.dealer_logo} alt="" srcSet="" height={100} width={150} />
                      </div> */}
                    </div>

                  </div>
                  <div className="navItem_text">
                    <div
                      key={3}
                      className="navItem_agent"
                      id={"dealer"}
                    >
                      Need Further assitance ?
                      <div className="navItem_arrow">
                        <a href={`tel:${dealerDetails?.mobile_no}`}>
                          <img src={phone} alt="" srcSet="" />
                          <b>{"  " + dealerDetails?.mobile_no}</b></a>
                      </div>
                    </div>
                  </div>
                </>
              }
              <div className="navItem_text">
                {navbarItem.map((item, index) => (
                  <p
                    className="navItem"
                    id={index}
                    onClick={(e) => handleNavRoute(item.title)}
                  >
                    <div className="navMobLeft_section">
                      <img src={item.icon} />
                      {item.title}
                    </div>
                    <img src={rightArrow} />
                    {/* {index !== 3 && index !== 5 && index !== 6 && (
                      <div className="navItem_arrow">
                        <img src={arrow} alt="" srcSet="" />
                      </div>
                    )} */}
                  </p>
                ))}
              </div>
              {/* <Botton
                text="Login"
                event={handleLoginButton}
                className="button nav_btn"
              /> */}
            </div>
          )}
        </div>
      </nav>
    </>




  );
};

export default Navbar;
