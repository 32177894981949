import React from "react";
import "./Razorpay.css";
import Failed from "../../Assets/Images/paymentFailed.svg";
import Footer from "../Footer/Footer";
import Botton from "../Button/Button";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";

const PaymentFailed = () => {
  const handleRetryPayment = () => {
    alert("Retry Payment Btn Clicked !");

    // navigate("/Propsal-Form"); 
  };

  const navigate = useNavigate();

  return (
    <div>
      <Navbar />
      <section className="redirectContainer">
        <div className="content-block-redirect">
          <img src={Failed} alt="" srcSet="" />
          <p className="msg-edirect-succes msg-edirect-failed ">Thank You!</p>

          <p className="msg-edirect">
            Sorry your payment is failed.
            <br /> Please check the details and try again.
          </p>

          <Botton
            text="Retry Payment"
            event={handleRetryPayment}
            className="button btn-redirect"
          />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PaymentFailed;
