import { createSlice } from "@reduxjs/toolkit";

export const addonsReducer = createSlice({
    name: "bodyDataReducer",
    initialState: {},
    reducers: {
        addonsAction: (state, action) => {
            state.data = action.payload;
        },
        resetaddonsActionAction: (state, action) => {
            state.data = undefined
        }
    },
});
export const { addonsAction, resetaddonsActionAction } = addonsReducer.actions;

