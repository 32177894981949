import React from "react";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import broadCoverage from "../../../Assets/Images/broadCoverage.svg";
import vehicleProtection from "../../../Assets/Images/vehicleProtection.svg";
import loanlease from "../../../Assets/Images/loan&lease.svg";
import protection from "../../../Assets/Images/protection.svg";
import passengerCover from "../../../Assets/Images/passengerCover.svg";
import resaleValue from "../../../Assets/Images/resaleValue.svg";
import car from "../../../Assets/Images/compcarins_720.png";
import claim from "../../../Assets/Images/claim.svg";
import emi from "../../../Assets/Images/emi.svg";
import cashless from "../../../Assets/Images/cashless.svg";
import assistance from "../../../Assets/Images/assistance.svg";
import policy from "../../../Assets/Images/policy.svg";
import refund from "../../../Assets/Images/refund.svg";
import tracking from "../../../Assets/Images/tracking.svg";
import standalone from "../../../Assets/Images/standalone.svg";
import { useEffect } from "react";
import ComparePolicy from "./comparePolicy";
import Card from "../../Card/Card";
import mobile from "../../../Assets/Images/mobileContent.png";
import success from "../../../Assets/Images/success.svg";
import fail from "../../../Assets/Images/failure.svg";
import tata from "../../../Assets/Partners_logo/TATA AIG.png";
import digit from "../../../Assets/Partners_logo/digit.svg";
import icici from "../../../Assets/Partners_logo/icici lombard.svg";
import hdfc from "../../../Assets/Partners_logo/hdfc.png";
import lady from "../../../Assets/Images/ladyTwo.png";
import Timeline from "../../InputFields/Timeline/Timeline";
import comparison from "../../../Assets/Images/Comparison.svg";
import assesments from "../../../Assets/Images/assesments.svg";
import applications from "../../../Assets/Images/application.svg";
import finalPolicy from "../../../Assets/Images/finalPolicy.svg";
import calculator from "../../../Assets/Images/calculator.png";
import Partners from "../../Partners/Partners";

const ComprehensiveCarIns = (props) => {
    const { firstLot, secondLot } = props;

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    let reasonsForCarIns = [
        {
            icon: broadCoverage,
            title: "Broad Coverage",
            description:
                "It provides extensive protection, encompassing various risks that can lead to financial loss.",
        },
        {
            icon: vehicleProtection,
            title: "Vehicle Protection",
            description:
                "Comprehensive insurance covers damage to your own vehicle, ensuring you're not burdened with repair or replacement costs.",
        },
        {
            icon: loanlease,
            title: "Loan and Lease Requirements",
            description:
                " If you've financed or leased your vehicle, comprehensive coverage is often mandatory to protect the lender's interests.",
        },
        {
            icon: protection,
            title: "Protection Against Unpredictable Events",
            description:
                "Comprehensive coverage is crucial for unforeseen incidents like hail damage, falling objects, or animal collisions, which can occur without warning.",
        },
        {
            icon: passengerCover,
            title: "Covers Passengers",
            description: "Provides coverage for passengers in your vehicle.",
        },
        {
            icon: resaleValue,
            title: "Resale Value",
            description:
                "Comprehensive insurance maintains your car's value despite unexpected events, benefiting its resale or trade-in worth.",
        },
    ];

    let tableHeading = ["What is covered?", "Third Party", "Comprehensive"];

    let tableObject = [
        {
            addonName: "Third-Party Vehicle Damage",
            coveredInTp: success,
            coveredInComprehensive: success,
        },
        {
            addonName: "Own Vehicle Damage",
            coveredInTp: success,
            coveredInComprehensive: success,
        },
        {
            addonName: "Vehicle Theft",
            coveredInTp: fail,
            coveredInComprehensive: success,
        },
        {
            addonName: "Fire Damage",
            coveredInTp: fail,
            coveredInComprehensive: success,
        },
        {
            addonName: "Cashless Claim",
            coveredInTp: fail,
            coveredInComprehensive: success,
        },
        {
            addonName: "Damage from Natural Calamities",
            coveredInTp: fail,
            coveredInComprehensive: success,
        },
        {
            addonName: "Personal Accident Covered",
            coveredInTp: success,
            coveredInComprehensive: success,
        },
        {
            addonName: "Engine Protection",
            coveredInTp: fail,
            coveredInComprehensive: "Through Ad-On",
        },
        {
            addonName: "Zero Depreciation Cover",
            coveredInTp: fail,
            coveredInComprehensive: "Through Ad-On",
        },
        {
            addonName: "NCB Protection",
            coveredInTp: fail,
            coveredInComprehensive: "Through Ad-On",
        },
        {
            addonName: "Invoice Cover",
            coveredInTp: fail,
            coveredInComprehensive: "Through Ad-On",
        },
    ];

    let planCards = [
        {
            id: 1,
            img: tata,
        },
        {
            id: 2,
            img: digit,
        },
        {
            id: 3,
            img: icici,
        },
        {
            id: 4,
            img: hdfc,
        },
    ];

    let timelineElements = [
        {
            id: 1,
            icon: assesments,
            title: "Assessment of Needs:",
            description:
                "Evaluate your coverage requirements based on your vehicle and personal circumstances.",
        },
        {
            id: 2,
            icon: comparison,
            title: "Comparison and Research",
            description:
                "Compare insurance policies and quotes from different providers to find the most suitable option.",
        },
        {
            id: 3,
            icon: applications,
            title: "Application Process",
            description:
                "Complete the insurance application, providing accurate information about your vehicle and driving history.",
        },
        {
            id: 4,
            icon: finalPolicy,
            title: "Policy Finalization",
            description:
                "Choose the preferred insurance policy, make the premium payment, and retain proof of insurance for your records.",
        },
    ];

    let checklist = [
        "Personal information (age, gender).",
        "Driving history (accidents, violations).",
        "Vehicle details (make, model).",
        "Usage (frequency, purpose).",
        "Location (ZIP code).",
        "Coverage choices.",
        "Eligible discounts.",
    ];

    return (
        <>
            <Navbar />

            {/* HERO */}

            <div className="hero-section-product">
                <img
                    src={car}
                    className="product-ins-hero-img car-product"
                    alt=""
                    srcSet=""
                />
                <div className="hero-section-product-parent maxWidth">
                    <div className="hero-content-product-ins">
                        <h1 className="title-product-ins">
                            Comprehensive <span>Car Insurance</span>
                        </h1>
                        <p>
                            Comprehensive car insurance is a type of auto insurance coverage
                            that provides financial protection for a wide range of risks,
                            including damage to your own vehicle from accidents, theft,
                            vandalism, natural disasters, and liability coverage for
                            third-party injuries and property damage.
                        </p>
                    </div>
                </div>
            </div>

            {/* WHY CAR INSURANCE */}

            <div className="content-container-prod-insurance">
                <div className="content-container-prod-insurance-parent maxWidth">
                    <h1 className="title-product-ins">
                        Why Car Insurance <span>is Important ?</span>
                    </h1>
                    <div className="list-container-prod-ins">
                        <div className="list-container-prod-left list-container-prod-block">
                            {reasonsForCarIns.map(
                                (data, key) =>
                                    key < 3 && (
                                        <div key={key} className="list-rectangle">
                                            <div className="list-rectangle-icon-square">
                                                <img src={data.icon} alt="" srcSet="" />
                                            </div>
                                            <div className="list-rectangle-content">
                                                <p>{data.title}</p>
                                                <p>{data.description}</p>
                                            </div>
                                        </div>
                                    )
                            )}
                        </div>
                        <div className="list-container-prod-right list-container-prod-block">
                            {reasonsForCarIns.map(
                                (data, key) =>
                                    key >= 3 && (
                                        <div key={key} className="list-rectangle">
                                            <div className="list-rectangle-icon-square">
                                                <img src={data.icon} alt="" srcSet="" />
                                            </div>
                                            <div className="list-rectangle-content">
                                                <p>{data.title}</p>
                                                <p>{data.description}</p>
                                            </div>
                                        </div>
                                    )
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* WHY to buy from us */}

            <div className="content-container-prod-insurance  explanation-container-prod-insurance  ">
                <div className="content-container-prod-insurance-parent maxWidth">
                    <div className="mobile-container-with-content">
                        <div className="mob-container-prod-pg">
                            <div className="circle-gradient-prod-pg">
                                <img src={mobile} alt="" srcSet="" />
                            </div>
                        </div>
                        <div className="content-container-prod-pg">
                            <div className="hero-content-product-ins w2b-content-product-ins">
                                <h1 className="title-product-ins">
                                    Why buy <span> from us? </span>
                                </h1>
                                <p>
                                    When you choose to buy insurance from us, you're selecting a
                                    trusted partner dedicated to safeguarding what matters most to
                                    you.
                                </p>
                                <p>
                                    Our competitive pricing, unwavering financial stability, and
                                    exceptional customer service make us the reliable choice for
                                    your insurance needs. With a reputation built on trust and a
                                    commitment to your peace of mind, we're here to provide the
                                    security and support you deserve. Choose us for insurance that
                                    truly cares about your well-being.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="card_div_container  card_div_container-prod-pg">
                        <div className="card_container_child">
                            <Card
                                icons={claim}
                                className="card_landing"
                                classNameTwo="cardLanding_content"
                                classNameThree="icon_container_landing"
                                title="100% Claims"
                                subtitle="Claim Guarantee"
                                hoverContent="in insurance ensures that the policyholder will receive full compensation for covered losses without any deductions or exceptions."
                            />
                            <Card
                                icons={emi}
                                className="card_landing"
                                classNameTwo="cardLanding_content"
                                classNameThree="icon_container_landing"
                                title="EMI Available"
                                subtitle="EMI option available on Credit Cards"
                            />
                            <Card
                                icons={cashless}
                                className="card_landing"
                                classNameTwo="cardLanding_content"
                                classNameThree="icon_container_landing"
                                title="100% Cashless"
                                subtitle="Cashless Policies"
                            />
                            <Card
                                icons={assistance}
                                className="card_landing"
                                classNameTwo="cardLanding_content"
                                classNameThree="icon_container_landing"
                                title="Claim Assistance"
                                subtitle="Free Claim assistance for all Insurance"
                            />
                        </div>
                        <div className="card_container_child">
                            <Card
                                icons={policy}
                                className="card_landing"
                                classNameTwo="cardLanding_content"
                                classNameThree="icon_container_landing"
                                title="Policy Correction"
                                subtitle="Free Online Policy Correction"
                            />
                            <Card
                                icons={refund}
                                className="card_landing"
                                classNameTwo="cardLanding_content"
                                classNameThree="icon_container_landing"
                                title="100% Refund"
                                subtitle="Free Policy Cancellation 100% Refund"
                            />
                            <Card
                                icons={tracking}
                                className="card_landing"
                                classNameTwo="cardLanding_content"
                                classNameThree="icon_container_landing"
                                title="Live Tracking"
                                subtitle="Free Claims Live Tracking"
                            />
                            <Card
                                icons={standalone}
                                className="card_landing"
                                classNameTwo="cardLanding_content"
                                classNameThree="icon_container_landing"
                                title="Stand Alone"
                                subtitle="Stand Alone Products"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* policy comparison */}

            <ComparePolicy
                title="Types of"
                titleInBold="Car Insurance and Comparison"
                policyTypeFirst="Third Party Insurance"
                policyTypeFirstDesc="Third-party insurance covers your responsibility when you harm
        someone or damage their property accidentally. It pays for
        their medical bills, property repairs, and legal costs if they
        sue you. It's insurance for accidents that affect other people
        or their belongings."
                policyTypeSecond="Comprehensive Insurance"
                policyTypeSecondDesc="Comprehensive insurance is a type of insurance that covers a
        wide range of damages to your own vehicle, not just accidents
        with other people. It includes things like theft, vandalism,
        natural disasters, and falling objects."
                tableHeading={tableHeading}
                tableObject={tableObject}
            />

            {/* Top Car Insurance Plans */}

            <div className="content-container-prod-insurance">
                <div className="content-container-prod-insurance-parent maxWidth">
                    <h1 className="title-product-ins">
                        Top Car Insurance Plans Available on <span>BookMyInsurance</span>
                    </h1>
                    <div className="cards-container-product-ins">
                        {planCards.map((data, key) => (
                            <div key={key} className="card-prod-ins">
                                <div className="ins-logo-container">
                                    <img src={data.img} alt="" srcSet="" />
                                </div>
                                <div className="breakup_container breakup_container-dummy ">
                                    <div className="planHeadValueContainer">
                                        <div className="planHead">
                                            <p>
                                                Basic Price <span>₹ 11,222.41</span>
                                            </p>
                                        </div>
                                        <div className="result_pc">
                                            <img src={success} alt="" srcSet="" />
                                        </div>
                                    </div>
                                    <div className="planHeadValueContainer">
                                        <div className="planHead">
                                            <p>Personal Accident Cover</p>
                                        </div>
                                        <div className="result_pc">
                                            <img src={success} alt="" srcSet="" />
                                        </div>
                                    </div>
                                    <div className="planHeadValueContainer">
                                        <div className="planHead">
                                            <p>Zero Depreciation</p>
                                        </div>
                                        <div className="result_pc">
                                            <img src={success} alt="" srcSet="" />
                                        </div>
                                    </div>
                                    <div className="planHeadValueContainer">
                                        <div className="planHead">
                                            <p>Engine Protector</p>
                                        </div>
                                        <div className="result_pc">
                                            <img src={success} alt="" srcSet="" />
                                        </div>
                                    </div>
                                    <div className="planHeadValueContainer">
                                        <div className="planHead">
                                            <p>Consumable</p>
                                        </div>
                                        <div className="result_pc">
                                            <img src={success} alt="" srcSet="" />
                                        </div>
                                    </div>
                                    <div className="planHeadValueContainer">
                                        <div className="planHead">
                                            <p>Key and Lock Replacement</p>
                                        </div>
                                        <div className="result_pc">
                                            <img src={fail} alt="" srcSet="" />
                                        </div>
                                    </div>
                                    <div className="planHeadValueContainer">
                                        <div className="planHead">
                                            <p>Invoice Cover</p>
                                        </div>
                                        <div className="result_pc">
                                            <img src={success} alt="" srcSet="" />
                                        </div>
                                    </div>
                                    <div className="planHeadValueContainer">
                                        <div className="planHead">
                                            <p>24x7 Roadside Assistance</p>
                                        </div>
                                        <div className="result_pc">
                                            <img src={success} alt="" srcSet="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-card-btn">
                                    <p>Get a Quote</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* HOW TO BUY CAR INSURANCE */}

            <div className="content-container-prod-insurance comparison-container-prod-insurance">
                <div className="content-container-prod-insurance-parent maxWidth">
                    <h1 className="title-product-ins">
                        How to buy Comprehensive Car Insurance <span>from us</span>
                    </h1>
                    <div className="timeline-container-and-lady">
                        <div className="lady-container-prod-pg">
                            <div className="lady-box-prod-pg">
                                <img src={lady} alt="" srcSet="" />
                            </div>
                        </div>
                        <div className="timeline-container-prod-pg">
                            <Timeline timelineElements={timelineElements} />
                        </div>
                    </div>
                </div>
            </div>

            {/* CALCULATE PREMIUM  */}

            <div className="content-container-prod-insurance prem-calc-container-prod-insurance">
                <div className="content-container-prod-insurance-parent maxWidth">
                    <div className="mobile-container-with-content mobile-container-with-content-two ">
                        <div className="content-container-prod-pg content-container-prod-pg-two ">
                            <div className="hero-content-product-ins w2b-content-product-ins">
                                <h1 className="title-product-ins">
                                    How to calculate <span> Car Insurance Premium </span>
                                </h1>
                                <p className="bold-description-calc">
                                    Car insurance companies use online calculators to estimate
                                    premium amounts based on various factors.
                                </p>
                                <p>
                                    You provide personal details, driving history, vehicle
                                    information, usage patterns, and location. <br />
                                    Then, you choose coverage options and input applicable
                                    discounts. The calculator processes this data through an
                                    algorithm to generate an estimated premium quote. <br />
                                    It's essential to remember that this quote serves as an
                                    estimate, and the final premium may vary depending on further
                                    underwriting and insurer-specific factors. Comparing quotes
                                    from multiple insurers helps you find the most suitable and
                                    cost-effective coverage.
                                </p>
                                <p className="bold-description-calc">
                                    Car insurance premiums are estimated using online calculators,
                                    considering:
                                </p>
                                <div className="checklist-calc-container-parent">
                                    {checklist.map((text, key) => (
                                        <div key={key} className="checklist-calc-container">
                                            <img src={success} alt="" srcSet="" />
                                            <p>{text}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="mob-container-prod-pg">
                            <div className="calc-gradient-prod-pg">
                                <img src={calculator} alt="" srcSet="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* LIST- COVERED & NOT_COVERED */}

            <div className="content-container-prod-insurance comparison-container-prod-insurance">
                <div className="content-container-prod-insurance-parent covered-container-prod-insurance-parent maxWidth">
                    <div className="covered-container-compinspolicy">
                        <h1 className="title-product-ins">
                            What is Covered <span>in Comprehensive Car Insurance </span>
                        </h1>
                        <div className="covered-rect-comp-ins">
                            <div className="covered-point-comp-ins">
                                <div className="p-tag-container-compins">
                                    <div className="points-comp-ins">
                                        <div className="success-icon">
                                            <img src={success} alt="" srcSet="" />
                                        </div>
                                        <p className="point-title-comp-ins">
                                            Physical Damage to Your Vehicle:&nbsp;
                                            <span>
                                                Coverage for damage caused by theft, vandalism, fire,
                                                natural disasters (e.g., hail, floods), falling objects
                                                (e.g., tree branches), and collisions with animals
                                                (e.g., deer).
                                            </span>
                                        </p>
                                    </div>
                                    <div className="points-comp-ins">
                                        <div className="success-icon">
                                            <img src={success} alt="" srcSet="" />
                                        </div>
                                        <p className="point-title-comp-ins">
                                            Glass Damage:&nbsp;
                                            <span>
                                                Repair or replacement of broken or damaged glass,
                                                including windshields.
                                            </span>
                                        </p>
                                    </div>
                                    <div className="points-comp-ins">
                                        <div className="success-icon">
                                            <img src={success} alt="" srcSet="" />
                                        </div>
                                        <p className="point-title-comp-ins">
                                            Theft and Stolen Parts:&nbsp;
                                            <span>
                                                Protection against the theft of your vehicle or any
                                                parts and accessories stolen from it.
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="covered-container-compinspolicy">
                        <h1 className="title-product-ins">
                            What is Not covered <span> Comprehensive Car Insurance </span>
                        </h1>
                        <div className="covered-rect-comp-ins">
                            <div className="covered-point-comp-ins">
                                <div className="p-tag-container-compins">
                                    <div className="points-comp-ins">
                                        <div className="success-icon">
                                            <img src={success} alt="" srcSet="" />
                                        </div>
                                        <p className="point-title-comp-ins">
                                            Collision Damage:&nbsp;
                                            <span>
                                                Damage to your vehicle resulting from a collision with
                                                another vehicle or object (covered by collision
                                                insurance).
                                            </span>
                                        </p>
                                    </div>
                                    <div className="points-comp-ins">
                                        <div className="success-icon">
                                            <img src={success} alt="" srcSet="" />
                                        </div>
                                        <p className="point-title-comp-ins">
                                            Medical Expenses:&nbsp;
                                            <span>
                                                Personal injury or medical expenses for you or your passengers (covered by personal injury protection or medical payments coverage).
                                            </span>
                                        </p>
                                    </div>
                                    <div className="points-comp-ins">
                                        <div className="success-icon">
                                            <img src={success} alt="" srcSet="" />
                                        </div>
                                        <p className="point-title-comp-ins">
                                            Liability Coverage:&nbsp;
                                            <span>
                                                Coverage for damage or injuries you cause to others in an accident (covered by liability insurance)
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* partners section */}

            <div className="content-container-prod-insurance ">
                <div className="content-container-prod-insurance-parent maxWidth">
                    <h1 className="title-product-ins">
                        Top Car Insurance Companies in <span> India</span>
                    </h1>
                    <div className="partner-container-prod-pg">
                        <Partners
                            firstLot={firstLot}
                            secondLot={secondLot}
                            hideTitle={true}
                            noPadding="noPadding"
                            component="ComprehensiveCarIns"
                        />
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default ComprehensiveCarIns;
