import React, { useState } from "react";
import "./ProposalForm.css";
import Botton from "../Button/Button";
import RadioButton from "../RadioButton/RadioButton";
import InputFields from "../InputFields/InputFields";
import DropDown from "../DropDown/DropDown";
import grayArrow from "../../Assets/Images/grayArrow.svg";
import backIcon from "../../Assets/Images/backIcon.svg";
import { useSelector } from "react-redux";
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader";


const Inspection = (props) => {
  const [ShowStateDropdown, setShowStateDropdown] = useState(false);

  const {
    HandleInputChange,
    handleSubmitForm,
    handleCityDropdown,
    ShowCityDropdown,
    handleBackBtn,
    emailValue,
    phoneValue,
    formData,
    stateList,
    cityList,
    state,
    city,
    pincode,
    addressOne,
    addressTwo,
    Edit,
    type,
  } = props;

  const handlePopUps = () => {
    if (ShowStateDropdown) {
      setShowStateDropdown(false);
    }
  };

  let transactionStatus = useSelector((state) => {
    return state.getTransactionStatus;
  });

  return (
    <div>

      {/* <p
        className="mobile-back-fmc mobile-back-pform"
        onClick={() => handleBackBtn("InspectionDetails")}
      >
        <img src={backIcon} alt="" srcSet="" /> Back to Kyc Details
      </p> */}

      <div className="titleContainer titleContainer_pform ">
        <p>Inspection Details</p>
        <p>All fields are mandatory</p>
      </div>

      <form className="formSection-pform" action="">
        <div className="input_group_pform input_group_pform-loan ">
          <div className="inputGropOne_form inputGrop inputGrop_caseTwo  ">
            <p className="customLabel_pform">
              Do you want to do self inspection for your car?
            </p>
            <div className="radioContainer-pform">
              <RadioButton
                label="Yes"
                className=""
                id="isSelfInspectionYes"
                groupName="isSelfInspection"
                value="Yes"
                changeEvent={HandleInputChange}
              />
              <RadioButton
                label="No"
                className=""
                id="isSelfInspectionNo"
                groupName="isSelfInspection"
                value="No"
                changeEvent={HandleInputChange}
              />
            </div>
          </div>
          <div className="inputGropOne_form inputGrop inputGrop_caseTwo "></div>
        </div>

        {formData?.isSelfInspection === "Yes" && (
          <div className="input_group_pform">
            <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
              <p className="customLabel_pform">
                Confirm your Email Id and Phone number
              </p>
              <div className="">
                <InputFields
                  value={emailValue}
                  placeholder="Email ID"
                  id="email"
                  type="email"
                  name="email"
                  onChangeEvent={(e) => HandleInputChange(e)}
                />
              </div>
            </div>
            <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
              <p className="customLabel_pform"> &nbsp; </p>
              <InputFields
                value={phoneValue}
                placeholder="Phone Number"
                id="phoneNumber"
                type="number"
                name="phoneNumber"
                onChangeEvent={(e) => HandleInputChange(e)}
              />
            </div>
          </div>
        )}

        {formData?.isSelfInspection === "No" && (
          <form className="formSection-pform" action="" onClick={handlePopUps} onSubmit={(e) => { e.preventDefault(); }}>
            <div className="input_group_pform">
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={addressOne}
                  placeholder="Address Line 1"
                  id="addressOne"
                  type="text"
                  name="addressOne"
                  onChangeEvent={(e) => HandleInputChange(e)}
                />
              </div>
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={addressTwo}
                  placeholder="Address Line 2"
                  id="addressTwo"
                  type="text"
                  name="addressTwo"
                  onChangeEvent={(e) => HandleInputChange(e)}
                />
              </div>
            </div>

            <div className="input_group_pform">
              <div className="inputGropOne_form inputGrop ">
                <DropDown
                  type="submit"
                  dropDownMenu={stateList}
                  handleChangeInput={(e) => HandleInputChange(e)}
                  value={state}
                  name="state"
                  placeHolder="State"
                  arrowInGray={grayArrow}
                  Head="State"
                  classNameForHead="headTxtClassPform"
                  inputId="state"
                  clickEvent={(e) => console.log(e)}
                  headClick={(e) => setShowStateDropdown(!ShowStateDropdown)}
                  revealOptions={ShowStateDropdown}
                  afterTrueClass="dropDown_container NoBorderRadius"
                  className="dropDown_container"
                  valueSelectionEvent={HandleInputChange}
                  revealOptionsClasses=" dropDownOptions showdropDownOptions "
                  classNameTwo="dropDownOptions"
                  dropDownHeadClass="dropDownHead"
                  idForHead="stateHead"
                  idForHeadTxt="stateHeadTxt"
                  idForArrow="stateHeadArrow"
                  dropdownId="stateListDp"
                  idForParentObj="stateParentDp"
                />
              </div>
              <div className="inputGropOne_form inputGrop ">
                <DropDown
                  type="submit"
                  dropDownMenu={cityList}
                  handleChangeInput={(e) => HandleInputChange(e)}
                  value={city}
                  name="city"
                  arrowInGray={grayArrow}
                  inputId="city"
                  placeHolder="City"
                  Head="City"
                  classNameForHead="headTxtClassPform"
                  clickEvent={(e) => console.log(e)}
                  headClick={(e) => handleCityDropdown(e)}
                  revealOptions={ShowCityDropdown}
                  afterTrueClass="dropDown_container NoBorderRadius"
                  className="dropDown_container"
                  valueSelectionEvent={HandleInputChange}
                  revealOptionsClasses=" dropDownOptions showdropDownOptions "
                  classNameTwo="dropDownOptions"
                  dropDownHeadClass="dropDownHead"
                  idForHead="cityHead"
                  idForHeadTxt="cityHeadTxt"
                  idForArrow="cityHeadArrow"
                  dropdownId="cityListDp"
                  idForParentObj="cityParentDp"
                />
              </div>
            </div>

            <div className="input_group_pform">
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={pincode}
                  placeholder="Pin Code"
                  id="pincode"
                  type="number"
                  name="pincode"
                  onChangeEvent={(e) => HandleInputChange(e)}
                />
              </div>
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={formData?.alternatePhoneNumber}
                  placeholder="Alternate Phone Number"
                  id="alternatePhoneNumber"
                  type="number"
                  name="alternatePhoneNumber"
                  onChangeEvent={(e) => HandleInputChange(e)}
                />
              </div>
            </div>
          </form>
        )}

        <section className="btnContainer_pform">
          <Botton
            text="Back"
            className="button btn_pForm  secondry_btn"
            event={(e) => handleBackBtn("InspectionDetails")}
          />
          {transactionStatus?.isLoading ?
            <ButtonLoader className="btn_pForm" />
            : <Botton type="submit"
              text={
                formData?.isSelfInspection === "Yes" ? "Send Link" : "Proceed"
              }
              className="button btn_pForm  "
              event={(e) => handleSubmitForm("InspectionDetails")}
            />}
        </section>
      </form>
    </div>
  );
};

export default Inspection;
