import React, { useState, useEffect } from 'react'
// import './IntimateClaim.scss'
import arrow from "../../../../Assets/Images/arrow.svg";
import Navbar from '../../../Navbar/Navbar'
import IncidentDetail from './IncidentDetail';
import VehicleDetails from './VehicleDetails';
import UploadDocument from './uploadDocument';

const IntimateClaim = (props) => {

    const [showDetailsOf, setshowDetailsOf] = useState("incidentDetails"); //vehicleDetails documentDetails

    const toggleColapsible = (menuName) => {
        if (menuName === showDetailsOf) {
            setshowDetailsOf("");
        } else {
            setshowDetailsOf(menuName);
        }
    };

    let claimInputValues = {
        driver: null,
        incidentDate: null,
        incidentTime: null,
        incidentArea: null,
        incidentState: null,
        incidentCity: null,
        incidentPincode: null,
        claimReason: null,
        claimReasonCategory: null,
        lossDescription: null,
        policyNumber: null,
        policyInsurer: null,
        chassisNumber: null,
        engineNumber: null,
        carIsInLoan: null,
        carHypothecationCompany: null,
        DrivingLicence: null,
        rcCopy: null,
        isAccidentSpotPhotos: null,
        accidentPhotos: null,
        selectedGarage: null,
    }

    const [ClaimFormValues, setClaimFormValues] = useState(claimInputValues);

    const handleFormValue = (e) => {
        const { id, value, name, checked } = e?.target || {};
        setClaimFormValues((prevItems) => ({
            ...prevItems,
            [name]: value,
        }))
    }

    const handleStateSelect = (e) => {
        setClaimFormValues((prevItems) => ({
            ...prevItems,
            incidentState: e.value,
        }))
    }

    const handleCitySelect = (e) => {
        setClaimFormValues((prevItems) => ({
            ...prevItems,
            incidentCity: e.value,
        }))
    }

    const handleInsurerSelect = (e) => {
        setClaimFormValues((prevItems) => ({
            ...prevItems,
            policyInsurer: e.value,
        }))
    }

    return (
        <>
            <Navbar />
            <section className='intimate-claim-container' >
                <div className="intimate-claim-container-parent maxWidth">

                    <div className="product-insurer-container">
                        <p>987654321 - Private Car Policy Insurance</p>
                        <p>HDFC Ergo</p>
                    </div>

                    {/* incidentDetails */}

                    <div className="collapsible_container">
                        <div className="collapsible_head_content">
                            <div
                                className={showDetailsOf === "incidentDetails" ? "collapsible_head collapsible_head_withBorder " : "collapsible_head"}
                                onClick={(e) => toggleColapsible("incidentDetails")}
                            >
                                <p className="collapsible_head_title">Incident Details</p>
                                <img src={arrow} className='arrow-collapsible-down' alt="" srcSet="" />
                            </div>
                            <div className={showDetailsOf === "incidentDetails" ? "collapsible_content show_collapsible_content " : "collapsible_content"}>
                                <IncidentDetail
                                    ClaimFormValues={ClaimFormValues}
                                    handleFormValue={handleFormValue}
                                    handleStateSelect={handleStateSelect}
                                    handleCitySelect={handleCitySelect}
                                    toggleColapsible={toggleColapsible}
                                />
                            </div>
                        </div>

                        {/*  vehicleDetails */}

                        <div className="collapsible_head_content">
                            <div
                                className={
                                    showDetailsOf === "vehicleDetails"
                                        ? "collapsible_head collapsible_head_withBorder "
                                        : "collapsible_head"
                                }
                                onClick={(e) => toggleColapsible("vehicleDetails")}
                            >
                                <p className="collapsible_head_title">Vehicle Details</p>
                                <img src={arrow} className='arrow-collapsible-down' alt="" srcSet="" />
                            </div>
                            <div
                                className={
                                    showDetailsOf === "vehicleDetails"
                                        ? "collapsible_content show_collapsible_content "
                                        : "collapsible_content"
                                }
                            >
                                <VehicleDetails
                                    ClaimFormValues={ClaimFormValues}
                                    handleFormValue={handleFormValue}
                                    toggleColapsible={toggleColapsible}
                                    handleInsurerSelect={handleInsurerSelect}
                                />
                            </div>
                        </div>

                        <div className="collapsible_head_content">
                            <div
                                className={
                                    showDetailsOf === "documentDetails"
                                        ? "collapsible_head collapsible_head_withBorder "
                                        : "collapsible_head"
                                }
                                onClick={(e) => toggleColapsible("documentDetails")}
                            >
                                <p className="collapsible_head_title">Documents for Claim Intimate</p>
                                <img src={arrow} className='arrow-collapsible-down' alt="" srcSet="" />
                            </div>
                            <div
                                className={
                                    showDetailsOf === "documentDetails"
                                        ? "collapsible_content show_collapsible_content "
                                        : "collapsible_content"
                                }
                            >
                                <UploadDocument
                                    ClaimFormValues={ClaimFormValues}
                                    handleFormValue={handleFormValue}
                                    toggleColapsible={toggleColapsible}
                                    handleInsurerSelect={handleInsurerSelect}
                                />
                            </div>
                        </div>

                        <div className="collapsible_head_content">
                            <div
                                className={
                                    showDetailsOf === "garagesDetails"
                                        ? "collapsible_head collapsible_head_withBorder "
                                        : "collapsible_head"
                                }
                                onClick={(e) => toggleColapsible("garagesDetails")}
                            >
                                <p className="collapsible_head_title">Select Garages</p>
                                <img src={arrow} className='arrow-collapsible-down' alt="" srcSet="" />
                            </div>
                            <div
                                className={
                                    showDetailsOf === "garagesDetails"
                                        ? "collapsible_content show_collapsible_content "
                                        : "collapsible_content"
                                }
                            >

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IntimateClaim
