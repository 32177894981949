import React, { useState, useEffect } from "react";
import InputFields from "../InputFields/InputFields";
import { useSelector } from "react-redux";
import Botton from "../Button/Button";
import Modal from "react-modal";
import CheckBox from "../Checkbox/CheckBox";
import grayArrow from "../../Assets/Images/grayArrow.svg";
import blueArrow from "../../Assets/Images/arrowUp.svg"
import RadioButton from "../RadioButton/RadioButton";

const AddonContainer = (props) => {
  const {
    dataList,
    ShowAccesoriesField,
    ShowPcSumInsuredField,
    handleApplyClick,
    setElectricalAmt,
    ElectricalAmt,
    setNonElectricalAmt,
    NonElectricalAmt,
    setPassengerCoverAmt,
    PassengerCoverAmt,
    errors,
    handleCancelPassenger,
    cancelAccessoriesField,
    isMobileMode,
    newCheckBoxData,
    handleCheckboxChange,
    AddonsForMobile,
    updateCoveragesClone,
    setAddonsForMobile,
    ShowNilDepModal,
    ShowEngProtectModal,
    setShowNilDepModal,
    ShowRtiModal,
    setShowEngProtectModal,
    handleInputCarDetails,
    carDetails,
    setShowRtiModal,
    setShowPackageModal,
    setShowDigPackageModal,
    setShowICICIPackageModal,
    setIsActiveFromPlanCard,
    IsActiveFromPlanCard,
    setCarDetails,
    showAddonsForMobile,
  } = props;

  const [ActiveTab, setActiveTab] = useState("checkbox1");

  const customStyles = {
    content: window.innerWidth <= 610
      ? {
        top: "auto",
        left: "0",
        right: "0",
        bottom: "0",
        margin: "0",
        transform: "none",
        borderRadius: "10px 10px 0 0",
        padding: "20px",
        height: "auto",
      }
      : {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "10px",
        padding: "20px",
        height: "auto",
      },
  };

  Modal.setAppElement("#root");

  const handleDescription = (id) => {
    if (id === ActiveTab) {
      setActiveTab("")
    } else {
      setActiveTab(id);
    }
  };

  const HandleFakeFun = (() => {
    console.log("Select your Plan Buddy")
  })

  useEffect(() => {
    if (carDetails?.isNilDepInPrevPolicy === "false") {
      setCarDetails((prevItems) => ({
        ...prevItems,
        quotePackageName: ""
      }))
    }
  }, [carDetails?.isNilDepInPrevPolicy])

  const CompanyName = useSelector((state) => {
    return state.companyName?.data?.companyName;
  });

  const handlePackageModal = () => {
    if (CompanyName == "tata") {
      // setShowPackageModal(true);
      if (carDetails?.isNilDepInPrevPolicy === "true") {
        setShowRtiModal(true);
      } else {
        setShowPackageModal(true);
      }
    } else if (CompanyName == "icici") {
      setShowICICIPackageModal(true);
      setIsActiveFromPlanCard(false);
    } else {
      setShowDigPackageModal(true);
      setIsActiveFromPlanCard(false);
    }

    setShowNilDepModal(false);
  }
  console.log(IsActiveFromPlanCard, "IsActiveFromPlanCard");


  return (
    <>
      {!isMobileMode ? (
        <div
          className={`addonContainer__ ${ShowPcSumInsuredField || ShowAccesoriesField
            ? "disable-container"
            : ""
            }`}
        >
          {dataList.map((item, key) => (
            <div key={key}> {item} </div>
          ))}
        </div>
      ) : (
        <div className="mobile-addon-container" >
          {newCheckBoxData?.map((data, key) => (
            <>
              <div className="accordian-checkbox">
                <div className="accordian-checkbox-head">
                  <div className="arrow-icon-container" onClick={() => handleDescription(data.id)} >
                    <img src={ActiveTab === data.id ? blueArrow : grayArrow} />
                  </div>
                  <CheckBox
                    label={
                      data.label === "Mandate by IRDAI"
                        ? "PA Cover"
                        : data.label
                    }
                    name={data.name}
                    value={data.value}
                    className={ActiveTab === data.id ? "activeLabel" : "inActiveLabel"}
                    changeEvent={handleCheckboxChange}
                    checked={data.checked}
                    id={data.id}
                    isHidden={AddonsForMobile ? true : false}
                    tooltip={data.tooltip || "No Data"}
                  />
                </div>
                <div
                  className={
                    ActiveTab === data.id
                      ? "accordian-checkbox-content accordian-checkbox-content-active"
                      : "accordian-checkbox-content"
                  }
                >
                  <p> {data.tooltip} </p>
                </div>
              </div>
            </>
          ))}
          <div className="btn-accordian-checkbox">
            {/* <Botton
              text="Cancel"
              className="secondry_btn checkbox-modal-btn"
              event={(e) => setAddonsForMobile(false)}
            /> */}
            <Botton
              text="Apply"
              className="button checkbox-modal-btn"
              event={(e) => showAddonsForMobile("hide")}
            />
          </div>
        </div>
      )}

      {/* PASSENGER POP UP */}

      <Modal
        isOpen={ShowPcSumInsuredField}
        style={customStyles}
        contentLabel="Example Modal"
        id=""
      >
        <div className="accesoriesContainer-fields sumInsuredContainer-fields">
          <div className="sum-insured-container">
            <p className="fields-accesories-title">Select Passenger Coverage</p>
            <div className="sum-insured-btns">
              <Botton
                text="₹ 10000"
                value="10000"
                name="pcCoverSumInsured"
                className={
                  PassengerCoverAmt === "10000"
                    ? "btn-sumInsurd btn-sumInsurd-active"
                    : "btn-sumInsurd"
                }
                event={(e) => setPassengerCoverAmt("10000")}
              />
              <Botton
                text="₹ 50000"
                value="50000"
                name="pcCoverSumInsured"
                className={
                  PassengerCoverAmt === "50000"
                    ? "btn-sumInsurd btn-sumInsurd-active"
                    : "btn-sumInsurd"
                }
                event={(e) => setPassengerCoverAmt("50000")}
              />
              <Botton
                text="₹ 100000"
                value="100000"
                name="pcCoverSumInsured"
                className={
                  PassengerCoverAmt === "100000"
                    ? "btn-sumInsurd btn-sumInsurd-active"
                    : "btn-sumInsurd"
                }
                event={(e) => setPassengerCoverAmt("100000")}
              />
              <Botton
                text="₹ 200000"
                value="200000"
                name="pcCoverSumInsured"
                className={
                  PassengerCoverAmt === "200000"
                    ? "btn-sumInsurd btn-sumInsurd-active"
                    : "btn-sumInsurd"
                }
                event={(e) => setPassengerCoverAmt("200000")}
              />
            </div>
            {errors.passengerCover ? (
              <p className="err-msg">{errors.passengerCover}</p>
            ) : (
              ""
            )}
          </div>

          <div className="fields-accesories-btn-container">
            <Botton
              text="Cancel"
              name="pcCoverSumInsured"
              className="btn-sumInsurd-apply secondry_btn"
              event={handleCancelPassenger}
            />
            <Botton
              text="Apply"
              name="pcCoverSumInsured"
              className="btn-sumInsurd-apply button"
              event={(e) => handleApplyClick(e, "PassengerCover")}
            />
          </div>
        </div>
      </Modal>

      {/* ACCESSORIES POP UP */}

      <Modal
        isOpen={ShowAccesoriesField}
        style={customStyles}
        contentLabel="Example Modal"
        id=""
      >
        <form action="" onSubmit={(e) => { e.preventDefault(); }} className="accesoriesContainer-fields">
          <div className="fields-accesories">
            <p className="fields-accesories-title">
              Enter electrical accessories value
            </p>
            <div className="inputGroup-accesories">
              <InputFields
                placeholder="Enter Amount"
                type="number"
                value={ElectricalAmt}
                name="electricalAccesoriesCopy"
                onChangeEvent={(e) => setElectricalAmt(e.target.value)}
              />
              <p>
                Cover your car's extra fitted electrical accessories like
                stereo, fog lamps, buzzers etc
              </p>
            </div>
          </div>

          <div className="fields-accesories">
            <p className="fields-accesories-title">
              Enter non-electrical accessories value
            </p>
            <div className="inputGroup-accesories">
              <InputFields
                placeholder="Enter Amount"
                type="number"
                value={NonElectricalAmt}
                name="nonElectricalAccesoriesCopy"
                onChangeEvent={(e) => setNonElectricalAmt(e.target.value)}
              />
              <p>
                Cover your car's extra fitted non-electrical accessories like
                roof rack, wheel cover, etc
              </p>
            </div>
            {errors.accesories ? (
              <p className="err-msg">{errors.accesories}</p>
            ) : (
              ""
            )}
          </div>

          <div className="fields-accesories-btn-container fields-accesories-btn-container-width">
            <Botton
              text="Cancel"
              className="button btn-accesories secondry_btn"
              event={cancelAccessoriesField}
            />
            <Botton
              text="Apply"
              type="submit"
              className="button btn-accesories"
              event={(e) => handleApplyClick(e, "Accessories")}
            />
          </div>
        </form>
      </Modal>

      {/* Zero Dep Pop-Up */}

      <Modal
        isOpen={ShowNilDepModal}
        style={customStyles}
        contentLabel="Example Modal"
        id=""
      >
        <div className="addon-modal-container">
          <div className="head-addon-modal">
            <p>Zero Depreciation Cover</p>
          </div>
          <p>Did you select zero depreciation cover in your previous policy?</p>
          <RadioButton
            label="Yes"
            className=""
            id="YesZerDep"
            groupName="isNilDepInPrevPolicy"
            value={true}
            changeEvent={(e) => handleInputCarDetails(e)}
            modalBtn={true}
            isChecked={carDetails?.isNilDepInPrevPolicy === "true" ? true : false}
          />
          <RadioButton
            label="No"
            className=""
            id="NoZerDep"
            groupName="isNilDepInPrevPolicy"
            value={false}
            changeEvent={(e) => handleInputCarDetails(e)}
            modalBtn={true}
            isChecked={carDetails?.isNilDepInPrevPolicy === "false" ? true : false}
          />
          <section className="btnContainer_pform btnContainer_pform-edit">
            <Botton
              text="Cancel"
              className="button btn_pForm  secondry_btn"
              event={() => setShowNilDepModal(false)}
            />
            {
              (IsActiveFromPlanCard) ? (
                <Botton
                  text={!carDetails?.isNilDepInPrevPolicy ? "Select" : "Save"}
                  className={!carDetails?.isNilDepInPrevPolicy ? "button btn_pForm noSelectedPlan" : "button btn_pForm"}
                  event={carDetails?.isNilDepInPrevPolicy ? handlePackageModal : HandleFakeFun}
                />
              ) : (
                <Botton
                  text={!carDetails?.isNilDepInPrevPolicy ? "Select" : "Save"}
                  className={!carDetails?.isNilDepInPrevPolicy ? "button btn_pForm noSelectedPlan" : "button btn_pForm"}
                  event={() => carDetails?.isNilDepInPrevPolicy ? setShowNilDepModal(false) : HandleFakeFun}
                />
              )
            }
          </section>
        </div>
      </Modal>

      {/* Eng Protect Pop-Up */}



      <Modal
        isOpen={ShowEngProtectModal}
        style={customStyles}
        contentLabel="Example Modal"
        id=""
      >
        <div className="addon-modal-container">
          <div className="head-addon-modal">
            <p>Engine Protection Cover</p>
          </div>
          <p>Did you select Engine Protection cover in your previous policy?</p>
          <RadioButton
            label="Yes"
            className=""
            id="YesEngProtect"
            groupName="isEngProtectInPrevPolicy"
            value={true}
            modalBtn={true}
            changeEvent={(e) => handleInputCarDetails(e)}
          // isChecked={formData?.isNilDepInPrevPolicy === "true" ? true : false}
          />
          <RadioButton
            label="No"
            className=""
            id="NoEngProtect"
            groupName="isEngProtectInPrevPolicy"
            value={false}
            modalBtn={true}
            changeEvent={(e) => handleInputCarDetails(e)}
          // isChecked={formData?.isNilDepInPrevPolicy === "true" ? true : false}
          />

          <section className="btnContainer_pform btnContainer_pform-edit">
            <Botton
              text="Cancel"
              className="button btn_pForm  secondry_btn"
              event={() => setShowEngProtectModal(false)}
            />
            <Botton
              text={!carDetails?.isEngProtectInPrevPolicy ? "Select" : "Save"}
              className={!carDetails?.isEngProtectInPrevPolicy ? "button btn_pForm noSelectedPlan" : "button btn_pForm"}
              event={() => setShowEngProtectModal(false)}
            />
          </section>

        </div>

      </Modal>
      {/* Return to invoice cover */}

      <Modal
        isOpen={ShowRtiModal}
        style={customStyles}
        contentLabel="Example Modal"
        id=""
      >
        <div className="addon-modal-container">
          <div className="head-addon-modal">
            <p>Return To Invoice</p>
          </div>
          <p>Did you select Invoice cover in your previous policy?</p>
          <RadioButton
            label="Yes"
            className=""
            id="YesRti"
            groupName="isInvoiceCoverInPrevPolicy"
            value={true}
            modalBtn={true}
            changeEvent={(e) => handleInputCarDetails(e)}
            isChecked={carDetails?.isInvoiceCoverInPrevPolicy === "true" ? true : false}
          />
          <RadioButton
            label="No"
            className=""
            id="NoRti"
            groupName="isInvoiceCoverInPrevPolicy"
            value={false}
            modalBtn={true}
            changeEvent={(e) => handleInputCarDetails(e)}
            isChecked={carDetails?.isInvoiceCoverInPrevPolicy === "false" ? true : false}
          />

          <section className="btnContainer_pform btnContainer_pform-edit">
            <Botton
              text="Cancel"
              className="button btn_pForm  secondry_btn"
              event={() => setShowRtiModal(false)}
            />
            {
              (IsActiveFromPlanCard) ? (
                <Botton
                  text={!carDetails?.isInvoiceCoverInPrevPolicy ? "Select" : "Save"}
                  className={!carDetails?.isInvoiceCoverInPrevPolicy ? "button btn_pForm noSelectedPlan" : "button btn_pForm"}
                  event={() => { setShowPackageModal(true); setIsActiveFromPlanCard(false); setShowRtiModal(false) }}
                />
              ) : (
                <Botton
                  text={!carDetails?.isInvoiceCoverInPrevPolicy ? "Select" : "Save"}
                  className={!carDetails?.isInvoiceCoverInPrevPolicy ? "button btn_pForm noSelectedPlan" : "button btn_pForm"}
                  event={() => setShowRtiModal(false)}
                />
              )
            }
          </section>

        </div>
      </Modal>
    </>
  );
};

export default AddonContainer;
