import React from 'react'
import './SkeletonLoader.css'
// import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const SkeletonLoader = (props) => {

    const { height, width, variant } = props;

    return (
        <>
            {/* variants of skeleton laoaders -------------------> */}
            {/* <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> */}
            {/* <Skeleton variant="circular" width={40} height={40} /> */}
            {/* <Skeleton variant="rounded" width={210} height={60} /> */}

            {/* using this dynamically */}
            <Skeleton variant={variant} width={width} height={height} />
        </>
    )
}

export default SkeletonLoader
