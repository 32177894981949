import { createAsyncThunk } from "@reduxjs/toolkit";

// Production Url Quick Quote
export const sbiQuickQuoteAction = createAsyncThunk(
  "fetchSbiData",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_SBI_QUICK_QUOTE_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);
// Production Url manual kyc
export const sbiKyc = createAsyncThunk("sbiKyc", async (bodyData) => {
  const response = await fetch(`${process.env.REACT_APP_SBI_KYC_MANUAL_URL}`, {
    method: "POST",
    body: JSON.stringify(bodyData),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  });
  return response.json();
});

// Production Url auto Kyc
export const sbiKycAuto = createAsyncThunk("sbiKycAuto", async (bodyData) => {
  const response = await fetch(`${process.env.REACT_APP_SBI_KYC_AUTO_URL}`, {
    method: "POST",
    body: JSON.stringify(bodyData),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  });
  return response.json();
});

// Production Url Proposal
export const sbiFullQuoteAction = createAsyncThunk(
  "sbiFullQuoteAction",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_SBI_FULL_QUOTE_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

// sbi policy issurance
export const sbiPolicyGenerateAction = createAsyncThunk(
  "sbiPolicyGenerateAction",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_SBI_POLICY_ISSURANCE_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

// sbi pdf download
export const sbiPdfDownloadAction = createAsyncThunk(
  "sbiPdfDownloadAction",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_SBI_PDF_DOWNLOAD_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);
