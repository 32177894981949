import { createSlice } from "@reduxjs/toolkit";

export const fmcContentType = createSlice({
    name: "fmcContentType",
    initialState: {
        data: {
            "activeContent": "Make"
        }
    },
    reducers: {
        isFmcContentAction: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { isFmcContentAction } = fmcContentType.actions;

// handleContentReveal