import { createAsyncThunk } from "@reduxjs/toolkit";

// Production Url storing payment req resp
export const paymentWebhookAction = createAsyncThunk(
  "paymentWebhookAction",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_PAYMENT_TXN_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const paymentSendURLAction = createAsyncThunk(
  "paymentSendURLAction",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_SEND_PAYMENT_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);