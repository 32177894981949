import React, { useState } from "react";
import "./Accordian.css";
import Botton from "../Button/Button";

const Accordian = (props) => {
  const { carQueries, bikeQueries, healthQueries, taxiQueries, content, screen } = props;

  const [selected, setSelected] = useState(0);
  const [selectedMenu, setselectedMenu] = useState("car");
  const [showContent, setshowContent] = useState(screen === "claim" ? [...content] : [...carQueries]);

  const toggleContent = (index) => {
    if (index === selected) {
      setSelected("");
    } else {
      setSelected(index);
    }
  };

  const handleAccordianMenu = (menu) => {
    setselectedMenu(menu);
    setSelected(0);

    switch (menu) {
      case "car":
        setshowContent([...carQueries]);
        break;
      case "bike":
        setshowContent([...bikeQueries]);
        break;
      case "health":
        setshowContent([...healthQueries]);
        break;
      case "taxi":
        setshowContent([...taxiQueries]);
        break;
      default:
        setshowContent([]);
        break;
    }
  };

  return (
    <>
      <div className={screen !== "claim" ? "accordian_container_main" : ""}>
        <div className="accordian_container maxWidth">
          {
            screen !== "claim" && (
              <h1 className="title accordian_container_title">
                Frequently Asked <span>Questions</span>
              </h1>
            )
          }
          <div className={screen !== "claim" ? "accordian_parent_container" : ""}>

            {
              screen !== "claim" ? (
                <div className="accordian_container_nav">
                  <Botton
                    text="Car"
                    className={
                      selectedMenu === "car"
                        ? "accordian_button active_accordian_btn"
                        : "accordian_button"
                    }
                    name="accordian"
                    event={(e) => handleAccordianMenu("car")}
                  />
                  <Botton
                    text="Bike"
                    className={
                      selectedMenu === "bike"
                        ? "accordian_button active_accordian_btn"
                        : "accordian_button"
                    }
                    name="accordian"
                    event={(e) => handleAccordianMenu("bike")}
                  />
                  <Botton
                    text="Health"
                    className={
                      selectedMenu === "health"
                        ? "accordian_button active_accordian_btn"
                        : "accordian_button"
                    }
                    name="accordian"
                    event={(e) => handleAccordianMenu("health")}
                  />
                  <Botton
                    text="Taxi"
                    className={
                      selectedMenu === "taxi"
                        ? "accordian_button active_accordian_btn"
                        : "accordian_button"
                    }
                    name="accordian"
                    event={(e) => handleAccordianMenu("taxi")}
                  />
                </div>
              ) : null
            }

            <div className="accordian">
              {showContent.map((item, index) => (
                <div key={index} className="accordian_item">
                  <div
                    className="accordian_title"
                    onClick={() => toggleContent(index)}
                  >
                    <h2 className="title accordian_title_text ">
                      {item.question}
                    </h2>
                    <span>{selected === index ? "-" : "+"}</span>
                  </div>
                  <div
                    className={
                      selected === index
                        ? "accordian_content accordian_content_show"
                        : "accordian_content"
                    }
                  >
                    {item.answer}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accordian;
