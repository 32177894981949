import React from "react";
import "./Timeline.css";

const Timeline = (props) => {
    const { timelineElements } = props;
    return (
        <>
            <div className="vertical-timeline">
                <div className="timeline-line"></div>
                {timelineElements.map((data, key) => (
                    <div key={key} className="timeline-item">
                        <div className="circle">{data.id}</div>
                        <div className="timeline-content">
                            <img
                                src={data.icon}
                                className={
                                    (data.id == 1 && data.title !== "Assessment of Needs:")
                                        ? "small-timeline-icon"
                                        : data.id == 2 || data.id == 4
                                            ? "small-timeline-icon-two"
                                            : ""
                                }
                                alt=""
                                srcSet=""
                            />
                            <div>
                                <p className="title-timeline">{data.title}</p>
                                <p className="desc-timeline">{data.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Timeline;
