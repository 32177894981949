import { createSlice } from "@reduxjs/toolkit";

export const bodyDataReducer = createSlice({
  name: "bodyData",
  initialState: {},
  reducers: {
    allBodyDataAction: (state, action) => {
      state.data = action.payload;
    },
    resetBodyDataAction: (state, action) => {
      state.data = undefined
    }
  },
});

export const { allBodyDataAction, resetBodyDataAction } = bodyDataReducer.actions;

export const storePreviousData = createSlice({
  name: "storePreviousData",
  initialState: {},
  reducers: {
    previousfilenameAction: (state, action) => {
      state.data = action.payload;
    },
    resetpreviousfilenameAction: (state, action) => {
      state.data = undefined
    }
  },
});
export const { previousfilenameAction, resetpreviousfilenameAction } = storePreviousData.actions;

