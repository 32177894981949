import { createAsyncThunk } from "@reduxjs/toolkit";

export const shareLinkAction = createAsyncThunk(
  "shareLink",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_SHARE_EMAIL_UR}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

// Phone NumberValidation
export const phonevalidationAction = createAsyncThunk(
  "phonevalidation",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_IS_PHONEVALID_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);