import {
  rahejaGetIdvAction,
  rahejaGetTraceIdAction,
  rahejaPremiumCalculationAction,
  rahejaSaveQuoationAction,
  rahejaProposalCreationAction,
  rahejaGenerateTransactionNumberAction,
  rahejaGeneratePolicyNumberAction,
} from "../Actions/rahejaApiAction";
import { createSlice } from "@reduxjs/toolkit";

export const rahejaGetIdvReducer = createSlice({
  name: "rahejaGetIdv",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(rahejaGetIdvAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(rahejaGetIdvAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(rahejaGetIdvAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
});

export const rahejaPremiumCalculationReducer = createSlice({
  name: "rahejaPremiumCalculation",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(rahejaPremiumCalculationAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(
      rahejaPremiumCalculationAction.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(
      rahejaPremiumCalculationAction.rejected,
      (state, action) => {
        console.error("Error", action.payload);
        state.isError = true;
        state.isLoading = false;
      }
    );
  },
});

export const rahejaSaveQuoationReducer = createSlice({
  name: "rahejaSaveQuote",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(rahejaSaveQuoationAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(rahejaSaveQuoationAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(rahejaSaveQuoationAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
});

export const rahejaProposalCreationReducer = createSlice({
  name: "rahejaProposalCreation",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(rahejaProposalCreationAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(rahejaProposalCreationAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(rahejaProposalCreationAction.rejected, (state, action) => {
      console.errro("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
});

export const rahejaGenerateTransactionNumberReducer = createSlice({
  name: "rahejaGenerateTransationNumber",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(
      rahejaGenerateTransactionNumberAction.pending,
      (state, action) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      rahejaGenerateTransactionNumberAction.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(
      rahejaGenerateTransactionNumberAction.rejected,
      (state, action) => {
        console.error("Error", action.payload);
        state.isError = true;
        state.isLoading = false;
      }
    );
  },
});

export const rahejaGeneratePolicyNumberReducer = createSlice({
  name: "rahejaGeneratePolicyNumber",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(
      rahejaGeneratePolicyNumberAction.pending,
      (state, action) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      rahejaGeneratePolicyNumberAction.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(
      rahejaGeneratePolicyNumberAction.rejected,
      (state, action) => {
        console.error("Error", action.payload);
        state.isError = true;
        state.isLoading = false;
      }
    );
  },
});

export const rahejaGetTraceIdReducer = createSlice({
  name: "rahejaTraceId",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(rahejaGetTraceIdAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(rahejaGetTraceIdAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(rahejaGetTraceIdAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
});
