import { createAsyncThunk } from "@reduxjs/toolkit";

export const loginAction = createAsyncThunk(
  "loginData",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_LOGIN_URL}`,
    //   "https://gkaxpf3fw4.execute-api.ap-south-1.amazonaws.com/prod/prod_brilljust_send_verify_otp", //PROD
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const partnerPnaKycAction = createAsyncThunk(
  "partnerPnaKycData",
  async (panDobValue) => {
    const response = await fetch(
      `${process.env.REACT_APP_PARTNER_AUTOKYC_PAN}?pan=${panDobValue.pan}&dob=${panDobValue.dob}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);