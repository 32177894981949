import { Buffer } from "buffer";
const { PDFDocument } = require("pdf-lib");
const { pdfBuf } = require("./PdfBuffer")

export const combinePDFBuffers = async (inputPdfBuffer) => {
    try {
        const pdfFirstPg = await PDFDocument.load(Buffer.from(inputPdfBuffer, 'base64'));
        const policyPdf = await PDFDocument.load(Buffer.from(pdfBuf, 'base64'));

        const pagesArray = await policyPdf.copyPages(pdfFirstPg, pdfFirstPg.getPageIndices());

        for (const page of pagesArray) {
            policyPdf.addPage(page);
        }
        let policyBuffer = await policyPdf.save()
        var u8 = new Uint8Array(policyBuffer);
        var b64 = Buffer.from(u8).toString('base64');
        return b64 //.toString('base64');
    } catch (error) {
        console.log("errr", error)
    }
};