import React from "react";

const RangeSlider = (props) => {
  const { changeEvent, value, minValue, maxValue, name, className } = props;

  return (
    <>
      <div className={className ? className : "range-container"}>
        <input
          type="range"
          min={minValue}
          max={maxValue}
          value={value}
          name={name}
          className="rangeSlider"
          onChange={(e) => changeEvent(e)}
        />
        <div className="range-container-values">
          <p>{Math.round(minValue).toLocaleString()}</p>
          <p>{Math.round(maxValue).toLocaleString()}</p>
        </div>
      </div>
    </>
  );
};

export default RangeSlider;
