import React, { useState } from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = (props) => {

    const { name, event, value, status, checked } = props;

    return (
        <>
            <div>
                <label className="switch-container">
                    <input
                        type="checkbox"
                        checked={checked}
                        className="toggle-checkbox"
                        name={name}
                        onChange={(e) => event(e)}
                        hidden
                    />
                    <span className="slider-toggle">
                        <div className={status == true ? "slider-toggle-circle  slider-toggle-circle-active" : "slider-toggle-circle"} ></div>
                        <p className={status == true ? "toggle-status" : "toggle-status-off"}>
                            {status === true ? "On" : "Off"}
                        </p>
                    </span>
                </label>
            </div>
        </>
    );
};

export default ToggleSwitch;
