import { createSlice } from "@reduxjs/toolkit";

export const dealerDataReducer = createSlice({
    name: "dealerData",
    initialState: {},
    reducers: {
        dealerDataAction: (state, action) => {
            state.data = action.payload;
        },
        resetDealerDataAction: (state, action) => {
            state.data = undefined
        }
    },
});

export const { dealerDataAction, resetDealerDataAction } = dealerDataReducer.actions;
