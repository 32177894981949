import { createAsyncThunk } from "@reduxjs/toolkit";

export const zunoQuickQuoteAction = createAsyncThunk(
  "zunoQuikQuotes",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ZUNO_QUICK_QUOTE_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);
export const zunoFullQuoteAction = createAsyncThunk(
  "zunoFullQuotes",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ZUNO_PROPOSAL_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const zunoIssuePolicyAction = createAsyncThunk(
  "zunoIssuePolicy",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ZUNO_ISSUEPOLICY_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const zunoKycCustomerAction = createAsyncThunk(
  "zunoKycCustomer",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ZUNO_AUTO_KYC_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const zunoManualKycAction = createAsyncThunk(
  "zunoManualKyc",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ZUNO_MANUAL_KYC_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const zunoOnlinePaymentAction = createAsyncThunk(
  "zunoOnlinePayment",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ZUNO_ONLINEPAYMENT_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const zunoPdfDownloadAction = createAsyncThunk(
  "zunoPdfDownload",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ZUNO_PDF_DOWNLOAD_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const zunoInsertBodydAction = createAsyncThunk(
  "zunoInsertBody",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ZUNO_INSERTBODY_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);
