import React from 'react'
import InputFields from '../../../InputFields/InputFields';
import Botton from '../../../Button/Button';
import RadioButton from '../../../RadioButton/RadioButton';
import SearchSelect from '../../../DropDown/Search Select/SearchSelect';

const VehicleDetails = (props) => {

    const {
        ClaimFormValues,
        handleFormValue,
        toggleColapsible,
        handleInsurerSelect,
    } = props;

    const stateList = [
        { label: "Husband", value: "Husband" },
        { label: "Father", value: "Father" },
        { label: "Mother", value: "Mother" },
        { label: "Son", value: "Son" },
        { label: "Daughter", value: "Daughter" },
        { label: "Brother", value: "Brother" },
        { label: "Sister", value: "Sister" },
        { label: "Other", value: "Other" },
    ];

    return (
        <>
            <form className="formSection-pform  formSection-claimIntimation" action="" >

                <div className="input_group_pform">
                    <div className="inputGropOne_form inputGrop ">
                        <InputFields
                            value={ClaimFormValues.policyNumber}
                            placeholder="Policy Number"
                            id="policyNumber"
                            type="text"
                            name="policyNumber"
                            onChangeEvent={(e) => handleFormValue(e)}
                        />
                    </div>
                    <div className="inputGropOne_form inputGrop ">
                        <SearchSelect
                            optionList={stateList}
                            handleSelect={handleInsurerSelect}
                            value={ClaimFormValues.policyInsurer}
                            placeholder="Insurance Company"
                            label="Insurance Company"
                            name="policyInsurer"
                        />
                    </div>
                </div>

                <div className="input_group_pform">
                    <div className="inputGropOne_form inputGrop ">
                        <InputFields
                            value={ClaimFormValues.chassisNumber}
                            placeholder="Chassis Number"
                            id="chassisNumber"
                            type="text"
                            name="chassisNumber"
                            onChangeEvent={(e) => handleFormValue(e)}
                        />
                    </div>
                    <div className="inputGropOne_form inputGrop ">
                        <InputFields
                            value={ClaimFormValues.engineNumber}
                            placeholder="Engine Number"
                            id="engineNumber"
                            type="text"
                            name="engineNumber"
                            onChangeEvent={(e) => handleFormValue(e)}
                        />
                    </div>
                </div>

                <div className="input_group_pform">
                    <div className="inputGropOne_form inputGrop ">
                        <p className="customLabel_pform">
                            Car is on Loan?
                        </p>
                        <div className="radioContainer-pform">
                            <RadioButton
                                label="Yes"
                                className=""
                                id="carLoanYes"
                                groupName="carIsInLoan"
                                value="Yes"
                                changeEvent={(e) => handleFormValue(e)}
                            />
                            <RadioButton
                                label="No"
                                className=""
                                id="carLoanNo"
                                groupName="carIsInLoan"
                                value="No"
                                changeEvent={(e) => handleFormValue(e)}
                            />
                        </div>
                    </div>
                    <div className="inputGropOne_form inputGrop ">
                        {ClaimFormValues.carIsInLoan === "Yes" && (
                            <>
                                <p> &nbsp; </p>
                                <SearchSelect
                                    optionList={stateList}
                                    handleSelect={handleInsurerSelect}
                                    value={ClaimFormValues.policyInsurer}
                                    placeholder="Insurance Company"
                                    label="Insurance Company"
                                    name="policyInsurer"
                                />
                            </>)}
                    </div>
                </div>

                <section className="btnContainer_pform">
                    <Botton
                        text="Cancel"
                        className="button btn_pForm  secondry_btn"
                        event={(e) => console.log("documentDetails")}
                    />
                    <Botton
                        text="Save"
                        className="button btn_pForm "
                        event={(e) => toggleColapsible("documentDetails")}
                    />
                </section>

            </form>
        </>
    )
}

export default VehicleDetails
