import React, { useEffect, useState } from "react";
import activeRadio from "../../Assets/Images/radioActiveSvg.svg";
import radio from "../../Assets/Images/radioSvg.svg";
import tick from "../../Assets/Images/success.svg";
import arrow from "../../Assets/Images/arrow.svg";
import Botton from "../Button/Button";
import { useSelector, useDispatch } from "react-redux";
import { carDetailsforProposal } from "../../utils/commonUtils";
import SkeletonLoader from "../Pages/Loader/Skeleton loader/SkeletonLoader";
let TotalPackage = 0;
let seTplanType = [];
let planforDigit = [];

const DigitPackageModal = (props) => {
    const {
        packageData,
        setCarDetails,
        carDetails,
        closeModal,
        carDetailsCopyState,
        setcarDetailsCopyState,
        test,
        tataQuoteLoading,
    } = props;

    const getBodyData = useSelector((state) => {
        return state?.bodyData;
    });

    const DigitPlans = useSelector((state) => {
        return state?.digitQuickQuote;
    });

    const RSA = DigitPlans?.data?.data?.plans?.RSA;
    const Pro = DigitPlans?.data?.data?.plans?.Pro
    const DPro = DigitPlans?.data?.data?.plans?.["D Pro"];
    const DCpro = DigitPlans?.data?.data?.plans?.["DC Pro"];
    const DCEpro = DigitPlans?.data?.data?.plans?.["DCE Pro"];
    const DCT = DigitPlans?.data?.data?.plans?.["DCT Pro"];
    const DCET = DigitPlans?.data?.data?.plans?.["DCET Pro"];
    const DCRTI = DigitPlans?.data?.data?.plans?.["DC- RTI Pro"];
    const DCERTI = DigitPlans?.data?.data?.plans?.["DCE - RTI Pro"];
    const DCTRTI = DigitPlans?.data?.data?.plans?.["DCT - RTI Pro"];
    const DCETRTI = DigitPlans?.data?.data?.plans?.["DCET - RTI Pro"];
    let DigitQoute = []

    let ValuesandPlans = DCERTI?.data?.data?.quote?.plans;

    if (RSA?.["Motor-Quick Quote"]) {
        if (RSA?.["Motor-Quick Quote"]?.netPremium) {
            const vehicleBreakup = carDetailsforProposal("digit", RSA, getBodyData, test, "RSA");
            DigitQoute.push({ planName: "RSA", netPremium: vehicleBreakup?.DuePremium })
        }
    }
    if (Pro) {
        if (Pro && parseInt(Pro?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.keyAndLockProtect?.netPremium?.slice(4))) {
            const vehicleBreakup = carDetailsforProposal("digit", Pro, getBodyData, test, "Pro");
            DigitQoute.push({ planName: "Pro", netPremium: vehicleBreakup?.DuePremium })
        }
    }
    if (DPro) {
        if (DPro && parseInt(DPro?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.partsDepreciation?.netPremium?.slice(4)) && parseInt(DPro?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.partsDepreciation?.netPremium?.slice(4))) {
            const vehicleBreakup = carDetailsforProposal("digit", DPro, getBodyData, test, "DPro");
            DigitQoute.push({ planName: "D Pro", netPremium: vehicleBreakup?.DuePremium })
        }
    }
    if (DCpro) {
        if (DCpro && parseInt(DCpro?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.consumables?.netPremium?.slice(4)) && parseInt(DCpro?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.partsDepreciation?.netPremium?.slice(4))) {
            const vehicleBreakup = carDetailsforProposal("digit", DCpro, getBodyData, test, "DCpro");
            DigitQoute.push({ planName: "DC Pro", netPremium: vehicleBreakup?.DuePremium })
        }
    }
    if (DCEpro) {
        if (parseInt(DCEpro?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.engineProtection?.netPremium?.slice(4)) && parseInt(DCEpro?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.partsDepreciation?.netPremium?.slice(4))) {
            const vehicleBreakup = carDetailsforProposal("digit", DCEpro, getBodyData, test, 'DCEpro');
            DigitQoute.push({ planName: "DCE Pro", netPremium: vehicleBreakup?.DuePremium })
        }
    }
    if (DCT) {
        if (parseInt(DCT?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.tyreProtection?.netPremium?.slice(4) && parseInt(DCT?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.partsDepreciation?.netPremium?.slice(4)))) {
            const vehicleBreakup = carDetailsforProposal("digit", DCT, getBodyData, test, 'DCEpro');
            DigitQoute.push({ planName: "DCT Pro", netPremium: vehicleBreakup?.DuePremium })
        }
    }
    if (DCET) {
        if (parseInt(DCET?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.tyreProtection?.netPremium?.slice(4)) && parseInt(DCET?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.partsDepreciation?.netPremium?.slice(4)) && parseInt(DCET?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.engineProtection?.netPremium?.slice(4))) {
            const vehicleBreakup = carDetailsforProposal("digit", DCET, getBodyData, test, 'DCET');
            DigitQoute.push({ planName: "DCET Pro", netPremium: vehicleBreakup?.DuePremium })
        }
    }
    if (DCRTI) {
        if (parseInt(DCRTI?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.returnToInvoice?.netPremium?.slice(4)) && parseInt(DCRTI?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.partsDepreciation?.netPremium?.slice(4)) && parseInt(DCRTI?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.consumables?.netPremium?.slice(4))) {
            const vehicleBreakup = carDetailsforProposal("digit", DCRTI, getBodyData, test, 'DCRTI');
            DigitQoute.push({ planName: "DCRTI Pro", netPremium: vehicleBreakup?.DuePremium })
        }
    }
    if (DCERTI) {
        if (parseInt(DCERTI?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.returnToInvoice?.netPremium?.slice(4)) && parseInt(DCERTI?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.partsDepreciation?.netPremium?.slice(4)) && parseInt(DCERTI?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.engineProtection?.netPremium?.slice(4)) && parseInt(DCERTI?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.returnToInvoice?.netPremium?.slice(4))) {
            const vehicleBreakup = carDetailsforProposal("digit", DCERTI, getBodyData, test, 'DCERTI');
            DigitQoute.push({ planName: "DCERTI Pro", netPremium: vehicleBreakup?.DuePremium })
        }
    }
    if (DCTRTI) {
        if (parseInt(DCTRTI?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.returnToInvoice?.netPremium?.slice(4)) && parseInt(DCTRTI?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.partsDepreciation?.netPremium?.slice(4)) && parseInt(DCTRTI?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.tyreProtection?.netPremium?.slice(4))) {
            const vehicleBreakup = carDetailsforProposal("digit", DCTRTI, getBodyData, test, 'DCTRTI');
            DigitQoute.push({ planName: "DCTRTI Pro", netPremium: vehicleBreakup?.DuePremium })
        }
    }
    if (DCETRTI) {
        if (parseInt(DCETRTI?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.returnToInvoice?.netPremium?.slice(4)) && parseInt(DCETRTI?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.partsDepreciation?.netPremium?.slice(4)) && parseInt(DCETRTI?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.engineProtection?.netPremium?.slice(4)) && parseInt(DCETRTI?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.tyreProtection?.netPremium?.slice(4))) {
            const vehicleBreakup = carDetailsforProposal("digit", DCETRTI, getBodyData, test, 'DCETRTI');
            DigitQoute.push({ planName: "DCETRTI Pro", netPremium: vehicleBreakup?.DuePremium })
        }
    }

    const [ShowClaimList, setShowClaimList] = useState(false);
    const [planType, seTplanTypeDig] = useState([]);

    const handleDropdown = () => {
        if (ShowClaimList) {
            setShowClaimList(false);
        }
    };

    let packageDataa = DigitPlans?.data?.data?.plans
    const totalNetPremiums = {};

    let newObj = {};

    const planNames = {
        P1: "RSA",
        P2: "Pro",
        P3: "D Pro",
        P4: "DC Pro",
        P5: "DCE Pro",
        P6: "DCT Pro",
        P7: "DCET Pro",
        P8: "DC-RTI Pro",
        P9: "DCE-RTI Pro",
        P10: "DCT-RTI Pro",
        P11: "DCET-RTI Pro",
    };

    let TpVal = 0;

    if (test && test[0].checked) {
        let PACoverVal = parseInt(Pro?.data?.data?.quote?.["Motor-Quick Quote"]?.contract?.coverages?.personalAccident?.netPremium?.slice(4))
        TpVal += PACoverVal
    };

    if (test && test[8].checked) {
        let PACoverVal = parseInt(Pro?.data?.data?.quote?.["Motor-Quick Quote"]?.contract?.coverages?.unnamedPA?.unnamedPaidDriver?.netPremium?.slice(4))
        TpVal += PACoverVal
    };

    if (test && test[9].checked) {
        let PACoverVal = parseInt(Pro?.data?.data?.quote?.["Motor-Quick Quote"]?.contract?.coverages?.unnamedPA?.unnamedPax?.netPremium?.slice(4))
        TpVal += PACoverVal
    };


    const handleSelectedPlan = (planName) => {
        if (planName === carDetailsCopyState?.quotePackageNameDigitCopy) {
            setcarDetailsCopyState((prevItems) => ({
                ...prevItems,
                quotePackageNameDigitCopy: "",
            }));
        } else {
            setcarDetailsCopyState((prevItems) => ({
                ...prevItems,
                quotePackageNameDigitCopy: planName,
            }));
        }
    };

    const setPlanPackage = (planName) => {
        setCarDetails((prevItems) => ({
            ...prevItems,
            quotePackageNameDigit: carDetailsCopyState?.quotePackageNameDigitCopy,
        }));
        if (carDetailsCopyState?.quotePackageNameDigitCopy) {
            closeModal();
        }

    };

    const resetPlanPackage = () => {
        setcarDetailsCopyState((prevItems) => ({
            ...prevItems,
            quotePackageNameDigitCopy: carDetails?.quotePackageNameDigit,
        }));
        closeModal();
    };

    const planAddons = {
        "RSA": ["Road Side Assistance"],
        "Pro": [
            "Road Side Assistance",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],


        "D Pro": [
            "Zero Depreciation",
            "Road Side Assistance",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],
        "DC Pro": [
            "Zero Depreciation",
            "Key Replacement",
            "Consumable Expenses",
            "Loss Of Personal Belongings",
            "Road Side Assistance",
        ],
        "DCE Pro": [
            "Zero Depreciation",
            "Road Side Assistance",
            "Consumable Expenses",
            "Engine Secure",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],
        "DCT Pro": [
            "Zero Depreciation",
            "Road Side Assistance",
            "Consumable Expenses",
            "Tyre Protect",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],
        "DCET Pro": [
            "Zero Depreciation",
            "Road Side Assistance",
            "Consumable Expenses",
            "Tyre Protect",
            "Engine Secure",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],
        "DCRTI Pro": [
            "Zero Depreciation",
            "Return to Invoice",
            "Road Side Assistance",
            "Consumable Expenses",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],

        "DCERTI Pro": [
            "Zero Depreciation",
            "Road Side Assistance",
            "Consumable Expenses",
            "Engine Secure",
            "Return to Invoice",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],
        "DCTRTI Pro": [
            "Zero Depreciation",
            "Road Side Assistance",
            "Consumable Expenses",
            "Tyre Protect",
            "Return to Invoice",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],
        "DCETRTI Pro": [
            "Zero Depreciation",
            "Road Side Assistance",
            "Tyre Protect",
            "Return to Invoice",
            "Consumable Expenses",
            "Engine Secure",
            "Key Replacement",
            "Loss Of Personal Belongings",
        ],

    };

    const handleNumberOfClaim = (e) => {
        const { id, name, value } = e?.target || {};
        setCarDetails((prevItems) => ({
            ...prevItems,
            [name]: value,
        }))
    };

    let uniq = [...new Set(seTplanType)];

    const handleZeroDepValue = (selectedValue) => {
        setCarDetails((prevItems) => ({
            ...prevItems,
            isNilDepInPrevPolicy: selectedValue,
        }))
    };

    return (
        <>
            <div className="package-container packageModalHeight" onClick={handleDropdown} >
                <div className="head-addon-modal head-package-modal">
                    <div>
                        <p>Digit Plans</p>
                        <p style={{ color: "#5086FF", fontSize: ".8rem" }} >({DigitQoute?.length} Plans Available)</p>
                    </div>
                    {parseInt(DPro?.["Motor-Quick Quote"]?.contract?.coverages?.addons?.partsDepreciation?.netPremium?.slice(4)) ?
                        <div className="dp-main-container" >
                            <div className="dp-main-head" onClick={() => setShowClaimList(!ShowClaimList)} >
                                <p> {carDetails?.numberOfClaim === "99" ? "Unlimited" : carDetails?.numberOfClaim}  Claim</p>
                                <p>Apply on Zero Dep</p>
                                <img src={arrow} />
                            </div>
                            {
                                ShowClaimList && (
                                    <div className="dp-main-options">
                                        <button
                                            type="button"
                                            name="numberOfClaim"
                                            value="1"
                                            onClick={(e) => handleNumberOfClaim(e)} >
                                            1 Claim
                                        </button>
                                        <button
                                            type="button"
                                            name="numberOfClaim"
                                            value="2"
                                            onClick={(e) => handleNumberOfClaim(e)} >
                                            2 Claim
                                        </button>
                                        <button
                                            type="button"
                                            name="numberOfClaim"
                                            value="99"
                                            onClick={(e) => handleNumberOfClaim(e)} >
                                            Unlimited Claim
                                        </button>
                                    </div>
                                )
                            }
                        </div> : ""
                    }

                </div>
                <div className="package-container-modal" >
                    <div className="switch-container-modal">
                        <div className="textnilldeppervious">Zero Dep in your previous policy?  </div>
                        <div className="switchBtn-container" >
                            <button className={carDetails?.isNilDepInPrevPolicy == "true" ? "active-switchclass" : "Non-active-switch-class"} id="activeStwitchbtn" onClick={() => handleZeroDepValue("true")} >Yes</button>
                            <button className={carDetails?.isNilDepInPrevPolicy == "false" ? "active-switchclass" : "Non-active-switch-class"} id="noactiveStwitchbtn" onClick={() => handleZeroDepValue("false")} >No</button>
                        </div>
                    </div>
                    {DigitQoute?.map((planKey) => (
                        <div
                            className={
                                carDetailsCopyState?.quotePackageNameDigitCopy == planKey.planName
                                    ? "plan-package-container active-plan-package-container"
                                    : "plan-package-container"
                            }
                            key={planKey}
                        >
                            <div
                                className="plan-package-head"
                                onClick={() => handleSelectedPlan(planKey.planName)}
                            >
                                <div className="radio-container-package-popup">
                                    <img
                                        src={
                                            carDetailsCopyState?.quotePackageNameDigitCopy ==
                                                planKey.planName
                                                ? activeRadio
                                                : radio
                                        }
                                        alt="radio"
                                    />
                                    <p>{planKey.planName}</p>
                                </div>
                                <div className="radio-container-package-plan">
                                    {
                                        tataQuoteLoading ? (
                                            <SkeletonLoader height={30} />
                                        ) : (
                                            <>
                                                <p>₹ {parseInt((planKey.netPremium))}</p>
                                                <p>Inc GST</p>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            {carDetailsCopyState?.quotePackageNameDigitCopy ==
                                planKey.planName && (
                                    <div className="plan-breakup-package">
                                        {planAddons[planKey.planName]?.map((addon, index) => (
                                            <div className="addons-plan-tab" key={index}>
                                                <img src={tick} alt="tick" />
                                                <p>{addon}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                        </div>
                    ))}
                </div>
                <div className="packahe-modal-btn-container">
                    <Botton
                        text="Cancel"
                        className="secondry_btn btn-packageModals"
                        event={resetPlanPackage}
                    />
                    <Botton
                        text={!carDetailsCopyState?.quotePackageNameDigitCopy ? "Select Plan" : "Proceed"}
                        className={!carDetailsCopyState?.quotePackageNameDigitCopy ? "button btn-packageModals noSelectedPlan" : "button btn-packageModals"}
                        event={setPlanPackage}
                    />
                </div>
            </div>
        </>
    );
};

export default DigitPackageModal;
