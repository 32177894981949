import { createAsyncThunk } from "@reduxjs/toolkit";

export const ackoQuickQuoteAction = createAsyncThunk(
  "ackoQuikQuote",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ACKO_QUICK_QUOTE_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const ackoProposalAction = createAsyncThunk(
  "ackoProposal",
  async (bodyData) => {
    const response = await fetch(`${process.env.REACT_APP_ACKO_PROPOSAL_URL}`, {
      method: "POST",
      body: JSON.stringify(bodyData),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    return response.json();
  }
);

export const ackopdfDownloadAction = createAsyncThunk(
  "ackopdfDownload",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ACKO_PDF_DOWNLOAD_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const ackocheckPaymentStatusAction = createAsyncThunk(
  "ackocheckPaymentStatus",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ACKO_CHECK_PAYMENT_STATUS}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const ackoGetLeadsAction = createAsyncThunk(
  "ackoGetLeads",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_GET_ACKO_LEADS}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const ackoautoKycAction = createAsyncThunk(
  "ackoAutoKyc",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ACKO_AUTOKYC_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const ackoManualKycAction = createAsyncThunk(
  "ackoManualKyc",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ACKO_MANUALKYC_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const getPanDetailsByNo = createAsyncThunk(
  "getPanDetailsByNo",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ACKO_GET_PAN_DETAILS_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const getVehicleCashbackApiAction = createAsyncThunk(
  "getVehicleCashback",
  async (regNo) => {
    const response = await fetch(
      `${process.env.REACT_APP_GET_DISCOUNT_CB_URL}?vehicleNumber=${regNo}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  });