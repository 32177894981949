import React from "react";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import car from "../../../Assets/Images/tpCarIns.png";
import fullParts from "../../../Assets/Images/fullParts.svg";
import financialSec from "../../../Assets/Images/financialSec.svg";
import newCar from "../../../Assets/Images/newCar.svg";
import higherCoverage from "../../../Assets/Images/higherCoverage.svg";
import fairValue from "../../../Assets/Images/fairValue.svg";
import peace from "../../../Assets/Images/peaceOfMind.svg";
import Card from "../../Card/Card";
import mobile from "../../../Assets/Images/mobileContent.png";
import calculator from "../../../Assets/Images/calculator.png";
import claim from "../../../Assets/Images/claim.svg";
import emi from "../../../Assets/Images/emi.svg";
import cashless from "../../../Assets/Images/cashless.svg";
import assistance from "../../../Assets/Images/assistance.svg";
import policy from "../../../Assets/Images/policy.svg";
import refund from "../../../Assets/Images/refund.svg";
import tracking from "../../../Assets/Images/tracking.svg";
import standalone from "../../../Assets/Images/standalone.svg";
import tata from "../../../Assets/Partners_logo/TATA AIG.png";
import digit from "../../../Assets/Partners_logo/digit.svg";
import icici from "../../../Assets/Partners_logo/icici lombard.svg";
import hdfc from "../../../Assets/Partners_logo/hdfc.png";
import success from "../../../Assets/Images/success.svg";
import fail from "../../../Assets/Images/failure.svg";
import assesments from "../../../Assets/Images/research.svg";
import comparison from "../../../Assets/Images/coverageLimit.svg";
import rightPolicy from "../../../Assets/Images/onlinePayment.svg";
import carDetails from "../../../Assets/Images/carDetails.svg";
import Timeline from "../../InputFields/Timeline/Timeline";
import lady from "../../../Assets/Images/ladyTwo.png";
import Partners from "../../Partners/Partners";

const ZeroDepreciation = (props) => {
  const { firstLot, secondLot } = props;

  // useEffect(() => {
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  // }, []);

  let reasonsForCarIns = [
    {
      icon: fullParts,
      title: "Full Parts Replacement",
      description:
        "Zero Depreciation ensures that you receive the entire replacement cost of damaged car parts during claims, without considering depreciation.",
    },
    {
      icon: financialSec,
      title: "Financial Security",
      description:
        "Zero Depreciation coverage offers financial security by eliminating the cost of depreciation when replacing or repairing car parts after an accident.",
    },
    {
      icon: newCar,
      title: "New Car Benefits",
      description:
        "This insurance is particularly beneficial for brand-new cars, as depreciation can significantly impact the claim amount with standard coverage.",
    },
    {
      icon: higherCoverage,
      title: "Higher Coverage",
      description:
        "It offers a higher level of coverage, especially for newer cars, reducing your out-of-pocket expenses for repairs.",
    },
    {
      icon: fairValue,
      title: "Fair Value",
      description:
        "It ensures you get a fair value for your car's parts, maintaining your vehicle's overall worth.",
    },
    {
      icon: peace,
      title: "Enhanced Peace of Mind",
      description:
        "Knowing your vehicle is shielded from a range of risks provides added confidence and security while driving, enhancing your overall peace of mind.",
    },
  ];

  let planCards = [
    {
      id: 1,
      img: tata,
    },
    {
      id: 2,
      img: digit,
    },
    {
      id: 3,
      img: icici,
    },
    {
      id: 4,
      img: hdfc,
    },
  ];

  let timelineElements = [
    {
      id: 1,
      icon: assesments,
      title: "Research and Compare",
      description:
        "Research insurers, gather information, and compare quotes from different companies.",
    },
    {
      id: 2,
      icon: comparison,
      title: "Choose Coverage Limits",
      description:
        "Decide on the coverage limits for injuries and property damage.",
    },
    {
      id: 3,
      icon: carDetails,
      title: "Enter Car Details",
      description:
        "Provide your car's registration number and other essential information.",
    },
    {
      id: 4,
      icon: rightPolicy,
      title: "Make Online Payment",
      description:
        "Make your premium payment securely online using various methods, including debit/credit cards, internet banking, and digital wallets.",
    },
  ];

  let checklist = [
    "Personal information (age, gender).",
    "Driving history (accidents, violations).",
    "Vehicle details (make, model).",
    "Usage (frequency, purpose).",
    "Location (ZIP code).",
    "Coverage choices.",
    "Eligible discounts.",
  ];

  return (
    <>
      <Navbar />

      {/* HERO */}

      <div className="hero-section-product hero-section-product-tp">
        <img
          src={car}
          className="product-ins-hero-img car-product"
          alt=""
          srcset=""
        />
        <div className="hero-section-product-parent maxWidth">
          <div className="hero-content-product-ins">
            <h1 className="title-product-ins">
              Zero Depreciation <span>Car Insurance</span>
            </h1>
            <p>
              Zero Depreciation car insurance is an add-on that covers the full
              replacement cost of damaged car parts during claim settlements,
              eliminating depreciation deductions and providing enhanced
              financial protection.
            </p>
          </div>
        </div>
      </div>

      {/* WHY CAR INSURANCE */}

      <div className="content-container-prod-insurance">
        <div className="content-container-prod-insurance-parent maxWidth">
          <h1 className="title-product-ins">
            Why Third Party Car Insurance <span>is Important ?</span>
          </h1>
          <div className="list-container-prod-ins">
            <div className="list-container-prod-left list-container-prod-block">
              {reasonsForCarIns.map(
                (data, key) =>
                  key < 3 && (
                    <div key={key} className="list-rectangle">
                      <div className="list-rectangle-icon-square">
                        <img src={data.icon} alt="" srcset="" />
                      </div>
                      <div className="list-rectangle-content">
                        <p>{data.title}</p>
                        <p>{data.description}</p>
                      </div>
                    </div>
                  )
              )}
            </div>
            <div className="list-container-prod-right list-container-prod-block">
              {reasonsForCarIns.map(
                (data, key) =>
                  key >= 3 && (
                    <div key={key} className="list-rectangle">
                      <div className="list-rectangle-icon-square">
                        <img src={data.icon} alt="" srcset="" />
                      </div>
                      <div className="list-rectangle-content">
                        <p>{data.title}</p>
                        <p>{data.description}</p>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </div>

      {/* WHY to buy from us */}

      <div className="content-container-prod-insurance  explanation-container-prod-insurance  ">
        <div className="content-container-prod-insurance-parent maxWidth">
          <div className="mobile-container-with-content">
            <div className="mob-container-prod-pg">
              <div className="circle-gradient-prod-pg">
                <img src={mobile} alt="" srcset="" />
              </div>
            </div>
            <div className="content-container-prod-pg">
              <div className="hero-content-product-ins w2b-content-product-ins">
                <h1 className="title-product-ins">
                  Why buy <span> from us? </span>
                </h1>
                <p>
                  When you choose to buy insurance from us, you're selecting a
                  trusted partner dedicated to safeguarding what matters most to
                  you.
                </p>
                <p>
                  Our competitive pricing, unwavering financial stability, and
                  exceptional customer service make us the reliable choice for
                  your insurance needs. With a reputation built on trust and a
                  commitment to your peace of mind, we're here to provide the
                  security and support you deserve. Choose us for insurance that
                  truly cares about your well-being.
                </p>
              </div>
            </div>
          </div>
          <div className="card_div_container  card_div_container-prod-pg">
            <div className="card_container_child">
              <Card
                icons={claim}
                className="card_landing"
                classNameTwo="cardLanding_content"
                classNameThree="icon_container_landing"
                title="100% Claims"
                subtitle="Claim Guarantee"
                hoverContent="in insurance ensures that the policyholder will receive full compensation for covered losses without any deductions or exceptions."
              />
              <Card
                icons={emi}
                className="card_landing"
                classNameTwo="cardLanding_content"
                classNameThree="icon_container_landing"
                title="EMI Available"
                subtitle="EMI option available on Credit Cards"
              />
              <Card
                icons={cashless}
                className="card_landing"
                classNameTwo="cardLanding_content"
                classNameThree="icon_container_landing"
                title="100% Cashless"
                subtitle="Cashless Policies"
              />
              <Card
                icons={assistance}
                className="card_landing"
                classNameTwo="cardLanding_content"
                classNameThree="icon_container_landing"
                title="Claim Assistance"
                subtitle="Free Claim assistance for all Insurance"
              />
            </div>
            <div className="card_container_child">
              <Card
                icons={policy}
                className="card_landing"
                classNameTwo="cardLanding_content"
                classNameThree="icon_container_landing"
                title="Policy Correction"
                subtitle="Free Online Policy Correction"
              />
              <Card
                icons={refund}
                className="card_landing"
                classNameTwo="cardLanding_content"
                classNameThree="icon_container_landing"
                title="100% Refund"
                subtitle="Free Policy Cancellation 100% Refund"
              />
              <Card
                icons={tracking}
                className="card_landing"
                classNameTwo="cardLanding_content"
                classNameThree="icon_container_landing"
                title="Live Tracking"
                subtitle="Free Claims Live Tracking"
              />
              <Card
                icons={standalone}
                className="card_landing"
                classNameTwo="cardLanding_content"
                classNameThree="icon_container_landing"
                title="Stand Alone"
                subtitle="Stand Alone Products"
              />
            </div>
          </div>
        </div>
      </div>

      {/* comparision ------------------- yet to code */}

      {/* Top Car Insurance Plans */}

      <div className="content-container-prod-insurance">
        <div className="content-container-prod-insurance-parent maxWidth">
          <h1 className="title-product-ins">
            Top Car Insurance Plans Available on <span>BookMyInsurance</span>
          </h1>
          <div className="cards-container-product-ins">
            {planCards.map((data, key) => (
              <div key={key} className="card-prod-ins">
                <div className="ins-logo-container">
                  <img src={data.img} alt="" srcset="" />
                </div>
                <div className="breakup_container breakup_container-dummy ">
                  <div className="planHeadValueContainer">
                    <div className="planHead">
                      <p>
                        Basic Price <span>₹ 11,222.41</span>
                      </p>
                    </div>
                    <div className="result_pc">
                      <img src={success} alt="" srcset="" />
                    </div>
                  </div>
                  <div className="planHeadValueContainer">
                    <div className="planHead">
                      <p>Personal Accident Cover</p>
                    </div>
                    <div className="result_pc">
                      <img src={success} alt="" srcset="" />
                    </div>
                  </div>
                  <div className="planHeadValueContainer">
                    <div className="planHead">
                      <p>Zero Depreciation</p>
                    </div>
                    <div className="result_pc">
                      <img src={success} alt="" srcset="" />
                    </div>
                  </div>
                  <div className="planHeadValueContainer">
                    <div className="planHead">
                      <p>Engine Protector</p>
                    </div>
                    <div className="result_pc">
                      <img src={success} alt="" srcset="" />
                    </div>
                  </div>
                  <div className="planHeadValueContainer">
                    <div className="planHead">
                      <p>Consumable</p>
                    </div>
                    <div className="result_pc">
                      <img src={success} alt="" srcset="" />
                    </div>
                  </div>
                  <div className="planHeadValueContainer">
                    <div className="planHead">
                      <p>Key and Lock Replacement</p>
                    </div>
                    <div className="result_pc">
                      <img src={fail} alt="" srcset="" />
                    </div>
                  </div>
                  <div className="planHeadValueContainer">
                    <div className="planHead">
                      <p>Invoice Cover</p>
                    </div>
                    <div className="result_pc">
                      <img src={success} alt="" srcset="" />
                    </div>
                  </div>
                  <div className="planHeadValueContainer">
                    <div className="planHead">
                      <p>24x7 Roadside Assistance</p>
                    </div>
                    <div className="result_pc">
                      <img src={success} alt="" srcset="" />
                    </div>
                  </div>
                </div>
                <div className="bottom-card-btn">
                  <p>Get a Quote</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* HOW TO BUY CAR INSURANCE */}

      <div className="content-container-prod-insurance comparison-container-prod-insurance">
        <div className="content-container-prod-insurance-parent maxWidth">
          <h1 className="title-product-ins">
            How to buy Zero Depreciation Car Insurance <span>from us</span>
          </h1>

          <div className="timeline-container-and-lady">
            <div className="lady-container-prod-pg">
              <div className="lady-box-prod-pg">
                <img src={lady} alt="" srcset="" />
              </div>
            </div>
            <div className="timeline-container-prod-pg">
              <Timeline timelineElements={timelineElements} />
            </div>
          </div>
        </div>
      </div>

      {/* CALCULATE PREMIUM  */}

      <div className="content-container-prod-insurance prem-calc-container-prod-insurance">
        <div className="content-container-prod-insurance-parent maxWidth">
          <div className="mobile-container-with-content mobile-container-with-content-two ">
            <div className="content-container-prod-pg content-container-prod-pg-two ">
              <div className="hero-content-product-ins w2b-content-product-ins">
                <h1 className="title-product-ins">
                  How to calculate <span> Car Insurance Premium </span>
                </h1>
                <p className="bold-description-calc">
                  Car insurance companies use online calculators to estimate
                  premium amounts based on various factors.
                </p>
                <p>
                  You provide personal details, driving history, vehicle
                  information, usage patterns, and location. <br />
                  Then, you choose coverage options and input applicable
                  discounts. The calculator processes this data through an
                  algorithm to generate an estimated premium quote. <br />
                  It's essential to remember that this quote serves as an
                  estimate, and the final premium may vary depending on further
                  underwriting and insurer-specific factors. Comparing quotes
                  from multiple insurers helps you find the most suitable and
                  cost-effective coverage.
                </p>
                <p className="bold-description-calc">
                  Car insurance premiums are estimated using online calculators,
                  considering:
                </p>
                <div className="checklist-calc-container-parent">
                  {checklist.map((text, key) => (
                    <div key={key} className="checklist-calc-container">
                      <img src={success} alt="" srcset="" />
                      <p>{text}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="mob-container-prod-pg">
              <div className="calc-gradient-prod-pg">
                <img src={calculator} alt="" srcset="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* LIST- COVERED & NOT_COVERED */}

      <div className="content-container-prod-insurance comparison-container-prod-insurance">
        <div className="content-container-prod-insurance-parent covered-container-prod-insurance-parent maxWidth">
          <div className="covered-container-compinspolicy">
            <h1 className="title-product-ins">
              What is Covered in <span>Zero Depreciation Car Insurance</span>
            </h1>
            <div className="covered-rect-comp-ins">
              <div className="covered-point-comp-ins">
                <div className="p-tag-container-compins">
                  <div className="points-comp-ins">
                    <div className="success-icon">
                      <img src={success} alt="" srcset="" />
                    </div>
                    <p className="point-title-comp-ins">
                      Full Claim for Car Parts&nbsp;
                      <span>
                        Zero Depreciation coverage ensures that you receive the
                        full replacement cost of damaged car parts during claims
                        settlement, without any deduction for depreciation.
                      </span>
                    </p>
                  </div>
                  <div className="points-comp-ins">
                    <div className="success-icon">
                      <img src={success} alt="" srcset="" />
                    </div>
                    <p className="point-title-comp-ins">
                      Plastic, Rubber, and Fibre Parts: &nbsp;
                      <span>
                        Protection against the theft of your vehicle or any
                        parts and accessories stolen from it.
                      </span>
                    </p>
                  </div>
                  <div className="points-comp-ins">
                    <div className="success-icon">
                      <img src={success} alt="" srcset="" />
                    </div>
                    <p className="point-title-comp-ins">
                      Glass Parts:&nbsp;
                      <span>
                        Zero Depreciation usually covers glass parts like the
                        windshield, windows, and mirrors, offering 100%
                        reimbursement for their replacement.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="covered-container-compinspolicy">
            <h1 className="title-product-ins">
              What is Not covered in{" "}
              <span>Zero Depreciation Car Insurance</span>
            </h1>
            <div className="covered-rect-comp-ins">
              <div className="covered-point-comp-ins">
                <div className="p-tag-container-compins">
                  <div className="points-comp-ins">
                    <div className="success-icon">
                      <img src={success} alt="" srcset="" />
                    </div>
                    <p className="point-title-comp-ins">
                      Regular Wear and Tear:&nbsp;
                      <span>
                        Expenses related to the natural wear and tear of car
                        parts due to age and usage are not covered..
                      </span>
                    </p>
                  </div>
                  <div className="points-comp-ins">
                    <div className="success-icon">
                      <img src={success} alt="" srcset="" />
                    </div>
                    <p className="point-title-comp-ins">
                      Mechanical Breakdowns: &nbsp;
                      <span>
                        Repairs or replacements due to mechanical failures
                        unrelated to accidents or covered events are typically
                        excluded.
                      </span>
                    </p>
                  </div>
                  <div className="points-comp-ins">
                    <div className="success-icon">
                      <img src={success} alt="" srcset="" />
                    </div>
                    <p className="point-title-comp-ins">
                      Non-Factory-Fitted Accessories:&nbsp;
                      <span>
                        Unless specified in the policy, aftermarket or
                        non-factory-fitted accessories and modifications may not
                        be covered.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* partners section */}

      <div className="content-container-prod-insurance ">
        <div className="content-container-prod-insurance-parent maxWidth">
          <h1 className="title-product-ins">
            Top Car Insurance Companies in <span> India</span>
          </h1>
          <div className="partner-container-prod-pg">
            <Partners
              firstLot={firstLot}
              secondLot={secondLot}
              hideTitle={true}
              noPadding="noPadding"
              component="thirdPartyIns"
            />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ZeroDepreciation;
