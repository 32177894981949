import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { HashRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById("root"));
if (!navigator.onLine) {
  { alert("You are offline. Please check your internet connection.") }
}
root.render(
  <Provider store={Store}>
    <PersistGate persistor={persistor}>
      <HashRouter>
        <App />
      </HashRouter>
    </PersistGate>
  </Provider>
);
reportWebVitals();
