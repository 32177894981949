import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import policyRenewal from "../../../Assets/Images/policyRenewal.svg";
import policyClaim from "../../../Assets/Images/policyClaim.svg";
import policyCorrection from "../../../Assets/Images/policyCorrection.svg";
import "./Login.css";
import Footer from "../../Footer/Footer";
import InputFields from "../../InputFields/InputFields";
import Botton from "../../Button/Button";
import OtpInput from "react-otp-input";
import Navbar from "../../Navbar/Navbar";
import { isLoggedInAction } from "../../../redux/Reducers/isLoginAuthentication";
import {
  Routes,
  Route,
  useNavigate,
  Link,
  useLocation,
} from "react-router-dom";
import loginVector from "../../../Assets/Images/loginVector.svg"
import otpIllustration from "../../../Assets/Images/otpIllustration.svg"
import line from "../../../Assets/Images/line_Login.svg"
import docLogin from "../../../Assets/Images/docLogin.svg"
import docLoginTwo from "../../../Assets/Images/docLoginTwo.svg"
import docLoginThree from "../../../Assets/Images/docLoginThree.svg"
import carTwo from "../../../Assets/Images/carTwo.svg"
import { loginAction } from "../../../redux/Actions/LoginAction";
import { ToastContainer, toast } from "react-toastify";
import ButtonLoader from "../Loader/Button Loader/ButtonLoader";


const Login = () => {
  const [ShowOtpField, setShowOtpField] = useState(false);
  const [otp, setOtp] = useState("");
  const [IsLoggedIn, setIsLoggedIn] = useState(false);
  const [inputId, setInputId] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");


  const dispatch = useDispatch();

  // navigation
  const navigate = useNavigate();

  // retriving data
  const { state } = useLocation();

  // const verifyOTP = () => {
  //   setIsLoggedIn(true);
  //   navigate("/My-Account")
  //   dispatch(isLoggedInAction({ isLoggedIn: true }))

  //   // TO BLOCK THE FLOW MY ACCOUNT UNCOMMENT THIS ->
  //   navigate("/");
  // };
  // useEffect(() => {
  //   dispatch(
  //     isLoggedInAction({
  //       isLoggedIn: false,
  //       loginData: null,
  //     })
  //   );
  // }, []);

  const validateMobile = () => {
    let newErrors = {};
    let regexMobile = /^[2-9]{1}[0-9]{9}$/;
    let regexOtp = /^[1-9]{1}[0-9]{5}$/;
    if (!ShowOtpField) {
      if (!inputId) {
        newErrors.phoneNumber = "Phone Number is required";
      }
      if (inputId && !regexMobile.test(inputId)) {
        newErrors.phoneNumber = "Mobile number is Invalid";
      }
    } else {
      if (!otp) {
        newErrors.otp = "Otp is required";
      }
      if (otp && !regexOtp.test(otp)) {
        newErrors.otp = "OTP is Invalid";
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;

  }
  const sendOtp = async () => {
    const isValidMobile = validateMobile();
    if (isValidMobile) {
      setLoading(true);
      let response = await dispatch(
        loginAction({
          data: {
            mobile_no: inputId,
          },
        })
      );
      if (response?.payload?.status) {
        setLoading(false);
        toast(
          response?.payload?.msg
            ? response?.payload?.msg
            : "Something went Wrong, try after sometime!",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setShowOtpField(true);
      } else {
        setLoading(false);
        toast.error(
          response?.payload?.msg
            ? response?.payload?.msg
            : "Something went Wrong, try after sometime!",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    }
  };

  const verifyOTP = async () => {
    //call API for verifyOTp

    const isValidOtp = validateMobile();
    if (isValidOtp) {
      setLoading(true);
      // setIsLoggedIn(true);
     
      //call verify otp API
      let response = await dispatch(
        loginAction({
          data: {
            mobile_no: inputId,
            otp: otp,
          },
        })
      );
      if (response?.payload?.status) {
        setLoading(false);
        toast("Logged In !", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsLoggedIn(true);
        dispatch(
          isLoggedInAction({
            isLoggedIn: true,
            loginData: response?.payload?.data,
          })
        );
         navigate("/My-Account");
      } else {
        setLoading(false);
        toast.error(
          response?.payload?.msg
            ? response?.payload?.msg
            : "Something went Wrong, try after sometime!",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    }
  };

  const handlePhoneNumber = (e) => {
    delete errors?.phoneNumber
    const { id, value, name } = e?.target || {};
    if (value.length <= 10) {
      setInputId(value);
    }
  };
  useEffect(() => {
    if (loading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [loading]);
  return (
    <>
      <ToastContainer />
      <Navbar IsLoggedIn={IsLoggedIn} />
      <section className={ShowOtpField ? "loginContainer loginContainer-height" : "loginContainer"}>
        <div className="line-container-logo">
          <img src={line} alt="" srcset="" />
          <div className="block-one-login block-login">
            <img src={docLogin} className="docLogin-one" alt="" srcset="" />
          </div>
          <div className="block-two-login block-login">
            <img src={carTwo} className="docLogin-one" alt="" srcset="" />
          </div>
          <div className="block-three-login block-login">
            <img src={docLoginTwo} className="docLogin-one" alt="" srcset="" />
          </div>
          <div className="block-four-login block-login">
            <img src={docLoginThree} className="docLogin-one" alt="" srcset="" />
          </div>
        </div>
        <div className="login-illustration-container">
          <img src={ShowOtpField ? otpIllustration : loginVector} className="vector-login" alt="" srcset="" />
        </div>
        <div className="loginParentContainer">
          <div className="login-child login-child-content ">
            <p className="login-child-head login-child-head-hide">
              Securely Manage <br /> Your Insurance Policies.
            </p>
            <p className="login-child-text login-child-text-margin ">
              Manage all your insurance policies at one place. We can help you
              stay organized and ensure you have a clear overview of your
              coverage.
            </p>
            <div className={!ShowOtpField ? "feature_container-login" : "feature_container-login hideInMob-login"}>
              <div className="content-login-left">
                <img src={policyRenewal} alt="" srcSet="" />
                <p>
                  Policy <br /> Renewal
                </p>
              </div>
              <div className="content-login-left">
                <img src={policyClaim} alt="" srcSet="" />
                <p>
                  Policy <br /> Claims
                </p>
              </div>
              <div className="content-login-left">
                <img src={policyCorrection} alt="" srcSet="" />
                <p>
                  Policy <br /> Correction
                </p>
              </div>
            </div>
          </div>
          {
            !ShowOtpField && (
              <>
                <div className="login-child login-child-form ">
                  <div className="form-login-head">
                    <p className="login-child-head">Login</p>
                    <p className="login-child-text login-child-text-two ">
                      Please enter mobile number.
                    </p>
                  </div>
                  <InputFields
                    placeholder="My Mobile Number"
                    id="loginInputs"
                    type="number"
                    name="userInputs"
                    value={inputId}
                    errState={errors?.phoneNumber ? true : false}
                    errMsg={errors?.phoneNumber}
                    onChangeEvent={(e) => handlePhoneNumber(e)}
                  />{!loading ? <Botton
                    text="Send OTP"
                    className="button login-btn "
                    // event={(e) => setShowOtpField(true)}
                    event={(e) => sendOtp()}
                  />
                    : <ButtonLoader />

                  }

                  <p className="login-child-text login-child-text-two ">
                    By clicking on Send OTP, I agree to &nbsp;
                    <span className="footer_hyper_link footer_hyper_link-login underline_text">
                      Terms & Conditions
                    </span>
                  </p>
                </div>
              </>
            )
          }

          {
            ShowOtpField && (
              <div className="login-child login-child-form ">
                <div className="form-login-head">
                  <p className="login-child-head">OTP Verification</p>
                  <p className="login-child-text login-child-text-two ">
                    Please enter OTP sent on &nbsp;
                    <span style={{ color: "var(--primary)" }}>{inputId}</span>
                  </p>
                </div>

                <div className="otpInput-container">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                {errors.otp ? <p className="err-msg">{errors.otp}</p> : ""}
                {!loading ? <Botton
                  text="Verify OTP"
                  className="button login-btn "
                  event={(e) => verifyOTP()}
                /> : <ButtonLoader />

                }


                <div className="footer-login-text">
                  <p className="login-child-text login-child-text-two login-child-text-three ">
                    Don’t get OTP?
                    <span className="footer_hyper_link footer_hyper_link-login underline_text">
                      Resend now.
                    </span>
                  </p>

                  <span style={{ color: "var(--primary)", fontSize: ".75rem" }}>
                    Entered wrong number?
                  </span>
                </div>
              </div>
            )
          }
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Login;
