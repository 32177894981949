import {
    tataAutoKycAction, 
    tataProposalAction, 
    tataManualKycAction, 
    tataQuickQuoteAction,
    tataQuickQuoteActionWithAddOn,
    tataOnlinePaymentAction, 
    tataVerifyPaymentAction, 
    tataPolicyDocumentAction, 
    tataOtpVerifyAction
} from "../Actions/tataApiAction";

import { createSlice } from "@reduxjs/toolkit";

export const tataQuickQuoteReducer = createSlice({
    name: "tataQuickQuote",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(tataQuickQuoteAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(tataQuickQuoteAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(tataQuickQuoteAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resettataQuickQuoteAction: (state, action) => {
            state.data = undefined
        }
    }
});

export const tataQuickQuoteReducerwithAddon = createSlice({
    name: "tataQuickQuoteAddon",
    initialState: {
      isLoading: false,
      data: null,
      isError: false,
    },
    extraReducers: (builder) => {
      builder.addCase(tataQuickQuoteActionWithAddOn.pending, (state, action) => {
        state.isLoading = true;
      });
      builder.addCase(tataQuickQuoteActionWithAddOn.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
      builder.addCase(tataQuickQuoteActionWithAddOn.rejected, (state, action) => {
        console.error("Error", action.payload);
        state.isError = true;
        state.isLoading = false;
      });
    },
    reducers: {
        resettataQuickQuoteAction: (state, action) => {
        state.data = undefined
      }
    }
  });

export const { resettataQuickQuoteAction } = tataQuickQuoteReducer.actions;

export const tataProposalReducer = createSlice({
    name: "tataProposal",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(tataProposalAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(tataProposalAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(tataProposalAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resettataProposalAction: (state, action) => {
            state.data = undefined
        }
    }
});

export const { resettataProposalAction } = tataProposalReducer.actions;

export const tataAutoKycReducer = createSlice({
    name: "tataAutoKyc",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(tataAutoKycAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(tataAutoKycAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(tataAutoKycAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resettataAutoKycAction: (state, action) => {
            state.data = undefined
        }
    }
});

export const { resettataAutoKycAction } = tataAutoKycReducer.actions;

export const tataManualKycReducer = createSlice({
    name: "tataManualKyc",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(tataManualKycAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(tataManualKycAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(tataManualKycAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resettataManualKycAction: (state, action) => {
            state.data = undefined
        }
    }
});

export const { resettataManualKycAction } = tataManualKycReducer.actions;

export const tataOnlinePaymentReducer = createSlice({
    name: "tataOnlinePayment",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(tataOnlinePaymentAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(tataOnlinePaymentAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(tataOnlinePaymentAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resettataOnlinePaymentAction: (state, action) => {
            state.data = undefined
        }
    }
});

export const { resettataOnlinePaymentAction } = tataOnlinePaymentReducer.actions;

export const tataVerifyPaymentReducer = createSlice({
    name: "tataVerifyPayment",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(tataVerifyPaymentAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(tataVerifyPaymentAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(tataVerifyPaymentAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resettataVerifyPaymentAction: (state, action) => {
            state.data = undefined
        }
    }
});

export const { resettataVerifyPaymentAction } = tataVerifyPaymentReducer.actions;

export const tataPolicyDocumentReducer = createSlice({
    name: "tataPolicyDocument",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(tataPolicyDocumentAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(tataPolicyDocumentAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(tataPolicyDocumentAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resettataPolicyDocumentAction: (state, action) => {
            state.data = undefined
        }
    }
});

export const { resettataPolicyDocumentAction } = tataPolicyDocumentReducer.actions;

export const tataOtpVerifyReducer = createSlice({
    name: "tataOtpVerify",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(tataOtpVerifyAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(tataOtpVerifyAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(tataOtpVerifyAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resettataOtpVerifyAction: (state, action) => {
            state.data = undefined
        }
    }
});

export const { resettataOtpVerifyAction } = tataOtpVerifyReducer.actions;