import React from 'react'
import Botton from '../Button/Button';

const Tab = (props) => {

    const { img, content, event, gradImg } = props;

    return (
        <div className='ui-tab-container' >
            <div className="img-container-uiTab">
                <img src={gradImg} />
                <img src={img} />
            </div>
            <p> {content} </p>
            <Botton text="Read Now" className="button tab-btn " event={(e) => console.log("readNow btn clicked")} />
        </div>
    )
}

export default Tab
