import React, { useState } from "react";
import "./CheckBox.css";
import AddRemoveBtn from "./AddRemoveBtn";
import tick from "../../Assets/Images/success.svg"

const CheckBox = (props) => {
  const {
    id,
    name,
    title,
    label,
    value,
    idForP,
    checked,
    className,
    changeEvent,
    classNameTwo,
    idForDropdown,
    isHidden,
    tooltip,
  } = props;

  return (
    <>
      {
        !isHidden ? (
          <div >
            <label
              id={idForDropdown}
              className={classNameTwo ? classNameTwo : "container"}
            >
              <input
                type="checkbox"
                checked={checked}
                value={value}
                onChange={(e) => changeEvent(e)}
                name={name}
                id={id}
                title={title}
              />
              <div className="checkmark"></div>
              <p id={idForP} className={className}>
                {label}
              </p>
            </label>
          </div>
        ) : (
          <>
            <div className="flex-label" >
              <p id={idForP} className={className}>
                {label}
              </p>
              <label
                id={idForDropdown}
                className={classNameTwo ? classNameTwo : "container"}
              >
                <input
                  type="checkbox"
                  checked={checked}
                  value={value}
                  onChange={(e) => changeEvent(e)}
                  name={name}
                  id={id}
                  title={title}
                />
                <AddRemoveBtn isChecked={checked} id={id} onChange={changeEvent} icon={tick} />
              </label>
            </div>
          </>
        )
      }
    </>
  );
};

export default CheckBox;
